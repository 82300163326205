import React from 'react';
import { useModalContext } from 'contexts/ModalContext';
import { useTranslation } from 'react-i18next';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { useOrdersContext } from 'contexts/OrdersContext';

import styles from './ConfirmManualRefundModal.module.scss';

interface ConfirmManualRefundModalProps {
  refundAmount: number;
  orderId: string;
}
export const ConfirmManualRefundModal = ({
  refundAmount,
  orderId,
}: ConfirmManualRefundModalProps) => {
  const { t } = useTranslation();
  const { close } = useModalContext();
  const { processManualRefund } = useOrdersContext();
  return (
    <div className={styles['confirm-refund-modal']}>
      <Heading tag="h4">
        {t('orders.confirmManualRefundModal.heading', {
          amount: refundAmount.toFixed(2),
        })}
      </Heading>
      <Text>{t('orders.confirmManualRefundModal.info')}</Text>
      <div className={styles['confirm-refund-modal__buttons']}>
        <Button
          variant="accent"
          appearance="flat"
          label={t('orders.confirmManualRefundModal.backButton')}
          onClick={() => {
            close();
          }}
        />
        <Button
          label={t('orders.confirmManualRefundModal.confirmButton')}
          onClick={() => {
            processManualRefund(orderId);
            close();
          }}
        />
      </div>
    </div>
  );
};
