import { Client } from 'api/utils';
import { OrderLine, PomStatus } from 'models/Order';

export const updateOrderLineItem = async (
  orderId: number,
  orderlineId: number,
  itemId: number,
): Promise<OrderLine> => {
  const response = await Client.put(
    `/Orders/${orderId}/orderLines/${orderlineId}`,
    {
      medicinalProductId: itemId,
    },
  );
  return response.data;
};

export const updateOrderLinePomStatus = async (
  orderId: number,
  orderlineId: number,
  pomStatus: PomStatus,
  newQuantity: number | null,
): Promise<void> => {
  return await Client.put(
    `/Orders/${orderId}/orderLines/${orderlineId}/updatePomStatus`,
    {
      pomStatus,
      newQuantity: newQuantity,
    },
  );
};
