import React from 'react';
import { useCheckboxState, Checkbox } from 'reakit/Checkbox';
import cx from 'classnames';
import { Label } from 'components/common/Label';
import styles from './CheckBox.module.scss';

interface CheckBoxProps {
  className?: string;
  disabled?: boolean;
  label: string;
  onChange?: (value: boolean) => void;
  orientation?: 'horizontal' | 'vertical';
  size?: 'sm' | 'md' | 'lg';
  slim?: boolean;
  testId?: string;
  value?: boolean;
  variant?: 'ui' | 'accent';
}

const CheckBoxRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  CheckBoxProps
> = (
  {
    className,
    disabled = false,
    label,
    onChange,
    size = 'md',
    testId,
    value = true,
    variant = 'accent',
    slim = false,
  },
  ref,
) => {
  const checkbox = useCheckboxState({ state: value });

  React.useEffect(() => {
    checkbox.setState(value);
  }, [value]);

  const classes = cx(`${styles['input__checkbox-container']}`, {
    [`${styles[`input__checkbox-container--${variant}`]}`]: variant,
    [`${styles[`input__checkbox-container--${size}`]}`]: size,
    [`${styles['disabled']}`]: disabled,
    [`${className}`]: className,
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.checked);
  };

  return (
    <div className={classes} data-testid={testId}>
      <Label
        testId={testId ? `${testId}-label` : undefined}
        className={cx(
          styles['input__checkbox-label'],
          styles[`input__checkbox-label--${size}`],
          {
            [styles[`input__checkbox-label--slim`]]: slim,
          },
        )}
      >
        <Checkbox
          data-testid={testId ? `${testId}-checkbox` : undefined}
          {...checkbox}
          onChange={handleOnChange}
          className={styles['input__checkbox']}
          ref={ref}
        />
        <span
          className={styles['input__checkbox-indicator']}
          data-testid={testId ? `${testId}-indicator` : undefined}
        />
        <span className={styles['input__checkbox-text']}>{label}</span>
        <span className={styles['input__faux-background']}></span>
      </Label>
    </div>
  );
};

export const CheckBox = React.forwardRef(CheckBoxRef);
