import { Client } from 'api/utils';
import { DashboardStats } from 'models/DashboardStats';

export const getDashboardStats = async (
  pharmacyId?: string,
): Promise<DashboardStats> => {
  const queryParam = pharmacyId ? `?pharmacyId=${pharmacyId}` : '';
  const response = await Client.get(`Orders/dashboard${queryParam}`);
  return response.data;
};
