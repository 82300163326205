import React from 'react';
import { Button } from 'components/common/Button';

interface ActionButtonProps {
  testId?: string;
  className?: string;
  action: Action;
}

export interface Action {
  label: string;
  link?: { to: string; target?: string; rel?: string };
  actionFn?: Function;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  testId,
  className,
  action,
}) => {
  const { actionFn, label, link } = action;
  return (
    <Button
      testId={testId}
      className={className}
      label={label}
      link={link ? link : undefined}
      onClick={
        actionFn
          ? () => {
              actionFn();
            }
          : undefined
      }
      size="sm"
      variant="accent"
      appearance="solid"
    />
  );
};
