import React from 'react';
import { useHistory } from 'react-router-dom';
import { useApiError } from 'hooks/useApiError';
import { useModalContext } from 'contexts/ModalContext';
import {
  PrescriptionGroupItem,
  PrescriptionItem,
} from 'models/Prescriptions/Prescriptions';
import {
  MedicinalProductGroup,
  MedicinalProductGroupFull,
} from 'models/MedicinalProductGroup';
import { BranchStatus } from 'components/common/Branch/Branch';
import {
  getMedicinalProducts,
  getMedicinalProductsGroups,
  updateMedicinalProduct,
} from 'api/Prescriptions/Prescriptions';
import {
  createMedicinalProductGroup,
  deleteMedicinalProductGroup,
  getMedicinalProductGroup,
  deleteMedicinalProductGroupItem,
  deleteProductFromGroup,
  addMedicinalProductGroupItem,
  addProductToGroup,
} from 'api/Groups';
import { routes } from 'routes';

export interface PrescriptionsContextLoading {
  getPrescriptionItemsStatus?: BranchStatus;
  getPrescriptionGroupsStatus?: BranchStatus;
  createMedicinalProductGroupStatus?: BranchStatus;
  deleteMedicinalProductGroupStatus?: BranchStatus;
  getMedicinalProductGroupStatus?: BranchStatus;
  deleteMedicinalProductGroupItemStatus?: BranchStatus;
  deleteProductFromGroupStatus?: BranchStatus;
  addMedicinalProductGroupItemStatus?: BranchStatus;
  addProductToGroupStatus?: BranchStatus;
  updateMedicinalProductStatus?: BranchStatus;
}

export interface PrescriptionsContextProps {
  loading: PrescriptionsContextLoading;
  prescriptionsFilters: PrescriptionsFilters;
  getGroups: () => Promise<void>;
  createGroup: (group: MedicinalProductGroup) => Promise<void>;
  deleteGroup: (id: string) => Promise<void>;
  getGroup: (id: string) => Promise<void>;
  deleteMedFromGroup: (groupId: string, medId: string) => Promise<void>;
  addMedToGroup: (groupId: string, medId: string) => Promise<void>;
  addProdToGroup: (groupId: string, medId: string) => Promise<void>;
  deleteProdFromGroup: (groupId: string, productId: string) => Promise<void>;
  medicinalProductGroupDetail: MedicinalProductGroupFull | null;
  setMedicinalProductGroupDetail: Function;
  productGroups: PrescriptionGroupItem[];
  getPrescriptionItems: (
    filters?: PrescriptionsFilters,
    setItems?: boolean,
  ) => Promise<void>;
  prescriptionItems: PrescriptionItem[];
  setExempt: (state: boolean) => void;
  setPage: (to: number) => void;
  getAllItems: () => void;
  getUnmappedItems: () => void;
  unmappedCount: number | null;
  allItemsCount: number | null;
  setGroupLoading: (state: BranchStatus) => void;
  updateExemptStatus: (
    productId: string,
    isContraceptive: boolean,
  ) => Promise<void>;
}

export interface PrescriptionsFilters {
  page: number;
  pages: number;
  isExempt: boolean | null;
  assignedToGroup: boolean | null;
  count?: number;
}
export const prescriptionsFiltersDefaults: PrescriptionsFilters = {
  page: 1,
  pages: 1,
  isExempt: false,
  assignedToGroup: null,
};

export const PrescriptionsContextValues = (): PrescriptionsContextProps => {
  const history = useHistory();
  const { handleApiError } = useApiError();
  const { close } = useModalContext();
  const [loading, setLoading] = React.useState<PrescriptionsContextLoading>({
    getPrescriptionItemsStatus: 'idle',
  });
  const [productGroups, setProductGroups] = React.useState<
    PrescriptionGroupItem[]
  >([]);
  const [prescriptionsFilters, setPrescriptionsFilters] =
    React.useState<PrescriptionsFilters>(prescriptionsFiltersDefaults);
  const [prescriptionItems, setPrescriptionItems] = React.useState<
    PrescriptionItem[]
  >([]);
  const [unmappedCount, setUnmappedCount] = React.useState<number | null>(null);
  const [allItemsCount, setAllItemsCount] = React.useState<number | null>(null);
  const [medicinalProductGroupDetail, setMedicinalProductGroupDetail] =
    React.useState<MedicinalProductGroupFull | null>(null);

  const getGroups = async (
    filters: PrescriptionsFilters = prescriptionsFiltersDefaults,
  ) => {
    const skip = filters.page ? (filters.page - 1) * 50 : 0;
    try {
      setLoading({ ...loading, getPrescriptionGroupsStatus: 'loading' });
      const result = await getMedicinalProductsGroups(skip);
      setPrescriptionsFilters({
        ...filters,
        pages: Math.ceil(result.count / result.top),
      });
      setProductGroups(result.items);
      result.items.length > 0
        ? setLoading({ ...loading, getPrescriptionGroupsStatus: 'finished' })
        : setLoading({ ...loading, getPrescriptionGroupsStatus: 'empty' });
    } catch (error) {
      setLoading({ ...loading, getPrescriptionGroupsStatus: 'error' });
    }
  };

  const getGroup = async (id: string, skipLoading: boolean = false) => {
    try {
      !skipLoading &&
        setLoading({ ...loading, getMedicinalProductGroupStatus: 'loading' });
      const result = await getMedicinalProductGroup(id);
      setMedicinalProductGroupDetail(result);
      setLoading({ ...loading, getMedicinalProductGroupStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, getMedicinalProductGroupStatus: 'error' });
    }
  };

  const createGroup = async (group: MedicinalProductGroup) => {
    try {
      setLoading({ ...loading, createMedicinalProductGroupStatus: 'loading' });
      const result = await createMedicinalProductGroup(group);
      setLoading({ ...loading, createMedicinalProductGroupStatus: 'finished' });
      history.push(`${routes.PRESCRIPTIONS.GROUP}/${result.id}`);
    } catch (error) {
      handleApiError(error);
      setLoading({ ...loading, createMedicinalProductGroupStatus: 'finished' });
    }
  };

  const deleteGroup = async (id: string) => {
    try {
      setLoading({ ...loading, deleteMedicinalProductGroupStatus: 'loading' });
      await deleteMedicinalProductGroup(id);
      history.push(`${routes.PRESCRIPTIONS.BASE}`);
      setLoading({ ...loading, deleteMedicinalProductGroupStatus: 'finished' });
      close();
    } catch (error) {
      handleApiError(error);
      setLoading({ ...loading, deleteMedicinalProductGroupStatus: 'finished' });
    }
  };

  const deleteMedFromGroup = async (groupId: string, medId: string) => {
    try {
      setLoading({
        ...loading,
        deleteMedicinalProductGroupItemStatus: 'loading',
      });
      await deleteMedicinalProductGroupItem(groupId, medId);
      await getGroup(groupId, true);
      setLoading({
        ...loading,
        deleteMedicinalProductGroupItemStatus: 'finished',
      });
    } catch (error) {
      handleApiError(error);
      setLoading({
        ...loading,
        deleteMedicinalProductGroupItemStatus: 'finished',
      });
    }
  };

  const addMedToGroup = async (groupId: string, medId: string) => {
    try {
      setLoading({
        ...loading,
        addMedicinalProductGroupItemStatus: 'loading',
      });
      await addMedicinalProductGroupItem(groupId, medId);
      await getGroup(groupId, true);
      close();
      setLoading({
        ...loading,
        addMedicinalProductGroupItemStatus: 'finished',
      });
    } catch (error) {
      handleApiError(error);
      setLoading({
        ...loading,
        addMedicinalProductGroupItemStatus: 'finished',
      });
    }
  };

  const deleteProdFromGroup = async (groupId: string, medId: string) => {
    try {
      setLoading({
        ...loading,
        deleteProductFromGroupStatus: 'loading',
      });
      await deleteProductFromGroup(groupId, medId);
      await getGroup(groupId, true);
      setLoading({
        ...loading,
        deleteProductFromGroupStatus: 'finished',
      });
    } catch (error) {
      handleApiError(error);
      setLoading({
        ...loading,
        deleteMedicinalProductGroupItemStatus: 'finished',
      });
    }
  };

  const addProdToGroup = async (groupId: string, medId: string) => {
    try {
      setLoading({
        ...loading,
        addProductToGroupStatus: 'loading',
      });
      await addProductToGroup(groupId, Number(medId));
      await getGroup(groupId, true);
      close();
      setLoading({
        ...loading,
        addProductToGroupStatus: 'finished',
      });
    } catch (error) {
      handleApiError(error);
      setLoading({
        ...loading,
        addProductToGroupStatus: 'finished',
      });
    }
  };

  const updateExemptStatus = async (
    productId: string,
    isContraceptive: boolean,
  ) => {
    try {
      setLoading({ ...loading, updateMedicinalProductStatus: 'loading' });
      await updateMedicinalProduct(productId, isContraceptive);
      close();
      setLoading({ ...loading, updateMedicinalProductStatus: 'finished' });
      getPrescriptionItems({ ...prescriptionsFilters, isExempt: true }, true);
    } catch (error) {
      handleApiError(error);
      setLoading({ ...loading, updateMedicinalProductStatus: 'finished' });
    }
  };

  const getPrescriptionItems = async (
    filters: PrescriptionsFilters = prescriptionsFiltersDefaults,
    setItems = false,
  ) => {
    const skip = filters.page ? (filters.page - 1) * 50 : 0;
    try {
      setLoading({ ...loading, getPrescriptionItemsStatus: 'loading' });
      const result = await getMedicinalProducts(filters, skip);
      if (setItems) {
        setPrescriptionsFilters({
          ...filters,
          pages: Math.ceil(result.count / result.top),
          count: result.count,
        });
        setPrescriptionItems(result.items);
      } else {
        filters.assignedToGroup === false && setUnmappedCount(result.count);
        filters.assignedToGroup === null && setAllItemsCount(result.count);
      }
      result.items.length > 0
        ? setLoading({ ...loading, getPrescriptionItemsStatus: 'finished' })
        : setLoading({ ...loading, getPrescriptionItemsStatus: 'empty' });
    } catch (error) {
      setLoading({ ...loading, getPrescriptionItemsStatus: 'error' });
    }
  };

  const setExempt = (state: boolean) => {
    state === true
      ? getPrescriptionItems({ ...prescriptionsFilters, isExempt: state }, true)
      : setPrescriptionsFilters({ ...prescriptionsFilters, isExempt: state });
  };

  const getAllItems = () => {
    getPrescriptionItems({ ...prescriptionsFilters, isExempt: null });
  };

  const getUnmappedItems = () => {
    getPrescriptionItems({
      ...prescriptionsFilters,
      assignedToGroup: false,
      isExempt: null,
    });
  };

  const setGroupLoading = (state: BranchStatus) => {
    setLoading({ ...loading, getMedicinalProductGroupStatus: state });
  };

  const setPage = (to: number) => {
    if (prescriptionsFilters.isExempt === false) {
      getGroups({ ...prescriptionsFilters, page: to });
    } else {
      to !== prescriptionsFilters.page &&
        getPrescriptionItems(
          {
            ...prescriptionsFilters,
            page: to,
          },
          true,
        );
    }
  };

  return {
    loading,
    getGroups,
    createGroup,
    deleteGroup,
    getGroup,
    deleteMedFromGroup,
    addMedToGroup,
    addProdToGroup,
    deleteProdFromGroup,
    medicinalProductGroupDetail,
    setMedicinalProductGroupDetail,
    productGroups,
    prescriptionsFilters,
    getPrescriptionItems,
    prescriptionItems,
    setExempt,
    setPage,
    getAllItems,
    getUnmappedItems,
    unmappedCount,
    allItemsCount,
    setGroupLoading,
    updateExemptStatus,
  };
};

export const PrescriptionsContext =
  React.createContext<PrescriptionsContextProps>(
    {} as ReturnType<typeof PrescriptionsContextValues>,
  );

export const usePrescriptionsContext = (): PrescriptionsContextProps => {
  const context = React.useContext(PrescriptionsContext);

  if (context === undefined) {
    throw new Error('context error');
  }

  return context;
};

interface PrescriptionsProviderProps {
  children?: React.ReactNode;
}

export const PrescriptionsProvider = ({
  children,
}: PrescriptionsProviderProps) => {
  return (
    <PrescriptionsContext.Provider value={PrescriptionsContextValues()}>
      {children}
    </PrescriptionsContext.Provider>
  );
};
