//Toast Provider uses https://fkhadra.github.io/react-toastify/introduction
import React from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { Toast } from 'components/common/Toast';
import 'react-toastify/dist/ReactToastify.css';
import 'stylesheets/vendor-override/_toast.scss';

export interface ToastContextProps {
  setToast: Function;
  dismissToast: Function;
  toastId: React.ReactText | undefined;
}

export type ToastStatus = 'success' | 'info' | 'warning' | 'error';

export interface ToastProps {
  status: ToastStatus;
  title?: string;
  description?: string;
  duration?: number | boolean;
  isClosable?: boolean;
}

export const defaultValues = {
  setToast: Function,
  dismissToast: Function,
  toastId: undefined,
};

export const ToastContext =
  React.createContext<ToastContextProps>(defaultValues);

export const useToastContext = (): ToastContextProps =>
  React.useContext(ToastContext);

interface ToastProviderProps {
  children?: React.ReactNode;
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const DEFAULT_TITLE = t('common.error.genericTitle');
  const DEFAULT_MESSAGE = '';
  const [toastId, setToastId] = React.useState<React.ReactText>();

  const dismissToast = () => {
    toast.dismiss(toastId);
    setToastId(undefined);
  };

  React.useEffect(() => {
    location.pathname !== '/' && dismissToast();
  }, [location]);

  return (
    <ToastContext.Provider
      value={{
        dismissToast,
        toastId,
        setToast: (toastConfig: ToastProps) => {
          const id = toast[toastConfig.status](
            <Toast
              title={toastConfig?.title || DEFAULT_TITLE}
              description={toastConfig?.description || DEFAULT_MESSAGE}
              type={toastConfig.status}
            />,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              transition: Bounce,
              autoClose: false,
              pauseOnHover: true,
              hideProgressBar: false,
              draggable: false,
              closeButton: false,
              closeOnClick: toastConfig?.isClosable ?? false,
            },
          );
          setToastId(id);
        },
      }}
    >
      {children}
      <ToastContainer role="alert" />
    </ToastContext.Provider>
  );
};
