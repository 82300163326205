/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Label } from 'components/common/Label';
import { PomFormResponse } from 'models/PomForm';

import styles from '../PatientPomFormModal.module.scss';

const answerStyle = styles['pom-modal__forms-container__form-content__answer'];

interface PomFormViewProps {
  pomForm: PomFormResponse;
  conditionName: string;
}

export const PomFormView = ({ pomForm, conditionName }: PomFormViewProps) => {
  return (
    <>
      <Heading size="sm">{conditionName}</Heading>
      <div className={styles['pom-modal__forms-container__form-content']}>
        {pomForm.responses.map((response, index) => {
          const label = response.question;
          let answer: string = '';
          let images: JSX.Element[] = [];
          if (
            response.dataType === 'single select' ||
            response.dataType === 'string'
          ) {
            answer =
              response.answer && response.answer !== ''
                ? response.answer
                : 'N/A';
          } else if (response.dataType === 'multi select') {
            const answerArray = JSON.parse(response.answer) as string[];
            answer = answerArray.join(', ');
          } else if (
            response.dataType === 'image' &&
            pomForm?.images?.length > 0
          ) {
            images = pomForm.images.map((image, imageIndex) => {
              return (
                <a
                  key={imageIndex}
                  href={`${process.env.REACT_APP_IMG_URL}thumbnail/${image}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}thumbnail/${image}`}
                    alt={`User provided image #${imageIndex + 1}`}
                    className={styles['pom-modal__image-preview']}
                  />
                </a>
              );
            });
          }

          return (
            <div className={answerStyle} key={index}>
              <Label>{label}</Label>
              {answer && answer !== '' && <Text>{answer}</Text>}
              {images && images.length > 0 && (
                <div className={styles['pom-modal__images-container']}>
                  {images}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
