import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { PatientsList } from 'containers/PatientsList';
import { PatientOrders } from 'containers/PatientOrders';
import { PatientView } from 'containers/PatientView';
import { routes } from 'routes';

export const Patients: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute path={routes.PATIENTS.BASE} exact>
        <PatientsList />
      </PrivateRoute>
      <PrivateRoute path={`${routes.PATIENTS.BASE}/:patientId`} exact>
        <PatientView />
      </PrivateRoute>
      <PrivateRoute path={`${routes.PATIENTS.ORDERS}/:patientId`} exact>
        <PatientOrders />
      </PrivateRoute>
    </Switch>
  );
};
