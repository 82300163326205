import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { useAuth } from 'hooks/useAuth';
import { routes } from 'routes';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { isAuthenticated } = useAuth();
  const { setAuthStatus } = useAuthContext();

  React.useEffect(() => {
    isAuthenticated() ? setAuthStatus(true) : setAuthStatus(false);
  }, []);

  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated() ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: routes.BASE,
            }}
          />
        )
      }
    />
  );
};
