import * as yup from 'yup';
import i18n from 'i18n';

const trackingFieldsMaxLength = 100;
export const orderStatusSchema = yup.object().shape({
  status: yup.string().required(i18n.t('orders.statusString.required')),
  courier: yup
    .string()
    .nullable()
    .when('status', (status, schema) => {
      if (status === 'OutForDelivery') {
        return schema
          .required(
            i18n.t(
              'orders.statusStrings.deliveryTracking.courierRequiredError',
            ),
          )
          .max(
            trackingFieldsMaxLength,
            i18n.t(
              'orders.statusStrings.deliveryTracking.trackingInfoMaxLengthError',
              { max: trackingFieldsMaxLength },
            ),
          )
          .trim()
          .matches(
            /^[a-zA-Z0-9]+$/,
            i18n.t(
              'orders.statusStrings.deliveryTracking.alphaNumericOnlyError',
            ),
          );
      }
      return schema;
    }),
  deliveryTrackingNumber: yup.string().when('status', (status, schema) => {
    if (status === 'OutForDelivery') {
      return schema
        .required(
          i18n.t('orders.statusStrings.deliveryTracking.numberRequiredError'),
        )
        .max(
          trackingFieldsMaxLength,
          i18n.t(
            'orders.statusStrings.deliveryTracking.trackingInfoMaxLengthError',
            { max: trackingFieldsMaxLength },
          ),
        )
        .trim()
        .matches(
          /^[a-zA-Z0-9]+$/,
          i18n.t('orders.statusStrings.deliveryTracking.alphaNumericOnlyError'),
        );
    }
    return schema;
  }),
});
