import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { TableHeader } from 'components/TableHeader';
import { Table, TableRow, TDCell, THCellProps } from 'components/table';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { Container, Col, Row } from 'components/common/grid';
import { Page } from 'components/Page';
import { ActionsBar } from 'components/ActionsBar';
import { Icon } from 'components/common/Icon';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { ReactComponent as ShopGreen } from 'assets/icons/lawsat/ShopGreen.svg';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { routes } from 'routes';
import { useHistory } from 'react-router';

import styles from './ConditionsGroupList.module.scss';

interface ConditionsGroupListProps {
  testId?: string;
  className?: string;
}

export const ConditionsGroupList = ({
  testId,
  className,
}: ConditionsGroupListProps) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const {
    getConditionGroups,
    conditionGroups,
    loading: { getConditionGroupsStatus },
    setConditionGroupsPage,
    setConditionGroupsSort,
    conditionGroupsFilters,
  } = useConditionsContext();

  const { page, pages } = conditionGroupsFilters;

  React.useEffect(() => {
    getConditionGroups();
  }, []);

  const [columns, setColumns] = React.useState<THCellProps[]>([
    {
      title: t('conditionGroups.tableHeaders.id'),
      sortable: false,
      field: 'id',
    },
    {
      title: t('conditionGroups.tableHeaders.name'),
      sortable: true,
      field: 'name',
    },
  ]);

  React.useEffect(() => {
    setColumns(
      columns.map((col) =>
        col.field === conditionGroupsFilters.sortBy
          ? { ...col, state: conditionGroupsFilters.sortDirection }
          : { ...col, state: undefined },
      ),
    );
  }, [conditionGroupsFilters.sortBy, conditionGroupsFilters.sortDirection]);

  return (
    <Page
      className={cx(styles['Group-list'], { [`${className}`]: className })}
      data-testid={testId}
      actionsBar={
        <ActionsBar
          pagination={{
            page: page ? page : 1,
            pages: pages ? pages : 1,
            change: setConditionGroupsPage,
          }}
          actions={[
            {
              action: () => push(routes.CONDITIONS.BASE),
              buttonType: 'accent',
              label: t('conditionGroups.switch'),
              disabled: false,
              appearance: 'solid',
            },
          ]}
        />
      }
    >
      <div className={cx(styles['conditions-group-list__main'])}>
        <Container fluid={true}>
          <Row>
            <Col>
              <TableHeader title={t('conditionGroups.title')} />
              <Table
                className={`${
                  getConditionGroupsStatus === 'loading'
                    ? styles['conditions-group-list__loading']
                    : ''
                }`}
                headers={columns}
                onSort={setConditionGroupsSort}
                selectable={false}
              >
                <Branch
                  status={getConditionGroupsStatus}
                  LoadingComponent={() => (
                    <tr>
                      <td colSpan={8}>
                        <Spinner />
                      </td>
                    </tr>
                  )}
                  EmptyComponent={() => (
                    <tr>
                      <td colSpan={8}>
                        <div
                          className={
                            styles['conditions-group-list__no_conditions']
                          }
                        >
                          <Icon
                            icon={ShopGreen}
                            size="xxl"
                            className={styles['conditions-group-list__icon']}
                          />
                          <Heading tag="h5" size="sm">
                            {t('conditionGroups.noConditions')}
                          </Heading>
                          <Text
                            tag="span"
                            className={styles['conditions-group-list__text']}
                          >
                            {t('conditionGroups.whenYouAdd')}
                          </Text>
                        </div>
                      </td>
                    </tr>
                  )}
                  Component={() => (
                    <>
                      {conditionGroups?.map(({ id, name }) => {
                        return (
                          <TableRow key={id} selectable={false}>
                            <TDCell line1={id.toString()} />
                            <TDCell
                              link={{
                                to: `${routes.CONDITIONS.GROUPS.BASE}/${id}`,
                                text: name,
                              }}
                            />
                          </TableRow>
                        );
                      })}
                    </>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  );
};
