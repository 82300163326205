import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { MedResult } from 'api/MedSuggest';
import { useSuggestContext } from 'contexts/SuggestContext';
import { useToastContext } from 'contexts/ToastContext';
import { useOrdersContext } from 'contexts/OrdersContext';
import { useModalContext } from 'contexts/ModalContext';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { AutoComplete } from 'components/AutoComplete';
import { SelectList, listValues } from 'components/SelectList';
import styles from './EditMedicationModal.module.scss';

interface EditMedicationModalProps {
  testId?: string;
  className?: string;
  orderlineId: number;
}

export const convertList = (originalList: MedResult[]): listValues[] => {
  return originalList.map((item) => {
    return {
      value: item.Id,
      label: item['@search.text'],
    };
  });
};

export const EditMedicationModal: React.FC<EditMedicationModalProps> = ({
  testId,
  className,
  orderlineId,
}) => {
  const { t } = useTranslation();
  const { getMedlist, medList, setMedList } = useSuggestContext();
  const { setToast } = useToastContext();
  const { orderDetail, updateOrderLineMed } = useOrdersContext();
  const { close } = useModalContext();

  const schema = yup.object().shape({
    selectedMed: yup.string().required(),
  });

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('orders.editOrder.error'),
    });
  };

  const onSubmit = async () => {
    if (orderDetail) {
      await updateOrderLineMed(
        orderDetail?.id,
        orderlineId,
        getValues('selectedMed'),
        close,
      );
    }
  };

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <div
      className={cx(styles['edit-medication-modal'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading size="md" className={styles['edit-medication-modal__title']}>
        {t('orders.editOrder.title')}
      </Heading>
      <AutoComplete
        getResults={(str: string) => {
          getMedlist(str);
        }}
        clearResults={() => {
          setMedList([]);
        }}
        placeholder={t('orders.editOrder.placeholder')}
        label={t('orders.editOrder.placeholder')}
      />
      <form data-testid="gp-form" onSubmit={handleSubmit(onSubmit, invalid)}>
        <div className={styles['edit-medication-modal__list-container']}>
          {medList.length > 0 && (
            <Controller
              control={control}
              defaultValue=""
              name="selectedMed"
              isRequired
              render={(props) => (
                <>
                  <SelectList
                    id="selectedGP"
                    listValues={convertList(medList)}
                    {...props}
                  />
                </>
              )}
            />
          )}
        </div>
        <div className={styles['edit-medication-modal__button-container']}>
          <Button
            className={styles['edit-medication-modal__button']}
            testId="submit-button"
            type="submit"
            label={t('orders.editOrder.CTA')}
          />
        </div>
      </form>
    </div>
  );
};
