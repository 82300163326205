import React, { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useHistory } from 'react-router-dom';
import { useToastContext } from 'contexts/ToastContext';
import { Branch } from 'components/common/Branch';
import { Card } from 'components/Card';
import { Heading } from 'components/common/Heading';
import { InputText } from 'components/common/InputText';
import { Textarea } from 'components/common/Textarea';
import { Spinner } from 'components/common/Spinner';
import { ErrorState } from 'components/ErrorState';
import { Button } from 'components/common/Button';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { ConditionGroup } from 'models/Categorisations/Conditions';
import { ConditionGroupDetailsSchema } from 'formSchema/ConditionDetails';
import { CheckBox } from 'components/common/CheckBox';
import { EditorState } from 'lexical';
import { RichTextEditor } from 'components/RichTextEditor';
import {
  getRichTextLength,
  richTextFieldDefaultState,
  RICH_TEXT_CHARACTER_LIMIT,
  isRichTextLengthValid,
} from 'utils/RichTextField';
import { routes } from 'routes';

import styles from './ConditionGroupForm.module.scss';

interface ConditionGroupFormProps {
  testId?: string;
  className?: string;
  conditionGroup?: ConditionGroup | null;
}

export const ConditionGroupForm = ({
  testId,
  className,
  conditionGroup,
}: ConditionGroupFormProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const { updateConditionGroup } = useConditionsContext();

  const history = useHistory();

  const { control, handleSubmit, errors, formState, setValue } = useForm({
    resolver: yupResolver(ConditionGroupDetailsSchema()),
  });

  const [richTextLength, setRichTextLength] = useState(0);

  const onSubmit = async (data: ConditionGroup) => {
    if (conditionGroup?.id) {
      updateConditionGroup({ ...conditionGroup, ...data });
    }
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('common.formErrors.validationErrorsTitle'),
      description: t('common.formErrors.validationErrorsDescription'),
    });
  };

  const richTextHandler = (state: EditorState) => {
    setValue('description', JSON.stringify(state), { shouldDirty: true });
    setRichTextLength(getRichTextLength(state));
  };

  const inputStyle = styles['condition-group-form__input'];
  const cardStyle = styles['condition-group-form__card'];
  const maxTextCaptionTranslation = 'common.formErrors.maxCaption';

  return (
    <div
      className={cx(styles['condition-group-form'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Branch
        ErrorComponent={() => <ErrorState testId="form-error-state" />}
        LoadingComponent={() => <Spinner testId="form-loading-spinner" />}
        Component={() => (
          <form
            className={styles['condition-group-form__form']}
            onSubmit={handleSubmit(onSubmit, invalid)}
          >
            <Card className={cardStyle}>
              <Heading
                size="sm"
                className={styles['condition-group-form__title']}
              >
                {t('conditionGroups.form.detailFieldSet')}
              </Heading>
              <div className={styles['condition-group-form__form-col-wrap']}>
                <div
                  className={cx(
                    styles['condition-group-form__form-col'],
                    styles['condition-group-form__form-col--wide'],
                  )}
                >
                  <Controller
                    control={control}
                    defaultValue={conditionGroup?.name ?? ''}
                    name="name"
                    isRequired
                    render={(props, fieldState) => (
                      <InputText
                        className={inputStyle}
                        disabled
                        variant={fieldState.invalid ? 'negative' : 'accent'}
                        validationError={errors.name?.message}
                        maxLength={300}
                        label={t('conditionGroups.form.name')}
                        {...props}
                        caption={t(maxTextCaptionTranslation, {
                          max: '300',
                        })}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    defaultValue={conditionGroup?.tagline ?? ''}
                    name="tagline"
                    render={(props, fieldState) => (
                      <Textarea
                        className={inputStyle}
                        variant={fieldState.invalid ? 'negative' : 'accent'}
                        maxLength={500}
                        label={t('conditionGroups.form.tagline')}
                        helperText={t('conditionGroups.form.taglineHelper')}
                        {...props}
                        caption={t(maxTextCaptionTranslation, {
                          max: '500',
                        })}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    defaultValue={
                      conditionGroup?.description ?? richTextFieldDefaultState
                    }
                    name="description"
                    render={() => (
                      <RichTextEditor
                        label={t('conditionGroups.form.description')}
                        helperText={t('conditionGroups.form.descriptionHelper')}
                        caption={t(maxTextCaptionTranslation, {
                          max: RICH_TEXT_CHARACTER_LIMIT,
                        })}
                        onChangeHandler={richTextHandler}
                        richText={
                          conditionGroup?.description ??
                          richTextFieldDefaultState
                        }
                        error={!isRichTextLengthValid(richTextLength)}
                      />
                    )}
                  />
                </div>

                <div className={styles['condition-group-form__form-col']}>
                  <Heading
                    size="xs"
                    className={styles['condition-group-form__sub-title']}
                  >
                    {t('conditionGroups.form.checkBoxes')}
                  </Heading>
                  <Controller
                    control={control}
                    defaultValue={conditionGroup?.showInShop}
                    name="showInShop"
                    render={(props) => (
                      <CheckBox
                        className={styles['condition-group-form__checkbox']}
                        label={t('conditionGroups.form.show')}
                        size="md"
                        slim={true}
                        orientation="vertical"
                        {...props}
                      />
                    )}
                  />
                </div>
              </div>
            </Card>
            <div className={styles['condition-group-form__footer']}>
              {formState.isDirty && (
                <Heading
                  tag="h6"
                  size="xs"
                  className={styles['condition-group-form__unsaved-changes']}
                >
                  {t('common.form.unsavedChanges')}
                </Heading>
              )}

              <div
                className={styles['condition-group-form__actions-container']}
              >
                <Button
                  className={styles['condition-group-form__action-button']}
                  label={t('common.form.discard')}
                  type="button"
                  variant="negative"
                  appearance="flat"
                  onClick={() => {
                    history.push(routes.CONDITIONS.GROUPS.BASE);
                  }}
                />

                <Button
                  testId="submit-button"
                  className={styles['condition-group-form__action-button']}
                  label={t('common.form.save')}
                  type="submit"
                  disabled={
                    !formState.isDirty || !isRichTextLengthValid(richTextLength)
                  }
                />
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};
