import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row } from 'components/common/grid';
import { Page } from 'components/Page';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { routes } from 'routes';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { ConditionForm } from 'containers/ConditionForm';

import styles from './ConditionView.module.scss';

export const ConditionView = () => {
  const { conditionId } = useParams<{ conditionId: string }>();
  const { t } = useTranslation();
  const {
    getCondition,
    setCondition,
    condition,
    loading: { getConditionStatus },
  } = useConditionsContext();
  const [headingText, setHeadingText] = useState('');
  const [breadcrumbRoute, setBreadcrumbRoute] = useState('');

  useEffect(() => {
    conditionId !== 'edit' && getCondition(Number(conditionId));
    return () => setCondition(null);
  }, [conditionId]);

  useEffect(() => {
    if (condition) {
      setHeadingText(condition?.name);
      setBreadcrumbRoute(`${routes.CONDITIONS.BASE}/${conditionId}`);
    } else {
      setHeadingText(t('conditions.addCondition'));
      setBreadcrumbRoute(routes.CONDITIONS.ADD);
    }
  }, [condition]);

  return (
    <Page>
      <Branch
        status={getConditionStatus}
        LoadingComponent={() => <Spinner />}
        Component={() => (
          <>
            <Container fluid={true}>
              <Row>
                <Col>
                  <Breadcrumbs
                    links={[
                      {
                        text: t('conditions.title'),
                        route: routes.CONDITIONS.BASE,
                      },
                      {
                        text: headingText,
                        route: breadcrumbRoute,
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Container>
            <div className={cx(styles['condition-view__main'])}>
              <Container fluid={true}>
                <Row>
                  <Col>
                    <ConditionForm condition={condition} />
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        )}
      />
    </Page>
  );
};
