import React from 'react';
import cx from 'classnames';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { MedicinalProductGroupFull } from 'models/MedicinalProductGroup';
import { useToastContext } from 'contexts/ToastContext';
import { usePrescriptionsContext } from 'contexts/PrescriptionsContext';
import { InputText } from 'components/common/InputText';
import { Button } from 'components/common/Button';
import { Card } from 'components/Card';
import styles from './GroupForm.module.scss';

interface GroupFormProps {
  testId?: string;
  className?: string;
  group: MedicinalProductGroupFull | null;
  isEdit: boolean;
}

export const GroupForm: React.FC<GroupFormProps> = ({
  testId,
  className,
  group,
  isEdit,
}) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const {
    createGroup,
    loading: { createMedicinalProductGroupStatus },
  } = usePrescriptionsContext();

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('prescriptions.groupForm.errors.title'),
    });
  };

  const onSubmit = () => {
    const result = getValues();
    createGroup({
      name: result.name,
      categoryCode: result.categoryCode,
    });
  };

  const schema = yup.object().shape({
    name: yup.string().required(t('prescriptions.groupForm.errors.name')),
  });

  const { control, getValues, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  return (
    <div
      className={cx(styles['group-form'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <Card>
        <form
          className={styles['group-form__form']}
          onSubmit={handleSubmit(onSubmit, invalid)}
        >
          <Controller
            control={control}
            defaultValue={group?.name || ''}
            name="name"
            isRequired
            render={(props, fieldState) => (
              <InputText
                labelClassName={styles['group-form__label']}
                size="md"
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.name?.message}
                maxLength={300}
                disabled={isEdit}
                label={t('prescriptions.groupForm.name')}
                {...props}
                caption={t('common.formErrors.maxCaption', {
                  max: '300',
                })}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={group?.categoryCode || ''}
            name="categoryCode"
            isRequired
            render={(props, fieldState) => (
              <InputText
                labelClassName={styles['group-form__label']}
                size="md"
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.categoryCode?.message}
                maxLength={300}
                disabled={isEdit}
                label={t('prescriptions.groupForm.cat')}
                {...props}
                caption={t('common.formErrors.maxCaption', {
                  max: '300',
                })}
              />
            )}
          />
          <div className={styles['group-form__actions-container']}>
            <Button
              testId="submit-button"
              size="sm"
              className={styles['group-form__action-button']}
              label={t('common.form.save')}
              type="submit"
              disabled={isEdit}
              isLoading={createMedicinalProductGroupStatus === 'loading'}
            />
          </div>
        </form>
      </Card>
    </div>
  );
};
