/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOrdersContext } from 'contexts/OrdersContext';
import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import { PrescriptionDetailsSchema } from 'formSchema/PrescriptionDetails';
import { InputText } from 'components/common/InputText';
import { DropDown } from 'components/DropDown';
import { useSettingsContext } from 'contexts/SettingsContext';
import { Button } from 'components/common/Button';
import { PrescriptionPayload } from 'models/Prescription';
import { ReactComponent as HelpIcon } from 'assets/icons/lawsat/Help.svg';
import { useAuthContext } from 'contexts/AuthContext';
import { Label } from 'components/common/Label';
import { Text } from 'components/common/Text';
import { routes } from 'routes';
import { Heading } from 'components/common/Heading';
import { useModalContext } from 'contexts/ModalContext';
import { LandingPageSpinner } from 'components/common/Spinner/Spinner';
import { useAdminContext } from 'contexts/AdminContext';
import { OrderLine } from 'models/Order';
import { RichTextEditor } from 'components/RichTextEditor';
import {
  getRichTextLength,
  isRichTextLengthValid,
  isValidJSON,
  richTextFieldDefaultState,
} from 'utils/RichTextField';
import { EditorState } from 'lexical';
import { RichTextViewer } from 'components/RichTextViewer';

import styles from './PrescriptionForm.module.scss';

const inputStyle = styles['prescription-form__inputs'];
type FormMode = 'edit' | 'create';

interface PrescriptionFormProps {
  orderId: string;
  prescriptionId?: string;
  pomOrderlines: OrderLine[];
}

export const PrescriptionForm = ({
  orderId,
  prescriptionId,
  pomOrderlines,
}: PrescriptionFormProps) => {
  const mode: FormMode =
    prescriptionId && prescriptionId !== '' ? 'edit' : 'create';

  const { t } = useTranslation();
  const history = useHistory();
  const { getPharmaciesList, pharmacies } = useSettingsContext();
  const [pharmacyStampId, setPharmacyStampId] = useState<number>();
  const [prescriberPharmacyId, setPrescriberPharmacyId] = useState<number>();
  const { userName } = useAuthContext();
  const {
    createOrUpdatePrescription,
    getPrescriptionAgainstOrder,
    prescription,
    setPrescription,
  } = useOrdersContext();
  const { getOperatorDetails, operatorDetails } = useAdminContext();
  const { open: openModal, close: closeModal } = useModalContext();
  const [readonly, setReadonly] = useState(mode === 'edit');
  const [medsRichTextLength, setMedsRichTextLength] = useState(0);
  const [medsPlainText, setPlainText] = useState();

  // store starting value of Prescribed Meds for comparisons later on
  const initialMedsValue =
    mode === 'create'
      ? pomOrderlines.map((orderline) => `${orderline.name}`).join('\n')
      : prescription?.prescribedMedication ?? '';

  const schema = PrescriptionDetailsSchema(
    initialMedsValue,
    (value: string | null | undefined) =>
      value !== richTextFieldDefaultState &&
      !(mode === 'create' && medsPlainText === undefined) &&
      !(mode === 'create' && medsPlainText === initialMedsValue),
  );

  const { control, handleSubmit, errors, formState, setValue, watch, reset } =
    useForm({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    !pharmacies && getPharmaciesList();
    mode === 'edit' && getPrescriptionAgainstOrder(orderId);
    return () => setPrescription(null);
  }, []);

  useEffect(() => {
    if (prescription) {
      getOperatorDetails(prescription.prescriberId);
      if (mode === 'edit') {
        setPrescriberPharmacyId(prescription.prescriberPharmacyId);
        setPharmacyStampId(prescription.pharmacyStampId);
        reset(prescription);
      }
    }
  }, [prescription]);

  useEffect(() => {
    if (formState.isDirty) {
      setValue('prescriberPharmacyId', watch('pharmacyStampId'), {
        shouldValidate: formState.isSubmitted,
        shouldDirty: formState.isSubmitted,
      });
      setPrescriberPharmacyId(watch('pharmacyStampId'));
    }
  }, [watch('pharmacyStampId')]);

  if (!pharmacies || (prescriptionId && !prescription)) {
    return <LandingPageSpinner />;
  }

  const handleClickSubmit = async (data: PrescriptionPayload) => {
    openModal(
      <ConfirmPrescriptionActionModal
        onConfirm={async () => {
          const success = await createOrUpdatePrescription(
            orderId,
            data,
            prescriptionId,
          );
          if (success) {
            mode === 'create'
              ? history.push(`${routes.ORDER.SINGLE}/${orderId}`)
              : setReadonly(true);
          }
          closeModal();
        }}
        onDiscard={() => closeModal()}
        mode={mode}
      />,
    );
  };

  const handleMedsRichTextChange = (state: EditorState) => {
    setValue('prescribedMedication', JSON.stringify(state), {
      shouldDirty: true,
    });
    setMedsRichTextLength(getRichTextLength(state));
  };

  let displayedPharmacistName = userName;
  if (mode === 'edit' && !formState.isDirty) {
    displayedPharmacistName = `${operatorDetails?.firstName} ${operatorDetails?.lastName}`;
  }
  return (
    <form
      onSubmit={handleSubmit(handleClickSubmit)}
      className={styles['prescription-form']}
    >
      <Controller
        control={control}
        name="pharmacyStampId"
        isRequired
        render={(props, fieldState) => (
          <DropDown
            className={inputStyle}
            placeholder={t('prescriptionCreate.form.errors.pharmacyStampId')}
            label={t('prescriptionCreate.form.labels.pharmacyStampId')}
            variant={fieldState.invalid ? 'negative' : 'accent'}
            validationError={errors.pharmacyStampId?.message}
            values={pharmacies}
            selected={pharmacyStampId?.toString()}
            setChangedVal={setPharmacyStampId}
            disabled={readonly}
            {...props}
          />
        )}
      />

      <Controller
        control={control}
        defaultValue={mode === 'create' ? initialMedsValue : undefined}
        name="prescribedMedication"
        isRequired
        render={() =>
          readonly ? (
            <div>
              <Label size="lg">
                {t('prescriptionCreate.form.labels.prescriptionItems')}
              </Label>
              <div className={styles['prescription-form__rich-text-wrapper']}>
                <RichTextViewer
                  richText={
                    (prescription?.prescribedMedication &&
                      isValidJSON(prescription.prescribedMedication) &&
                      prescription.prescribedMedication) ||
                    richTextFieldDefaultState
                  }
                  themeProps={{ textSize: 'md' }}
                />
              </div>
            </div>
          ) : (
            <div>
              <RichTextEditor
                syncPlainText={setPlainText}
                initialPlainText={
                  mode === 'create' ? initialMedsValue : undefined
                }
                richText={mode === 'edit' ? initialMedsValue : undefined}
                label={t('prescriptionCreate.form.labels.prescriptionItems')}
                caption={
                  errors?.prescribedMedication
                    ? t(
                        'prescriptionCreate.form.errors.prescribedMedicationNotUpdated',
                      )
                    : t('common.formErrors.maxCaption', {
                        max: '500',
                      })
                }
                helperText={t(
                  'prescriptionCreate.form.labels.prescriptionItemsHelper',
                )}
                onChangeHandler={handleMedsRichTextChange}
                error={
                  !isRichTextLengthValid(medsRichTextLength, 500) ||
                  errors?.prescribedMedication
                }
              />
            </div>
          )
        }
      />

      <Controller
        control={control}
        defaultValue={''}
        name="gphcNumber"
        isRequired
        render={(props, fieldState) => (
          <div>
            <Label size="lg" className={styles['prescription-form__label']}>
              {t('prescriptionCreate.form.labels.gphcNumber')}
              {
                <div className={styles['prescription-form__gphc-tooltip']}>
                  <div
                    className={styles['prescription-form__gphc-tooltip__icon']}
                  >
                    <HelpIcon />
                  </div>
                  <div
                    className={
                      styles['prescription-form__gphc-tooltip__message']
                    }
                  >
                    <Text>
                      {t('prescriptionCreate.form.labels.gphcNumberTooltip')}
                    </Text>
                  </div>
                </div>
              }
            </Label>
            <InputText
              type="number"
              helperText={t('prescriptionCreate.form.labels.gphcNumberHelper', {
                prescriber: displayedPharmacistName,
              })}
              className={inputStyle}
              variant={fieldState.invalid ? 'negative' : 'accent'}
              validationError={errors.gphcNumber?.message}
              maxLength={300}
              label={''}
              disabled={readonly}
              {...props}
            />
          </div>
        )}
      />

      <Controller
        control={control}
        name="prescriberPharmacyId"
        isRequired
        render={(props, fieldState) => (
          <DropDown
            className={inputStyle}
            placeholder={t('prescriptionCreate.form.errors.pharmacyAddress')}
            label={t('prescriptionCreate.form.labels.pharmacyAddress')}
            variant={fieldState.invalid ? 'negative' : 'accent'}
            validationError={errors.prescriberPharmacyId?.message}
            values={pharmacies}
            selected={prescriberPharmacyId?.toString()}
            setChangedVal={setPrescriberPharmacyId}
            disabled={readonly}
            {...props}
          />
        )}
      />
      {readonly ? (
        <div className={styles['prescription-form__buttons']}>
          <Button
            label={t('prescriptionCreate.print')}
            type="button"
            variant="accent"
            appearance="flat"
            link={{
              to: `${routes.ORDER.SINGLE}/${orderId}${routes.ORDER.PRESCRIPTION.PRINT}`,
              target: 'blank',
              rel: 'noreferrer noopener',
            }}
          />
          <Button
            label={t('prescriptionCreate.edit')}
            type="button"
            variant="accent"
            appearance="flat"
            onClick={(e) => {
              e.preventDefault();
              setReadonly((prevValue) => !prevValue);
            }}
          />
        </div>
      ) : (
        <div className={styles['prescription-form__buttons']}>
          <Button
            variant="negative"
            appearance="flat"
            label={
              mode === 'edit'
                ? t('prescriptionCreate.discardChanges')
                : t('prescriptionCreate.discard')
            }
            disabled={mode === 'edit' && readonly}
            type="button"
            onClick={
              mode === 'edit'
                ? () => {
                    reset(prescription!);
                    setPrescriberPharmacyId(prescription!.prescriberPharmacyId);
                    setPharmacyStampId(prescription!.pharmacyStampId);
                    setReadonly(true);
                  }
                : () => history.push(`${routes.ORDER.SINGLE}/${orderId}`)
            }
          />
          <Button
            label={
              mode === 'edit'
                ? t('prescriptionCreate.saveChanges')
                : t('prescriptionCreate.save')
            }
            type="submit"
            disabled={
              !formState.isDirty ||
              !isRichTextLengthValid(medsRichTextLength, 500)
            }
          />
        </div>
      )}
    </form>
  );
};

interface ConfirmCreatePrescriptionModalProps {
  onConfirm: () => void;
  onDiscard: () => void;
  mode?: FormMode;
}

export const ConfirmPrescriptionActionModal = ({
  onConfirm,
  onDiscard,
  mode = 'create',
}: ConfirmCreatePrescriptionModalProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles['prescription-form__modal']}>
      <Heading tag="h3">
        {t('prescriptionCreate.modals.confirmHeading')}
      </Heading>
      {mode === 'edit' && (
        <Text size="lg"> {t('prescriptionCreate.modals.editWarning')}</Text>
      )}
      <Button
        label={
          mode === 'create'
            ? t('prescriptionCreate.save')
            : t('prescriptionCreate.saveChanges')
        }
        onClick={onConfirm}
      />
      <Button
        variant="negative"
        appearance="flat"
        label={t('prescriptionCreate.modals.goBack')}
        onClick={onDiscard}
      />
    </div>
  );
};
