import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSettingsContext } from 'contexts/SettingsContext';
import { Row, Col } from 'components/common/grid';
import { DashboardStatCard } from 'components/DashboardStatCard';
import styles from './DashboardStats.module.scss';
import { routes } from 'routes';

interface DashboardStatsProps {
  testId?: string;
  className?: string;
}

export const DashboardStats: React.FC<DashboardStatsProps> = ({
  testId,
  className,
}) => {
  const {
    loadingStats,
    dashboardStatsList,
    getDashboardStatsList,
    selectedPharmacy,
  } = useSettingsContext();
  const { t } = useTranslation();

  React.useEffect(() => {
    getDashboardStatsList(selectedPharmacy);
  }, [selectedPharmacy]);

  return (
    <div
      className={cx(styles['dashboard-stats'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <Row>
        <Col xs={12} md={4}>
          <DashboardStatCard
            status={loadingStats}
            number={dashboardStatsList?.newOrders}
            title={t('dashboard.newOrdersTitle')}
            text={t('dashboard.newOrdersText')}
            ctaText={t('dashboard.newOrdersCTA')}
            route={`${routes.ORDER.BASE}/new`}
          />
        </Col>
        <Col xs={12} md={4}>
          <DashboardStatCard
            status={loadingStats}
            number={dashboardStatsList?.ordersDueForDeliveryToday}
            title={t('dashboard.deliveryTitle')}
            text={`${dashboardStatsList?.ordersDueForDeliveryTomorrow} ${t(
              'dashboard.deliveryText',
            )}`}
            ctaText={t('dashboard.deliveryCTA')}
            route={`${routes.ORDER.BASE}/delivery`}
          />
        </Col>
        <Col xs={12} md={4}>
          <DashboardStatCard
            testId="lapsed-card"
            urgent={
              dashboardStatsList?.lapsedOrders
                ? dashboardStatsList?.lapsedOrders > 0
                : false
            }
            status={loadingStats}
            number={dashboardStatsList?.lapsedOrders}
            title={t('dashboard.lapsedTitle')}
            text={t('dashboard.lapsedText')}
            ctaText={t('dashboard.lapsedCTA')}
            route={`${routes.ORDER.BASE}/lapsed`}
            disabled={dashboardStatsList?.lapsedOrders === 0}
          />
        </Col>
      </Row>
    </div>
  );
};
