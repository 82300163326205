import React from 'react';
import cx from 'classnames';
import { TableHead, THCellProps } from 'components/table';
import styles from './Table.module.scss';

interface TableProps {
  className?: string;
  headers?: THCellProps[];
  selectable: boolean;
  onChange?: Function;
  onSort?: Function;
  allRowsSelected?: boolean;
  children?: React.ReactNode;
}

export const Table = ({
  headers,
  className,
  selectable = false,
  onChange,
  onSort,
  children,
  allRowsSelected,
}: TableProps) => {
  return (
    <div className={styles['table__container']}>
      <table className={cx(styles['table'], { [`${className}`]: className })}>
        {headers && (
          <TableHead
            headers={headers}
            selectable={selectable}
            onChange={onChange}
            onSort={onSort}
            allRowsSelected={allRowsSelected}
          />
        )}
        <tbody className={styles['table__body']}>{children}</tbody>
      </table>
    </div>
  );
};
