import React from 'react';
import { useApiError } from 'hooks/useApiError';
import { useModalContext } from 'contexts/ModalContext';
import { BranchStatus } from 'components/common/Branch/Branch';
import {
  Patient,
  PatientDetail,
  PatientLinkedAccount,
  PrescriptionItem,
} from 'models/Patient';
import {
  addPrescriptionItem,
  deletePrescriptionItem,
  getPatient,
  deletePatient,
  getPatients,
  getLinkedAccounts,
  sendMessage,
  PatientInvitePayload,
  invitePatient,
} from 'api/Patient';

export interface PatientsContextProps {
  patientList: Patient[];
  patientListFilters: PatientListFilters;
  getPatientsList: (filters?: PatientListFilters) => Promise<void>;
  setPharmacy: (id: number) => void;
  setPage: (to: number) => void;
  changeFilter: (filter: 'all' | 'partial' | 'dormant') => void;
  changeSearch: (searchTerm: string) => void;
  setSort: (field: string, direction: 'Ascending' | 'Descending') => void;
  loading: PatientsContextLoading;
  selectedRows: (string | number)[];
  setSelectedRows: Function;
  getPatientDetail: (patientId: number) => Promise<void>;
  deletePatientDetails: (
    patientId: string | number,
    onSuccessCallback: () => void,
  ) => Promise<void>;
  setPatientDetail: (patientDetail: PatientDetail | null) => void;
  patientDetail: PatientDetail | null;
  addToPrescriptionItems: (
    patientId: number,
    medicinalProductId: string,
  ) => Promise<void>;
  deletePrescription: (
    patientId: number,
    medicinalProductId: string,
  ) => Promise<void>;
  prescriptionItems: PrescriptionItem[];
  getPatientLinkedAccounts: (patientId: number) => Promise<void>;
  linkedAccounts: PatientLinkedAccount[];
  contactPatient: (
    patientId: number | null,
    pharmacyId: number | null,
    message: string,
  ) => Promise<void>;
  sendPatientInvite: (
    patientInvitePayload: PatientInvitePayload,
  ) => Promise<void>;
}

export interface PatientsContextLoading {
  getPatientsListStatus?: BranchStatus;
  getPatientDetailStatus?: BranchStatus;
  deletePatientStatus?: BranchStatus;
  addPrescriptionItemStatus?: BranchStatus;
  deletePrescriptionStatus?: BranchStatus;
  getPrescriptionsStatus?: BranchStatus;
  getLinkedAccountsStatus?: BranchStatus;
  contactPatientStatus?: BranchStatus;
  invitePatientStatus?: BranchStatus;
}

export interface PatientListFilters {
  pharmacyId: number | null;
  page?: number;
  pages?: number;
  sortBy?: string;
  sortDirection?: 'Ascending' | 'Descending';
  dormant?: boolean | null;
  search?: string;
}

export const defaultFilters: PatientListFilters = {
  pharmacyId: null,
  sortBy: 'createdDateTime',
  sortDirection: 'Ascending',
  dormant: null,
};

export const PatientsContextValues = (): PatientsContextProps => {
  const { handleApiError } = useApiError();
  const { close } = useModalContext();

  const [patientListFilters, setPatientListFilters] =
    React.useState<PatientListFilters>(defaultFilters);
  const [patientList, setPatientList] = React.useState<Patient[]>([]);

  const [loading, setLoading] = React.useState<PatientsContextLoading>({
    getPatientsListStatus: 'idle',
  });

  const [selectedRows, setSelectedRows] = React.useState<(string | number)[]>(
    [],
  );
  const [patientDetail, setPatientDetail] =
    React.useState<PatientDetail | null>(null);

  const [prescriptionItems, setPrescriptionItems] = React.useState<
    PrescriptionItem[]
  >([]);
  const [linkedAccounts, setLinkedAccounts] = React.useState<
    PatientLinkedAccount[]
  >([]);

  const getPatientsList = async (
    filters: PatientListFilters = defaultFilters,
  ) => {
    const skip = filters.page ? (filters.page - 1) * 20 : 0;
    try {
      setLoading({ ...loading, getPatientsListStatus: 'loading' });
      const result = await getPatients(filters, skip);

      setSelectedRows([]);
      setPatientListFilters({
        ...filters,
        pages: Math.ceil(result.count / result.top),
      });
      setPatientList(result.items);
      result.items.length > 0
        ? setLoading({
            ...loading,
            getPatientsListStatus: 'finished',
          })
        : setLoading({
            ...loading,
            getPatientsListStatus: 'empty',
          });
    } catch (error) {
      setLoading({
        ...loading,
        getPatientsListStatus: 'error',
      });
    }
  };

  const changeFilter = (filter: 'all' | 'partial' | 'dormant') => {
    getPatientsList({
      ...patientListFilters,
      dormant: filter === 'dormant' ? true : null,
      page: 1,
    });
  };

  const changeSearch = (searchTerm: string) => {
    getPatientsList({
      ...patientListFilters,
      search: searchTerm,
      dormant: null,
      page: 1,
    });
  };

  const getPatientDetail = async (patientId: number) => {
    try {
      setLoading({ ...loading, getPatientDetailStatus: 'loading' });
      const response = await getPatient(patientId);
      setPatientDetail(response);
      setPrescriptionItems(response.prescriptionItems);
      setLoading({ ...loading, getPatientDetailStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, getPatientDetailStatus: 'error' });
    }
  };

  const deletePatientDetails = async (
    patientId: string | number,
    onSuccessCallback: () => void,
  ) => {
    try {
      setLoading({ ...loading, deletePatientStatus: 'loading' });
      await deletePatient(patientId);
      await getPatientsList(patientListFilters);
      setLoading({ ...loading, deletePatientStatus: 'finished' });
      onSuccessCallback();
    } catch (error) {
      handleApiError(error);
      setLoading({ ...loading, deletePatientStatus: 'finished' });
    }
  };

  const addToPrescriptionItems = async (
    patientId: number,
    medicinalProductId: string,
  ) => {
    try {
      setLoading({ ...loading, addPrescriptionItemStatus: 'loading' });
      await addPrescriptionItem(patientId, medicinalProductId);
      await getPrescriptionItemsList(patientId);
      close();
      setLoading({ ...loading, addPrescriptionItemStatus: 'finished' });
    } catch (error) {
      handleApiError(error);
      setLoading({ ...loading, addPrescriptionItemStatus: 'error' });
    }
  };

  const deletePrescription = async (
    patientId: number,
    medicinalProductId: string,
  ) => {
    try {
      setLoading({ ...loading, deletePrescriptionStatus: 'loading' });
      await deletePrescriptionItem(patientId, medicinalProductId);
      await getPrescriptionItemsList(patientId);
      setLoading({ ...loading, deletePrescriptionStatus: 'finished' });
    } catch (error) {
      handleApiError(error);
      setLoading({ ...loading, deletePrescriptionStatus: 'finished' });
    }
  };

  const getPrescriptionItemsList = async (patientId: number) => {
    try {
      setLoading({ ...loading, getPrescriptionsStatus: 'loading' });
      const result = await getPatient(patientId);
      result && setPrescriptionItems(result.prescriptionItems);
      setLoading({ ...loading, getPrescriptionsStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, getPrescriptionsStatus: 'finished' });
    }
  };

  const getPatientLinkedAccounts = async (patientId: number) => {
    try {
      setLoading({ ...loading, getLinkedAccountsStatus: 'loading' });
      const result = await getLinkedAccounts(patientId);
      result && setLinkedAccounts(result);
      setLoading({ ...loading, getLinkedAccountsStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, getLinkedAccountsStatus: 'finished' });
    }
  };

  const contactPatient = async (
    patientId: number | null,
    pharmacyId: number | null,
    message: string,
  ) => {
    try {
      setLoading({ ...loading, contactPatientStatus: 'loading' });
      await sendMessage(patientId, pharmacyId, message);
      setLoading({ ...loading, contactPatientStatus: 'finished' });
    } catch (error) {
      handleApiError(error);
      setLoading({ ...loading, contactPatientStatus: 'finished' });
    }
  };

  const setPage = (to: number) => {
    to !== patientListFilters.page &&
      getPatientsList({
        ...patientListFilters,
        page: to,
      });
  };

  const setSort = (field: string, direction: 'Ascending' | 'Descending') => {
    getPatientsList({
      ...patientListFilters,
      page: 1,
      sortBy: field,
      sortDirection: direction,
    });
  };

  const setPharmacy = (id: number) => {
    id !== patientListFilters.pharmacyId &&
      getPatientsList({
        ...patientListFilters,
        pharmacyId: id,
        page: 1,
      });
  };

  const sendPatientInvite = async (
    patientInvitePayload: PatientInvitePayload,
  ): Promise<void> => {
    try {
      setLoading({ ...loading, invitePatientStatus: 'loading' });
      await invitePatient(patientInvitePayload);
      setLoading({ ...loading, invitePatientStatus: 'finished' });
    } catch (error) {
      handleApiError(error);
      setLoading({ ...loading, invitePatientStatus: 'finished' });
    }
  };

  return {
    patientList,
    patientListFilters,
    getPatientsList,
    setPharmacy,
    changeFilter,
    changeSearch,
    setPage,
    setSort,
    loading,
    selectedRows,
    setSelectedRows,
    getPatientDetail,
    deletePatientDetails,
    setPatientDetail,
    patientDetail,
    addToPrescriptionItems,
    deletePrescription,
    prescriptionItems,
    getPatientLinkedAccounts,
    linkedAccounts,
    contactPatient,
    sendPatientInvite,
  };
};

export const PatientsContext = React.createContext<PatientsContextProps>(
  {} as ReturnType<typeof PatientsContextValues>,
);

export const usePatientsContext = (): PatientsContextProps => {
  const context = React.useContext(PatientsContext);

  if (context === undefined) {
    throw new Error('context error');
  }

  return context;
};

interface PatientsProviderProps {
  children?: React.ReactNode;
}

export const PatientsProvider = ({ children }: PatientsProviderProps) => {
  return (
    <PatientsContext.Provider value={PatientsContextValues()}>
      {children}
    </PatientsContext.Provider>
  );
};
