import { Client } from 'api/utils';
import { PMedQuestionnaire } from 'models/PMedQuestionnaire';

export const getPMedQuestionaire = async (
  orderId: number,
  orderlineId: number,
): Promise<PMedQuestionnaire> => {
  const response = await Client.get(
    `/Orders/${orderId}/orderLines/${orderlineId}/pmedQuestionnaire`,
  );
  return response.data;
};
