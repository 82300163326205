/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import * as yup from 'yup';
import cx from 'classnames';
import i18n from 'i18n';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useOrdersContext } from 'contexts/OrdersContext';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { OrdersActionsBar } from 'components/OrdersActionsBar';
import { OrderDetailBlock } from 'components/OrderDetailBlock';
import { OrderPatientDetailBlock } from 'components/OrderPatientDetailBlock';
import { OrderSummaryBlock } from 'components/OrderSummaryBlock';
import { OrderNoteBlock } from 'components/OrderNoteBlock';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { Container, Col, Row } from 'components/common/grid';
import { Card } from 'components/Card';
import { Heading } from 'components/common/Heading';
import { Icon } from 'components/common/Icon';
import { ReactComponent as CircleAlert } from 'assets/icons/lawsat/CircleAlert.svg';
import { Page } from 'components/Page';
import { routes } from 'routes';
import { yupResolver } from '@hookform/resolvers';
import { Textarea } from 'components/common/Textarea';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'components/common/Button';
import { Text } from 'components/common/Text';
import { useModalContext } from 'contexts/ModalContext';
import { SubmitRefundModal } from 'components/SubmitRefundModal';
import { OrderCancelModal } from 'components/OrderCancelModal';
import { ConfirmManualRefundModal } from 'components/ConfirmManualRefundModal';
import { Adjustment, RefundDetail } from 'models/Order';

import styles from './OrderDetails.module.scss';

interface OrderDetailsProps {
  testId?: string;
  className?: string;
}

export const OrderDetails = ({ testId, className }: OrderDetailsProps) => {
  const { orderId } = useParams<{ orderId: string }>();
  const { t } = useTranslation();
  const { open } = useModalContext();
  const {
    setSelectedRows,
    getOrderDetail,
    orderDetail,
    updateOrderAdminNotes,
    loading: { getOrderDetailStatus },
  } = useOrdersContext();

  const {
    prescriptionId,
    adminNotes,
    deliveryOption,
    deliveryDate,
    orderStatus,
    orderReceivedDateTime,
    deliveryTimeSlot,
    containsPrescriptionItems,
    lapsed,
    deliveryName,
    deliveryAddress,
    patientId,
    patientFirstName,
    patientMiddleName,
    patientLastName,
    contactEmail,
    contactPhoneNumber,
    patientGpPractice,
    orderLines,
    subTotal,
    refunded,
    deliveryCost,
    totalVat,
    totalCost,
    note,
    adjustment,
    courier,
    deliveryTrackingNumber,
  } = { ...orderDetail };

  useEffect(() => {
    getOrderDetail(parseInt(orderId));
  }, []);

  useEffect(() => {
    if (orderDetail) {
      setSelectedRows([orderDetail.id]);
      setValue('adminNotes', adminNotes);
      reset({ adminNotes: adminNotes }, { isDirty: false });
    }
  }, [orderDetail]);

  const { control, handleSubmit, formState, setValue, reset } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        adminNotes: yup
          .string()
          .max(
            1000,
            i18n.t('common.formErrors.maxLengthError', {
              max: 1000,
            }),
          )
          .trim(),
      }),
    ),
  });

  const hasUnreviewedPoms =
    orderLines?.reduce((runningCheck, orderline) => {
      if (orderline.isPom && orderline.pomStatus === null) {
        return true;
      }
      return runningCheck;
    }, false) ?? false;

  const hasApprovedPoms = orderLines?.reduce((runningCheck, orderline) => {
    if (orderline.isPom && orderline.pomStatus === 'Approved') {
      return true;
    }
    return runningCheck;
  }, false);

  const hasAmendedPoms = orderLines?.reduce((runningCheck, orderline) => {
    if (orderline.isPom && orderline.pomStatus === 'Amended') {
      return true;
    }
    return runningCheck;
  }, false);

  // refund not yet attempted, but is required
  const isRefundRequired = !!(
    adjustment?.totalRefundAmount && !adjustment.refund
  );

  const hasSuccesfulRefund = adjustment?.refund?.refundStatus === 'Success';
  const hasFailedRefund = adjustment?.refund?.refundStatus === 'Failed';

  const isCancellation =
    orderLines?.length ===
    orderLines?.filter(
      (orderLine) => orderLine.isPom && orderLine.pomStatus === 'Rejected',
    ).length;

  const handleUpdateAdminNotes = (data: { adminNotes: string }) => {
    updateOrderAdminNotes(parseInt(orderId), data.adminNotes);
  };

  const handleDiscardAdminNotesChanges = () => {
    setValue('adminNotes', adminNotes ?? '');
    reset({ adminNotes: adminNotes ?? '' }, { isDirty: false });
  };

  const handleActionRefund = (
    orderIdToAction: string,
    refundAmount: number,
  ) => {
    isCancellation
      ? open(<OrderCancelModal />)
      : open(
          <SubmitRefundModal
            refundAmount={refundAmount}
            orderId={orderIdToAction}
          />,
          'extra-wide',
        );
  };

  const handleManualRefund = (
    orderIdToAction: string,
    refundAmount: number,
  ) => {
    open(
      <ConfirmManualRefundModal
        orderId={orderIdToAction}
        refundAmount={refundAmount}
      />,
    );
  };

  return (
    <Page
      className={cx(styles['order-details'], { [`${className}`]: className })}
      data-testid={testId}
      actionsBar={
        <OrdersActionsBar
          showPagination={false}
          isCancelEnabled={orderStatus !== 'Cancelled'}
        />
      }
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <Breadcrumbs
              links={[
                { text: t('orders.ordersMain'), route: routes.ORDER.BASE },
                {
                  text: `#${orderId}`,
                  route: `${routes.ORDER.SINGLE}/${orderId}`,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
      <div className={cx(styles['order-details__main'])}>
        <Container>
          <Branch
            status={getOrderDetailStatus}
            LoadingComponent={() => (
              <div>
                <Spinner />
              </div>
            )}
            ErrorComponent={() => (
              <div className={styles['order-details__error']}>
                <Icon icon={CircleAlert} size="xl" />
                <Heading
                  tag="h3"
                  size="sm"
                  className={styles['order-details__error-text']}
                >
                  {t('common.error.genericMessage')}
                </Heading>
              </div>
            )}
            Component={() => (
              <Row>
                <Col md={8}>
                  <Card className={styles['order-details__card']}>
                    {orderDetail && (
                      <OrderDetailBlock
                        orderId={orderId}
                        orderReceivedDateTime={orderReceivedDateTime}
                        containsPrescriptionItems={containsPrescriptionItems}
                        deliveryOption={deliveryOption}
                        deliveryTimeSlot={deliveryTimeSlot}
                        deliveryDate={deliveryDate}
                        orderStatus={orderStatus}
                        courier={courier}
                        deliveryTrackingNumber={deliveryTrackingNumber}
                        lapsed={lapsed}
                      />
                    )}
                  </Card>
                  <Card className={styles['order-details__card']}>
                    <Heading tag="h2" size="sm">
                      {t('orders.orderSummaryTitle')}
                    </Heading>
                    <OrderSummaryBlock
                      orderId={Number(orderId)}
                      orderLines={orderLines}
                      subTotal={subTotal}
                      refunded={refunded ?? 0}
                      deliveryCost={deliveryCost}
                      deliveryOption={deliveryOption}
                      totalVat={totalVat}
                      totalCost={totalCost}
                      pomReviewEnabled={
                        adjustment?.refund?.refundStatus !== 'Success'
                      }
                    />
                    {note && <OrderNoteBlock note={note} />}
                  </Card>
                  <Card>
                    <Heading tag="h2" size="sm">
                      {t('orders.orderNotesTitle')}
                    </Heading>
                    <form
                      onSubmit={handleSubmit(handleUpdateAdminNotes)}
                      className={styles['order-details__notes-card']}
                    >
                      <Controller
                        control={control}
                        defaultValue={adminNotes ?? ''}
                        name="adminNotes"
                        render={(props, fieldState) => (
                          <Textarea
                            className={
                              styles['order-details__notes-card__notes-input']
                            }
                            testId="notes-textarea"
                            variant={fieldState.invalid ? 'negative' : 'accent'}
                            maxLength={1000}
                            label=""
                            {...props}
                            caption={t('common.formErrors.maxCaption', {
                              max: '1000',
                            })}
                          />
                        )}
                      />
                      <div
                        className={styles['order-details__notes-card__buttons']}
                      >
                        <Button
                          testId="submit-button"
                          variant="negative"
                          appearance="flat"
                          label={t('orders.discardOrderNotesButton')}
                          onClick={() => handleDiscardAdminNotesChanges()}
                          disabled={!formState.isDirty}
                        />
                        <Button
                          testId="submit-button"
                          label={t('orders.saveOrderNotesButton')}
                          type="submit"
                          disabled={!formState.isDirty}
                        />
                      </div>
                    </form>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <OrderPatientDetailBlock
                      name={`${patientFirstName} ${patientMiddleName} ${patientLastName}`}
                      delivery={deliveryAddress}
                      deliveryName={
                        deliveryOption === 'Collection'
                          ? deliveryName
                          : undefined
                      }
                      email={contactEmail}
                      phone={contactPhoneNumber}
                      gp={patientGpPractice}
                      id={patientId}
                    />
                  </Card>

                  {isRefundRequired && (
                    <RefundRequiredCard
                      adjustment={adjustment}
                      hasUnreviewedPoms={hasUnreviewedPoms}
                      isCancellation={isCancellation}
                      handleClick={() =>
                        handleActionRefund(
                          orderId,
                          adjustment.totalRefundAmount,
                        )
                      }
                    />
                  )}

                  {hasFailedRefund && (
                    <RefundFailedCard
                      adjustment={adjustment}
                      handleClickManualRefund={() =>
                        handleManualRefund(
                          orderId,
                          adjustment.totalRefundAmount,
                        )
                      }
                      handleClickRefund={() =>
                        handleActionRefund(
                          orderId,
                          adjustment.totalRefundAmount,
                        )
                      }
                    />
                  )}

                  {hasSuccesfulRefund && (
                    <RefundSuccesfulCard refund={adjustment.refund!} />
                  )}

                  {!hasUnreviewedPoms &&
                    (hasApprovedPoms || hasAmendedPoms) && (
                      <ManagePrescriptionCard
                        orderId={orderId}
                        prescriptionId={prescriptionId}
                      />
                    )}
                </Col>
              </Row>
            )}
          />
        </Container>
      </div>
    </Page>
  );
};

interface ManagePrescriptionCardProps {
  orderId: string;
  prescriptionId?: number | null;
}

const ManagePrescriptionCard = ({
  orderId,
  prescriptionId,
}: ManagePrescriptionCardProps) => {
  const { t } = useTranslation();
  return (
    <Card className={styles['order-details__manage-prescription-card']}>
      <Heading tag="h2" size="sm">
        {t('orders.managePrescriptions')}
      </Heading>
      {prescriptionId ? (
        <Button
          label={t('prescriptionCreate.viewPrescription')}
          link={{
            to: `${routes.ORDER.SINGLE}/${orderId}${routes.ORDER.PRESCRIPTION.VIEW}/${prescriptionId}`,
          }}
        />
      ) : (
        <Button
          label={t('prescriptionCreate.createPrescription')}
          link={{
            to: `${routes.ORDER.SINGLE}/${orderId}${routes.ORDER.PRESCRIPTION.ADD}`,
          }}
        />
      )}
    </Card>
  );
};

interface RefundRequiredCardProps {
  adjustment: Adjustment;
  hasUnreviewedPoms: boolean;
  isCancellation: boolean;
  handleClick: (event: React.MouseEvent<Element>) => void;
}

const RefundRequiredCard = ({
  adjustment,
  hasUnreviewedPoms,
  isCancellation,
  handleClick,
}: RefundRequiredCardProps) => {
  const { t } = useTranslation();
  return (
    <Card className={styles['order-details__refund-card']}>
      <Heading tag="h5">{t('orders.isRefundRequired')}</Heading>
      <Heading tag="h4">
        {`£${adjustment.totalRefundAmount.toFixed(2)}`}
      </Heading>
      {hasUnreviewedPoms ? (
        <Text size="md">{t('orders.reviewAllPoms')}</Text>
      ) : (
        <Button
          label={
            isCancellation
              ? t('orders.cancelOrderButton')
              : t('orders.submitRefundButton')
          }
          appearance={isCancellation ? 'flat' : 'solid'}
          variant={isCancellation ? 'negative' : 'accent'}
          onClick={handleClick}
        ></Button>
      )}
    </Card>
  );
};

interface RefundFailedCardProps {
  adjustment: Adjustment;
  handleClickRefund: (event: React.MouseEvent<Element>) => void;
  handleClickManualRefund: (event: React.MouseEvent<Element>) => void;
}

const RefundFailedCard = ({
  adjustment,
  handleClickRefund,
  handleClickManualRefund,
}: RefundFailedCardProps) => {
  const { t } = useTranslation();
  return (
    <Card
      className={cx(
        styles['order-details__refund-card'],
        styles['order-details__refund-card--failed'],
      )}
    >
      <Heading tag="h5">{t('orders.refundStatus')}</Heading>
      <Heading tag="h4">
        {t('orders.refundFailed', {
          amount: adjustment.refund?.refundedAmount.toFixed(2),
        })}
      </Heading>
      {adjustment?.refund?.errorMessage && (
        <Text>{adjustment.refund.errorMessage}</Text>
      )}
      <Button
        label={t('orders.tryAgainButton')}
        appearance="solid"
        variant="accent"
        onClick={handleClickRefund}
      />
      <Button
        label={t('orders.processRefundManuallyButton')}
        appearance="minimal"
        onClick={handleClickManualRefund}
      />
    </Card>
  );
};

interface RefundSuccessfulCardProps {
  refund: RefundDetail;
}

const RefundSuccesfulCard = ({
  refund: { refundedAmount, refundStatus, lastFourDigits, isManual },
}: RefundSuccessfulCardProps) => {
  const { t } = useTranslation();

  if (refundStatus === 'Failed') {
    return null;
  }

  return (
    <Card
      className={cx(
        styles['order-details__refund-card'],
        styles['order-details__refund-card--success'],
      )}
    >
      <Heading tag="h5">{t('orders.refundStatus')}</Heading>
      <Heading tag="h4">{t('orders.refundSucceeded')}</Heading>
      <Text size="lg">
        {refundStatus === 'Success' && !isManual
          ? t('orders.refundSucceededDetails', {
              amount: refundedAmount.toFixed(2),
              lastFourCardDigits: lastFourDigits,
            })
          : t('orders.manualRefundCompleted')}
      </Text>
    </Card>
  );
};
