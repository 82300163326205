import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAdminContext } from 'contexts/AdminContext';
import { useSettingsContext } from 'contexts/SettingsContext';
import { routes } from 'routes';
import { Container, Col, Row } from 'components/common/grid';
import { Page } from 'components/Page';
import { TableHeader } from 'components/TableHeader';
import { Table, TableRow, TDCell, THCellProps } from 'components/table';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { ActionsBar } from 'components/ActionsBar';
import styles from './AdminList.module.scss';

export const AdminList: React.FC = () => {
  const { t } = useTranslation();
  const {
    loading: { getOperatorsStatus },
    isMainView,
    setIsMainView,
    operatorList,
    getOperators,
    adminListFilters,
    setPage,
  } = useAdminContext();
  const { pharmacies, getPharmaciesList, pharmaciesStatus } =
    useSettingsContext();

  const { page, pages } = adminListFilters;

  const tabs = [
    {
      label: t('admin.operators'),
      active: isMainView,
      value: true,
    },
    {
      label: t('admin.pharmacies'),
      active: !isMainView,
      value: false,
    },
  ];

  const actions = [
    {
      label: t('admin.operatorsCTA'),
      link: { to: `${routes.ADMIN.OPERATOR}/add` },
    },
    {
      label: t('admin.pharmacyCTA'),
      link: { to: `${routes.ADMIN.PHARMACY}/add` },
    },
  ];

  const tableHeaders: THCellProps[] = [
    {
      title: t(
        isMainView
          ? 'admin.tableHeaders.operatorName'
          : 'admin.tableHeaders.pharmacyName',
      ),
      sortable: false,
    },
    {
      title: t('admin.tableHeaders.email'),
      sortable: false,
    },
    {
      title: t('admin.tableHeaders.phone'),
      sortable: false,
    },
  ];

  React.useEffect(() => {
    isMainView ? getOperators() : getPharmaciesList();
  }, [isMainView]);

  return (
    <Page
      className={'admin-list'}
      actionsBar={
        isMainView ? (
          <ActionsBar
            pagination={{
              page: page ? page : 1,
              pages: pages ? pages : 1,
              change: setPage,
            }}
          />
        ) : undefined
      }
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <TableHeader
              title={t('admin.title')}
              tabs={tabs}
              changeTab={setIsMainView}
              action={isMainView ? actions[0] : actions[1]}
            />
            <Table
              className={`${
                getOperatorsStatus === 'loading' ||
                pharmaciesStatus === 'loading'
                  ? styles['admin-list__loading']
                  : ''
              }`}
              selectable={false}
              headers={tableHeaders}
            >
              <Branch
                status={isMainView ? getOperatorsStatus : pharmaciesStatus}
                LoadingComponent={() => (
                  <tr>
                    <td colSpan={8}>
                      <Spinner />
                    </td>
                  </tr>
                )}
                Component={() => (
                  <>
                    {isMainView
                      ? operatorList?.map(
                          ({
                            id,
                            mfaPhoneNumber,
                            emailAddress,
                            firstName,
                            lastName,
                          }) => {
                            return (
                              <TableRow key={id} selectable={false}>
                                <TDCell
                                  link={{
                                    to: `${routes.ADMIN.OPERATOR}/${id}`,
                                    text: `${firstName} ${lastName}`,
                                  }}
                                />
                                <TDCell line1={emailAddress} />
                                <TDCell line1={mfaPhoneNumber} />
                              </TableRow>
                            );
                          },
                        )
                      : pharmacies?.map(
                          ({ id, emailAddress, name, phoneNumber }) => {
                            return (
                              <TableRow key={id} selectable={false}>
                                <TDCell
                                  link={{
                                    to: `${routes.ADMIN.PHARMACY}/${id}`,
                                    text: name,
                                  }}
                                />
                                <TDCell line1={emailAddress} />
                                <TDCell line1={phoneNumber} />
                              </TableRow>
                            );
                          },
                        )}
                  </>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
