import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useToastContext } from 'contexts/ToastContext';
import { useProductsContext } from 'contexts/ProductsContext';
import { Product } from 'models/Product';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { TableSearch } from 'components/TableSearch';
import { SelectList, listValues } from 'components/SelectList';
import styles from './AddProductModal.module.scss';

interface AddMedicationModalProps {
  testId?: string;
  className?: string;
  loading: boolean;
  addFn: Function;
}

export const AddProductModal: React.FC<AddMedicationModalProps> = ({
  testId,
  className,
  loading,
  addFn,
}) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const {
    products,
    setProducts,
    loading: { getProductsSearchStatus },
    getProductsBySearch,
  } = useProductsContext();

  React.useEffect(() => {
    return setProducts([]);
  }, []);

  const schema = yup.object().shape({
    selectedProduct: yup.string().required(),
  });

  const convertList = (originalList: Product[]): listValues[] => {
    return originalList.map((item) => {
      return {
        value: item.id.toString(),
        label: item.title,
      };
    });
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('orders.editOrder.error'),
    });
  };

  const onSubmit = async () => {
    const value = getValues('selectedProduct');
    addFn(value);
  };

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <div
      className={cx(styles['add-product-modal'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading size="md" className={styles['add-product-modal__title']}>
        {t('prescriptions.groupForm.productModalTitle')}
      </Heading>

      <TableSearch
        className={styles['add-product-modal__search']}
        label={t('prescriptions.groupForm.productModalSearch')}
        placeholder={t('prescriptions.groupForm.productModalSearch')}
        changeSearch={getProductsBySearch}
        searchLoading={getProductsSearchStatus}
      />
      <form data-testid="med-form" onSubmit={handleSubmit(onSubmit, invalid)}>
        <div className={styles['add-product-modal__list-container']}>
          {products.length > 0 && (
            <Controller
              control={control}
              defaultValue=""
              name="selectedProduct"
              isRequired
              render={(props) => (
                <SelectList
                  id="selectedProduct"
                  listValues={convertList(products)}
                  {...props}
                />
              )}
            />
          )}
        </div>
        <div className={styles['add-product-modal__button-container']}>
          <Button
            size="sm"
            className={styles['add-product-modal__button']}
            testId="submit-button"
            type="submit"
            label={t('prescriptions.groupForm.productModalCTA')}
            isLoading={loading}
          />
        </div>
      </form>
    </div>
  );
};
