import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { routes } from 'routes';
import { formatAddress } from 'utils/Address';
import { usePatientsContext } from 'contexts/PatientsContext';
import { Page } from 'components/Page';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { Card } from 'components/Card';
import { Col, Container, Row } from 'components/common/grid';
import { PatientSummaryBlock } from 'components/PatientSummaryBlock';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ErrorState } from 'components/ErrorState';
import { PatientPrescriptionBlock } from 'components/PatientPrescriptionBlock';
import { PatientLinkedAccounts } from 'components/PatientLinkedAccounts';
import { PatientDetailsBlock } from 'components/PatientDetailsBlock';
import { PatientsActionsBar } from 'containers/PatientsActionsBar';
import styles from './PatientView.module.scss';

interface PatientViewProps {
  testId?: string;
  className?: string;
}

export const PatientView: React.FC<PatientViewProps> = ({
  testId,
  className,
}) => {
  const { patientId } = useParams<{ patientId: string }>();
  const { t } = useTranslation();
  const {
    loading: { getPatientDetailStatus },
    getPatientDetail,
    patientDetail,
    setSelectedRows,
  } = usePatientsContext();

  const {
    firstName,
    lastName,
    createdDateTime,
    isDependant,
    lastOrderedDateTime,
    totalSpend,
    totalSpendNumberOfOrders,
    emailAddress,
    dateOfBirth,
    patientGpPractice,
    exemptionReason,
    exemptionExpiryDate,
    nhsNumber,
    pharmacyName,
    patientAddressLine1,
    patientAddressLine2,
    patientAddressLine3,
    patientAddressPostCode,
    patientAddressTownCity,
    phoneNumber,
    contactPreference,
  } = { ...patientDetail };

  React.useEffect(() => {
    setSelectedRows([patientId]);
    getPatientDetail(parseInt(patientId));
  }, [patientId]);

  const addressBasics =
    patientAddressLine1 && patientAddressTownCity && patientAddressPostCode;

  const cardClass = 'patient-view__card';

  return (
    <Page
      className={cx(styles['patient-view'], { [`${className}`]: className })}
      data-testid={testId}
      actionsBar={<PatientsActionsBar showPagination={false} />}
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <Branch
              status={getPatientDetailStatus}
              LoadingComponent={() => <Breadcrumbs links={[]} />}
              Component={() => (
                <Breadcrumbs
                  links={[
                    { text: t('patients.title'), route: routes.PATIENTS.BASE },
                    {
                      text: `${firstName} ${lastName}`,
                      route: `${routes.PATIENTS.BASE}/${patientId}`,
                    },
                  ]}
                />
              )}
            />
          </Col>
        </Row>
      </Container>
      <div className={styles['patient-view__main']}>
        <Container>
          <Branch
            status={getPatientDetailStatus}
            LoadingComponent={() => <Spinner />}
            ErrorComponent={() => <ErrorState />}
            Component={() => (
              <Row>
                <Col md={8}>
                  <Card className={styles[cardClass]}>
                    <PatientSummaryBlock
                      isDependant={isDependant}
                      joinedDate={createdDateTime}
                      lastOrdered={lastOrderedDateTime}
                      totalSpend={totalSpend}
                      totalOrders={totalSpendNumberOfOrders}
                    />
                  </Card>
                  <Card className={styles[cardClass]}>
                    <PatientPrescriptionBlock patientId={Number(patientId)} />
                  </Card>
                  <Card className={styles[cardClass]}>
                    <PatientLinkedAccounts
                      patientId={Number(patientId)}
                      isDependant={isDependant}
                    />
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <PatientDetailsBlock
                      patientId={patientId}
                      name={`${firstName} ${lastName}`}
                      email={emailAddress}
                      dob={dateOfBirth}
                      gpAddress={patientGpPractice}
                      exemptionReason={exemptionReason}
                      exemptionExpiry={exemptionExpiryDate}
                      nhsNumber={nhsNumber}
                      phone={phoneNumber}
                      pharmacyName={pharmacyName}
                      contactPreference={contactPreference}
                      address={
                        addressBasics
                          ? formatAddress({
                              line1: patientAddressLine1,
                              line2: patientAddressLine2 || null,
                              line3: patientAddressLine3 || null,
                              county: null,
                              townCity: patientAddressTownCity,
                              postCode: patientAddressPostCode,
                            })
                          : null
                      }
                    />
                  </Card>
                </Col>
              </Row>
            )}
          />
        </Container>
      </div>
    </Page>
  );
};
