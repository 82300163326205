import React from 'react';
import { THCell, THCellProps } from '../';
import { TableRowSelect } from 'components/TableRowSelect';
import styles from './TableHead.module.scss';

interface TableHeadProps {
  headers: THCellProps[];
  selectable?: boolean;
  onChange?: Function;
  onSort?: Function;
  allRowsSelected?: boolean;
}

export const TableHead: React.FC<TableHeadProps> = ({
  headers,
  selectable = false,
  onChange,
  onSort,
  allRowsSelected,
}) => {
  return (
    <thead className={styles['table-head']}>
      <tr>
        {selectable && (
          <THCell className={styles['table-head__select']}>
            <TableRowSelect
              size="sm"
              value={allRowsSelected}
              label="select all"
              onChange={(newState) => onChange && onChange(newState)}
              className="table-header-select"
            />
          </THCell>
        )}

        {headers.map(({ title, sortable, state, className, field }, index) => {
          return (
            <THCell
              key={index}
              title={title}
              sortable={sortable}
              state={state}
              field={field}
              className={className}
              onSort={onSort}
            />
          );
        })}
      </tr>
    </thead>
  );
};
