import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { OrdersList } from 'containers/OrderList';
import { OrderDetails } from 'containers/OrderDetails';
import { OrderAudit } from 'containers/OrderAudit';
import { PrintOrders } from 'components/PrintOrders';
import { routes } from 'routes';
import { PrescriptionDetails } from 'containers/PrescriptionDetails';
import { PrintPrescriptions } from 'components/PrintPrescriptions';

export const Orders = () => {
  return (
    <Switch>
      <PrivateRoute path={routes.ORDER.BASE} exact>
        <OrdersList />
      </PrivateRoute>
      <PrivateRoute path={`${routes.ORDER.BASE}/delivery`} exact>
        <OrdersList deliveryOption="Delivery" />
      </PrivateRoute>
      <PrivateRoute path={`${routes.ORDER.BASE}/lapsed`} exact>
        <OrdersList lapsed={true} />
      </PrivateRoute>
      <PrivateRoute path={`${routes.ORDER.BASE}/new`} exact>
        <OrdersList newOrders={true} />
      </PrivateRoute>
      <PrivateRoute path={`${routes.ORDER.SINGLE}/:orderId`} exact>
        <OrderDetails />
      </PrivateRoute>
      <PrivateRoute path={`${routes.ORDER.AUDIT}/:orderId`} exact>
        <OrderAudit />
      </PrivateRoute>
      <PrivateRoute
        path={`${routes.ORDER.SINGLE}/:orderId${routes.ORDER.PRESCRIPTION.ADD}`}
        exact
      >
        <PrescriptionDetails />
      </PrivateRoute>
      <PrivateRoute
        path={`${routes.ORDER.SINGLE}/:orderId${routes.ORDER.PRESCRIPTION.VIEW}/:prescriptionId`}
        exact
      >
        <PrescriptionDetails />
      </PrivateRoute>
      <PrivateRoute
        path={`${routes.ORDER.SINGLE}/:orderId${routes.ORDER.PRESCRIPTION.PRINT}`}
        exact
      >
        <PrintPrescriptions />
      </PrivateRoute>
      <PrivateRoute path={`${routes.ORDER.PRINT}/:orderIds`}>
        <PrintOrders />
      </PrivateRoute>
      <PrivateRoute
        path={`${routes.ORDER.SINGLE}/:orderId${routes.ORDER.PRESCRIPTION.ADD}`}
        exact
      >
        <PrescriptionDetails />
      </PrivateRoute>
    </Switch>
  );
};
