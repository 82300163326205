import React from 'react';
import { TableRow, TDCell } from 'components/table';
import { routes } from 'routes';
import { PrescriptionGroupItem } from 'models/Prescriptions/Prescriptions';
import styles from './ProductGroups.module.scss';

interface ProductGroupsProps {
  productGroups?: PrescriptionGroupItem[];
}

export const ProductGroups: React.FC<ProductGroupsProps> = ({
  productGroups,
}) => {
  return (
    <>
      {productGroups?.map(
        (
          { name, id, categoryCode, medicinalProductCount, productCount },
          index,
        ) => {
          return (
            <TableRow key={index} selectable={false}>
              <TDCell
                className={styles['product-groups__group-name']}
                link={{
                  to: `${routes.PRESCRIPTIONS.GROUP}/${id}`,
                  text: name,
                }}
              />
              <TDCell line1={categoryCode} />
              <TDCell line1={medicinalProductCount.toString()} />
              <TDCell line1={productCount.toString()} />
            </TableRow>
          );
        },
      )}
    </>
  );
};
