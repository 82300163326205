import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { DeliveryAddress } from 'models/DeliveryOption';
import { GpPractice } from 'models/GpPractice';
import { formatAddress } from 'utils/Address';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { routes } from 'routes';
import styles from './OrderPatientDetailBlock.module.scss';

interface OrderPatientDetailBlockProps {
  testId?: string;
  className?: string;
  name?: string;
  email?: string;
  phone?: string;
  deliveryName?: string;
  delivery?: DeliveryAddress;
  gp?: GpPractice;
  id?: string | number;
}

export const OrderPatientDetailBlock: React.FC<
  OrderPatientDetailBlockProps
> = ({
  testId,
  className,
  name,
  email,
  phone,
  delivery,
  gp,
  id,
  deliveryName,
}) => {
  const { t } = useTranslation();
  const labelClass = styles['order-patient-detail-block__label'];
  const listClass = styles['order-patient-detail-block__list-item'];

  return (
    <div
      className={cx(styles['order-patient-detail-block'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading tag="h2" size="sm">
        {t('orders.orderPatientDetailTitle')}
      </Heading>
      <ul className={styles['order-patient-detail-block__list']}>
        <li className={listClass}>
          <Text tag="span" className={labelClass}>
            {t('orders.patientDetailLabels.name')}:
          </Text>
          <Text tag="span">{name}</Text>
        </li>
        {email && (
          <li className={listClass}>
            <Text tag="span" className={labelClass}>
              {t('orders.patientDetailLabels.email')}:
            </Text>
            <Text
              className={styles['order-patient-detail-block__email']}
              tag="span"
            >
              {email}
            </Text>
          </li>
        )}
        {phone && (
          <li className={listClass}>
            <Text tag="span" className={labelClass}>
              {t('orders.patientDetailLabels.phone')}:
            </Text>
            <Text tag="span">{phone}</Text>
          </li>
        )}
        {delivery && (
          <li className={listClass}>
            <Text tag="span" className={labelClass}>
              {t('orders.patientDetailLabels.delivery')}:
            </Text>
            <Text tag="span">
              {deliveryName && `${deliveryName}, `}
              {formatAddress(delivery)}
            </Text>
          </li>
        )}
        {gp && (
          <li className={listClass}>
            <Text tag="span" className={labelClass}>
              {t('orders.patientDetailLabels.gp')}:
            </Text>
            <Text tag="span">{formatAddress(gp)}</Text>
          </li>
        )}
      </ul>

      {id && (
        <Button
          className={styles['order-patient-detail-block__button']}
          appearance="flat"
          variant="ui"
          label={t('orders.orderPatientDetailCTA')}
          link={{ to: `${routes.PATIENTS.BASE}/${id}` }}
        />
      )}
    </div>
  );
};
