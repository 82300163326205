import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOrdersContext } from 'contexts/OrdersContext';
import { useModalContext } from 'contexts/ModalContext';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Label } from 'components/common/Label';
import { Button } from 'components/common/Button';
import { Icon } from 'components/common/Icon';
import { ReactComponent as CircleAlert } from 'assets/icons/lawsat/CircleAlert.svg';
import styles from './PatientPmedFormModal.module.scss';

interface PatientPmedFormModalProps {
  testId?: string;
  className?: string;
  orderlineId: number;
  orderId: number;
  itemName: string;
}

export const PatientPmedFormModal: React.FC<PatientPmedFormModalProps> = ({
  orderlineId,
  orderId,
  itemName,
}) => {
  const { t } = useTranslation();
  const {
    getPMedForm,
    pMedForm,
    loading: { getPMedFormStatus },
  } = useOrdersContext();
  const { close } = useModalContext();

  React.useEffect(() => {
    getPMedForm(orderId, orderlineId);
  }, [orderlineId]);

  const answers: Record<string, string> = {
    Myself: t('orders.patientAnswers.Myself'),
    SomeoneElse: t('orders.patientAnswers.SomeoneElse'),
    LessThanAWeek: t('orders.patientAnswers.LessThanAWeek'),
    OneToThreeWeeks: t('orders.patientAnswers.OneToThreeWeeks'),
    OverThreeWeeks: t('orders.patientAnswers.OverThreeWeeks'),
    true: t('orders.patientAnswers.True'),
    false: t('orders.patientAnswers.False'),
    userExistingConditions: t('orders.patientAnswers.userExistingConditions'),
    userOtherMedications: t('orders.patientAnswers.userOtherMedications'),
    additionalComments: t('orders.patientAnswers.additionalComments'),
  };

  const label = 'patient-pmed-form-modal__label';
  const answer = 'patient-pmed-form-modal__answer';
  const questionAnswerContainer =
    'patient-pmed-form-modal__question-answer-container';

  return (
    <div className={styles['patient-pmed-form-modal']}>
      <Branch
        status={getPMedFormStatus}
        LoadingComponent={() => <Spinner />}
        ErrorComponent={() => (
          <>
            <Heading
              size="md"
              className={styles['patient-pmed-form-modal__title']}
            >
              {`${itemName} ${t('orders.patientForm.title')}`}
            </Heading>
            <div className={styles['patient-pmed-form-modal__error']}>
              <Icon icon={CircleAlert} size="xl" />
              <Heading
                tag="h3"
                size="sm"
                className={styles['patient-pmed-form-modal__error-text']}
              >
                {t('common.error.genericMessage')}
              </Heading>
            </div>

            <div
              className={styles['patient-pmed-form-modal__button-container']}
            >
              <Button
                testId="done-button"
                appearance="solid"
                variant="accent"
                label={t('orders.cancelStrings.done')}
                onClick={() => {
                  close();
                }}
              />
            </div>
          </>
        )}
        Component={() => (
          <div>
            <Heading
              size="md"
              className={styles['patient-pmed-form-modal__title']}
            >
              {`${itemName} ${t('orders.patientForm.title')}`}
            </Heading>
            <div className={styles['patient-pmed-form-modal__container']}>
              <div className={styles[questionAnswerContainer]}>
                <Label tag="label" size="sm" className={styles[label]}>
                  {t('orders.patientForm.whoWillUseIt')}
                </Label>
                {pMedForm?.whoWillUseIt && (
                  <Text size="md" className={styles[answer]}>
                    {answers[pMedForm?.whoWillUseIt]}
                  </Text>
                )}
              </div>
              <div className={styles[questionAnswerContainer]}>
                <Label size="sm" className={styles[label]}>
                  {t('orders.patientForm.isUserPregnant')}
                </Label>
                {pMedForm && (
                  <Text size="md" className={styles[answer]}>
                    {answers[pMedForm?.isUserPregnant.toString()]}
                  </Text>
                )}
              </div>
              <div className={styles[questionAnswerContainer]}>
                <Label size="sm" className={styles[label]}>
                  {t('orders.patientForm.isUserBreastFeeding')}
                </Label>
                {pMedForm && (
                  <Text size="md" className={styles[answer]}>
                    {answers[pMedForm?.isUserBreastFeeding.toString()]}
                  </Text>
                )}
              </div>
              <div className={styles[questionAnswerContainer]}>
                <Label size="sm" className={styles[label]}>
                  {t('orders.patientForm.userAge')}
                </Label>
                {pMedForm?.userAgeInYears && (
                  <Text size="md" className={styles[answer]}>
                    {pMedForm?.userAgeInYears}
                  </Text>
                )}
              </div>
              <div className={styles[questionAnswerContainer]}>
                <Label size="sm" className={styles[label]}>
                  {t('orders.patientForm.symptomsUsedFor')}
                </Label>
                {pMedForm?.conditionsSymptomsUsedFor && (
                  <Text size="md" className={styles[answer]}>
                    {pMedForm?.conditionsSymptomsUsedFor}
                  </Text>
                )}
              </div>
              <div className={styles[questionAnswerContainer]}>
                <Label size="sm" className={styles[label]}>
                  {t('orders.patientForm.symptomsDuration')}
                </Label>
                {pMedForm?.userSymptomsDuration && (
                  <Text size="md" className={styles[answer]}>
                    {answers[pMedForm?.userSymptomsDuration]}
                  </Text>
                )}
              </div>

              {pMedForm?.userExistingConditions && (
                <div className={styles[questionAnswerContainer]}>
                  <Label size="sm" className={styles[label]}>
                    {t('orders.patientForm.userExistingConditions')}
                  </Label>
                  <Text size="md" className={styles[answer]}>
                    {pMedForm?.userExistingConditions}
                  </Text>
                </div>
              )}
              {pMedForm?.userOtherMedications && (
                <div className={styles[questionAnswerContainer]}>
                  <Label size="sm" className={styles[label]}>
                    {t('orders.patientForm.userOtherMedications')}
                  </Label>
                  <Text size="md" className={styles[answer]}>
                    {pMedForm?.userOtherMedications}
                  </Text>
                </div>
              )}
              {pMedForm?.additionalComments && (
                <div className={styles[questionAnswerContainer]}>
                  <Label size="sm" className={styles[label]}>
                    {t('orders.patientForm.additionalComments')}
                  </Label>
                  <Text size="md" className={styles[answer]}>
                    {pMedForm?.additionalComments}
                  </Text>
                </div>
              )}
            </div>

            <div
              className={styles['patient-pmed-form-modal__button-container']}
            >
              <Button
                testId="done-button"
                appearance="solid"
                variant="accent"
                label={t('orders.cancelStrings.done')}
                onClick={() => {
                  close();
                }}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
};
