import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  usePrescriptionsContext,
  PrescriptionsFilters,
} from 'contexts/PrescriptionsContext';
import { Table, TableRow, TDCell, THCellProps } from 'components/table';
import { Page } from 'components/Page';
import { ActionsBar } from 'components/ActionsBar';
import { Col, Container, Row } from 'components/common/grid';
import { Branch } from 'components/common/Branch';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Spinner } from 'components/common/Spinner';
import { routes } from 'routes';
import styles from './MedicinalProductsList.module.scss';

interface MedicinalProductsListProps {
  testId?: string;
  className?: string;
  filterByUnmapped: boolean;
}

export const MedicinalProductsList: React.FC<MedicinalProductsListProps> = ({
  testId,
  className,
  filterByUnmapped,
}) => {
  const { t } = useTranslation();
  const {
    loading: { getPrescriptionItemsStatus },
    getPrescriptionItems,
    prescriptionItems,
    prescriptionsFilters,
    setPage,
    setExempt,
  } = usePrescriptionsContext();

  const listFilters: PrescriptionsFilters = {
    page: 1,
    pages: 1,
    isExempt: null,
    assignedToGroup: null,
  };

  React.useEffect(() => {
    const setItems = true;
    filterByUnmapped
      ? getPrescriptionItems(
          { ...listFilters, assignedToGroup: false },
          setItems,
        )
      : getPrescriptionItems(listFilters, setItems);

    return () => setExempt(false);
  }, []);

  const tableHeaders: THCellProps[] = [
    {
      title: t('prescriptions.medicinalProdList.tableHeaders.name'),
      sortable: false,
    },
    {
      title: t('prescriptions.medicinalProdList.tableHeaders.code'),
      sortable: false,
    },
    {
      title: t('prescriptions.medicinalProdList.tableHeaders.group'),
      sortable: false,
    },
  ];

  return (
    <Page
      className={cx(styles['medicinal-products-list'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
      actionsBar={
        <ActionsBar
          pagination={{
            page: prescriptionsFilters.page ? prescriptionsFilters.page : 1,
            pages: prescriptionsFilters.pages ? prescriptionsFilters.pages : 1,
            change: setPage,
          }}
        />
      }
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <Breadcrumbs
              links={[
                {
                  text: t('prescriptions.title'),
                  route: routes.PRESCRIPTIONS.BASE,
                },
                {
                  text: filterByUnmapped
                    ? t('prescriptions.medicinalProdList.unMapTitle', {
                        count: prescriptionsFilters.count,
                      })
                    : t('prescriptions.medicinalProdList.allTitle', {
                        count: prescriptionsFilters.count,
                      }),
                  route: filterByUnmapped
                    ? routes.PRESCRIPTIONS.ITEMS.UNMAPPED
                    : routes.PRESCRIPTIONS.ITEMS.ALL,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col>
            <Table
              className={`${
                getPrescriptionItemsStatus === 'loading'
                  ? styles['medicinal-products-list__loading']
                  : ''
              }`}
              headers={tableHeaders}
              selectable={false}
            >
              <Branch
                status={getPrescriptionItemsStatus}
                LoadingComponent={() => (
                  <tr>
                    <td colSpan={8}>
                      <Spinner />
                    </td>
                  </tr>
                )}
                Component={() => (
                  <>
                    {prescriptionItems.map(
                      (
                        {
                          name,
                          id,
                          medicinalProductGroupName,
                          medicinalProductGroupId,
                        },
                        index,
                      ) => {
                        return (
                          <TableRow key={index} selectable={false}>
                            <TDCell line1={name} />
                            <TDCell line1={id} />
                            {medicinalProductGroupName ? (
                              <TDCell
                                link={{
                                  to: `${routes.PRESCRIPTIONS.GROUP}/${medicinalProductGroupId}`,
                                  text: medicinalProductGroupName,
                                }}
                              />
                            ) : (
                              <TDCell line1={''} />
                            )}
                          </TableRow>
                        );
                      },
                    )}
                  </>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
