import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { formatAddress } from 'utils/Address';
import { GpPractice } from 'models/GpPractice';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { Text } from 'components/common/Text';
import { formatDOBString } from 'utils/transformers';
import styles from './PatientDetailsBlock.module.scss';

interface PatientDetailsBlockProps {
  testId?: string;
  className?: string;
  patientId: string;
  name: string;
  email?: string;
  dob?: string;
  gpAddress?: GpPractice;
  exemptionReason?: string;
  exemptionExpiry?: string;
  nhsNumber?: string;
  phone?: string;
  pharmacyName?: string;
  contactPreference?: string;
  address: string | null;
}

export const PatientDetailsBlock: React.FC<PatientDetailsBlockProps> = ({
  testId,
  className,
  patientId,
  name,
  email,
  dob,
  gpAddress,
  exemptionReason,
  exemptionExpiry,
  nhsNumber,
  phone,
  pharmacyName,
  contactPreference,
  address,
}) => {
  const { t } = useTranslation();
  const labelClass = styles['patient-detail-block__label'];
  const listClass = styles['patient-detail-block__list-item'];

  const expirationDate = exemptionExpiry && formatDOBString(exemptionExpiry);

  return (
    <div
      className={cx(styles['patient-detail-block'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading tag="h2" size="sm">
        {t('orders.orderPatientDetailTitle')}
      </Heading>
      <ul className={styles['patient-detail-block__list']}>
        <li className={listClass}>
          <Text tag="span" className={labelClass}>
            {t('orders.patientDetailLabels.name')}:
          </Text>
          <Text tag="span">{name}</Text>
        </li>
        <li className={listClass}>
          <Text tag="span" className={labelClass}>
            {t('patients.tableHeader.dob')}:
          </Text>
          <Text tag="span">{dob && formatDOBString(dob)}</Text>
        </li>
        {email && (
          <li className={listClass}>
            <Text tag="span" className={labelClass}>
              {t('orders.patientDetailLabels.email')}:
            </Text>
            <Text
              className={styles['order-patient-detail-block__email']}
              tag="span"
            >
              {email}
            </Text>
          </li>
        )}

        {phone && (
          <li className={listClass}>
            <Text tag="span" className={labelClass}>
              {t('orders.patientDetailLabels.phone')}:
            </Text>
            <Text tag="span">{phone}</Text>
          </li>
        )}
        {contactPreference && (
          <li className={listClass}>
            <Text tag="span" className={labelClass}>
              {t('patients.patientDetails.contact')}:
            </Text>
            <Text tag="span">{contactPreference}</Text>
          </li>
        )}
        {address && (
          <li className={listClass}>
            <Text tag="span" className={labelClass}>
              {t('patients.patientDetails.home')}:
            </Text>
            <Text tag="span">{address}</Text>
          </li>
        )}
        {gpAddress && (
          <li className={listClass}>
            <Text tag="span" className={labelClass}>
              {t('orders.patientDetailLabels.gp')}:
            </Text>
            <Text tag="span">{formatAddress(gpAddress)}</Text>
          </li>
        )}
        {pharmacyName && (
          <li className={listClass}>
            <Text tag="span" className={labelClass}>
              {'Pharmacy'}:
            </Text>
            <Text tag="span">{pharmacyName}</Text>
          </li>
        )}
        <li className={listClass}>
          <Text tag="span" className={labelClass}>
            {t('patients.patientDetails.nhsNumber')}:
          </Text>
          <Text tag="span">
            {nhsNumber ? nhsNumber : t('patients.notApplicable')}
          </Text>
        </li>
        {exemptionReason && (
          <li className={listClass}>
            <Text tag="span" className={labelClass}>
              {t('patients.patientDetails.exemption')}:
            </Text>
            <Text tag="span">{exemptionReason}</Text>
            <Text tag="span" className={labelClass}>
              {exemptionExpiry &&
                `${t(
                  'patients.patientDetails.exemptionExpires',
                )} ${expirationDate}`}
            </Text>
          </li>
        )}
      </ul>
      <Button
        className={styles['patient-detail-block__button']}
        appearance="flat"
        variant="ui"
        label={t('patients.patientDetails.viewHistory')}
        link={{ to: `${routes.PATIENTS.ORDERS}/${patientId}` }}
      />
    </div>
  );
};
