import { yupResolver } from '@hookform/resolvers';
import cx from 'classnames';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card } from 'components/Card';
import { Button } from 'components/common/Button';
import { Heading } from 'components/common/Heading';
import { InputText } from 'components/common/InputText';
import { Text } from 'components/common/Text';
import { CountryPhoneCodeSelector } from 'components/CountryPhoneCodeSelector';
import { DeleteOperatorModal } from 'components/DeleteOperatorModal';
import { useAdminContext } from 'contexts/AdminContext';
import { useToastContext } from 'contexts/ToastContext';
import { OperatorDetailsSchema } from 'formSchema/OperatorDetails';
import { AdminDetail } from 'models/Admin';
import { routes } from 'routes';
import { useModalContext } from 'contexts/ModalContext';
import styles from './OperatorForm.module.scss';

interface OperatorFormProps {
  testId?: string;
  className?: string;
  operatorId?: string;
  operatorDetails?: AdminDetail | null;
}

export interface OperatorFormFields {
  firstName: string;
  lastName: string;
  emailAddress: string;
  countryPhoneCode: string;
  phoneNumber: string;
}

export const OperatorForm: React.FC<OperatorFormProps> = ({
  testId,
  className,
  operatorId,
  operatorDetails,
}) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const { open } = useModalContext();
  const history = useHistory();
  const { createOperator, updateOperator, deleteOperator } = useAdminContext();

  const { control, errors, formState, handleSubmit } =
    useForm<OperatorFormFields>({
      resolver: yupResolver(OperatorDetailsSchema()),
    });

  const isNewOperator = operatorId === 'add';

  const [defaultCountryPhoneCode, defaultPhoneNumber]: string[] =
    operatorDetails?.mfaPhoneNumber.split(' ') || [];

  const [countryPhoneCode, setCountryPhoneCode] = useState(
    defaultCountryPhoneCode || '+44',
  );

  const onSubmit = async (data: OperatorFormFields) => {
    if (isNewOperator) {
      createOperator(data);
    } else {
      operatorId && updateOperator(+operatorId, data);
    }
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('common.formErrors.validationErrorsTitle'),
      description: t('common.formErrors.validationErrorsDescription'),
    });
  };

  const inputStyle = styles['operator-form__input'];
  const cardStyle = styles['operator-form__card'];

  return (
    <div
      className={cx(styles['operator-form'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <form
        className={styles['operator-form__form']}
        onSubmit={handleSubmit(onSubmit, invalid)}
      >
        <Card className={cardStyle}>
          <Heading size="sm" className={styles['operator-form__title']}>
            {t('admin.operatorDetails.detailFieldSet')}
          </Heading>
          <Controller
            control={control}
            defaultValue={operatorDetails?.firstName || ''}
            name="firstName"
            isRequired
            render={(props, fieldState) => (
              <InputText
                className={inputStyle}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.firstName?.message}
                label={t('admin.operatorDetails.firstName')}
                disabled={!isNewOperator}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={operatorDetails?.lastName || ''}
            name="lastName"
            isRequired
            render={(props, fieldState) => (
              <InputText
                className={inputStyle}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.lastName?.message}
                label={t('admin.operatorDetails.lastName')}
                disabled={!isNewOperator}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={operatorDetails?.emailAddress || ''}
            name="emailAddress"
            isRequired
            render={(props, fieldState) => (
              <InputText
                className={inputStyle}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.emailAddress?.message}
                label={t('admin.operatorDetails.lawsatEmailAddress')}
                caption={t('admin.operatorDetails.lawsatEmailAddressCaption')}
                disabled={!isNewOperator}
                {...props}
              />
            )}
          />
        </Card>

        <Card className={cardStyle}>
          <Heading size="sm" className={styles['operator-form__title']}>
            {t('admin.operatorDetails.twoStepVerificationFieldSetTitle')}
          </Heading>
          <Text className={styles['operator-form__sub-title']}>
            {t('admin.operatorDetails.twoStepVerificationFieldSetDescription')}
          </Text>

          <Controller
            control={control}
            name="countryPhoneCode"
            defaultValue={defaultCountryPhoneCode || '+44'}
            isRequired
            render={(props, fieldState) => (
              <CountryPhoneCodeSelector
                label={t('admin.operatorDetails.countryCode')}
                className={inputStyle}
                selected={countryPhoneCode}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                setChangedVal={setCountryPhoneCode}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            defaultValue={defaultPhoneNumber || ''}
            isRequired
            render={(props, fieldState) => (
              <InputText
                className={inputStyle}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.phoneNumber?.message}
                label={t('admin.operatorDetails.phoneNumber')}
                {...props}
              />
            )}
          />
        </Card>

        <div className={styles['operator-form__button-container']}>
          {operatorId !== 'add' && (
            <Button
              testId="delete-button"
              className={styles['operator-form__delete-button']}
              appearance="solid"
              variant="negative"
              label={t('admin.operatorDetails.deleteOperator')}
              onClick={() =>
                open(
                  <DeleteOperatorModal
                    operatorId={operatorId}
                    operatorName={`${operatorDetails?.firstName} ${operatorDetails?.lastName}`}
                    deleteOperator={deleteOperator}
                  />,
                )
              }
            />
          )}
        </div>

        <div className={styles['operator-form__footer']}>
          {formState.isDirty && (
            <Heading
              tag="h6"
              size="xs"
              className={styles['operator-form__unsaved-changes']}
            >
              {isNewOperator
                ? t('common.form.unsavedChanges')
                : t('admin.operatorDetails.unsavedPhoneChanges')}
            </Heading>
          )}

          <div className={styles['operator-form__actions-container']}>
            <Button
              testId={'discard-button'}
              className={styles['operator-form__action-button']}
              label={t('common.form.discard')}
              type="button"
              variant="negative"
              appearance="flat"
              onClick={() => {
                history.push(routes.ADMIN.BASE);
              }}
            />

            <Button
              testId="submit-button"
              className={styles['operator-form__action-button']}
              label={
                isNewOperator
                  ? t('admin.operatorDetails.saveAndSendInvite')
                  : t('common.form.save')
              }
              type="submit"
              disabled={!formState.isDirty}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
