import React from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {
  GetLexicalViewerThemeArgs,
  getLexicalViewerTheme,
} from './lexicalViewerTheme';
import { ListNode, ListItemNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';

interface RichTextViewerProps {
  richText?: string;
  placeholder?: JSX.Element;
  themeProps?: GetLexicalViewerThemeArgs;
}

export const RichTextViewer = ({
  richText,
  placeholder = <></>,
  themeProps = { textSize: 'lg' },
}: RichTextViewerProps) => {
  const initialConfig = {
    namespace: 'RichTextViewer',
    theme: getLexicalViewerTheme(themeProps),
    editorState: richText,
    editable: false,
    onError: (error: Error) => {
      console.error(error);
    },
    nodes: [HeadingNode, ListNode, ListItemNode],
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        contentEditable={<ContentEditable />}
        placeholder={placeholder}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <ListPlugin />
    </LexicalComposer>
  );
};
