import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'contexts/ModalContext';
import { usePatientsContext } from 'contexts/PatientsContext';
import { Heading } from 'components/common/Heading';
import { GroupItemList } from 'components/GroupItemList';
import { AddMedicationModal } from 'containers/AddMedicationModal';
import styles from './PatientPrescriptionBlock.module.scss';

interface PatientPrescriptionBlockProps {
  testId?: string;
  className?: string;
  patientId: number;
}

export const PatientPrescriptionBlock: React.FC<
  PatientPrescriptionBlockProps
> = ({ testId, className, patientId }) => {
  const { t } = useTranslation();
  const { open } = useModalContext();
  const {
    loading: { addPrescriptionItemStatus },
    addToPrescriptionItems,
    deletePrescription,
    prescriptionItems,
  } = usePatientsContext();

  const openModal = () =>
    open(
      <AddMedicationModal
        testId="productModal"
        loading={addPrescriptionItemStatus === 'loading'}
        addFn={(value: string) => addToPrescriptionItems(patientId, value)}
        title={t('prescriptions.groupForm.itemModalTitle')}
      />,
    );

  return (
    <div
      className={cx(styles['patient-prescription-block'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading tag="h5" size="sm">
        {t('patients.prescriptions.header')}
      </Heading>
      <GroupItemList
        testId="add-items"
        items={prescriptionItems}
        deleteFn={(itemId: string) => deletePrescription(patientId, itemId)}
        cta={t('prescriptions.groupForm.itemsCTA')}
        ctaFn={() => openModal()}
      />
    </div>
  );
};
