import {
  AccountInfo,
  BrowserCacheLocation,
  SilentRequest,
} from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AD_CLIENT}`,
    authority: `https://${process.env.REACT_APP_AD_TENANT}.b2clogin.com/${process.env.REACT_APP_AD_TENANT}.onmicrosoft.com/${process.env.REACT_APP_AD_SIGNIN}`,
    redirectUri: `${process.env.REACT_APP_URL}`,
    postLogoutRedirectUri: `${process.env.REACT_APP_URL}`,
    knownAuthorities: [`${process.env.REACT_APP_AD_TENANT}.b2clogin.com`],
  },
  cache: {
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
};

const scopes = [
  'openid',
  `https://${process.env.REACT_APP_AD_TENANT}.onmicrosoft.com/api/orders.manage`,
  `https://${process.env.REACT_APP_AD_TENANT}.onmicrosoft.com/api/pharmacies.manage`,
  `https://${process.env.REACT_APP_AD_TENANT}.onmicrosoft.com/api/reference.read`,
  `https://${process.env.REACT_APP_AD_TENANT}.onmicrosoft.com/api/products.manage`,
  `https://${process.env.REACT_APP_AD_TENANT}.onmicrosoft.com/api/patients.manage.all`,
  `https://${process.env.REACT_APP_AD_TENANT}.onmicrosoft.com/api/admins.manage.all`,
];

// Add here scopes for silent token request
export const silentRequest: SilentRequest = {
  scopes: scopes,
  account: {} as AccountInfo,
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: scopes,
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const signUpRequest = {
  scopes: scopes,
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
  authority: `https://${process.env.REACT_APP_AD_TENANT}.b2clogin.com/${process.env.REACT_APP_AD_TENANT}.onmicrosoft.com/${process.env.REACT_APP_AD_SIGNUP}`,
};

export const forgotPasswordUrl = {
  scopes: scopes,
  authority: `https://${process.env.REACT_APP_AD_TENANT}.b2clogin.com/${process.env.REACT_APP_AD_TENANT}.onmicrosoft.com/${process.env.REACT_APP_AD_PASSRESET}`,
};
