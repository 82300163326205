import React from 'react';
import { useTranslation } from 'react-i18next';
import * as msal from '@azure/msal-browser';
import {
  msalConfig,
  loginRequest,
  signUpRequest,
  silentRequest,
} from 'api/utils/AzureConfig';
import { useToastContext } from 'contexts/ToastContext';
import { AxiosInterceptors } from 'components/AxiosInterceptors';

export interface AuthContextProps {
  setAuthStatus: Function;
  authStatus: boolean;
  handleRedirectPromise: Function;
  logout: Function;
  login: Function;
  signUp: Function;
  setAuthFlags: Function;
  clearSessionStorage: Function;
  userName: string | null;
  setUserName: Function;
}

export const defaultValues = {
  setAuthStatus: Function,
  authStatus: false,
  handleRedirectPromise: Function,
  logout: Function,
  login: Function,
  signUp: Function,
  setAuthFlags: Function,
  clearSessionStorage: Function,
  userName: null,
  setUserName: Function,
};

export const clearSessionStorage = (): void => {
  window.sessionStorage.removeItem('Authenticated');
};

export const AuthContext = React.createContext<AuthContextProps>(defaultValues);

export const useAuthContext = (): AuthContextProps =>
  React.useContext(AuthContext);

interface AuthProviderProps {
  children?: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { t } = useTranslation();
  const { setToast, dismissToast } = useToastContext();
  const pc = new msal.PublicClientApplication(msalConfig);
  const [authStatus, setAuthStatus] = React.useState<boolean>(false);
  const [userName, setUserName] = React.useState<string | null>(null);

  const setAuthFlags = (state: boolean) => {
    window.sessionStorage.setItem('Authenticated', state.toString());
    setAuthStatus(state);
  };

  const errorToast = () => {
    setToast({
      status: 'error',
      title: 'Error',
      description: t('common.error.authErrorRedirectMessage'),
    });
  };

  const login = async () => {
    pc.loginRedirect(loginRequest).catch(() => {
      errorToast();
    });
  };

  const signUp = async () => {
    pc.loginRedirect(signUpRequest).catch(() => {
      errorToast();
    });
  };

  const logout = async () => {
    setAuthFlags(false);
    clearSessionStorage();
    const { idToken } = await pc.acquireTokenSilent(silentRequest);
    pc.logoutRedirect({
      idTokenHint: idToken,
      postLogoutRedirectUri: `${process.env.REACT_APP_URL}`,
    });
  };

  const handleRedirectPromise = async (requestType?: string) => {
    setAuthFlags(false);
    try {
      const response = await pc.handleRedirectPromise();

      if (response === null) {
        if (requestType === 'signUp') {
          signUp();
        } else {
          login();
        }
      } else {
        setAuthFlags(true);
        dismissToast();
      }
    } catch (error) {
      setAuthFlags(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        setAuthStatus,
        authStatus,
        handleRedirectPromise,
        logout,
        login,
        signUp,
        setAuthFlags,
        clearSessionStorage,
        userName,
        setUserName,
      }}
    >
      <AxiosInterceptors />
      {children}
    </AuthContext.Provider>
  );
};
