import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { useToastContext } from 'contexts/ToastContext';
import { usePharmaciesContext } from 'contexts/PharmaciesContext';
import { PharmacyDetailsSchema } from 'formSchema/PharmacyDetails';
import { PharmacyDetail } from 'models/Pharmacy';
import { Card } from 'components/Card';
import { Heading } from 'components/common/Heading';
import { InputText } from 'components/common/InputText';
import { Textarea } from 'components/common/Textarea';
import { FormFooter } from 'components/FormFooter';
import styles from './PharmacyForm.module.scss';

interface PharmacyFormProps {
  testId?: string;
  className?: string;
  isAdding: boolean;
  pharmacyDetail: PharmacyDetail | null;
}

export const PharmacyForm: React.FC<PharmacyFormProps> = ({
  testId,
  className,
  isAdding,
  pharmacyDetail,
}) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const history = useHistory();
  const { addPharmacy } = usePharmaciesContext();

  const { control, formState, errors, handleSubmit } = useForm({
    resolver: yupResolver(PharmacyDetailsSchema()),
  });

  const onSubmit = async (data: PharmacyDetail) => {
    addPharmacy(data);
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('admin.pharmacyForm.errors.toastTitle'),
      description: t('admin.pharmacyForm.errors.toastDescription'),
    });
  };

  const headingStyles = styles['pharmacy-form__heading'];
  const cardStyle = styles['pharmacy-form__card'];

  const captions = [
    t('admin.pharmacyForm.outwardCaption1'),
    t('admin.pharmacyForm.outwardCaption2'),
  ];

  return (
    <div
      className={cx(styles['pharmacy-form'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <form onSubmit={handleSubmit(onSubmit, invalid)}>
        <Card className={cardStyle}>
          <Heading size="sm" className={styles[headingStyles]}>
            {t('admin.tableHeaders.pharmacyName')}
          </Heading>
          <Controller
            control={control}
            name={'name'}
            defaultValue={pharmacyDetail?.name || ''}
            render={(props, fieldState) => (
              <InputText
                testId="name-input"
                label=""
                size="md"
                variant={fieldState.invalid ? 'negative' : 'accent'}
                caption={t('common.formErrors.maxCaption', {
                  max: '50',
                })}
                validationError={errors.name?.message}
                disabled={!isAdding}
                {...props}
              />
            )}
          />
        </Card>
        <Card className={cardStyle}>
          <Heading size="sm" className={headingStyles}>
            {t('admin.pharmacyForm.address')}
          </Heading>
          <Controller
            control={control}
            name={'addressLine1'}
            defaultValue={pharmacyDetail?.addressLine1 || ''}
            render={(props, fieldState) => (
              <InputText
                label={t('admin.pharmacyForm.addressLine1')}
                size="md"
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.addressLine1?.message}
                disabled={!isAdding}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name={'addressLine2'}
            defaultValue={pharmacyDetail?.addressLine2 || ''}
            render={(props, fieldState) => (
              <InputText
                label={t('admin.pharmacyForm.addressLineOptional', {
                  number: 2,
                })}
                size="md"
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.addressLine2?.message}
                disabled={!isAdding}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name={'addressLine3'}
            defaultValue={pharmacyDetail?.addressLine3 || ''}
            render={(props, fieldState) => (
              <InputText
                label={t('admin.pharmacyForm.addressLineOptional', {
                  number: 3,
                })}
                size="md"
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.addressLine3?.message}
                disabled={!isAdding}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name={'addressTownCity'}
            defaultValue={pharmacyDetail?.addressTownCity || ''}
            render={(props, fieldState) => (
              <InputText
                label={t('admin.pharmacyForm.townCity')}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                size="md"
                validationError={errors.addressTownCity?.message}
                disabled={!isAdding}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name={'addressCounty'}
            defaultValue={pharmacyDetail?.addressCounty || ''}
            render={(props, fieldState) => (
              <InputText
                label={t('admin.pharmacyForm.county')}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                size="md"
                validationError={errors.addressCounty?.message}
                disabled={!isAdding}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name={'addressPostCode'}
            defaultValue={pharmacyDetail?.addressPostCode || ''}
            render={(props, fieldState) => (
              <InputText
                label={t('admin.pharmacyForm.postcode')}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                size="md"
                validationError={errors.addressPostCode?.message}
                disabled={!isAdding}
                {...props}
              />
            )}
          />
        </Card>
        <Card className={cardStyle}>
          <Heading size="sm" className={headingStyles}>
            {t('admin.pharmacyForm.contactInfo')}
          </Heading>
          <Controller
            control={control}
            name={'phoneNumber'}
            defaultValue={pharmacyDetail?.phoneNumber || ''}
            render={(props, fieldState) => (
              <InputText
                label={t('admin.tableHeaders.phone')}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                size="md"
                validationError={errors.phoneNumber?.message}
                disabled={!isAdding}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name={'emailAddress'}
            defaultValue={pharmacyDetail?.emailAddress || ''}
            render={(props, fieldState) => (
              <InputText
                label={t('admin.tableHeaders.email')}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                size="md"
                validationError={errors.emailAddress?.message}
                disabled={!isAdding}
                {...props}
              />
            )}
          />
        </Card>
        <Card className={cx(cardStyle, styles['pharmacy-form__last-card'])}>
          <Heading size="sm" className={headingStyles}>
            {t('admin.pharmacyForm.catchment')}
          </Heading>
          <Controller
            control={control}
            name={'deliveryCatchmentAreas'}
            defaultValue={pharmacyDetail?.deliveryCatchmentAreas || ''}
            render={(props, fieldState) => (
              <Textarea
                label={t('admin.pharmacyForm.outward')}
                captions={captions}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                size="md"
                rows={5}
                validationError={errors.deliveryCatchmentAreas?.message}
                disabled={!isAdding}
                {...props}
              />
            )}
          />
        </Card>
        {isAdding && (
          <FormFooter
            isDirty={formState.isDirty}
            discardFn={() => history.push(routes.ADMIN.BASE)}
            unsavedText={t('common.form.unsaved')}
          />
        )}
      </form>
    </div>
  );
};
