import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'components/common/grid';
import { Spinner } from 'components/common/Spinner';
import { useAuthContext } from 'contexts/AuthContext';
import { routes } from 'routes';
import styles from './NewAccount.module.scss';

export const NewAccount: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const { authStatus, handleRedirectPromise } = useAuthContext();

  const redirectUrl = routes.DEFAULT_AUTH_ROUTE;

  React.useEffect(() => {
    handleRedirectPromise('signUp');
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && authStatus && <Redirect to={redirectUrl} />}
      {loading && !authStatus && (
        <Container className={styles['landing-page']}>
          <Row>
            <Col>
              <Spinner />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
