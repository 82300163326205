import React from 'react';
import { useModalContext } from 'contexts/ModalContext';
import { useTranslation } from 'react-i18next';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { useOrdersContext } from 'contexts/OrdersContext';

import styles from './SubmitRefundModal.module.scss';

interface SubmitRefundModalProps {
  refundAmount: number;
  orderId: string;
}
export const SubmitRefundModal = ({
  refundAmount,
  orderId,
}: SubmitRefundModalProps) => {
  const { t } = useTranslation();
  const { close } = useModalContext();
  const {
    processAutomaticRefund,
    loading: { processAutomaticRefundStatus },
  } = useOrdersContext();

  return (
    <div className={styles['submit-refund-modal']}>
      <Heading tag="h4">{t('orders.submitRefundModal.heading')}</Heading>
      <Text>
        {t('orders.submitRefundModal.info', {
          refundAmount: refundAmount.toFixed(2),
        })}
      </Text>
      <div className={styles['submit-refund-modal__buttons']}>
        <Button
          variant="accent"
          appearance="flat"
          label={t('orders.submitRefundModal.backButton')}
          disabled={processAutomaticRefundStatus === 'loading'}
          onClick={() => {
            close();
          }}
        />
        <Button
          label={t('orders.submitRefundModal.confirmButton')}
          isLoading={processAutomaticRefundStatus === 'loading'}
          onClick={async () => {
            await processAutomaticRefund(orderId);
            close();
          }}
        />
      </div>
    </div>
  );
};
