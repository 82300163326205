import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/IconButton';
import { ReactComponent as Add } from 'assets/icons/lawsat/Add.svg';
import { ReactComponent as Minus } from 'assets/icons/lawsat/Minus.svg';

import styles from './QuantitySelector.module.scss';

interface QuantitySelectorProps {
  maxQuantity?: number;
  quantitySelected: number;
  isDisabled?: boolean;
  onClickDecreaseQuantity: () => void;
  onClickIncreaseQuantity: () => void;
}

export const QuantitySelector = ({
  maxQuantity,
  quantitySelected,
  isDisabled,
  onClickDecreaseQuantity,
  onClickIncreaseQuantity,
}: QuantitySelectorProps) => {
  const { t } = useTranslation();

  const iconButtonStyle = styles['quantity-selector__button'];

  return (
    <div className={cx(styles['quantity-selector'])}>
      <IconButton
        testId="decrease-button"
        icon={Minus}
        label={t('OrderFlow.Qty.removeSingle')}
        className={iconButtonStyle}
        onClick={!isDisabled ? onClickDecreaseQuantity : undefined}
        disabled={isDisabled ? true : quantitySelected === 1}
      />
      <span
        className={styles['quantity-selector__quantity']}
        data-testid="quantity-to-add"
      >
        {quantitySelected}
      </span>
      <IconButton
        icon={Add}
        testId="increase-button"
        label={t('OrderFlow.Qty.add')}
        className={iconButtonStyle}
        onClick={!isDisabled ? onClickIncreaseQuantity : undefined}
        disabled={
          isDisabled
            ? true
            : maxQuantity
            ? quantitySelected >= maxQuantity
            : false
        }
      />
    </div>
  );
};
