import React from 'react';
import { Container, Row, Col } from 'components/common/grid';
import { Spinner } from 'components/common/Spinner';
import { useAuthContext } from 'contexts/AuthContext';

interface AuthWrapperProps {
  children?: React.ReactNode;
}

export const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const [loading, setLoading] = React.useState(true);
  const { authStatus, handleRedirectPromise } = useAuthContext();

  React.useEffect(() => {
    handleRedirectPromise('login');
    setLoading(false);
  }, []);

  return (
    <>
      {(loading || !authStatus) && (
        <>
          <Container>
            <Row>
              <Col>
                <Spinner />
              </Col>
            </Row>
          </Container>
        </>
      )}
      {authStatus && <>{children}</>}
    </>
  );
};
