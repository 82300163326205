import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/IconButton';
import { Heading } from 'components/common/Heading';
import { ReactComponent as ArrowLeft } from 'assets/icons/lawsat/ArrowLeft.svg';
import styles from './Pagination.module.scss';

interface PaginationProps {
  testId?: string;
  className?: string;
  page: number;
  pages: number;
  change: (to: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  testId,
  className,
  page,
  pages,
  change,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(styles['pagination'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <IconButton
        testId="back-btn"
        icon={ArrowLeft}
        label={t('common.pagination.backButton')}
        disabled={page <= 1}
        onClick={() => {
          change(page - 1);
        }}
      />
      <Heading tag="span" size="xs" className={cx(styles['pagination__text'])}>
        {page} {t('common.pagination.of')} {pages}
      </Heading>

      <IconButton
        testId="fwd-btn"
        className={cx(styles['pagination__forward'])}
        icon={ArrowLeft}
        label={t('common.pagination.fwdButton')}
        disabled={page >= pages}
        onClick={() => {
          change(page + 1);
        }}
      />
    </div>
  );
};
