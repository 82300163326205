import * as yup from 'yup';
import i18n from 'i18next';

export const ProductDetailsSchema = (): yup.ObjectSchema => {
  const maxLength3000Error = i18n.t('common.formErrors.maxLengthError', {
    max: 3000,
  });
  return yup.object().shape(
    {
      title: yup
        .string()
        .required(i18n.t('products.formErrors.productName'))
        .trim(),
      productCode: yup.string().notRequired().trim(),
      price: yup
        .number()
        .typeError(i18n.t('products.formErrors.typeNumber'))
        .required(i18n.t('products.formErrors.price'))
        .test(
          'maxDigitsAfterDecimal',
          i18n.t('products.formErrors.priceFormat'),
          (number) => /^\d+(\.\d{1,2})?$/.test(String(number)),
        ),
      taxBandId: yup.string().required(i18n.t('products.formErrors.tax')),
      productCategoryId: yup.string(),
      orderLimit: yup
        .string()
        .test(
          'wholeNumber',
          i18n.t('products.formErrors.orderLimitType'),
          (value) => value !== '0' && /^[0-9]*$/.test(String(value)),
        ),
      isPMed: yup
        .boolean()
        .test(
          'notBothChecked',
          i18n.t('products.formErrors.invalidAttributes'),
          function (this: yup.TestContext, value: boolean | null | undefined) {
            const isPom = this.parent.isPom;
            return !(value && isPom);
          },
        )
        .optional(),
      isPom: yup
        .boolean()
        .test(
          'notBothChecked',
          i18n.t('products.formErrors.invalidAttributes'),
          function (this: yup.TestContext, value: boolean | null | undefined) {
            const isPMed = this.parent.isPMed;
            return !(value && isPMed);
          },
        )
        .optional(),
      productConditionId: yup.string().when('isPom', {
        is: true,
        then: yup
          .string()
          .required(i18n.t('products.formErrors.conditionRequiredForPom')),
        otherwise: yup.string().notRequired(),
      }),
      inStock: yup.boolean().optional(),
      imageFile: yup
        .mixed()
        .optional()
        .when('imageFile', {
          is: (value) => value !== undefined,
          then: yup
            .mixed()
            .test(
              'notBiggerThan2mb',
              i18n.t('common.formErrors.fileSizeLimit'),
              (file) => file.size / Math.pow(1024, 2) <= 2,
            ),
        })
        .when('imageFile', {
          is: (value) => value !== undefined,
          then: yup
            .mixed()
            .test(
              'fileTypeIsJpg',
              i18n.t('common.formErrors.incorrectFileType'),
              (file) => file.type === 'image/jpeg',
            ),
        }),
      suitableFor: yup.string().max(3000, maxLength3000Error).trim(),
      howToUse: yup.string().max(3000, maxLength3000Error).trim(),
      ingredients: yup.string().max(3000, maxLength3000Error).trim(),
    },
    [['imageFile', 'imageFile']],
  );
};
