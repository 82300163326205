import React from 'react';
import cx from 'classnames';
import { TDCell } from '..';
import { TableRowSelect } from 'components/TableRowSelect';
import styles from './TableRow.module.scss';

interface TableRowProps {
  selectable?: boolean;
  selected?: boolean;
  onChange?: Function;
  label?: string;
  value?: string | number;
  children?: React.ReactNode;
}

export const TableRow = ({
  selectable,
  selected,
  onChange,
  value,
  label,
  children,
}: TableRowProps) => {
  return (
    <tr
      className={cx(`${styles['table-row']}`, {
        [`${styles['table-row--selected']}`]: selected,
      })}
    >
      {selectable && (
        <TDCell className={cx(`${styles['table-row__select']}`)}>
          <TableRowSelect
            size="sm"
            value={selected}
            label={`${label}`}
            onChange={(newState) => onChange && onChange(newState, value)} // this needs to get function from table somehow
          />
        </TDCell>
      )}
      {children}
    </tr>
  );
};
