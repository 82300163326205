import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useHistory } from 'react-router-dom';
import { useToastContext } from 'contexts/ToastContext';
import { Branch } from 'components/common/Branch';
import { Card } from 'components/Card';
import { Heading } from 'components/common/Heading';
import { InputText } from 'components/common/InputText';
import { Textarea } from 'components/common/Textarea';
import { DropDown } from 'components/DropDown';
import { Spinner } from 'components/common/Spinner';
import { ErrorState } from 'components/ErrorState';
import { Button } from 'components/common/Button';
import { useConditionsContext } from 'contexts/ConditionsContext';
import {
  Condition,
  ConditionProductSummary,
} from 'models/Categorisations/Conditions';
import { ConditionDetailsSchema } from 'formSchema/ConditionDetails';
import { Text } from 'components/common/Text';
import { ReactComponent as ChevronUp } from 'assets/icons/lawsat/ChevronUp.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/lawsat/ChevronDown.svg';
import { IconButton } from 'components/IconButton';
import { moveArrayItem } from 'utils/Arrays';
import { CheckBox } from 'components/common/CheckBox';
import { RichTextEditor } from 'components/RichTextEditor';
import {
  getRichTextLength,
  richTextFieldDefaultState,
  RICH_TEXT_CHARACTER_LIMIT,
  isRichTextLengthValid,
} from 'utils/RichTextField';
import { EditorState } from 'lexical';
import { routes } from 'routes';

import styles from './ConditionForm.module.scss';

interface ConditionFormProps {
  testId?: string;
  className?: string;
  condition?: Condition | null;
}

interface RankedProductCardProps {
  array: ConditionProductSummary[];
  rank: number;
  name: string;
  arraySetter: Dispatch<SetStateAction<ConditionProductSummary[]>>;
}

const RankedProductCard = ({
  array,
  rank,
  name,
  arraySetter,
}: RankedProductCardProps) => {
  const index = rank - 1;
  return (
    <div className={styles['condition-form__ranked-product']}>
      <Text
        tag="span"
        className={styles['condition-form__ranked-product__rank']}
      >
        {rank}
      </Text>
      <Text
        tag="span"
        className={styles['condition-form__ranked-product__title']}
      >
        {name}
      </Text>
      <IconButton
        size="sm"
        icon={ChevronUp}
        label="Move product up rankings"
        onClick={(e) => {
          e.preventDefault();
          moveArrayItem(array, index, index - 1, arraySetter);
        }}
        disabled={rank === 1}
        className={styles['condition-form__ranked-product__button']}
        testId="move-up-button"
      />
      <IconButton
        size="sm"
        icon={ChevronDown}
        label="Move product down rankings"
        onClick={(e) => {
          e.preventDefault();
          moveArrayItem(array, index, index + 1, arraySetter);
        }}
        disabled={rank === array.length}
        className={styles['condition-form__ranked-product__button']}
        testId="move-down-button"
      />
    </div>
  );
};

export const ConditionForm = ({
  testId,
  className,
  condition,
}: ConditionFormProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const {
    conditionGroups,
    updateCondition,
    loading: { getConditionGroupsStatus },
    getConditionGroups,
  } = useConditionsContext();

  const history = useHistory();
  const [conditionGroupIdValue, setConditionGroupIdValue] = useState<number>();
  const [richTextLength, setRichTextLength] = useState(0);

  const { control, handleSubmit, errors, formState, setValue } = useForm({
    resolver: yupResolver(ConditionDetailsSchema()),
  });

  const [updatedRankedProducts, setUpdatedRankedProducts] = useState<
    ConditionProductSummary[]
  >(condition?.products ? [...condition.products] : []);

  useEffect(() => {
    condition && setUpdatedRankedProducts([...condition.products]);
  }, [condition]);

  useEffect(() => {
    getConditionGroups();
  }, []);

  useEffect(() => {
    setConditionGroupIdValue(condition?.conditionGroupId);
  }, [condition?.conditionGroupId]);

  const onSubmit = async (data: Condition) => {
    if (condition?.id) {
      if (formState.isDirty || rankingsUpdated) {
        updateCondition({
          condition: { ...condition, ...data },
          rankedProducts:
            (rankingsUpdated &&
              updatedRankedProducts.map((product, index) => ({
                productId: product.id,
                rank: index + 1,
              }))) ||
            undefined,
        });
      }
      history.push(routes.CONDITIONS.BASE);
    }
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('common.formErrors.validationErrorsTitle'),
      description: t('common.formErrors.validationErrorsDescription'),
    });
  };

  const richTextHandler = (state: EditorState) => {
    setValue('description', JSON.stringify(state), { shouldDirty: true });
    setRichTextLength(getRichTextLength(state));
  };

  const inputStyle = styles['condition-form__input'];
  const cardStyle = styles['condition-form__card'];
  const formColStyle = styles['condition-form__form-col'];
  const maxTextCaptionTranslation = 'common.formErrors.maxCaption';

  const rankingsUpdated =
    updatedRankedProducts.length > 0 &&
    JSON.stringify(updatedRankedProducts) !==
      JSON.stringify(condition?.products);

  return (
    <div
      className={cx(styles['condition-form'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <Branch
        status={getConditionGroupsStatus}
        ErrorComponent={() => <ErrorState testId="form-error-state" />}
        LoadingComponent={() => <Spinner testId="form-loading-spinner" />}
        Component={() => (
          <form
            className={styles['condition-form__form']}
            onSubmit={handleSubmit(onSubmit, invalid)}
          >
            <Card className={cardStyle}>
              <Heading size="sm" className={styles['condition-form__title']}>
                {t('conditions.form.detailFieldSet')}
              </Heading>
              <div className={styles['condition-form__form-col-wrap']}>
                <div
                  className={cx(
                    formColStyle,
                    styles['condition-form__form-col--wide'],
                  )}
                >
                  <Controller
                    control={control}
                    defaultValue={condition?.name ?? ''}
                    name="name"
                    isRequired
                    render={(props, fieldState) => (
                      <InputText
                        disabled
                        className={inputStyle}
                        variant={fieldState.invalid ? 'negative' : 'accent'}
                        validationError={errors.name?.message}
                        maxLength={300}
                        label={t('conditions.form.name')}
                        {...props}
                        caption={t(maxTextCaptionTranslation, {
                          max: '300',
                        })}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    defaultValue={condition?.conditionGroupId ?? ''}
                    name="conditionGroupId"
                    isRequired
                    render={(props, fieldState) => (
                      <DropDown
                        disabled
                        className={inputStyle}
                        label={t('conditions.form.parentGroup')}
                        variant={fieldState.invalid ? 'negative' : 'accent'}
                        validationError={errors.parentGroup?.message}
                        values={[
                          { id: 0, name: 'Unassigned' },
                          ...conditionGroups,
                        ]}
                        selected={conditionGroupIdValue?.toString()}
                        setChangedVal={setConditionGroupIdValue}
                        {...props}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    defaultValue={condition?.tagline ?? ''}
                    name="tagline"
                    render={(props, fieldState) => (
                      <Textarea
                        className={inputStyle}
                        variant={fieldState.invalid ? 'negative' : 'accent'}
                        maxLength={500}
                        label={t('conditions.form.tagline')}
                        helperText={t('conditions.form.taglineHelper')}
                        {...props}
                        caption={t(maxTextCaptionTranslation, {
                          max: '500',
                        })}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="description"
                    defaultValue={
                      condition?.description ?? richTextFieldDefaultState
                    }
                    render={() => (
                      <RichTextEditor
                        label={t('conditions.form.description')}
                        helperText={t('conditions.form.descriptionHelper')}
                        caption={t(maxTextCaptionTranslation, {
                          max: RICH_TEXT_CHARACTER_LIMIT,
                        })}
                        onChangeHandler={richTextHandler}
                        richText={
                          condition?.description ?? richTextFieldDefaultState
                        }
                        error={!isRichTextLengthValid(richTextLength)}
                      />
                    )}
                  />
                </div>
                <div className={formColStyle}>
                  <Heading
                    size="xs"
                    className={styles['condition-form__sub-title']}
                  >
                    {t('conditions.form.checkBoxes')}
                  </Heading>
                  <Controller
                    control={control}
                    defaultValue={condition?.showInShop}
                    name="showInShop"
                    render={(props) => (
                      <CheckBox
                        className={styles['condition-form__checkbox']}
                        label={t('conditions.form.show')}
                        size="md"
                        slim={true}
                        orientation="vertical"
                        {...props}
                      />
                    )}
                  />
                </div>
              </div>
            </Card>
            <Card className={cardStyle}>
              <Heading size="sm" className={styles['condition-form__title']}>
                {t('conditions.form.rankFieldSet')}
              </Heading>
              {updatedRankedProducts && updatedRankedProducts.length > 0 ? (
                <div className={styles['condition-form__form-col-wrap']}>
                  <div
                    className={cx(
                      formColStyle,
                      styles['condition-form__form-col--full'],
                    )}
                  >
                    <div className={styles['condition-form__ranked-products']}>
                      <div
                        className={
                          styles['condition-form__ranked-products__heading']
                        }
                      >
                        <Text
                          tag="span"
                          className={cx(
                            styles['condition-form__ranked-product__rank'],
                            styles['condition-form__ranked-product__heading'],
                          )}
                        >
                          {t('conditions.form.headers.rank')}
                        </Text>
                        <Text
                          tag="span"
                          className={cx(
                            styles['condition-form__ranked-product__title'],
                            styles['condition-form__ranked-product__heading'],
                          )}
                        >
                          {t('conditions.form.headers.productName')}
                        </Text>
                      </div>
                      {updatedRankedProducts.map((product, index) => (
                        <RankedProductCard
                          key={product.id}
                          array={updatedRankedProducts}
                          name={product.title}
                          rank={index + 1}
                          arraySetter={setUpdatedRankedProducts}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={
                    styles['condition-form__ranked-products__placeholder']
                  }
                >
                  <Text>{t('conditions.form.rankFieldPlaceholder')}</Text>
                </div>
              )}
            </Card>

            <div className={styles['condition-form__footer']}>
              {formState.isDirty && (
                <Heading
                  tag="h6"
                  size="xs"
                  className={styles['condition-form__unsaved-changes']}
                >
                  {t('common.form.unsavedChanges')}
                </Heading>
              )}

              <div className={styles['condition-form__actions-container']}>
                <Button
                  className={styles['condition-form__action-button']}
                  label={t('common.form.discard')}
                  type="button"
                  variant="negative"
                  appearance="flat"
                  onClick={() => {
                    history.push(routes.CONDITIONS.BASE);
                  }}
                />

                <Button
                  testId="submit-button"
                  className={styles['condition-form__action-button']}
                  label={t('common.form.save')}
                  type="submit"
                  disabled={
                    (!formState.isDirty && !rankingsUpdated) ||
                    !isRichTextLengthValid(richTextLength, 3000)
                  }
                />
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};
