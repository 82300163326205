import React from 'react';
import cx from 'classnames';
import styles from './TabSet.module.scss';

interface TabSetProps {
  testId?: string;
  className?: string;
  tabs: Tab[];
  changeTab: Function;
}

export interface Tab {
  label: string;
  count?: number;
  value: boolean | string;
  active: boolean;
}

export const TabSet: React.FC<TabSetProps> = ({
  testId,
  tabs,
  className,
  changeTab,
}) => {
  return (
    <ul
      className={cx(styles['tab-set'], { [`${className}`]: className })}
      data-testid={testId}
    >
      {tabs &&
        tabs.map(({ label, count, active, value }, index) => {
          return (
            <li key={index} className={cx(styles['tab-set__list-item'])}>
              <button
                className={cx(styles['tab-set__tab'], {
                  [`${styles['tab-set__tab--active']}`]: active,
                })}
                onClick={() => {
                  changeTab(value);
                }}
              >
                {label} {count && `(${count})`}
              </button>
            </li>
          );
        })}
    </ul>
  );
};
