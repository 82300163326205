import { Client } from 'api/utils';
import { Filter } from 'models/Filter';
import { Condition, ConditionGroup } from 'models/Categorisations/Conditions';
import dompurify from 'dompurify';
import { Collection } from 'models/Collection';
import { RankedProduct } from 'models/Product';

export type ConditionPayload = {
  showInShop: boolean;
  description?: string | null;
  summary?: string | null;
  tagline?: string | null;
};

export type ConditionGroupPayload = {
  description?: string | null;
  summary?: string | null;
  tagline?: string | null;
  showInShop: boolean;
};

const sanitizer = dompurify.sanitize;

const getConditionPayload = (
  original: Condition | ConditionGroup,
): ConditionPayload => {
  const payload: ConditionPayload = {
    showInShop: original.showInShop,
    description:
      original.description && original.description !== ''
        ? sanitizer(original.description)
        : null,
    tagline:
      original.tagline && original.tagline !== ''
        ? sanitizer(original.tagline)
        : null,
  };
  return payload;
};

export const getConditionUrl = (
  type: 'Conditions' | 'Groups',
  filters: Filter,
  skip: number,
): string => {
  return `/${
    type === 'Conditions' ? 'ProductConditions' : 'ProductConditionGroups'
  }?top=20&skip=${skip}&sortBy=${filters.sortBy}&sortDirection=${
    filters.sortDirection
  }`;
};

export const getCondition = async (id: number): Promise<Condition> => {
  const response = await Client.get(`/ProductConditions/${id}`);
  return response.data;
};

export const getConditions = async (
  filters: Filter | undefined = undefined,
  skip: number = 0,
): Promise<Collection<Condition>> => {
  const url = filters
    ? getConditionUrl('Conditions', filters, skip)
    : `/ProductConditions`;
  const response = await Client.get(url);
  return response.data;
};

export const getConditionGroup = async (
  id: number,
): Promise<ConditionGroup> => {
  const response = await Client.get(`/ProductConditionGroups/${id}`);
  return response.data;
};

export const getConditionGroups = async (
  filters: Filter | undefined = undefined,
  skip: number = 0,
): Promise<Collection<ConditionGroup>> => {
  const url = filters
    ? getConditionUrl('Groups', filters, skip)
    : `/ProductConditionGroupss`;
  const response = await Client.get(url);
  return response.data;
};

export const updateCondition = async (
  id: number,
  condition: Condition,
): Promise<Condition> => {
  const response = await Client.put(
    `/ProductConditions/${id}`,
    getConditionPayload(condition),
  );

  return response.data;
};

export const updateConditionProductRanks = async (
  id: number,
  productRanks: RankedProduct[],
): Promise<void> => {
  return Client.put(`/ProductConditions/${id}/productRank`, productRanks);
};

export const updateConditionGroup = async (
  conditionGroup: ConditionGroup,
): Promise<ConditionGroup> => {
  const response = await Client.put(
    `/ProductConditionGroups/${conditionGroup.id}`,
    getConditionPayload(conditionGroup),
  );
  return response.data;
};
