import React from 'react';
import cx from 'classnames';
import { MedicinalProduct } from 'models/MedicinalProductGroup';
import { IconButton } from 'components/IconButton';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { ReactComponent as Bin } from 'assets/icons/lawsat/BinRed.svg';
import styles from './GroupItemList.module.scss';

interface GroupItemListProps {
  testId?: string;
  className?: string;
  items: MedicinalProduct[];
  deleteFn: Function;
  cta: string;
  ctaFn: Function;
}

export const GroupItemList: React.FC<GroupItemListProps> = ({
  testId,
  className,
  items,
  deleteFn,
  cta,
  ctaFn,
}) => {
  return (
    <>
      <ul
        className={cx(styles['group-item-list'], {
          [`${className}`]: className,
        })}
        data-testid={testId}
      >
        {items.map((item) => {
          return (
            <li key={item.id} className={styles['group-item-list__item']}>
              <Text className={styles['group-item-list__text']}>
                {item.name}
              </Text>
              <IconButton
                icon={Bin}
                label={'delete'}
                className={styles['group-item-list__delete']}
                onClick={() => deleteFn(item.id)}
              />
            </li>
          );
        })}
      </ul>
      <div className={styles['group-item-list__button']}>
        <Button
          testId={`${testId}-button`}
          label={cta}
          appearance="flat"
          size="sm"
          onClick={() => ctaFn()}
        />
      </div>
    </>
  );
};
