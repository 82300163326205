import { Client } from 'api/utils';
import { GPOrder } from 'models/GPOrder';

export const getGPOrders = async (
  orderIds: (string | number)[],
): Promise<GPOrder[]> => {
  let query = '?';

  orderIds.forEach((item) => {
    query = `${query}orderIds=${item}&`;
  });

  const response = await Client.get(`OrdersForGps${query}`);
  return response.data;
};
