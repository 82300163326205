import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GPOrder } from 'models/GPOrder';
import { formatAddress } from 'utils/Address';
import { formatDateString } from 'utils/transformers';
import { formatOrderline } from 'utils/Orderlines';
import { useModalContext } from 'contexts/ModalContext';
import { useOrdersContext } from 'contexts/OrdersContext';
import { OrderCancelModal } from 'components/OrderCancelModal';
import { OrderStatusModal } from 'components/OrderStatusModal';
import { ActionsBar, Action } from 'components/ActionsBar';
import { routes } from 'routes';

import styles from './OrdersActionsBar.module.scss';

interface OrdersActionsBarProps {
  testId?: string;
  className?: string;
  selected?: string;
  showPagination?: boolean;
  isList?: boolean;
  isPrintEnabled?: boolean;
  isEmailEnabled?: boolean;
  isCancelEnabled?: boolean;
}

export const urlString = (string: string): string => {
  return string.replace(/ /g, '%20');
};

export const orderStringGen = (orderDetail: GPOrder): string => {
  return urlString(
    `${encodeURIComponent('Patient Name:')} ${encodeURIComponent(
      orderDetail.patientFirstName,
    )} ${encodeURIComponent(
      orderDetail.patientLastName,
    )}%0d%0a%0d%0a${encodeURIComponent('Patient DOB:')} ${encodeURIComponent(
      formatDateString(orderDetail.patientDateOfBirth),
    )}%0d%0a%0d%0a${encodeURIComponent(
      'Patient Address:',
    )} ${encodeURIComponent(
      formatAddress(orderDetail.patientAddress),
    )}%0d%0a%0d%0a${encodeURIComponent(
      'Patient GP Address:',
    )} ${encodeURIComponent(
      formatAddress(orderDetail.patientGpPractice),
    )}%0d%0a%0d%0a${encodeURIComponent(
      'Prescription item(s) requested:',
    )} %0d%0a%0d%0a${formatOrderline(orderDetail.prescriptionItems)}`,
  );
};

export const OrdersActionsBar: React.FC<OrdersActionsBarProps> = ({
  testId,
  className,
  selected,
  isList,
  showPagination = true,
  isPrintEnabled = false,
  isEmailEnabled = false,
  isCancelEnabled = false,
}) => {
  const emailRef = React.useRef<HTMLAnchorElement>(null);
  const printRef = React.useRef<HTMLAnchorElement>(null);
  const { t } = useTranslation();
  const { open } = useModalContext();
  const {
    gpOrders,
    setGPOrders,
    getGPOrdersList,
    ordersListFilters: { page, pages },
    setPage,
    selectedRows,
    getStatusList,
    orderDetail,
    loading: { getStatusStatus, getOrderDetailStatus },
  } = useOrdersContext();

  const [buttonClicked, setButtonClicked] = React.useState('');

  const selectedOrderHasUnreviewedPoms = orderDetail?.orderLines.reduce(
    (runningCheck, orderline) => {
      if (orderline.isPom && orderline.pomStatus === null) {
        return true;
      }
      return runningCheck;
    },
    false,
  );

  const selectedOrderHasUnactionedRefund = !!(
    orderDetail?.adjustment?.totalRefundAmount && !orderDetail.adjustment.refund
  );

  const pomActionsIncomplete =
    selectedRows.length === 1 &&
    (!!selectedOrderHasUnreviewedPoms || selectedOrderHasUnactionedRefund);

  const tableActions: Action[] = [
    {
      buttonType: 'ui',
      label: t('orders.actionsBar.email'),
      loading: getOrderDetailStatus === 'loading',
      action: () => {
        selectedRows.length === 1 && getGPOrdersList([Number(selectedRows[0])]);
        setButtonClicked('email');
      },
      disabled: !isEmailEnabled,
    },
    {
      buttonType: 'ui',
      label: t('orders.actionsBar.print'),
      action: async () => {
        selectedRows.length > 0 && getGPOrdersList(selectedRows);
        setButtonClicked('print');
      },
      disabled: !isPrintEnabled,
    },
    {
      buttonType: 'negative',
      label: t('orders.actionsBar.cancel'),
      action: () => {
        open(<OrderCancelModal isList={isList} />);
      },
      disabled: selectedRows.length !== 1 || !isCancelEnabled,
    },
    {
      buttonType: 'accent',
      appearance: 'solid',
      loading: getStatusStatus === 'loading',
      label: t('orders.actionsBar.update'),
      action: () => {
        getStatusList(selectedRows);
        selectedRows.length === 1 &&
          open(
            <OrderStatusModal
              orderId={selectedRows[0]}
              isUpdateDisabled={{
                isDisabled: pomActionsIncomplete,
                reason: t('orders.statusStrings.pomDisabledReason'),
              }}
            />,
          );
      },
      disabled: selectedRows.length !== 1,
    },
  ];

  const paginationObject = showPagination
    ? {
        page: page ?? 1,
        pages: pages ?? 1,
        change: setPage,
      }
    : undefined;

  const [orderString, setOrderString] = React.useState('');
  const [printOrders, setPrintOrders] = React.useState<GPOrder[]>([]);

  const getOrderIds = (orders: GPOrder[]) => {
    const orderIds = orders.map((order) => order.orderId);
    return orderIds.toString();
  };

  React.useEffect(() => {
    if (gpOrders.length > 0 && selectedRows.length === 1) {
      gpOrders[0].orderId === Number(selectedRows[0])
        ? setOrderString(orderStringGen(gpOrders[0]))
        : setOrderString('');
    }
  }, [gpOrders]);

  React.useEffect(() => {
    if (gpOrders.length > 0 && selectedRows.length >= 1) {
      const printableOrders = gpOrders.filter(
        (order) => order.prescriptionItems.length > 0,
      );
      setPrintOrders(printableOrders);
    }
  }, [gpOrders]);

  React.useEffect(() => {
    printOrders.length > 0 && printRef.current?.click();
    setGPOrders([]);
  }, [printOrders]);

  React.useEffect(() => {
    orderString.length > 0 && emailRef.current?.click();
    setGPOrders([]);
    setOrderString('');
  }, [orderString]);

  return (
    <>
      {buttonClicked === 'email' && selectedRows.length === 1 && (
        <a
          className={styles['orders-actions-bar__link']}
          ref={emailRef}
          href={`mailto:?subject=${urlString(
            t('orders.actionsBar.emailTitle'),
          )}&body=${orderString}`}
          target="_blank"
          rel="noreferrer"
        >
          hidden link
        </a>
      )}
      {buttonClicked === 'print' && printOrders.length > 0 && (
        <Link
          to={{
            pathname: `${routes.ORDER.PRINT}/${getOrderIds(printOrders)}`,
          }}
          target="_blank"
          rel="noopener noreferrer"
          ref={printRef}
          className={styles['orders-actions-bar__link']}
        >
          Hidden link{' '}
        </Link>
      )}
      <ActionsBar
        testId={testId}
        className={className}
        pagination={paginationObject}
        actions={tableActions}
        selected={selected}
      />
    </>
  );
};
