import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useProductsContext } from 'contexts/ProductsContext';
import { TableHeader } from 'components/TableHeader';
import { Table, TableRow, TDCell, THCellProps } from 'components/table';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { Container, Col, Row } from 'components/common/grid';
import { Page } from 'components/Page';
import { ActionsBar } from 'components/ActionsBar';
import { ProductImage } from 'components/ProductImage';
import { routes } from 'routes';
import { Icon } from 'components/common/Icon';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { ReactComponent as ShopGreen } from 'assets/icons/lawsat/ShopGreen.svg';
import styles from './ProductList.module.scss';

interface ProductListProps {
  testId?: string;
  className?: string;
}

export const ProductList: React.FC<ProductListProps> = ({
  testId,
  className,
}) => {
  const { t } = useTranslation();
  const {
    getProducts,
    products,
    loading: { getProductsStatus },
    setProductListPage,
    setProductListSort,
    productsFilters,
  } = useProductsContext();

  const { page, pages } = productsFilters;

  React.useEffect(() => {
    getProducts();
  }, []);

  const tableHeaders: THCellProps[] = [
    {
      title: t('products.tableHeaders.created'),
      sortable: false,
    },
    {
      title: t('products.tableHeaders.name'),
      sortable: false,
    },
    {
      title: t('products.tableHeaders.condition'),
      sortable: true,
      field: 'ConditionName',
    },
    {
      title: t('products.tableHeaders.price'),
      sortable: false,
    },
    {
      title: t('products.tableHeaders.pmed'),
      sortable: false,
    },
    {
      title: t('products.tableHeaders.stock'),
      sortable: false,
    },
  ];

  const [columns, setColumns] = React.useState<THCellProps[]>(tableHeaders);

  React.useEffect(() => {
    const updateFilters = columns.map((col) =>
      col.field === productsFilters.sortBy
        ? { ...col, state: productsFilters.sortDirection }
        : { ...col, state: undefined },
    );
    setColumns(updateFilters);
  }, [productsFilters.sortBy, productsFilters.sortDirection]);

  const action = {
    label: t('products.addProduct'),
    link: { to: `${routes.PRODUCTS.ADD}` },
  };

  return (
    <Page
      className={cx(styles['product-list'], { [`${className}`]: className })}
      data-testid={testId}
      actionsBar={
        <ActionsBar
          pagination={{
            page: page ? page : 1,
            pages: pages ? pages : 1,
            change: setProductListPage,
          }}
        />
      }
    >
      <div className={cx(styles['product-list__main'])}>
        <Container fluid={true}>
          <Row>
            <Col>
              <TableHeader title={t('products.title')} action={action} />
              <Table
                className={`${
                  getProductsStatus === 'loading'
                    ? styles['product-list__loading']
                    : ''
                }`}
                headers={columns}
                onSort={setProductListSort}
                selectable={false}
              >
                <Branch
                  status={getProductsStatus}
                  LoadingComponent={() => (
                    <tr>
                      <td colSpan={8}>
                        <Spinner />
                      </td>
                    </tr>
                  )}
                  EmptyComponent={() => (
                    <tr>
                      <td colSpan={8}>
                        <div className={styles['product-list__no_products']}>
                          <Icon
                            icon={ShopGreen}
                            size="xxl"
                            className={styles['product-list__icon']}
                          />
                          <Heading tag="h5" size="sm">
                            {t('products.noProducts')}
                          </Heading>
                          <Text
                            tag="span"
                            className={styles['product-list__text']}
                          >
                            {t('products.whenYouAdd')}
                          </Text>
                        </div>
                      </td>
                    </tr>
                  )}
                  Component={() => (
                    <>
                      {products?.map(
                        ({
                          id,
                          title,
                          price,
                          imageFileName,
                          isPMed,
                          inStock,
                          conditionName,
                        }) => {
                          return (
                            <TableRow key={id} selectable={false}>
                              <TDCell line1={id.toString()} />
                              <TDCell
                                img={
                                  <ProductImage
                                    className={styles['product-list__image']}
                                    imageFileName={imageFileName}
                                    size={48}
                                  />
                                }
                                link={{
                                  to: `${routes.PRODUCTS.BASE}/${id}`,
                                  text: title,
                                }}
                              />
                              <TDCell line1={conditionName} />
                              <TDCell line1={`£${price.toFixed(2)}`} />
                              <TDCell
                                line1={
                                  isPMed
                                    ? t('products.statusString.true')
                                    : t('products.statusString.false')
                                }
                              />
                              <TDCell
                                line1={
                                  inStock
                                    ? t('products.statusString.false')
                                    : t('products.statusString.true')
                                }
                              />
                            </TableRow>
                          );
                        },
                      )}
                    </>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  );
};
