import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePrescriptionsContext } from 'contexts/PrescriptionsContext';
import { useModalContext } from 'contexts/ModalContext';
import { Card } from 'components/Card';
import { Heading } from 'components/common/Heading';
import { AddMedicationModal } from 'containers/AddMedicationModal';
import { MedicinalProduct } from 'models/MedicinalProductGroup';
import { GroupEmptyState } from 'components/GroupEmptyState';
import { GroupItemList } from 'components/GroupItemList';
import styles from './GroupItems.module.scss';

interface GroupItemsProps {
  testId?: string;
  className?: string;
  items?: MedicinalProduct[];
  groupId: string;
}

export const GroupItems: React.FC<GroupItemsProps> = ({
  testId,
  className,
  items,
  groupId,
}) => {
  const { t } = useTranslation();
  const { open } = useModalContext();
  const {
    deleteMedFromGroup,
    addMedToGroup,
    loading: { addMedicinalProductGroupItemStatus },
  } = usePrescriptionsContext();

  const openModal = () =>
    open(
      <AddMedicationModal
        title={t('prescriptions.groupForm.itemModalTitle')}
        loading={addMedicinalProductGroupItemStatus === 'loading'}
        addFn={(value: string) => addMedToGroup(groupId, value)}
      />,
    );
  return (
    <Card
      className={cx(styles['group-items'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <Heading size="sm" tag="h3">
        {t('prescriptions.groupForm.itmesListTitle')}
      </Heading>
      {(!items || items.length === 0) && (
        <GroupEmptyState
          testId="empty-state"
          cta={t('prescriptions.groupForm.itemsCTA')}
          text={t('prescriptions.groupForm.itemsText')}
          ctaFn={() => openModal()}
        />
      )}
      {items && items.length > 0 && (
        <GroupItemList
          testId="list-state"
          items={items}
          deleteFn={(itemId: string) => deleteMedFromGroup(groupId, itemId)}
          cta={t('prescriptions.groupForm.itemsCTA')}
          ctaFn={() => openModal()}
        />
      )}
    </Card>
  );
};
