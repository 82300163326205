import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { OrderStatus } from 'models/Order';
import styles from './StatusText.module.scss';

interface StatusTextProps {
  status: OrderStatus;
  showAsTag?: boolean;
  lapsed?: boolean;
  testId?: string;
  className?: string;
}

export const StatusText: React.FC<StatusTextProps> = ({
  testId,
  className,
  status,
  lapsed = false,
  showAsTag = true,
}) => {
  const { t } = useTranslation();

  const statusString = {
    OrderReceived: t('orders.statusString.OrderReceived'),
    WithGp: t('orders.statusString.WithGp'),
    ApprovedByGp: t('orders.statusString.ApprovedByGp'),
    OutForDelivery: t('orders.statusString.OutForDelivery'),
    ReadyForCollection: t('orders.statusString.ReadyForCollection'),
    OrderComplete: t('orders.statusString.OrderComplete'),
    Cancelled: t('orders.statusString.Cancelled'),
  };

  return (
    <span
      className={cx(styles['status-text'], {
        [`${styles['status-text--tag']}`]: showAsTag,
        [`${styles['status-text--lapsed']}`]: showAsTag && lapsed,
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      {statusString[status]}
    </span>
  );
};
