// TODO: manage application routes here

export const routes = {
  BASE: '/',
  DEFAULT_AUTH_ROUTE: '/',
  LOGIN: '/login',
  NEWUSER: '/new-user',
  DASHBOARD: '/dashboard',
  ORDER: {
    BASE: '/orders',
    SINGLE: '/orders/detail',
    AUDIT: '/orders/audit',
    PRINT: '/orders/print',
    PRESCRIPTION: {
      VIEW: '/prescription/view',
      ADD: '/prescription/add',
      PRINT: '/prescription/print',
    },
  },
  PRODUCTS: {
    BASE: '/products',
    ADD: '/products/edit',
  },
  CONDITIONS: {
    BASE: '/conditions',
    ADD: '/conditions/edit',
    GROUPS: {
      BASE: '/conditions/groups',
      ADD: '/conditions/groups/edit',
    },
  },
  PRESCRIPTIONS: {
    BASE: '/prescriptions',
    GROUP: '/prescriptions/group',
    ITEMS: {
      ALL: '/prescriptions/items/all',
      UNMAPPED: '/prescriptions/items/unmapped',
    },
  },
  PATIENTS: {
    BASE: '/patients',
    ORDERS: '/patients/orders',
  },
  ADMIN: {
    BASE: '/admin',
    OPERATOR: '/admin/operator',
    PHARMACY: '/admin/pharmacy',
  },
  CONFIG: {
    BASE: '/config',
  },
};
