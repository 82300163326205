import * as yup from 'yup';
import i18n from 'i18next';

export const ConfigDetailsSchema = (): yup.ObjectSchema => {
  const maxLength3000Error = i18n.t('common.formErrors.maxLengthError', {
    max: 3000,
  });
  const priceTypeErrorMessage = i18n.t('config.formErrors.typeNumber');
  const priceRequiredErrorMessage = i18n.t('config.formErrors.price');
  const priceFormatErrorMessage = i18n.t('config.formErrors.priceFormat');

  return yup.object().shape({
    tagline: yup.string().max(3000, maxLength3000Error).trim(),
    taglineSecondLine: yup.string().max(3000, maxLength3000Error).trim(),
    shopEnabled: yup.bool(),
    freeStandardDeliveryThreshold: yup
      .number()
      .typeError(priceTypeErrorMessage)
      .required(priceRequiredErrorMessage)
      .test('maxDigitsAfterDecimal', priceFormatErrorMessage, (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
    standardDeliveryEnabled: yup.bool(),
    expressDeliveryEnabled: yup.bool(),
    standardDeliveryCharge: yup
      .number()
      .typeError(priceTypeErrorMessage)
      .required(priceRequiredErrorMessage)
      .test('maxDigitsAfterDecimal', priceFormatErrorMessage, (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
    expressDeliveryCharge: yup
      .number()
      .typeError(priceTypeErrorMessage)
      .required(priceRequiredErrorMessage)
      .test('maxDigitsAfterDecimal', priceFormatErrorMessage, (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
    standardPrescriptionPrice: yup
      .number()
      .typeError(priceTypeErrorMessage)
      .required(priceRequiredErrorMessage)
      .test('maxDigitsAfterDecimal', priceFormatErrorMessage, (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
    threeMonthPrePayment: yup
      .number()
      .typeError(priceTypeErrorMessage)
      .required(priceRequiredErrorMessage)
      .test('maxDigitsAfterDecimal', priceFormatErrorMessage, (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
    twelveMonthPrePayment: yup
      .number()
      .typeError(priceTypeErrorMessage)
      .required(priceRequiredErrorMessage)
      .test('maxDigitsAfterDecimal', priceFormatErrorMessage, (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
  });
};
