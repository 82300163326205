import React from 'react';
import cx from 'classnames';
import { Heading } from 'components/common/Heading';
import { Icon } from 'components/common/Icon';
import { Text } from 'components/common/Text';
import styles from './TableEmptyState.module.scss';

interface TableEmptyStateProps {
  testId?: string;
  className?: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  text?: string;
  heading?: string;
}

export const TableEmptyState: React.FC<TableEmptyStateProps> = ({
  testId,
  className,
  text,
  heading,
  icon,
}) => {
  return (
    <div
      className={cx(styles['table-empty-state'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Icon
        icon={icon}
        size="xxl"
        className={styles['table-empty-state__icon']}
      />
      {heading && (
        <Heading tag="h5" size="sm">
          {heading}
        </Heading>
      )}
      {text && (
        <Text tag="span" className={styles['table-empty-state__text']}>
          {text}
        </Text>
      )}
    </div>
  );
};
