import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatDateString,
  formatTimeString,
  formatDOBString,
} from 'utils/transformers';
import { useModalContext } from 'contexts/ModalContext';
import { usePatientsContext } from 'contexts/PatientsContext';
import { useSettingsContext } from 'contexts/SettingsContext';
import { Branch } from 'components/common/Branch';
import { Container, Col, Row } from 'components/common/grid';
import { TableHeader } from 'components/TableHeader';
import { Page } from 'components/Page';
import { PatientInviteModal } from 'containers/PatientInviteModal';
import { PatientsActionsBar } from 'containers/PatientsActionsBar';
import { Table, TableRow, TDCell, THCellProps } from 'components/table';
import { TableEmptyState } from 'components/TableEmptyState';
import { ErrorState } from 'components/ErrorState';
import { Spinner } from 'components/common/Spinner';
import { routes } from 'routes';
import { ReactComponent as Account } from 'assets/icons/lawsat/Account-Filled.svg';

export const PatientsList: React.FC = () => {
  const { t } = useTranslation();
  const { selectedPharmacy } = useSettingsContext();
  const {
    patientList,
    getPatientsList,
    setPharmacy,
    setSort,
    selectedRows,
    setSelectedRows,
    setPatientDetail,
    changeFilter,
    changeSearch,
    patientListFilters: { dormant, sortBy, sortDirection },
    loading: { getPatientsListStatus },
    getPatientDetail,
  } = usePatientsContext();
  const { open } = useModalContext();

  React.useEffect(() => {
    setSelectedRows([]);
    setPatientDetail(null);
    selectedPharmacy ? setPharmacy(selectedPharmacy) : getPatientsList();
  }, [selectedPharmacy]);

  React.useEffect(() => {
    if (selectedRows && selectedRows.length === 1) {
      getPatientDetail(Number(selectedRows[0]));
    }
  }, [selectedRows]);

  React.useEffect(() => {
    const updateFilters = columns.map((col) =>
      col.field === sortBy
        ? { ...col, state: sortDirection }
        : { ...col, state: undefined },
    );
    setColumns(updateFilters);
  }, [sortBy, sortDirection]);

  const tabs = [
    {
      label: t('patients.tableHeader.all'),
      active: dormant === null,
      value: 'all',
    },
    {
      label: t('patients.tableHeader.dormant'),
      active: dormant === true,
      value: 'dormant',
    },
  ];
  const tableHeaders: THCellProps[] = [
    {
      title: t('patients.tableHeader.joined'),
      sortable: true,
      field: 'createdDateTime',
    },
    {
      title: t('patients.tableHeader.name'),
      sortable: false,
    },
    {
      title: t('patients.tableHeader.address'),
      sortable: false,
    },
    {
      title: t('patients.tableHeader.dob'),
      sortable: true,
      field: 'dateOfBirth',
    },
    {
      title: t('patients.tableHeader.dependant'),
      sortable: false,
    },
    {
      title: t('patients.tableHeader.ordered'),
      sortable: false,
    },
  ];

  const [columns, setColumns] = React.useState<THCellProps[]>(tableHeaders);
  const openModal = () => {
    open(<PatientInviteModal />);
  };

  const ChangeVal = (state: string, value: string | number) => {
    state === 'true'
      ? setSelectedRows([...selectedRows, value])
      : setSelectedRows(
          selectedRows.filter((item) => {
            return item !== value;
          }),
        );
  };

  const toggleAll = (state: string) => {
    state === 'true'
      ? setSelectedRows(
          patientList.map(({ id }) => {
            return id;
          }),
        )
      : setSelectedRows([]);
  };

  const allRowsSelected =
    patientList.length > 0 && patientList?.length === selectedRows?.length;

  const getPatientsActionsBar = () => {
    if (patientList.length && getPatientsListStatus === 'finished') {
      return (
        <>
          <PatientsActionsBar
            selected={t('patients.actionsBar.selectedWithCount', {
              count: selectedRows.length,
            })}
          />
        </>
      );
    }
  };

  return (
    <Page className={'patients'} actionsBar={getPatientsActionsBar()}>
      <Container fluid={true}>
        <Row>
          <Col>
            <TableHeader
              testId={'patient-table-header'}
              title={t('patients.title')}
              tabs={tabs}
              search={true}
              searchLabel={t('patients.tableHeader.searchPlaceholder')}
              searchPlaceholder={t('patients.tableHeader.searchPlaceholder')}
              changeTab={changeFilter}
              changeSearch={changeSearch}
              searchLoading={'finished'}
              action={{
                label: t('patients.tableHeader.actionCTA'),
                actionFn: openModal,
              }}
            />
            <Table
              headers={columns}
              selectable={true}
              onChange={toggleAll}
              allRowsSelected={allRowsSelected}
              onSort={setSort}
            >
              <Branch
                status={getPatientsListStatus}
                Component={() => (
                  <>
                    {patientList.map(
                      ({
                        id,
                        createdDateTime,
                        firstName,
                        lastName,
                        dateOfBirth,
                        addressLine1,
                        addressTownCity,
                        addressPostCode,
                        isDependant,
                        lastOrderedDateTime,
                      }) => {
                        return (
                          <TableRow
                            key={id}
                            selectable={true}
                            value={id}
                            onChange={ChangeVal}
                            label={id.toString()}
                            selected={selectedRows.includes(id)}
                          >
                            <TDCell
                              line1={
                                createdDateTime
                                  ? formatDateString(createdDateTime)
                                  : t('patients.notApplicable')
                              }
                              line2={
                                createdDateTime
                                  ? formatTimeString(lastOrderedDateTime)
                                  : undefined
                              }
                            />
                            <TDCell
                              link={{
                                to: `${routes.PATIENTS.BASE}/${id}`,
                                text: `${firstName} ${lastName}`,
                              }}
                            />
                            <TDCell
                              line1={`${addressLine1}, ${addressTownCity},${addressPostCode}`}
                            />
                            <TDCell line1={formatDOBString(dateOfBirth)} />
                            <TDCell
                              line1={
                                isDependant
                                  ? t('patients.tableHeader.yes')
                                  : t('patients.tableHeader.no')
                              }
                            />
                            <TDCell
                              line1={
                                lastOrderedDateTime
                                  ? formatDateString(lastOrderedDateTime)
                                  : t('patients.notApplicable')
                              }
                              line2={
                                lastOrderedDateTime
                                  ? formatTimeString(lastOrderedDateTime)
                                  : undefined
                              }
                            />
                          </TableRow>
                        );
                      },
                    )}
                  </>
                )}
                LoadingComponent={() => (
                  <tr>
                    <td colSpan={8}>
                      <Spinner />
                    </td>
                  </tr>
                )}
                EmptyComponent={() => (
                  <tr>
                    <td colSpan={8}>
                      <TableEmptyState
                        icon={Account}
                        heading={t('patients.table.emptyTitle')}
                        text={t('patients.table.emptyText')}
                      />
                    </td>
                  </tr>
                )}
                ErrorComponent={() => (
                  <tr>
                    <td colSpan={8}>
                      <ErrorState />
                    </td>
                  </tr>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
