import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePrescriptionsContext } from 'contexts/PrescriptionsContext';
import { useModalContext } from 'contexts/ModalContext';
import { LinkedProduct } from 'models/MedicinalProductGroup';
import { AddProductModal } from 'containers/AddProductModal';
import { Card } from 'components/Card';
import { Heading } from 'components/common/Heading';
import { GroupEmptyState } from 'components/GroupEmptyState';
import { GroupItemList } from 'components/GroupItemList';
import styles from './GroupProducts.module.scss';

interface GroupProductsProps {
  testId?: string;
  className?: string;
  items?: LinkedProduct[];
  groupId: string;
}

export const GroupProducts: React.FC<GroupProductsProps> = ({
  testId,
  className,
  items,
  groupId,
}) => {
  const { t } = useTranslation();
  const { open } = useModalContext();
  const {
    deleteProdFromGroup,
    addProdToGroup,
    loading: { addProductToGroupStatus },
  } = usePrescriptionsContext();

  const openModal = () =>
    open(
      <AddProductModal
        testId="productModal"
        loading={addProductToGroupStatus === 'loading'}
        addFn={(value: string) => addProdToGroup(groupId, value)}
      />,
    );

  return (
    <Card
      className={cx(styles['group-products'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <Heading size="sm" tag="h3">
        {t('prescriptions.groupForm.productListTitle')}
      </Heading>
      {(!items || items.length === 0) && (
        <GroupEmptyState
          testId="empty-state"
          cta={t('prescriptions.groupForm.productsCTA')}
          text={t('prescriptions.groupForm.productsText')}
          ctaFn={() => openModal()}
        />
      )}
      {items && items.length > 0 && (
        <GroupItemList
          testId="list-state"
          deleteFn={(itemId: string) => deleteProdFromGroup(groupId, itemId)}
          items={items.map(({ id, title }) => {
            return { id: String(id), name: title };
          })}
          cta={t('prescriptions.groupForm.productsCTA')}
          ctaFn={() => openModal()}
        />
      )}
    </Card>
  );
};
