import { Client } from 'api/utils';
import { Config, ConfigItem, ConfigKey, ConfigPayload } from 'models/Config';
import dompurify from 'dompurify';

const sanitizer = dompurify.sanitize;

const createConfigItem = (key: ConfigKey, value: string): ConfigItem => ({
  key,
  value: value !== '' ? sanitizer(value) : null,
});

export const updateConfig = async ({
  tagline,
  taglineSecondLine,
  shopEnabled,
  freeStandardDeliveryThreshold,
  expressDeliveryCharge,
  expressDeliveryEnabled,
  standardDeliveryCharge,
  standardDeliveryEnabled,
  standardPrescriptionPrice,
  threeMonthPrePayment,
  twelveMonthPrePayment,
}: ConfigPayload): Promise<Config> => {
  const configItems = [
    createConfigItem('Tagline', tagline),
    createConfigItem('TaglineSecondLine', taglineSecondLine),
    createConfigItem('ShopEnabled', `${shopEnabled}`),
    createConfigItem('StandardDeliveryEnabled', `${standardDeliveryEnabled}`),
    createConfigItem('StandardDeliveryCharge', `${standardDeliveryCharge}`),
    createConfigItem('ExpressDeliveryEnabled', `${expressDeliveryEnabled}`),
    createConfigItem('ExpressDeliveryCharge', `${expressDeliveryCharge}`),
    createConfigItem(
      'FreeStandardDeliveryThreshold',
      `${freeStandardDeliveryThreshold}`,
    ),
    createConfigItem(
      'StandardPrescriptionPrice',
      `${standardPrescriptionPrice}`,
    ),
    createConfigItem('ThreeMonthPrePayment', `${threeMonthPrePayment}`),
    createConfigItem('TwelveMonthPrePayment', `${twelveMonthPrePayment}`),
  ];
  const response = await Client.put('/config', configItems);
  return response.data;
};

export const getConfig = async (): Promise<Config> => {
  const response = await Client.get('/config');
  return response.data;
};
