import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { usePrescriptionsContext } from 'contexts/PrescriptionsContext';
import { useModalContext } from 'contexts/ModalContext';
import { Branch } from 'components/common/Branch';
import { Container, Col, Row } from 'components/common/grid';
import { Page } from 'components/Page';
import { TableHeader } from 'components/TableHeader';
import { Table, THCellProps } from 'components/table';
import { Spinner } from 'components/common/Spinner';
import { Action, ActionsBar } from 'components/ActionsBar';
import { ProductGroups } from 'components/ProductGroups';
import { ExemptItems } from 'components/ExemptItems';
import { AddMedicationModal } from 'containers/AddMedicationModal';
import { TableEmptyState } from 'components/TableEmptyState';
import { ReactComponent as Pill } from 'assets/icons/lawsat/Pill.svg';
import styles from './PrescriptionGroupsList.module.scss';

export const PrescriptionGroupsList: React.FC = () => {
  const { t } = useTranslation();
  const {
    loading: {
      getPrescriptionGroupsStatus,
      getPrescriptionItemsStatus,
      updateMedicinalProductStatus,
    },
    getGroups,
    productGroups,
    prescriptionItems,
    setExempt,
    prescriptionsFilters,
    setPage,
    getAllItems,
    getUnmappedItems,
    unmappedCount,
    allItemsCount,
    updateExemptStatus,
  } = usePrescriptionsContext();
  const { open } = useModalContext();
  const history = useHistory();

  const { isExempt, page, pages } = prescriptionsFilters;

  const tabs = [
    {
      label: t('prescriptions.groups'),
      active: isExempt === false,
      value: false,
    },
    {
      label: t('prescriptions.exemptItems'),
      active: isExempt === true,
      value: true,
    },
  ];

  const addGroup = {
    label: t('prescriptions.addGroup'),
    link: { to: `${routes.PRESCRIPTIONS.GROUP}/add` },
  };

  const addExempt = {
    label: t('prescriptions.addExemptItem'),
    actionFn: () => {
      open(
        <AddMedicationModal
          title={t('prescriptions.addExemptItem')}
          loading={updateMedicinalProductStatus === 'loading'}
          addFn={(value: string) => updateExemptStatus(value, true)}
        />,
      );
    },
  };

  const groupHeaders: THCellProps[] = [
    {
      title: t('prescriptions.tableHeaders.groupName'),
      sortable: false,
      field: 'name',
    },
    {
      title: t('prescriptions.tableHeaders.categoryCode'),
      sortable: false,
      field: 'categoryCode',
    },
    {
      title: t('prescriptions.tableHeaders.prescriptionItemCount'),
      sortable: false,
      field: 'medicinalProductCount',
    },
    {
      title: t('prescriptions.tableHeaders.shopProductCount'),
      sortable: false,
      field: 'productCount',
    },
  ];

  const exemptHeaders: THCellProps[] = [
    {
      title: t('prescriptions.tableHeaders.itemName'),
      sortable: false,
      field: 'name',
    },
  ];

  React.useEffect(() => {
    if (isExempt === false) {
      getGroups();
      getAllItems();
      getUnmappedItems();
    }
  }, [isExempt]);

  const tableActions: Action[] = [
    {
      buttonType: 'ui',
      label: t('prescriptions.allPrescriptionItems', {
        items: allItemsCount && `${allItemsCount}`,
      }),
      action: () => {
        history.push(`${routes.PRESCRIPTIONS.ITEMS.ALL}`);
      },
      disabled: false,
    },
    {
      buttonType: 'ui',
      label: t('prescriptions.unmappedItems', {
        items: unmappedCount && `${unmappedCount}`,
      }),
      action: () => {
        history.push(`${routes.PRESCRIPTIONS.ITEMS.UNMAPPED}`);
      },
      disabled: false,
    },
  ];

  return (
    <Page
      className={'prescriptions'}
      actionsBar={
        <ActionsBar
          pagination={{
            page: page ? page : 1,
            pages: pages ? pages : 1,
            change: setPage,
          }}
          actions={isExempt ? undefined : tableActions}
        />
      }
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <TableHeader
              title={t('prescriptions.title')}
              tabs={tabs}
              action={isExempt ? addExempt : addGroup}
              changeTab={setExempt}
            />
            <Table
              className={`${
                getPrescriptionGroupsStatus === 'loading' ||
                getPrescriptionItemsStatus === 'loading'
                  ? styles['prescriptions__loading']
                  : ''
              }`}
              selectable={false}
              headers={isExempt ? exemptHeaders : groupHeaders}
            >
              <Branch
                status={
                  isExempt
                    ? getPrescriptionItemsStatus
                    : getPrescriptionGroupsStatus
                }
                EmptyComponent={() => (
                  <tr>
                    <td colSpan={8}>
                      <TableEmptyState
                        icon={Pill}
                        heading={t(
                          isExempt
                            ? 'prescriptions.noExemptYet'
                            : 'prescriptions.noGroupsYet',
                        )}
                        text={t(
                          isExempt
                            ? 'prescriptions.whenYouAddExempt'
                            : 'prescriptions.whenYouAddGroups',
                        )}
                      />
                    </td>
                  </tr>
                )}
                LoadingComponent={() => (
                  <tr>
                    <td colSpan={8}>
                      <Spinner />
                    </td>
                  </tr>
                )}
                Component={() => (
                  <>
                    {!isExempt && (
                      <ProductGroups productGroups={productGroups} />
                    )}

                    {isExempt && (
                      <ExemptItems exemptItems={prescriptionItems} />
                    )}
                  </>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
