import React from 'react';
import cx from 'classnames';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { getLexicalEditorTheme } from './lexicalEditorTheme';
import { ListNode, ListItemNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { Label } from 'components/common/Label';
import { toolList } from 'components/RichTextEditor/toolsList';
import { IconButton } from 'components/IconButton';
import { useOnClickListener } from 'hooks/useOnClickListener';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  EditorState,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { richTextFieldDefaultState } from 'utils/RichTextField';

import styles from './RichTextEditor.module.scss';

export const Toolbar = () => {
  const [editor] = useLexicalComposerContext();
  const { onClick } = useOnClickListener(editor);
  return (
    <>
      {toolList.map((tool) => (
        <IconButton
          key={tool.id}
          icon={tool.icon}
          label={tool.event}
          type="button"
          onClick={() => onClick(tool.event)}
        />
      ))}
    </>
  );
};

interface RichTextEditorProps {
  label: string;
  helperText?: string;
  caption?: string;
  placeholder?: string;
  onChangeHandler: (state: EditorState) => void;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  initialPlainText?: string;
  richText?: string;
  error?: boolean;
  syncPlainText?: Function;
}

export const RichTextEditor = ({
  className,
  placeholder,
  onChangeHandler,
  size = 'lg',
  label,
  helperText,
  caption,
  richText,
  initialPlainText,
  syncPlainText,
  error,
}: RichTextEditorProps) => {
  const initialConfig = {
    namespace: 'RichTextEditor',
    theme: getLexicalEditorTheme({ textSize: 'lg' }),
    editorState: initialPlainText
      ? () => {
          const root = $getRoot();
          root.clear();
          const p = $createParagraphNode();
          p.append($createTextNode(initialPlainText));
          root.append(p);
        }
      : richText
      ? richText
      : richTextFieldDefaultState,
    onError: (err: Error) => {
      console.error(err);
    },
    nodes: [HeadingNode, ListNode, ListItemNode],
  };

  const handleContentChange = (editorState: EditorState) => {
    syncPlainText &&
      editorState.read(() => {
        syncPlainText($getRoot().__cachedText);
      });
    onChangeHandler(editorState);
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Label size={size} className={styles['rich-text-editor__label']}>
        {label}
      </Label>
      <span className={styles['rich-text-editor__helper-text']}>
        {helperText}
      </span>
      <Toolbar />
      <div className={styles['rich-text-editor__container']}>
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              className={cx(
                styles['rich-text-editor__textarea'],
                styles[`rich-text-editor__textarea--${size}`],
                { [styles['rich-text-editor__textarea--error']]: error },
                className,
              )}
            />
          }
          placeholder={
            <p className={styles['rich-text-editor__placeholder']}>
              {placeholder}
            </p>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ListPlugin />
        <HistoryPlugin />
        <OnChangePlugin
          onChange={(editorState) => handleContentChange(editorState)}
        />
      </div>
      <span
        className={cx(styles['rich-text-editor__caption'], {
          [styles['rich-text-editor__caption--error']]: error,
        })}
      >
        {caption}
      </span>
    </LexicalComposer>
  );
};
