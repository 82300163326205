import React from 'react';
import cx from 'classnames';
import {
  Button,
  ButtonAppearance,
  ButtonVariant,
} from 'components/common/Button';
import { Heading } from 'components/common/Heading';
import { Pagination } from 'components/Pagination';
import styles from './ActionsBar.module.scss';

export type Action = {
  buttonType: ButtonVariant;
  appearance?: ButtonAppearance;
  loading?: boolean;
  label: string;
  action: Function;
  disabled: boolean;
};
interface ActionsBarProps {
  testId?: string;
  className?: string;
  pagination?: { page: number; pages: number; change: (to: number) => void };
  actions?: Action[];
  selected?: string;
}

export const ActionsBar: React.FC<ActionsBarProps> = ({
  testId,
  className,
  pagination,
  selected,
  actions,
}) => {
  return (
    <div
      data-testid={testId}
      className={cx(styles['actions-bar'], { [`${className}`]: className })}
    >
      {pagination && (
        <Pagination
          testId="pagination"
          page={pagination.page}
          pages={pagination.pages}
          change={pagination.change}
        />
      )}
      <div className={cx(styles['actions-bar__end'])}>
        {selected && (
          <Heading
            className={cx(styles['actions-bar__selected'])}
            size="xs"
            tag="span"
          >
            {selected}
          </Heading>
        )}
        {actions &&
          actions.map(
            (
              {
                buttonType,
                appearance = 'flat',
                label,
                action,
                loading = false,
                disabled,
              },
              index,
            ) => {
              return (
                <Button
                  testId={`action-bar-button-${index}`}
                  appearance={appearance}
                  variant={buttonType}
                  isLoading={loading}
                  size="sm"
                  className={cx(styles['actions-bar__action'])}
                  key={index}
                  label={label}
                  onClick={() => {
                    action();
                  }}
                  disabled={disabled}
                />
              );
            },
          )}
      </div>
    </div>
  );
};
