import React, { HTMLInputTypeAttribute } from 'react';
import cx from 'classnames';
import { Input } from 'reakit/Input';
import { Label } from 'components/common/Label';
import { Icon } from 'components/common/Icon';
import { ReactComponent as CircleAlert } from 'assets/icons/lawsat/CircleAlert.svg';
import styles from './InputText.module.scss';

export type InputVariant =
  | 'outline'
  | 'accent'
  | 'positive'
  | 'warning'
  | 'negative';

type InputTextProps = {
  label: string;
  labelClassName?: string;
  name: string;
  helperText?: string;
  caption?: string;
  className?: string;
  floating?: boolean;
  disabled?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  readonly?: boolean;
  required?: boolean;
  placeholder?: string;
  radius?: 'rounded' | 'pill';
  size?: 'sm' | 'md' | 'lg';
  maxLength?: number;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  testId?: string;
  truncate?: boolean;
  variant?: InputVariant;
  validationError?: string;
  type?: HTMLInputTypeAttribute;
};

const InputTextRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputTextProps
> = (
  {
    label,
    labelClassName,
    helperText,
    name,
    caption,
    className,
    disabled,
    icon,
    floating,
    placeholder,
    radius = 'rounded',
    readonly,
    required,
    size = 'lg',
    maxLength,
    onBlur,
    testId,
    truncate = false,
    variant = 'accent',
    validationError,
    type = 'text',
    ...props
  },
  ref,
) => {
  const ariaDescribedBy = !!caption || !!validationError;
  const showLabel = (
    <Label
      inputId={`input__text-${name}`}
      size={size}
      className={cx(styles['input__text-label'], {
        [`${labelClassName}`]: labelClassName,
      })}
    >
      {label}
    </Label>
  );
  const showHelperText = (
    <span
      className={styles['input__text-caption']}
      data-testid={testId ? `helper-text-${name}` : null}
      id={`helper-text-${name}`}
    >
      {helperText}
    </span>
  );

  let textClasses = cx(`${styles['input__text-input']}`, {
    [`${styles[`input__text-input--${radius}`]}`]: radius,
    [`${styles[`input__text-input--${variant}`]}`]: variant,
    [`${className}`]: className,
    'text-truncate': truncate,
  });
  let showValidationIcon;

  if (variant === 'warning' || variant === 'negative') {
    textClasses = textClasses + ' text-truncate';
    showValidationIcon = (
      <Icon
        className="validation"
        icon={CircleAlert}
        size={size}
        testId={!!testId ? `icon` + testId : ''}
      />
    );
  }

  return (
    <div
      className={cx(
        styles['input__text-group'],
        styles[`input__text-group--${size}`],
        {
          [styles[`input__text-group--${variant}`]]: variant,
          [styles[`input__text-group--floating`]]: floating,
          [styles[`input__text-group--icon`]]: icon,
          [`${className}`]: className,
        },
      )}
      data-testid={!!testId ? `group-${name}` : null}
    >
      {!floating && showLabel}
      {!floating && helperText && showHelperText}
      <div className={styles['input__text-container']}>
        {!!validationError && (
          <span
            id={`error-${name}`}
            className={styles['input__text-validation']}
            data-testid={!!testId ? `error-${name}` : null}
            role="alert"
          >
            {validationError}
          </span>
        )}
        <Input
          {...props}
          type={type}
          maxLength={maxLength}
          id={`input__text-${name}`}
          className={textClasses}
          data-testid={!!testId ? testId : null}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readonly}
          required={required}
          ref={ref}
          {...(required && { 'aria-required': true })}
          {...(validationError && { 'aria-invalid': true })}
          {...(ariaDescribedBy && {
            'aria-describedby': `${!!validationError ? `error-${name} ` : ''}${
              !!caption ? `caption-${name}` : ''
            }`,
          })}
          onBlur={onBlur}
        />
        {!!icon && (
          <Icon
            className="prefix"
            icon={icon}
            size="md"
            testId={!!testId ? `icon` + testId : ''}
          />
        )}
        {(!!validationError ||
          variant === 'negative' ||
          variant === 'warning') &&
          showValidationIcon}
        {!!floating && showLabel}
      </div>
      {!!caption && (
        <span
          id={`caption-${name}`}
          className={styles['input__text-caption']}
          data-testid={!!testId ? `caption-${name}` : null}
        >
          {caption}
        </span>
      )}
    </div>
  );
};

export const InputText = React.forwardRef(InputTextRef);
