import { Client } from 'api/utils';
import { PrescriptionsFilters } from 'contexts/PrescriptionsContext';
import {
  PrescriptionItemsList,
  PrescriptionGroups,
} from 'models/Prescriptions/Prescriptions';

export const createUrl = (
  filters: PrescriptionsFilters,
  skip: number,
): string => {
  let baseUrl = `/medicinalProducts?top=50&skip=${skip}`;

  if (filters.isExempt) {
    baseUrl = `${baseUrl}&exempt=${filters.isExempt}`;
  }

  if (filters.assignedToGroup !== null) {
    baseUrl = `${baseUrl}&assignedToGroup=${filters.assignedToGroup}`;
  }

  return baseUrl;
};

export const getMedicinalProducts = async (
  filters: PrescriptionsFilters,
  skip: number,
): Promise<PrescriptionItemsList> => {
  const response = await Client.get(createUrl(filters, skip));
  return response.data;
};

export const getMedicinalProductsGroups = async (
  skip: number,
): Promise<PrescriptionGroups> => {
  const response = await Client.get(
    `/medicinalProductGroups?top=50&skip=${skip}`,
  );
  return response.data;
};

export const updateMedicinalProduct = async (
  productId: string,
  isContraceptive: boolean,
): Promise<void> => {
  return Client.put(`/MedicinalProducts/${productId}`, {
    canBeUsedAsContraceptive: isContraceptive,
  });
};
