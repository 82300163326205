import React from 'react';
import { useApiError } from 'hooks/useApiError';
import { useHistory } from 'react-router-dom';
import { getPharmacyById, postPharmacy } from 'api/Pharmacies';
import { PharmacyDetail } from 'models/Pharmacy';
import { BranchStatus } from 'components/common/Branch';
import { routes } from 'routes';

export interface PharmaciesContextLoading {
  addPharmacyStatus?: BranchStatus;
  getPharmacyDetailStatus?: BranchStatus;
}

export interface PharmaciesContextProps {
  loading: PharmaciesContextLoading;
  addPharmacy: (pharmacyPayload: PharmacyDetail) => Promise<void>;
  pharmacyDetail: PharmacyDetail | null;
  getPharmacy: (pharmacyId: number | string) => Promise<void>;
  setPharmacyDetail: Function;
}

export const PharmaciesContextDefaults: PharmaciesContextProps = {
  loading: { addPharmacyStatus: 'idle' },
  addPharmacy: () => Promise.resolve(),
  pharmacyDetail: null,
  getPharmacy: () => Promise.resolve(),
  setPharmacyDetail: () => ({}),
};

export const PharmaciesContextValues = (): PharmaciesContextProps => {
  const { handleApiError } = useApiError();
  const history = useHistory();
  const [loading, setLoading] = React.useState<PharmaciesContextLoading>({
    addPharmacyStatus: 'idle',
  });
  const [pharmacyDetail, setPharmacyDetail] =
    React.useState<PharmacyDetail | null>(null);

  const addPharmacy = async (pharmacyPayload: PharmacyDetail) => {
    try {
      setLoading({ ...loading, addPharmacyStatus: 'loading' });
      await postPharmacy(pharmacyPayload);
      setLoading({ ...loading, addPharmacyStatus: 'finished' });
      history.push(routes.ADMIN.BASE);
    } catch (error) {
      handleApiError(error);
      setLoading({ ...loading, addPharmacyStatus: 'error' });
    }
  };

  const getPharmacy = async (pharmacyId: number | string) => {
    try {
      setLoading({ ...loading, getPharmacyDetailStatus: 'loading' });
      const result = await getPharmacyById(pharmacyId);
      result && setPharmacyDetail(result);
      setLoading({ ...loading, getPharmacyDetailStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, getPharmacyDetailStatus: 'error' });
    }
  };

  return {
    loading,
    addPharmacy,
    pharmacyDetail,
    getPharmacy,
    setPharmacyDetail,
  };
};

export const PharmaciesContext = React.createContext<PharmaciesContextProps>(
  {} as ReturnType<typeof PharmaciesContextValues>,
);

export const usePharmaciesContext = (): PharmaciesContextProps =>
  React.useContext(PharmaciesContext);

interface PharmaciesProviderProps {
  children?: React.ReactNode;
}

export const PharmaciesProvider = ({ children }: PharmaciesProviderProps) => {
  return (
    <PharmaciesContext.Provider value={PharmaciesContextValues()}>
      {children}
    </PharmaciesContext.Provider>
  );
};
