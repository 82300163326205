import React, { ReactNode, createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToastContext } from 'contexts/ToastContext';
import {
  getConfig as getConfigAPI,
  updateConfig as updateConfigAPI,
} from 'api/Config';
import { Config, ConfigPayload } from 'models/Config';

export interface ConfigContextProps {
  config: ConfigPayload | null;
  getShopConfig: () => Promise<void>;
  updateShopConfig: (payload: ConfigPayload) => Promise<void>;
}

export const ConfigContextDefaults: ConfigContextProps = {
  config: null,
  getShopConfig: () => Promise.resolve(),
  updateShopConfig: () => Promise.resolve(),
};

export const ConfigContextValues = (): ConfigContextProps => {
  const { setToast } = useToastContext();
  const { t } = useTranslation();
  const [config, setConfig] = useState<ConfigPayload | null>(null);

  const processConfig = async (configPromise: Promise<Config>) => {
    try {
      const updatedConfig = await configPromise;
      setConfig({
        freeStandardDeliveryThreshold: parseFloat(
          updatedConfig.find(
            (configItem) => configItem.key === 'FreeStandardDeliveryThreshold',
          )?.value ?? '0',
        ),
        shopEnabled:
          updatedConfig.find((configItem) => configItem.key === 'ShopEnabled')
            ?.value === 'true',
        tagline:
          updatedConfig.find((configItem) => configItem.key === 'Tagline')
            ?.value ?? '',
        taglineSecondLine:
          updatedConfig.find(
            (configItem) => configItem.key === 'TaglineSecondLine',
          )?.value ?? '',
        expressDeliveryCharge: parseFloat(
          updatedConfig.find(
            (configItem) => configItem.key === 'ExpressDeliveryCharge',
          )?.value ?? '0',
        ),
        standardDeliveryCharge: parseFloat(
          updatedConfig.find(
            (configItem) => configItem.key === 'StandardDeliveryCharge',
          )?.value ?? '0',
        ),
        expressDeliveryEnabled:
          updatedConfig.find(
            (configItem) => configItem.key === 'ExpressDeliveryEnabled',
          )?.value === 'true',
        standardDeliveryEnabled:
          updatedConfig.find(
            (configItem) => configItem.key === 'StandardDeliveryEnabled',
          )?.value === 'true',
        standardPrescriptionPrice: parseFloat(
          updatedConfig.find(
            (configItem) => configItem.key === 'StandardPrescriptionPrice',
          )?.value ?? '0',
        ),
        threeMonthPrePayment: parseFloat(
          updatedConfig.find(
            (configItem) => configItem.key === 'ThreeMonthPrePayment',
          )?.value ?? '0',
        ),
        twelveMonthPrePayment: parseFloat(
          updatedConfig.find(
            (configItem) => configItem.key === 'TwelveMonthPrePayment',
          )?.value ?? '0',
        ),
      });
    } catch (error) {
      setToast({
        status: 'error',
        title: t('common.error.genericTitle'),
        description: t('common.error.genericMessage'),
      });
    }
  };

  const getShopConfig = async () => {
    await processConfig(getConfigAPI());
  };

  const updateShopConfig = async (updatedConfig: ConfigPayload) => {
    await processConfig(updateConfigAPI(updatedConfig));
  };

  return {
    config,
    getShopConfig,
    updateShopConfig,
  };
};

export const ConfigContext = createContext<ConfigContextProps | undefined>(
  undefined,
);

export const useConfigContext = (): ConfigContextProps => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error('useConfigContext must be used within a ConfigProvider');
  }

  return context;
};
interface ConfigProviderProps {
  children?: ReactNode;
}

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  return (
    <ConfigContext.Provider value={ConfigContextValues()}>
      {children}
    </ConfigContext.Provider>
  );
};
