import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useModalContext } from 'contexts/ModalContext';
import { usePrescriptionsContext } from 'contexts/PrescriptionsContext';
import { DeleteGroupModal } from 'containers/DeleteGroupModal';
import { Col, Container, Row } from 'components/common/grid';
import { Branch } from 'components/common/Branch';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Button } from 'components/common/Button';
import { Page } from 'components/Page';
import { GroupForm } from 'containers/GroupForm';
import { GroupItems } from 'containers/GroupItems';
import { GroupProducts } from 'containers/GroupProducts';
import { ErrorState } from 'components/ErrorState';
import { Spinner } from 'components/common/Spinner';
import { routes } from 'routes';
import styles from './GroupView.module.scss';

export const GroupView: React.FC = () => {
  const [isEdit, setIsEdit] = React.useState(false);
  const { t } = useTranslation();
  const { open } = useModalContext();
  const { groupId } = useParams<{ groupId: string }>();
  const {
    deleteGroup,
    getGroup,
    medicinalProductGroupDetail,
    setMedicinalProductGroupDetail,
    setGroupLoading,
    loading: { getMedicinalProductGroupStatus },
  } = usePrescriptionsContext();

  React.useEffect(() => {
    if (groupId && groupId === 'add') {
      setIsEdit(false);
      setGroupLoading('finished');
    } else {
      setIsEdit(true);
      getGroup(groupId);
    }
    return () => {
      setMedicinalProductGroupDetail(null);
    };
  }, [groupId]);

  const cardClass = styles['group-view__card'];
  return (
    <Page>
      <Container fluid={true}>
        <Row>
          <Col>
            <Branch
              status={getMedicinalProductGroupStatus}
              LoadingComponent={() => <Breadcrumbs links={[]} />}
              Component={() => (
                <Breadcrumbs
                  links={[
                    {
                      text: t('prescriptions.title'),
                      route: routes.PRESCRIPTIONS.BASE,
                    },
                    {
                      text: t('prescriptions.groups'),
                      route: routes.PRESCRIPTIONS.BASE,
                    },
                    {
                      text:
                        groupId === 'add'
                          ? `add`
                          : String(medicinalProductGroupDetail?.name),
                      route:
                        groupId === 'add'
                          ? `${routes.PRESCRIPTIONS.GROUP}/add`
                          : `${routes.PRESCRIPTIONS.GROUP}/${groupId}`,
                    },
                  ]}
                />
              )}
            />
          </Col>
        </Row>
      </Container>
      <div className={styles['group-view__main']}>
        <Container fluid={true}>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <Branch
                status={getMedicinalProductGroupStatus}
                ErrorComponent={() => <ErrorState testId="page-error-state" />}
                LoadingComponent={() => <Spinner />}
                Component={() => (
                  <>
                    <GroupForm
                      className={cardClass}
                      isEdit={isEdit}
                      group={medicinalProductGroupDetail}
                    />
                    {isEdit && (
                      <>
                        <GroupItems
                          groupId={groupId}
                          className={cardClass}
                          items={medicinalProductGroupDetail?.medicinalProducts}
                        />
                        <GroupProducts
                          groupId={groupId}
                          className={cardClass}
                          items={medicinalProductGroupDetail?.products}
                        />

                        <Button
                          testId="delete-button"
                          className={styles['group-view__button']}
                          size="sm"
                          variant="negative"
                          label={t('prescriptions.groupForm.deleteCTA')}
                          onClick={() => {
                            open(
                              <DeleteGroupModal
                                groupId={groupId}
                                groupName={medicinalProductGroupDetail?.name}
                                deleteFn={(id: string) => deleteGroup(id)}
                              />,
                            );
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  );
};
