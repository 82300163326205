import React from 'react';
import cx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'contexts/ModalContext';
import { useSuggestContext } from 'contexts/SuggestContext';
import { EditMedicationModal } from 'components/EditMedicationModal';
import { Icon } from 'components/common/Icon';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { ReactComponent as Pill } from 'assets/icons/lawsat/Pill.svg';
import { PatientPmedFormModal } from 'components/PatientPmedFormModal';
import { PatientPomFormModal } from 'components/PatientPomFormModal';
import { OrderLine as OrderLineType } from 'models/Order';
import styles from './OrderLine.module.scss';

interface OrderLineProps {
  testId?: string;
  pomReviewEnabled?: boolean;
  orderline: OrderLineType;
  orderId: number;
}

const SpacerDot = () => {
  return <span className={styles['order-line__spacer-dot']}>&middot;</span>;
};

const buttonStyle = styles['order-line__form-buttons'];

export const OrderLine = ({
  testId,
  orderId,
  orderline,
  pomReviewEnabled = false,
}: OrderLineProps) => {
  const { t } = useTranslation();
  const { open } = useModalContext();
  const { setMedList } = useSuggestContext();

  const {
    isPrescriptionItem,
    quantity,
    price,
    isPMed,
    isPom,
    name,
    id,
    pomStatus,
  } = orderline;

  return (
    <div className={styles['order-line']}>
      <span
        className={cx(
          styles['order-line__indicator'],
          pomStatus === 'Rejected' && styles['order-line__indicator--rejected'],
          quantity < orderline.originalQuantity &&
            styles['order-line__indicator--amended'],
        )}
      >
        {isPrescriptionItem ? (
          <Icon
            testId={`${testId}_icon`}
            icon={Pill}
            size="xs"
            className={styles['order-line__indicator-icon']}
          />
        ) : (
          <Text
            className={cx(
              styles['order-line__indicator-qty'],
              pomStatus === 'Rejected' &&
                styles['order-line__indicator-qty--rejected'],
              quantity < orderline.originalQuantity &&
                styles['order-line__indicator-qty--amended'],
            )}
          >
            {quantity}
          </Text>
        )}
      </span>
      <Text
        className={cx(
          pomStatus === 'Rejected' &&
            styles['order-line__product-name--rejected'],
        )}
      >
        {name}
      </Text>
      {isPrescriptionItem && (
        <>
          <SpacerDot />
          <Button
            className={buttonStyle}
            testId="open-modal"
            size="sm"
            label={t('orders.orderSummaryLabels.edit')}
            appearance="link"
            onClick={() => {
              setMedList([]);
              open(
                <EditMedicationModal orderlineId={id} />,
                styles['modal-overrides'],
              );
            }}
          />
        </>
      )}
      {isPMed && (
        <>
          <SpacerDot />
          <Button
            className={buttonStyle}
            size="sm"
            label={t('orders.orderSummaryLabels.isPMed')}
            appearance="link"
            onClick={() => {
              open(
                <PatientPmedFormModal
                  orderId={orderId}
                  orderlineId={id}
                  itemName={name}
                />,
                'wide',
              );
            }}
          />
        </>
      )}
      {isPom && (
        <>
          <SpacerDot />
          <Button
            className={buttonStyle}
            size="sm"
            label={t('orders.orderSummaryLabels.isPom')}
            appearance="link"
            onClick={() => {
              open(
                <PatientPomFormModal
                  orderline={orderline}
                  orderId={orderId}
                  pomReviewEnabled={pomReviewEnabled}
                />,
                'pom-forms',
              );
            }}
          />
        </>
      )}
      <span
        className={cx(
          styles['order-line__qty'],
          pomStatus === 'Rejected' && styles['order-line__qty--rejected'],
        )}
      >
        £{price.toFixed(2)}
      </span>
    </div>
  );
};
