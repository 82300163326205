import dompurify from 'dompurify';
import { Client } from 'api/utils';
import { PatientListFilters } from 'contexts/PatientsContext';
import { PatientDetail, Patient, PatientLinkedAccount } from 'models/Patient';

const sanitizer = dompurify.sanitize;

export type getPatientsResponse = {
  count: number;
  skip: number;
  top: number;
  items: Patient[];
};

export type PatientInvitePayload = {
  NotificationMethod: string;
  EmailAddress?: string;
  PhoneNumberCountryCode?: string;
  PhoneNumber?: string;
};

export const createUrl = (
  filters: PatientListFilters,
  skip: number,
): string => {
  let baseUrl = `Patients?top=20&skip=${skip}&sortBy=${filters.sortBy}&sortDirection=${filters.sortDirection}`;

  if (filters.pharmacyId) {
    baseUrl = `${baseUrl}&pharmacyId=${filters.pharmacyId}`;
  }

  if (filters.dormant !== null) {
    baseUrl = `${baseUrl}&dormant=${filters.dormant}`;
  }

  if (filters.search) {
    baseUrl = `${baseUrl}&search=${filters.search}`;
  }

  return baseUrl;
};

export const createSendUrl = (
  patientId: number | null,
  pharmacyId: number | null,
): string => {
  let baseUrl = `/Patients/sendMessage?`;

  if (patientId && patientId >= 0 && !pharmacyId) {
    baseUrl = `${baseUrl}patientIds=${patientId}`;
  }

  if (pharmacyId && !patientId) {
    baseUrl = `${baseUrl}pharmacyId=${pharmacyId}`;
  }

  return baseUrl;
};

export const getPatients = async (
  filters: PatientListFilters,
  skip: number,
): Promise<getPatientsResponse> => {
  const response = await Client.get(createUrl(filters, skip));
  return response.data;
};

export const getPatient = async (patientId: number): Promise<PatientDetail> => {
  const response = await Client.get(`Patients/${patientId}`);
  return response.data;
};

export const deletePatient = async (
  patientId: string | number,
): Promise<void> => {
  return Client.delete(`Patients/${patientId}`);
};

export const addPrescriptionItem = async (
  patientId: number,
  medicinalProductId: string,
): Promise<void> => {
  return Client.post(`/Patients/${patientId}/prescriptionItems`, {
    medicinalProductId,
  });
};

export const deletePrescriptionItem = async (
  patientId: number,
  medicinalProductId: string,
): Promise<void> => {
  return Client.delete(
    `/Patients/${patientId}/prescriptionItems/${medicinalProductId}`,
  );
};

export const getLinkedAccounts = async (
  patientId: number,
): Promise<PatientLinkedAccount[]> => {
  const response = await Client.get(`/Patients/${patientId}/linkedAccounts`);
  return response.data;
};

export const sendMessage = async (
  patientId: number | null,
  pharmacyId: number | null,
  message: string,
): Promise<void> => {
  return Client.post(createSendUrl(patientId, pharmacyId), {
    message: message,
  });
};

export const transformInvitePatientPayload = (
  originalPayload: PatientInvitePayload,
): PatientInvitePayload => ({
  ...originalPayload,
  PhoneNumber: originalPayload.PhoneNumber
    ? sanitizer(
        `${
          originalPayload.PhoneNumberCountryCode
        } ${originalPayload.PhoneNumber?.replace(/^0+/, '')}`,
      )
    : originalPayload.PhoneNumber,
});

export const invitePatient = async (
  patientInvitePayload: PatientInvitePayload,
): Promise<void> => {
  const payload = patientInvitePayload.PhoneNumber
    ? transformInvitePatientPayload(patientInvitePayload)
    : patientInvitePayload;
  return Client.post('/Patients/invite', payload);
};
