import { ReactComponent as ParagraphIcon } from 'assets/icons/Paragraph.svg';
import { ReactComponent as H1Icon } from 'assets/icons/Heading1.svg';
import { ReactComponent as H2Icon } from 'assets/icons/Heading2.svg';
import { ReactComponent as H3Icon } from 'assets/icons/Heading3.svg';
import { ReactComponent as ULIcon } from 'assets/icons/UnorderedList.svg';
import { ReactComponent as OLIcon } from 'assets/icons/OrderedList.svg';
import { ReactComponent as BoldIcon } from 'assets/icons/Bold.svg';
import { ReactComponent as ItalicIcon } from 'assets/icons/Italic.svg';

export enum eventTypes {
  PARAGRAPH = 'paragraph',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  UL = 'ul',
  OL = 'ol',
  FORMAT_BOLD = 'formatBold',
  FORMAT_ITALIC = 'formatItalic',
}

export const toolList = [
  {
    id: 1,
    icon: ParagraphIcon,
    event: eventTypes.PARAGRAPH,
  },
  {
    id: 2,
    icon: H1Icon,
    event: eventTypes.H1,
  },
  {
    id: 3,
    icon: H2Icon,
    event: eventTypes.H2,
  },
  {
    id: 4,
    icon: H3Icon,
    event: eventTypes.H3,
  },
  {
    id: 5,
    icon: ULIcon,
    event: eventTypes.UL,
  },
  {
    id: 6,
    icon: OLIcon,
    event: eventTypes.OL,
  },
  {
    id: 7,
    icon: BoldIcon,
    event: eventTypes.FORMAT_BOLD,
  },
  {
    id: 8,
    icon: ItalicIcon,
    event: eventTypes.FORMAT_ITALIC,
  },
];
