import React from 'react';
import cx from 'classnames';
import styles from './THCell.module.scss';

export type THCellProps = {
  className?: string;
  title?: string;
  sortable?: boolean;
  field?: string;
  state?: 'Ascending' | 'Descending';
  onSort?: Function;
  children?: React.ReactNode;
};

export const THCell = ({
  title,
  sortable = false,
  field,
  state,
  onSort,
  className,
  children,
}: THCellProps) => {
  const handleChange = () => {
    onSort && onSort(field, state === 'Ascending' ? 'Descending' : 'Ascending');
  };

  return (
    <th className={cx(styles['th-cell'], { [`${className}`]: className })}>
      <div className={styles['th-cell__inner']}>
        {title && <span>{title}</span>}
        {sortable && (
          <button
            className={cx(styles['th-cell__sort'], {
              [styles[`th-cell__sort--${state?.toLowerCase()}`]]: state,
            })}
            onClick={() => handleChange()}
          >
            <span className="sr-only">sortable</span>
          </button>
        )}
        {children}
      </div>
    </th>
  );
};
