import React from 'react';
import cx from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useOrdersContext } from 'contexts/OrdersContext';
import { useModalContext } from 'contexts/ModalContext';
import { useToastContext } from 'contexts/ToastContext';
import { Text } from 'components/common/Text';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { SelectList } from 'components/SelectList';
import styles from './OrderCancelModal.module.scss';

interface OrderCancelModalProps {
  testId?: string;
  className?: string;
  isList?: boolean;
}

export const OrderCancelModal: React.FC<OrderCancelModalProps> = ({
  testId,
  className,
  isList,
}) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const {
    selectedRows,
    orderCancel,
    loading: { cancelOrderStatus },
  } = useOrdersContext();
  const { close } = useModalContext();
  const schema = yup.object().shape({
    reason: yup.string().required(t('orders.statusStrings.required')),
  });

  const { control, getValues, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
    const inputValue = getValues('reason');
    await orderCancel(selectedRows[0], inputValue, isList);
    close();
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('orders.cancelStrings.error'),
    });
  };

  const cancelReasons = [
    {
      value: 'GpDeclinedOrder',
      label: t('orders.cancelStrings.GpDeclinedOrder'),
    },
    {
      value: 'ProductOutOfStock',
      label: t('orders.cancelStrings.ProductOutOfStock'),
    },
    {
      value: 'PharmacistDeclinedOrder',
      label: t('orders.cancelStrings.PharmacistDeclinedOrder'),
    },
  ];

  return (
    <div
      className={cx(styles['order-cancel-modal'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading tag="h3" size="md">
        {t('orders.cancelStrings.title')}
      </Heading>
      <Text size="md">{t('orders.cancelStrings.text')}</Text>
      <form
        className={styles['order-cancel-modal__form']}
        onSubmit={handleSubmit(onSubmit, invalid)}
      >
        <Controller
          control={control}
          defaultValue=""
          name="reason"
          isRequired
          render={(props) => (
            <SelectList
              id="test"
              listValues={cancelReasons}
              labelBy="labelForList"
              {...props}
            />
          )}
        />
        <div className={styles['order-cancel-modal__buttons']}>
          <Button
            testId="close-button"
            appearance="flat"
            variant="ui"
            label={t('orders.cancelStrings.back')}
            disabled={cancelOrderStatus === 'loading'}
            onClick={() => {
              close();
            }}
          />
          <Button
            testId="submit-button"
            variant="negative"
            label={t('orders.cancelStrings.submit')}
            className={styles['order-cancel-modal__submit']}
            isLoading={cancelOrderStatus === 'loading'}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
