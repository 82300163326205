import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToastContext } from 'contexts/ToastContext';
import { medSuggest, MedResult } from 'api/MedSuggest';

export interface SuggestContextProps {
  getMedlist: Function;
  medList: MedResult[];
  setMedList: Function;
  loading: boolean;
  setLoading: Function;
}

export const SuggestContextDefaults: SuggestContextProps = {
  getMedlist: Function,
  medList: [],
  setMedList: Function,
  loading: false,
  setLoading: Function,
};

export const SuggestContext = React.createContext<SuggestContextProps>(
  SuggestContextDefaults,
);

export const useSuggestContext = (): SuggestContextProps =>
  React.useContext(SuggestContext);

interface SuggestProviderProps {
  children?: React.ReactNode;
}

export const SuggestProvider = ({ children }: SuggestProviderProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const errors = {
    title: t('common.error.genericTitle'),
    message: t('common.error.genericMessage'),
  };
  const [loading, setLoading] = React.useState(false);
  const [medList, setMedList] = React.useState<MedResult[]>([]);

  const getMedlist = async (value: string) => {
    try {
      const result = await medSuggest(value);
      setMedList(result);
    } catch (error) {
      setToast({
        status: 'error',
        title: errors.title,
        description: errors.message,
      });
    }
    setLoading(false);
  };

  return (
    <SuggestContext.Provider
      value={{
        getMedlist,
        medList,
        setMedList,
        loading,
        setLoading,
      }}
    >
      {children}
    </SuggestContext.Provider>
  );
};
