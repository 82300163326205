import React, { useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useHistory } from 'react-router-dom';
import { useToastContext } from 'contexts/ToastContext';
import { Card } from 'components/Card';
import { Heading } from 'components/common/Heading';
import { Textarea } from 'components/common/Textarea';
import { Button } from 'components/common/Button';
import { CheckBox } from 'components/common/CheckBox';
import { useConfigContext } from 'contexts/ConfigContext';
import { ConfigDetailsSchema } from 'formSchema/ConfigDetails';
import { InputText } from 'components/common/InputText';
import { ConfigPayload } from 'models/Config';
import { routes } from 'routes';

import styles from './WebsiteConfigForm.module.scss';

const colStyles = cx(
  styles['website-config-form__form-col'],
  styles['website-config-form__form-col--wide'],
);
const helperTextStyles = styles['website-config-form__helper-text'];

const headingStyle = styles['website-config-form__title'];

interface WebsiteConfigFormProps {
  testId?: string;
}

export const WebsiteConfigForm = ({ testId }: WebsiteConfigFormProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const { config, updateShopConfig } = useConfigContext();

  useEffect(() => {
    reset({
      shopEnabled: config?.shopEnabled,
      tagline: config?.tagline ?? '',
      taglineSecondLine: config?.taglineSecondLine ?? '',
      freeStandardDeliveryThreshold: config?.freeStandardDeliveryThreshold,
      standardDeliveryEnabled: config?.standardDeliveryEnabled,
      standardDeliveryCharge: config?.standardDeliveryCharge,
      expressDeliveryEnabled: config?.expressDeliveryEnabled,
      expressDeliveryCharge: config?.expressDeliveryCharge,
      standardPrescriptionPrice: config?.standardPrescriptionPrice,
      threeMonthPrePayment: config?.threeMonthPrePayment,
      twelveMonthPrePayment: config?.twelveMonthPrePayment,
    });
  }, [config]);

  const history = useHistory();

  const { control, handleSubmit, formState, reset, errors } = useForm({
    resolver: yupResolver(ConfigDetailsSchema()),
    defaultValues: {
      tagline: config?.tagline,
      taglineSecondLine: config?.taglineSecondLine,
      shopEnabled: config?.shopEnabled,
      freeStandardDeliveryThreshold: config?.freeStandardDeliveryThreshold,
      standardDeliveryEnabled: config?.standardDeliveryEnabled,
      standardDeliveryCharge: config?.standardDeliveryCharge,
      expressDeliveryEnabled: config?.expressDeliveryEnabled,
      expressDeliveryCharge: config?.expressDeliveryCharge,
      standardPrescriptionPrice: config?.standardPrescriptionPrice,
      threeMonthPrePayment: config?.threeMonthPrePayment,
      twelveMonthPrePayment: config?.twelveMonthPrePayment,
    },
  });

  const onSubmit = async ({
    shopEnabled,
    tagline,
    taglineSecondLine,
    freeStandardDeliveryThreshold,
    expressDeliveryCharge,
    expressDeliveryEnabled,
    standardDeliveryCharge,
    standardDeliveryEnabled,
    standardPrescriptionPrice,
    threeMonthPrePayment,
    twelveMonthPrePayment,
  }: ConfigPayload) => {
    updateShopConfig({
      freeStandardDeliveryThreshold,
      shopEnabled,
      tagline,
      taglineSecondLine,
      expressDeliveryCharge,
      expressDeliveryEnabled,
      standardDeliveryCharge,
      standardDeliveryEnabled,
      standardPrescriptionPrice,
      threeMonthPrePayment,
      twelveMonthPrePayment,
    });
    history.push(routes.BASE);
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('common.formErrors.validationErrorsTitle'),
      description: t('common.formErrors.validationErrorsDescription'),
    });
  };

  const inputStyle = styles['website-config-form__input'];
  const cardStyle = styles['website-config-form__card'];
  const maxTextAreaLong = t('common.formErrors.maxCaption', {
    max: '3000',
  });

  return (
    <div className={styles['website-config-form']} data-testid={testId}>
      <form
        className={styles['website-config-form__form']}
        onSubmit={handleSubmit(onSubmit, invalid)}
      >
        <Card className={cardStyle}>
          <Heading size="md" className={headingStyle}>
            {t('config.shop.heading')}
          </Heading>
          <div className={colStyles}>
            <Controller
              control={control}
              name="shopEnabled"
              render={(props) => (
                <CheckBox
                  label={t('config.shop.enableShop')}
                  size="sm"
                  slim
                  {...props}
                />
              )}
            />
            <span className={helperTextStyles}>
              {t('config.shop.helperText')}
            </span>
          </div>
        </Card>

        <Card className={cardStyle}>
          <Heading size="md" className={headingStyle}>
            {t('config.delivery.heading')}
          </Heading>

          <Heading size="sm" className={headingStyle}>
            {t('config.delivery.standard.heading')}
          </Heading>
          <div className={colStyles}>
            <Controller
              control={control}
              name="standardDeliveryEnabled"
              render={(props) => (
                <CheckBox
                  className={inputStyle}
                  size="sm"
                  slim
                  label={t('config.delivery.standard.enable.label')}
                  {...props}
                />
              )}
            />

            <Controller
              control={control}
              name="standardDeliveryCharge"
              render={(props, fieldState) => (
                <InputText
                  className={inputStyle}
                  variant={fieldState.invalid ? 'negative' : 'accent'}
                  label={t('config.delivery.standard.cost.label')}
                  validationError={errors.standardDeliveryCharge?.message}
                  size="md"
                  type="number"
                  {...props}
                />
              )}
            />
            <Controller
              control={control}
              name="freeStandardDeliveryThreshold"
              defaultValue={config?.freeStandardDeliveryThreshold.toFixed(2)}
              render={(props, fieldState) => (
                <InputText
                  className={inputStyle}
                  variant={fieldState.invalid ? 'negative' : 'accent'}
                  label={t('config.delivery.standard.freeThreshold.label')}
                  validationError={
                    errors.freeStandardDeliveryThreshold?.message
                  }
                  size="md"
                  type="number"
                  {...props}
                />
              )}
            />
            <span className={helperTextStyles}>
              {t('config.delivery.standard.freeThreshold.helperText')}
            </span>
          </div>
          <div className={colStyles}>
            <Heading size="sm" className={headingStyle}>
              {t('config.delivery.express.heading')}
            </Heading>

            <div className={colStyles}>
              <Controller
                control={control}
                name="expressDeliveryEnabled"
                render={(props) => (
                  <CheckBox
                    className={inputStyle}
                    size="sm"
                    slim
                    label={t('config.delivery.express.enable.label')}
                    {...props}
                  />
                )}
              />

              <Controller
                control={control}
                name="expressDeliveryCharge"
                defaultValue={config?.expressDeliveryCharge.toFixed(2)}
                render={(props, fieldState) => (
                  <InputText
                    className={inputStyle}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    label={t('config.delivery.express.cost.label')}
                    validationError={errors.expressDeliveryCharge?.message}
                    size="md"
                    type="number"
                    {...props}
                  />
                )}
              />
            </div>
          </div>
        </Card>

        <Card className={cardStyle}>
          <Heading size="md" className={headingStyle}>
            {t('config.website.heading')}
          </Heading>
          <div className={colStyles}>
            <Controller
              control={control}
              name="tagline"
              render={(props, fieldState) => (
                <Textarea
                  className={inputStyle}
                  variant={fieldState.invalid ? 'negative' : 'accent'}
                  maxLength={3000}
                  label={t('config.website.form.tagline')}
                  {...props}
                  caption={maxTextAreaLong}
                />
              )}
            />

            <Controller
              control={control}
              name="taglineSecondLine"
              render={(props) => (
                <Textarea
                  className={inputStyle}
                  maxLength={3000}
                  label={t('config.website.form.taglineSecond')}
                  {...props}
                  caption={maxTextAreaLong}
                />
              )}
            />
          </div>
        </Card>

        <Card className={cardStyle}>
          <Heading size="md" className={headingStyle}>
            {t('config.prescriptions.heading')}
          </Heading>
          <div className={colStyles}>
            <Controller
              control={control}
              name="standardPrescriptionPrice"
              defaultValue={config?.standardPrescriptionPrice.toFixed(2)}
              render={(props, fieldState) => (
                <InputText
                  className={inputStyle}
                  variant={fieldState.invalid ? 'negative' : 'accent'}
                  label={t('config.prescriptions.standardPrice')}
                  validationError={errors.standardPrescriptionPrice?.message}
                  size="md"
                  type="number"
                  {...props}
                />
              )}
            />

            <Controller
              control={control}
              name="threeMonthPrePayment"
              defaultValue={config?.threeMonthPrePayment.toFixed(2)}
              render={(props, fieldState) => (
                <InputText
                  className={inputStyle}
                  variant={fieldState.invalid ? 'negative' : 'accent'}
                  label={t('config.prescriptions.threeMonthPrePayment')}
                  validationError={errors.threeMonthPrePayment?.message}
                  size="md"
                  type="number"
                  {...props}
                />
              )}
            />

            <Controller
              control={control}
              name="twelveMonthPrePayment"
              defaultValue={config?.twelveMonthPrePayment.toFixed(2)}
              render={(props, fieldState) => (
                <InputText
                  className={inputStyle}
                  variant={fieldState.invalid ? 'negative' : 'accent'}
                  label={t('config.prescriptions.twelveMonthPrePayment')}
                  validationError={errors.twelveMonthPrePayment?.message}
                  size="md"
                  type="number"
                  {...props}
                />
              )}
            />
          </div>
        </Card>

        <div className={styles['website-config-form__footer']}>
          {formState.isDirty && (
            <Heading
              tag="h6"
              size="xs"
              className={styles['website-config-form__unsaved-changes']}
            >
              {t('common.form.unsavedChanges')}
            </Heading>
          )}

          <div className={styles['website-config-form__actions-container']}>
            <Button
              className={styles['website-config-form__action-button']}
              label={t('common.form.discard')}
              type="button"
              variant="negative"
              appearance="flat"
              onClick={() => {
                history.push(routes.BASE);
              }}
            />

            <Button
              testId="submit-button"
              className={styles['website-config-form__action-button']}
              label={t('common.form.save')}
              type="submit"
              disabled={!formState.isDirty}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
