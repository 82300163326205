import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Heading } from 'components/common/Heading';
import { Icon } from 'components/common/Icon';
import { ReactComponent as CircleAlert } from 'assets/icons/lawsat/CircleAlert.svg';
import styles from './ErrorState.module.scss';

interface ErrorStateProps {
  testId?: string;
  className?: string;
}

export const ErrorState: React.FC<ErrorStateProps> = ({
  testId,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(styles['error-state'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <Icon icon={CircleAlert} size="xl" />
      <Heading
        tag="h3"
        size="sm"
        className={styles['order-details__error-text']}
      >
        {t('common.error.genericMessage')}
      </Heading>
    </div>
  );
};
