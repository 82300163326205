import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrdersContext } from 'contexts/OrdersContext';
import { useModalContext } from 'contexts/ModalContext';
import { useProductsContext } from 'contexts/ProductsContext';
import { GPResult, gpSuggest } from 'api/GPSuggest';
import { OrderLine } from 'models/Order';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { Spinner } from 'components/common/Spinner';
import { QuantitySelector } from 'components/QuantitySelector';
import { GeneralHealthFormView, PomFormView } from './FormViews';

import styles from './PatientPomFormModal.module.scss';

interface PatientPomFormModalProps {
  orderline: OrderLine;
  orderId: number;
  pomReviewEnabled?: boolean;
}

export const PatientPomFormModal = ({
  orderline,
  orderId,
  pomReviewEnabled = false,
}: PatientPomFormModalProps) => {
  const { t } = useTranslation();
  const {
    getGeneralHealthFormByOrderId,
    getPomForm,
    updateOrderLinePomStatus,
    genHealthForm,
    pomForm,
    setPomForm,
    setGenHealthForm,
  } = useOrdersContext();
  const { getProductDetail, productDetail, setProductDetail } =
    useProductsContext();
  const { close } = useModalContext();
  const [gpDetails, setGpDetails] = useState<GPResult>();
  const [quantitySelected, setQuantitySelected] = useState<number>(
    orderline.quantity,
  );

  useEffect(() => {
    getGeneralHealthFormByOrderId(orderId);
    getPomForm(orderId, orderline.id);
    getProductDetail(orderline.productId);

    return () => {
      setProductDetail(null);
      setPomForm(null);
      setGenHealthForm(null);
    };
  }, [orderline]);

  const getGpDetail = async (gpId: string) => {
    try {
      const gpDetailsResults = await gpSuggest(gpId);
      gpDetailsResults &&
        gpDetailsResults.length > 0 &&
        setGpDetails(gpDetailsResults[0]);
    } catch (e) {}
  };

  useEffect(() => {
    genHealthForm?.gpPracticeId && getGpDetail(genHealthForm.gpPracticeId);
  }, [genHealthForm]);

  if (!genHealthForm || !pomForm || !productDetail) {
    return <Spinner />;
  }

  const originalQuantity = orderline.originalQuantity;

  const onClickDecreaseQuantity = () => {
    setQuantitySelected((quantity) => Math.max(quantity - 1, 1));
  };

  const onClickIncreaseQuantity = () => {
    if (quantitySelected < originalQuantity) {
      setQuantitySelected((quantity) => quantity + 1);
    }
  };

  return (
    <div className={styles['pom-modal']}>
      <Heading size="lg">{productDetail.title}</Heading>

      <div className={styles['pom-modal__forms-container']}>
        <div className={styles['pom-modal__forms-container__form']}>
          <GeneralHealthFormView
            genHealthForm={genHealthForm}
            gpDetails={gpDetails}
          />
        </div>
        <div className={styles['pom-modal__forms-container__form']}>
          <div className={styles['pom-modal__forms-container__form-content']}>
            <PomFormView
              pomForm={pomForm}
              conditionName={
                productDetail.productConditionName ?? 'Condition Form'
              }
            />
          </div>
        </div>
      </div>

      {pomReviewEnabled && (
        <div className={styles['pom-modal__button-container']}>
          <Button
            testId="reject-button"
            appearance="solid"
            variant="negative"
            label={t('orders.pomForm.reject')}
            onClick={() => {
              updateOrderLinePomStatus(orderId, orderline.id, 'Rejected');
              close();
            }}
          />
          <Button
            testId="approve-button"
            appearance="solid"
            variant="accent"
            label={t('orders.pomForm.approve')}
            onClick={() => {
              if (quantitySelected === orderline.originalQuantity) {
                updateOrderLinePomStatus(orderId, orderline.id, 'Approved');
              } else {
                updateOrderLinePomStatus(
                  orderId,
                  orderline.id,
                  'Amended',
                  quantitySelected,
                );
              }
              close();
            }}
          />
          <div
            className={styles['pom-modal__button-container__quantity-selector']}
          >
            <QuantitySelector
              quantitySelected={quantitySelected}
              maxQuantity={originalQuantity}
              onClickDecreaseQuantity={onClickDecreaseQuantity}
              onClickIncreaseQuantity={onClickIncreaseQuantity}
            />
          </div>
        </div>
      )}
    </div>
  );
};
