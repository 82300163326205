import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useModalContext } from 'contexts/ModalContext';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Textarea } from 'components/common/Textarea';
import { CheckBox } from 'components/common/CheckBox';
import { Button } from 'components/common/Button';
import styles from './ContactPatientModal.module.scss';

interface ContactPatientModalProps {
  testId?: string;
  className?: string;
  pharmacyName?: string;
  pharmacyId?: number;
  patientId?: number;
  contactFn: Function;
}

export const ContactPatientModal: React.FC<ContactPatientModalProps> = ({
  testId,
  className,
  pharmacyName,
  pharmacyId,
  patientId,
  contactFn,
}) => {
  const { t } = useTranslation();
  const { close } = useModalContext();

  const schema = yup.object().shape({
    messageText: yup
      .string()
      .required(t('patients.contact.requiredError'))
      .max(
        160,
        t('common.formErrors.maxLengthError', {
          max: 160,
        }),
      )
      .trim(),
    sendAll: yup.boolean().optional(),
  });

  const { control, getValues, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    const inputValue = getValues();

    inputValue.sendAll === true
      ? contactFn(null, pharmacyId, inputValue.messageText)
      : contactFn(patientId, null, inputValue.messageText);

    close();
  };

  return (
    <div
      className={cx(styles['contact-patient-modal'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading size="md" className={styles['contact-patient-modal__title']}>
        {t('patients.contact.title')}
      </Heading>
      <Text className={styles['contact-patient-modal__text']}>
        {t('patients.contact.text')}
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          defaultValue=""
          name="messageText"
          isRequired
          render={(props, fieldState) => (
            <Textarea
              testId="message-input"
              className={styles['contact-patient-modal__input']}
              label={t('patients.contact.textareaLabel')}
              caption={t('common.formErrors.maxCaption', {
                max: '160',
              })}
              validationError={errors.messageText?.message}
              variant={fieldState.invalid ? 'negative' : 'accent'}
              rows={5}
              resizeable={false}
              {...props}
            />
          )}
        />
        {pharmacyName && (
          <Controller
            control={control}
            defaultValue={false}
            name="sendAll"
            render={(props) => (
              <CheckBox
                className={styles['contact-patient-modal__checkbox']}
                label={t('patients.contact.checkboxLabel', {
                  pharmacy: pharmacyName,
                })}
                size="md"
                slim={true}
                orientation="vertical"
                {...props}
              />
            )}
          />
        )}
        <div className={styles['contact-patient-modal__buttons']}>
          <Button
            testId="close-button"
            label={t('patients.contact.cancel')}
            appearance="flat"
            variant="ui"
            onClick={() => close()}
          />
          <Button
            testId="submit-button"
            className={styles['contact-patient-modal__send']}
            label={t('patients.contact.send')}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
