import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { routes } from 'routes';
import { usePharmaciesContext } from 'contexts/PharmaciesContext';
import { Page } from 'components/Page';
import { Col, Container, Row } from 'components/common/grid';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { PharmacyForm } from 'containers/PharmacyForm';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { ErrorState } from 'components/ErrorState';
import styles from './PharmacyView.module.scss';

interface PharmacyViewProps {
  testId?: string;
  className?: string;
}

export const PharmacyView: React.FC<PharmacyViewProps> = ({
  testId,
  className,
}) => {
  const { t } = useTranslation();
  const { pharmacyId } = useParams<{ pharmacyId: string }>();
  const [isAdding, setIsAdding] = React.useState(false);
  const {
    getPharmacy,
    setPharmacyDetail,
    pharmacyDetail,
    loading: { getPharmacyDetailStatus },
  } = usePharmaciesContext();

  React.useEffect(() => {
    if (pharmacyId && pharmacyId !== 'add') {
      getPharmacy(pharmacyId);
    } else {
      setIsAdding(true);
    }
    return () => {
      setPharmacyDetail(null);
    };
  }, [pharmacyId]);

  return (
    <Page
      className={cx(styles['pharmacy-view'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <Branch
              status={getPharmacyDetailStatus}
              LoadingComponent={() => <Breadcrumbs links={[]} />}
              Component={() => (
                <Breadcrumbs
                  links={[
                    {
                      text: t('admin.title'),
                      route: routes.ADMIN.BASE,
                    },
                    {
                      text: t('admin.pharmacies'),
                      route: routes.ADMIN.BASE,
                    },
                    {
                      text: isAdding
                        ? t('admin.pharmacyCTA')
                        : `${pharmacyDetail?.name}`,
                      route: isAdding
                        ? `${routes.ADMIN.PHARMACY}/add`
                        : `${routes.ADMIN.PHARMACY}/${pharmacyId}`,
                    },
                  ]}
                />
              )}
            />
          </Col>
        </Row>
      </Container>
      <div className={styles['pharmacy-view__main']}>
        <Container fluid={true}>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <Branch
                status={getPharmacyDetailStatus}
                LoadingComponent={() => <Spinner />}
                ErrorComponent={() => (
                  <ErrorState testId="pharmacy-view-error" />
                )}
                Component={() => (
                  <PharmacyForm
                    isAdding={isAdding}
                    pharmacyDetail={pharmacyDetail}
                  />
                )}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  );
};
