import * as yup from 'yup';
import i18n from 'i18next';

const maxLengthErrorTranslation = 'common.formErrors.maxLengthError';

export const ConditionDetailsSchema = (): yup.ObjectSchema => {
  return yup.object().shape(
    {
      name: yup.string().required(i18n.t('conditions.formErrors.name')).trim(),
      summary: yup
        .string()
        .max(
          250,
          i18n.t(maxLengthErrorTranslation, {
            max: 250,
          }),
        )
        .trim(),
      tagline: yup
        .string()
        .max(
          500,
          i18n.t(maxLengthErrorTranslation, {
            max: 500,
          }),
        )
        .trim(),
      conditionGroupId: yup
        .string()
        .required(i18n.t('conditions.formErrors.parentGroup')),
      showInShop: yup.boolean().optional(),
      imageFile: yup
        .mixed()
        .optional()
        .when('imageFile', {
          is: (value) => value !== undefined,
          then: yup
            .mixed()
            .test(
              'notBiggerThan2mb',
              i18n.t('common.formErrors.fileSizeLimit'),
              (file) => file.size / Math.pow(1024, 2) <= 2,
            ),
        })
        .when('imageFile', {
          is: (value) => value !== undefined,
          then: yup
            .mixed()
            .test(
              'fileTypeIsJpg',
              i18n.t('common.formErrors.incorrectFileType'),
              (file) => file.type === 'image/jpeg',
            ),
        }),
    },
    [['imageFile', 'imageFile']],
  );
};

export const ConditionGroupDetailsSchema = (): yup.ObjectSchema => {
  return yup.object().shape(
    {
      name: yup
        .string()
        .required(i18n.t('conditionGroups.formErrors.name'))
        .trim(),
      summary: yup
        .string()
        .max(
          250,
          i18n.t(maxLengthErrorTranslation, {
            max: 250,
          }),
        )
        .trim(),
      tagline: yup
        .string()
        .max(
          500,
          i18n.t(maxLengthErrorTranslation, {
            max: 500,
          }),
        )
        .trim(),
      showInShop: yup.boolean().optional(),
      imageFile: yup
        .mixed()
        .optional()
        .when('imageFile', {
          is: (value) => value !== undefined,
          then: yup
            .mixed()
            .test(
              'notBiggerThan2mb',
              i18n.t('common.formErrors.fileSizeLimit'),
              (file) => file.size / Math.pow(1024, 2) <= 2,
            ),
        })
        .when('imageFile', {
          is: (value) => value !== undefined,
          then: yup
            .mixed()
            .test(
              'fileTypeIsJpg',
              i18n.t('common.formErrors.incorrectFileType'),
              (file) => file.type === 'image/jpeg',
            ),
        }),
    },
    [['imageFile', 'imageFile']],
  );
};
