import React from 'react';
import cx from 'classnames';
import { Button } from 'components/common/Button';
import styles from './FilterButtonSet.module.scss';

interface FilterButtonSetProps {
  testId?: string;
  className?: string;
  filterButtons: FilterButton[];
}

export interface FilterButton {
  label: string;
  active: boolean;
  value: string | number | boolean;
  setFilter: Function;
}

export const FilterButtonSet: React.FC<FilterButtonSetProps> = ({
  testId,
  className,
  filterButtons,
}) => {
  return (
    <div
      className={cx(styles['filter-button-set'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <div>
        {filterButtons.map(({ label, value, setFilter, active }, index) => {
          return (
            <Button
              key={index}
              label={label}
              size="sm"
              variant="ui"
              appearance="minimal"
              className={cx(styles['filter-button-set__button'], {
                [`${styles['filter-button-set__button--active']}`]: active,
              })}
              onClick={() => {
                setFilter(active ? null : value);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
