import * as yup from 'yup';
import i18n from 'i18next';

export const PharmacyDetailsSchema = (): yup.ObjectSchema => {
  const maxLength = 'admin.pharmacyForm.errors.maxLength';
  const requiredString = i18n.t('admin.pharmacyForm.errors.required');
  const minLength3 = i18n.t('admin.pharmacyForm.errors.minLength', { min: 3 });

  return yup.object().shape(
    {
      name: yup
        .string()
        .max(
          50,
          i18n.t(maxLength, {
            max: 50,
          }),
        )
        .required(requiredString)
        .trim(),
      addressLine1: yup
        .string()
        .max(
          250,
          i18n.t(maxLength, {
            max: 250,
          }),
        )
        .required(requiredString)
        .trim(),
      addressLine2: yup
        .string()
        .optional()
        .when('addressLine2', {
          is: (value) => value?.length,
          then: yup.string().min(3, minLength3),
        })
        .trim(),
      addressLine3: yup
        .string()
        .optional()
        .when('addressLine3', {
          is: (value) => value?.length,
          then: yup.string().min(3, minLength3),
        })
        .trim(),
      addressTownCity: yup
        .string()
        .required(requiredString)
        .min(3, minLength3)
        .trim(),
      addressCounty: yup
        .string()
        .min(3, minLength3)
        .required(requiredString)
        .trim(),
      addressPostCode: yup
        .string()
        .required(requiredString)
        .matches(
          /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i,
          i18n.t('admin.pharmacyForm.errors.postcodeValidation'),
        )
        .trim(),
      phoneNumber: yup
        .string()
        .required(requiredString)
        .max(11, i18n.t('admin.pharmacyForm.errors.phoneValidation'))
        .matches(
          /^[0-9]{1,11}$/,
          i18n.t('admin.pharmacyForm.errors.phoneValidation'),
        )
        .trim(),
      emailAddress: yup
        .string()
        .email()
        .max(
          1000,
          i18n.t(maxLength, {
            max: 1000,
          }),
        )
        .trim()
        .required(requiredString),
      deliveryCatchmentAreas: yup.string().required(requiredString).trim(),
    },
    [
      ['addressLine2', 'addressLine2'],
      ['addressLine3', 'addressLine3'],
    ],
  );
};
