import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Text } from 'components/common/Text';
import styles from './TDCell.module.scss';

interface TDCellProps {
  className?: string;
  testId?: string;
  img?: React.ReactElement;
  line1?: string;
  line2?: string;
  link?: { to: string; text: string; lapsed?: boolean };
  flag?: boolean;
  button?: React.ReactElement;
  children?: React.ReactNode;
}

export const TDCell = ({
  img,
  line1,
  line2,
  link,
  flag = false,
  testId,
  className,
  children,
  button,
}: TDCellProps) => {
  return (
    <td className={cx(styles['td-cell'], { [`${className}`]: className })}>
      <div className={cx({ [styles['td-cell__wrap']]: img })}>
        {line1 && (
          <Text size="md" className={styles['td-cell__line1']}>
            {line1}
            {flag && (
              <span
                data-testid={testId && `${testId}-flag`}
                className={styles['td-cell__flag']}
                dangerouslySetInnerHTML={{ __html: ' &middot;' }}
              />
            )}
          </Text>
        )}
        {line2 && (
          <Text size="sm" className={styles['td-cell__sub-text']}>
            {line2}
          </Text>
        )}
        {img && <>{img}</>}
        {link && (
          <Link
            className={cx(styles['td-cell__link'], {
              [`${styles['td-cell__link--lapsed']}`]: link.lapsed,
            })}
            to={link.to}
          >
            {link.text}
          </Link>
        )}
        {button}
        {children}
      </div>
    </td>
  );
};
