import React, { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Input } from 'reakit/Input';
import { Label } from 'components/common/Label';
import { Text } from 'components/common/Text';
import { IconButton } from 'components/IconButton';
import { ProductImage } from 'components/ProductImage';
import { ReactComponent as BinRed } from 'assets/icons/lawsat/BinRed.svg';
import styles from './FileUpload.module.scss';

export interface FileUploadProps {
  inputLabel: string;
  setFileName: Function;
  accept?: string;
  fileName?: string;
  deleteImage?: Function;
  validationError?: string;
  onChange?: (value: File | null | undefined) => void;
  value?: string;
  editing?: boolean;
}

const FileUploadRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FileUploadProps
> = (
  {
    accept,
    fileName,
    setFileName,
    deleteImage,
    validationError,
    onChange,
    value,
    editing,
    inputLabel,
  },
  ref,
) => {
  const { t } = useTranslation();
  const [hasChanged, setHasChanged] = useState(false);

  const labelText = fileName
    ? t('common.form.replace')
    : t('common.form.addFile');

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      setHasChanged(true);
      onChange(event?.target?.files?.item(0));
      setFileName(event?.target?.files?.item(0)?.name);
    }
  };

  return (
    <div className={cx(styles['file-upload'])}>
      <Label inputId="file">{inputLabel}</Label>
      <Text>{t('common.form.uploadFormat')}</Text>
      <Input
        type="file"
        name="file"
        id="file"
        data-testid="file-input"
        accept={accept}
        className={styles['file-upload__input']}
        ref={ref}
        tabIndex={-1}
        onChange={handleOnChange}
        value={value}
      />
      {fileName && (
        <div className={styles['file-upload__file-name-container']}>
          <div className={styles['file-upload__image-container']}>
            {editing && !hasChanged && (
              <ProductImage
                className={styles['file-upload__image']}
                imageFileName={fileName}
                size={48}
              />
            )}

            <Text>{fileName}</Text>
          </div>
          {deleteImage && (
            <IconButton
              icon={BinRed}
              label={t('common.form.deleteImage')}
              onClick={() => deleteImage()}
              type="button"
              testId="delete-button"
            />
          )}
        </div>
      )}

      <span className={styles['file-upload__helper-text']}>
        {t('common.form.imageUploadWarning')}
      </span>

      {!!validationError && (
        <span
          className={styles['file-upload__error']}
          data-testid="file-upload__error"
          role="alert"
        >
          {validationError}
        </span>
      )}
      <div className={styles['file-upload__button-container']}>
        <div
          tabIndex={0}
          role="button"
          className={styles['file-upload__button']}
        >
          <label
            htmlFor="file"
            className={styles['file-upload__text']}
            data-testid="upload-button"
          >
            {labelText}
          </label>
        </div>
      </div>
    </div>
  );
};

export const FileUpload = React.forwardRef(FileUploadRef);
