import React from 'react';
import cx from 'classnames';
import styles from './Spinner.module.scss';
import { Container, Row, Col } from '../grid';

export type SpinnerSize = 'sm' | 'md' | 'lg';
export type SpinnerVariant =
  | 'ui'
  | 'accent'
  | 'positive'
  | 'warning'
  | 'negative';
type SpinnerAppearance = 'light' | 'dark';

export interface SpinnerProps {
  size?: SpinnerSize;
  variant?: SpinnerVariant;
  appearance?: SpinnerAppearance;
  isFullScreen?: boolean;
  testId?: string;
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({
  size = 'md',
  variant = 'ui',
  appearance = 'dark',
  isFullScreen,
  testId,
  className,
}) => {
  return (
    <div
      className={cx(`${styles['spinner']}`)}
      role="alert"
      aria-live="polite"
      aria-label="Content loading..."
    >
      <div
        data-testid={testId}
        className={cx(
          `${styles['spinner__animation']}`,
          [`${styles[`spinner__animation--${appearance}--${variant}`]}`],
          {
            [`${styles[`spinner__animation--${size}`]}`]: size,
            [`${styles[`spinner__animation--full-screen`]}`]: isFullScreen,
            [`${styles[`${className}`]}`]: className,
          },
        )}
      ></div>
    </div>
  );
};

export const LandingPageSpinner = () => (
  <>
    <Container className={styles['landing-page-spinner']}>
      <Row>
        <Col>
          <Spinner />
        </Col>
      </Row>
    </Container>
  </>
);
