import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { usePatientsContext } from 'contexts/PatientsContext';
import { formatDateString, formatTimeString } from 'utils/transformers';
import { Heading } from 'components/common/Heading';
import { Table, TableRow, TDCell, THCellProps } from 'components/table';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import styles from './PatientLinkedAccounts.module.scss';

interface PatientLinkedAccountsProps {
  testId?: string;
  className?: string;
  patientId: number;
  isDependant?: boolean;
}

export const PatientLinkedAccounts: React.FC<PatientLinkedAccountsProps> = ({
  testId,
  className,
  patientId,
  isDependant,
}) => {
  const { t } = useTranslation();
  const {
    getPatientLinkedAccounts,
    linkedAccounts,
    loading: { getLinkedAccountsStatus },
  } = usePatientsContext();

  const tableHeaders: THCellProps[] = [
    {
      title: t('patients.tableHeader.joined'),
      sortable: false,
    },
    {
      title: t(
        isDependant
          ? 'patients.dependantsBlock.name'
          : 'patients.dependantsBlock.dependantName',
      ),
      sortable: false,
    },
    {
      title: t('patients.tableHeader.ordered'),
      sortable: false,
    },
    {
      title: t('patients.tableHeader.totalSpend'),
      sortable: false,
    },
  ];

  React.useEffect(() => {
    getPatientLinkedAccounts(patientId);
  }, []);

  const totalOrdersString = (totalOrders: number) => {
    return totalOrders === 1
      ? t('patients.patientDetails.oneOrder')
      : t('patients.patientDetails.totalOrders', { count: totalOrders });
  };

  return (
    <div
      className={cx(styles['patient-dependant-block'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading
        tag="h5"
        size="sm"
        className={styles['patient-dependant-block__title']}
        testId="linked-account-title"
      >
        {t(
          isDependant
            ? 'patients.dependantsBlock.accountHolder'
            : 'patients.dependantsBlock.dependants',
        )}
      </Heading>

      <Branch
        status={getLinkedAccountsStatus}
        LoadingComponent={() => <Spinner testId="linked-accounts-spinner" />}
        Component={() => (
          <>
            {linkedAccounts.length > 0 && (
              <Table
                selectable={false}
                headers={tableHeaders}
                className={styles['patient-dependant-block__table']}
              >
                {linkedAccounts.map(
                  ({
                    id,
                    createdDateTime,
                    firstName,
                    lastName,
                    lastOrderedDateTime,
                    totalSpend,
                    totalSpendNumberOfOrders,
                  }) => {
                    return (
                      <TableRow selectable={false} key={id}>
                        <TDCell
                          line1={
                            createdDateTime && formatDateString(createdDateTime)
                          }
                          line2={
                            createdDateTime && formatTimeString(createdDateTime)
                          }
                        />
                        <TDCell
                          link={{
                            to: `${routes.PATIENTS.BASE}/${id}`,
                            text: `${firstName} ${lastName}`,
                          }}
                        />

                        {lastOrderedDateTime ? (
                          <TDCell
                            line1={formatDateString(lastOrderedDateTime)}
                            line2={formatTimeString(lastOrderedDateTime)}
                          />
                        ) : (
                          <TDCell line1={t('patients.notApplicable')} />
                        )}

                        <TDCell
                          line1={t('patients.patientDetails.totalSpend', {
                            total: totalSpend.toFixed(2),
                          })}
                          line2={totalOrdersString(totalSpendNumberOfOrders)}
                        />
                      </TableRow>
                    );
                  },
                )}
              </Table>
            )}
          </>
        )}
      />
    </div>
  );
};
