import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ModalProvider } from 'contexts/ModalContext';
import { AuthProvider } from 'contexts/AuthContext';
import { ToastProvider } from 'contexts/ToastContext';
import { SettingsProvider } from 'contexts/SettingsContext';
import { OrdersProvider } from 'contexts/OrdersContext';
import { SuggestProvider } from 'contexts/SuggestContext';
import { ProductsProvider } from 'contexts/ProductsContext';
import { PatientsProvider } from '../../contexts/PatientsContext';
import { AuthWrapper } from 'containers/AuthWrapper';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { NewAccount } from 'containers/NewAccountPage';
import { Dashboard } from 'containers/Dashboard';
import { Orders } from 'pages/Orders';
import { Product } from 'pages/Product';
import { Prescriptions } from 'pages/Prescriptions';
import { Patients } from 'pages/Patients';
import { Admin } from 'pages/Admin';
import { routes } from 'routes';
import { Conditions } from 'pages/Conditions';
import { ConditionsProvider } from 'contexts/ConditionsContext';
import { Config } from 'pages/Config';
import { AdminProvider } from 'contexts/AdminContext';
import { PharmaciesProvider } from 'contexts/PharmaciesContext';
import './App.scss';

export const App: React.FC = () => {
  return (
    <Router>
      <ToastProvider>
        <AuthProvider>
          <AdminProvider>
            <OrdersProvider>
              <ConditionsProvider>
                <ProductsProvider>
                  <PatientsProvider>
                    <SuggestProvider>
                      <ModalProvider>
                        <PharmaciesProvider>
                          <SettingsProvider>
                            <ErrorBoundary>
                              <Switch>
                                <Route path={routes.NEWUSER} exact>
                                  <NewAccount />
                                </Route>
                                <AuthWrapper>
                                  <PrivateRoute path={routes.BASE} exact>
                                    <Dashboard />
                                  </PrivateRoute>
                                  <PrivateRoute path={routes.ORDER.BASE}>
                                    <Orders />
                                  </PrivateRoute>
                                  <PrivateRoute path={routes.PRODUCTS.BASE}>
                                    <ProductsProvider>
                                      <Product />
                                    </ProductsProvider>
                                  </PrivateRoute>
                                  <PrivateRoute
                                    path={routes.PRESCRIPTIONS.BASE}
                                  >
                                    <Prescriptions />
                                  </PrivateRoute>
                                  <PrivateRoute path={routes.CONDITIONS.BASE}>
                                    <Conditions />
                                  </PrivateRoute>
                                  <PrivateRoute path={routes.PATIENTS.BASE}>
                                    <Patients />
                                  </PrivateRoute>
                                  <PrivateRoute path={routes.ADMIN.BASE}>
                                    <Admin />
                                  </PrivateRoute>
                                  <PrivateRoute path={routes.CONFIG.BASE}>
                                    <Config />
                                  </PrivateRoute>
                                </AuthWrapper>
                                <Redirect from="*" to={routes.BASE} />
                              </Switch>
                            </ErrorBoundary>
                          </SettingsProvider>
                        </PharmaciesProvider>
                      </ModalProvider>
                    </SuggestProvider>
                  </PatientsProvider>
                </ProductsProvider>
              </ConditionsProvider>
            </OrdersProvider>
          </AdminProvider>
        </AuthProvider>
      </ToastProvider>
    </Router>
  );
};
