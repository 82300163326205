import dompurify from 'dompurify';
import { Client } from 'api/utils';
import {
  MedicinalProductGroup,
  MedicinalProductGroupFull,
} from 'models/MedicinalProductGroup';

const sanitizer = dompurify.sanitize;

export const transformPayload = (
  original: MedicinalProductGroup,
): MedicinalProductGroup => {
  return {
    ...original,
    name: sanitizer(original.name),
    categoryCode:
      !original.categoryCode || original.categoryCode === ''
        ? null
        : sanitizer(original.categoryCode),
  };
};

export const createMedicinalProductGroup = async (
  payload: MedicinalProductGroup,
): Promise<MedicinalProductGroup> => {
  const response = await Client.post(
    `medicinalProductGroups`,
    transformPayload(payload),
  );
  return response.data;
};

export const deleteMedicinalProductGroup = async (
  id: string,
): Promise<void> => {
  return await Client.delete(`medicinalProductGroups/${id}`);
};

export const getMedicinalProductGroup = async (
  id: string,
): Promise<MedicinalProductGroupFull> => {
  const response = await Client.get(`medicinalProductGroups/${id}`);
  return response.data;
};

export const addMedicinalProductGroupItem = async (
  groupId: string,
  itemId: string,
): Promise<void> => {
  return Client.post(`/MedicinalProductGroups/${groupId}/medicinalProducts`, [
    { id: itemId },
  ]);
};

export const addProductToGroup = async (
  groupId: string,
  itemId: number,
): Promise<void> => {
  return Client.post(`/MedicinalProductGroups/${groupId}/products`, [
    { id: itemId },
  ]);
};

export const deleteMedicinalProductGroupItem = async (
  groupId: string,
  itemId: string,
): Promise<void> => {
  return Client.delete(
    `/MedicinalProductGroups/${groupId}/medicinalProducts/${itemId}`,
  );
};

export const deleteProductFromGroup = async (
  groupId: string,
  itemId: string,
): Promise<void> => {
  return Client.delete(`/MedicinalProductGroups/${groupId}/products/${itemId}`);
};
