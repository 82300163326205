import React from 'react';
import { SiteNav } from 'components/SiteNav';
import styles from './Page.module.scss';

interface PageProps {
  className?: string;
  actionsBar?: React.ReactElement;
  children?: React.ReactNode;
}

export const Page = ({ actionsBar, children }: PageProps) => {
  return (
    <div className={styles['page']}>
      <SiteNav />
      <div className={styles['page__main']}>{children}</div>
      {actionsBar}
    </div>
  );
};
