import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useOrdersContext } from 'contexts/OrdersContext';
import { usePatientsContext } from 'contexts/PatientsContext';
import { OrdersActionsBar } from 'components/OrdersActionsBar';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { formatDateString, formatTimeString } from 'utils/transformers';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { Container, Col, Row } from 'components/common/grid';
import { Table, TableRow, TDCell, THCellProps } from 'components/table';
import { StatusText } from 'components/StatusText';
import { Page } from 'components/Page';
import { routes } from 'routes';
import { EmptyOrders } from 'components/EmptyOrders';

import styles from './PatientOrders.module.scss';

export const PatientOrders = () => {
  const { t } = useTranslation();
  const {
    getPatientOrdersList,
    ordersListFilters,
    ordersList,
    setSort,
    loading: { getOrdersListStatus },
    selectedRows,
    setSelectedRows,
    getOrderDetail,
    setOrderDetail,
    orderDetail,
  } = useOrdersContext();
  const {
    patientDetail,
    getPatientDetail,
    loading: { getPatientDetailStatus },
  } = usePatientsContext();

  const { patientId } = useParams<{ patientId: string }>();

  useEffect(() => {
    setSelectedRows([]);
    getPatientOrdersList(patientId);
    getPatientDetail(Number(patientId));
  }, []);

  useEffect(() => {
    if (selectedRows && selectedRows.length >= 1) {
      getOrderDetail(Number(selectedRows[0]));
    } else {
      setOrderDetail(null);
    }
  }, [selectedRows]);

  useEffect(() => {
    const updateFilters = columns.map((col) =>
      col.field === ordersListFilters.sortBy
        ? { ...col, state: ordersListFilters.sortDirection }
        : { ...col, state: undefined },
    );
    setColumns(updateFilters);
  }, [ordersListFilters.sortBy, ordersListFilters.sortDirection]);

  const tableHeaders: THCellProps[] = [
    {
      title: t('orders.tableHeaders.date'),
      sortable: true,
      field: 'OrderReceivedDateTime',
    },
    {
      title: t('orders.tableHeaders.id'),
      sortable: false,
    },

    {
      title: t('orders.tableHeaders.type'),
      sortable: false,
    },
    {
      title: t('orders.tableHeaders.items'),
      sortable: false,
    },
    {
      title: t('orders.tableHeaders.status'),
      sortable: false,
    },
  ];

  const [columns, setColumns] = useState<THCellProps[]>(tableHeaders);

  const ChangeVal = (state: string, value: string | number) => {
    state === 'true'
      ? setSelectedRows([...selectedRows, value])
      : setSelectedRows(
          selectedRows.filter((item) => {
            return item !== value;
          }),
        );
  };

  const toggleAll = (state: string) => {
    state === 'true'
      ? setSelectedRows(
          ordersList.map(({ id }) => {
            return id;
          }),
        )
      : setSelectedRows([]);
  };

  const allRowsSelected =
    ordersList.length > 0 && ordersList?.length === selectedRows?.length;

  return (
    <Page
      className={'orders'}
      actionsBar={
        <OrdersActionsBar
          selected={t('orders.dataContext.ordersSelectedWithCount', {
            count: selectedRows.length,
          })}
          isCancelEnabled={
            selectedRows.length === 1 &&
            orderDetail?.orderStatus !== 'Cancelled'
          }
        />
      }
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <Branch
              status={getPatientDetailStatus}
              LoadingComponent={() => <Breadcrumbs links={[]} />}
              Component={() => (
                <Breadcrumbs
                  links={[
                    {
                      text: t('orders.patientsMain'),
                      route: routes.PATIENTS.BASE,
                    },
                    {
                      text: `${patientDetail?.firstName} ${patientDetail?.lastName} `,
                      route: `${routes.PATIENTS.BASE}/${patientId}`,
                    },
                    {
                      text: t('orders.patientTitle'),
                      route: `${routes.PATIENTS.ORDERS}/${patientId}`,
                    },
                  ]}
                />
              )}
            />
            <Table
              className={`${
                getOrdersListStatus === 'loading'
                  ? styles['orders__loading']
                  : ''
              }`}
              headers={columns}
              selectable={true}
              onChange={toggleAll}
              onSort={setSort}
              allRowsSelected={allRowsSelected}
            >
              <Branch
                status={getOrdersListStatus}
                LoadingComponent={() => (
                  <tr>
                    <td colSpan={8}>
                      <Spinner />
                    </td>
                  </tr>
                )}
                EmptyComponent={() => (
                  <EmptyOrders
                    heading={t('patients.orderHistory.emptyHeading')}
                    text={t('patients.orderHistory.emptyText')}
                  />
                )}
                Component={() => (
                  <>
                    {ordersList.map((row) => {
                      return (
                        <TableRow
                          key={row.id}
                          selectable={true}
                          value={row.id}
                          onChange={ChangeVal}
                          label={row.id.toString()}
                          selected={selectedRows.includes(row.id)}
                        >
                          <TDCell
                            line1={formatDateString(row.orderReceivedDateTime)}
                            line2={formatTimeString(row.orderReceivedDateTime)}
                          />
                          <TDCell
                            link={{
                              to: `${routes.ORDER.SINGLE}/${row.id}`,
                              text: `#${row.id}`,
                              lapsed: row.lapsed,
                            }}
                          />

                          <TDCell
                            line1={`${
                              row.containsPrescriptionItems ? 'Rx' : 'S'
                            }-${row.deliveryOption}`}
                            line2={
                              row.deliveryTimeSlot && `${row.deliveryTimeSlot}`
                            }
                          />

                          <TDCell
                            line1={t('orders.dataContext.itemWithCount', {
                              count: row.numberOfItems,
                            })}
                            line2={`£${row.totalCost}`}
                          />
                          <TDCell>
                            <StatusText
                              lapsed={row.lapsed}
                              status={row.orderStatus}
                            />
                          </TDCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
