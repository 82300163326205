import React from 'react';
import { Pharmacy } from 'models/Pharmacy';
import { DashboardStats } from 'models/DashboardStats';
import { BranchStatus } from 'components/common/Branch/Branch';
import { getPharmacies } from 'api/Pharmacies';
import { getDashboardStats } from 'api/Dashboard';

export interface SettingsContextProps {
  pharmacies: Pharmacy[];
  getPharmaciesList: Function;
  selectedPharmacy: number | null;
  setSelectedPharmacy: Function;
  dashboardStatsList?: DashboardStats;
  getDashboardStatsList: Function;
  loadingStats: BranchStatus;
  pharmaciesStatus: BranchStatus;
}

export const SettingsContextDefaults: SettingsContextProps = {
  pharmacies: [],
  getPharmaciesList: Function,
  selectedPharmacy: null,
  setSelectedPharmacy: Function,
  getDashboardStatsList: Function,
  loadingStats: 'idle',
  pharmaciesStatus: 'idle',
};

export const SettingsContext = React.createContext<SettingsContextProps>(
  SettingsContextDefaults,
);

export const useSettingsContext = (): SettingsContextProps =>
  React.useContext(SettingsContext);

interface SettingsProviderProps {
  children?: React.ReactNode;
}

export const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const [pharmacies, setPharmacies] = React.useState<Pharmacy[]>([]);
  const [dashboardStatsList, setDashboardStatsList] =
    React.useState<DashboardStats>();
  const [loadingStats, setLoadingStats] = React.useState<BranchStatus>('idle');
  const [pharmaciesStatus, setPharmaciesStatus] =
    React.useState<BranchStatus>('idle');
  const [selectedPharmacy, setSelectedPharmacy] = React.useState<number | null>(
    null,
  );

  const getPharmaciesList = async () => {
    try {
      setPharmaciesStatus('loading');
      const result = await getPharmacies();
      setPharmacies(result);
      setPharmaciesStatus('finished');
    } catch {
      setPharmaciesStatus('error');
    }
  };

  const getDashboardStatsList = async (pharmacyId?: string) => {
    try {
      setLoadingStats('loading');
      const result = await getDashboardStats(pharmacyId);
      setDashboardStatsList(result);
      setLoadingStats('finished');
    } catch {
      setLoadingStats('error');
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        pharmacies,
        getPharmaciesList,
        selectedPharmacy,
        setSelectedPharmacy,
        dashboardStatsList,
        loadingStats,
        getDashboardStatsList,
        pharmaciesStatus,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
