import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { AdminList } from 'containers/AdminList';
import { OperatorView } from 'containers/OperatorView';
import { PharmacyView } from 'containers/PharmacyView';
import { routes } from 'routes';
import { PharmaciesProvider } from 'contexts/PharmaciesContext';

export const Admin: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute path={routes.ADMIN.BASE} exact>
        <AdminList />
      </PrivateRoute>
      <PrivateRoute path={`${routes.ADMIN.OPERATOR}/:operatorId`} exact>
        <OperatorView />
      </PrivateRoute>
      <PrivateRoute path={`${routes.ADMIN.PHARMACY}/:pharmacyId`} exact>
        <PharmaciesProvider>
          <PharmacyView />
        </PharmaciesProvider>
      </PrivateRoute>
    </Switch>
  );
};
