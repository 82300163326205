import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Button as ReakitUIButton } from 'reakit/Button';
import { Icon } from 'components/common/Icon';
import styles from './IconButton.module.scss';

export type ButtonVariant = 'light' | 'mid' | 'dark';
export type ButtonSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type ButtonRadius = 'square' | 'rounded' | 'pill';
export type ButtonWidth = 'full' | 'grow';

export interface IconButtonProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  variant?: ButtonVariant;
  size?: ButtonSize;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  label: string;
  testId?: string;
  className?: string;
  link?: { to: string; target?: string; rel?: string };
  onClick?: (event: React.MouseEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
}

export const IconButton: React.FC<IconButtonProps> = ({
  size = 'md',
  type,
  disabled = false,
  icon,
  label,
  testId,
  className,
  link,
  onClick,
  onBlur,
  variant,
}) => {
  const buttonClasses = cx(`${styles['icon-btn']}`, {
    [styles[`icon-btn--${size}`]]: size,
    [styles[`icon-btn--${variant}`]]: variant,
    [styles[`icon-btn--disabled`]]: disabled,
    [`${className}`]: className,
  });
  return (
    <>
      {link ? (
        <Link
          className={buttonClasses}
          onClick={onClick}
          onBlur={onBlur}
          data-testid={testId}
          to={link.to}
          target={link.target}
          rel={link.rel}
        >
          <span
            className={cx(`${styles['btn__text']}`)}
            data-testid={testId && `${testId}-label`}
          >
            <Icon
              className={styles['icon-btn__icon']}
              icon={icon}
              size={size}
            />
            <span
              className={cx(styles['icon-btn__text'])}
              data-testid={testId && `${testId}-label`}
            >
              {label}
            </span>
          </span>
        </Link>
      ) : (
        <ReakitUIButton
          type={type}
          className={buttonClasses}
          disabled={disabled}
          onClick={onClick}
          onBlur={onBlur}
          data-testid={testId}
        >
          <Icon className={styles['icon-btn__icon']} icon={icon} size={size} />
          <span
            className={cx(styles['icon-btn__text'])}
            data-testid={testId && `${testId}-label`}
          >
            {label}
          </span>
        </ReakitUIButton>
      )}
    </>
  );
};
