import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { GPOrder } from 'models/GPOrder';
import { formatDateString } from 'utils/transformers';
import { useOrdersContext } from 'contexts/OrdersContext';
import { Text } from 'components/common/Text';
import { Heading } from 'components/common/Heading';
import { Spinner } from 'components/common/Spinner';
import { Container } from 'components/common/grid';
import styles from './PrintOrders.module.scss';

export const PrintOrders: React.FC = () => {
  const { getGPOrdersList, gpOrders } = useOrdersContext();
  const [printOrders, setPrintOrders] = React.useState<GPOrder[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { orderIds } = useParams<{ orderIds: string }>();
  const { t } = useTranslation();

  React.useEffect(() => {
    const orders = orderIds.split(',');
    getGPOrdersList(orders);
  }, []);

  React.useEffect(() => {
    if (gpOrders.length > 0) {
      setPrintOrders(gpOrders);
      setLoading(false);
    }
  }, [gpOrders]);

  const detailsClassname = 'orders-print__details';
  const detailsContainerClassname = 'orders-print__patient_details-container';

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container className={styles['orders-print']}>
      {printOrders?.map((order) => {
        return (
          <div
            key={order.orderId}
            className={styles['orders-print__container']}
          >
            <Heading tag="h3" className={styles['orders-print__title']}>
              {t('orders.print.title')}
            </Heading>
            <div className={styles['orders-print__patient_details']}>
              <Heading tag="h4">{t('orders.print.patientDetails')}</Heading>
              <div className={styles[detailsClassname]}>
                <div className={styles[detailsContainerClassname]}>
                  <Heading tag="h5">{t('orders.print.patientName')}</Heading>
                  <Text size="lg">
                    {order.patientFirstName} {order.patientLastName}
                  </Text>
                </div>
                <div className={styles[detailsContainerClassname]}>
                  <Heading tag="h5">{t('orders.print.patientDOB')}</Heading>
                  <Text size="lg">
                    {formatDateString(order.patientDateOfBirth, 'long')}
                  </Text>
                </div>
                <div className={styles[detailsContainerClassname]}>
                  <Heading tag="h5">{t('orders.print.patientAddress')}</Heading>
                  <div className={styles['print_orders__details-address']}>
                    <Text size="lg">{order.patientAddress.line1}</Text>
                    <Text size="lg">{order.patientAddress.line2}</Text>
                    <Text size="lg">{order.patientAddress.line3}</Text>
                    <Text size="lg">{order.patientAddress.townCity}</Text>
                    <Text size="lg">{order.patientAddress.postCode}</Text>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['orders-print___prescription_items']}>
              <Heading tag="h4">{t('orders.print.prescription')}</Heading>
              <div className={styles[detailsClassname]}>
                {order.prescriptionItems.map((item, index) => (
                  <Text size="lg" key={index}>
                    {item}
                  </Text>
                ))}
              </div>
            </div>

            <div className={styles['orders-print__practice_details']}>
              <Heading tag="h4">{t('orders.print.gpAddress')}</Heading>
              <div className={styles[detailsClassname]}>
                <Heading></Heading>
                <Text size="lg">{order.patientGpPractice.name}</Text>
                <Text size="lg">{order.patientGpPractice.address1}</Text>
                <Text size="lg">{order.patientGpPractice.address2}</Text>
                <Text size="lg">{order.patientGpPractice.address3}</Text>
                <Text size="lg">{order.patientGpPractice.city}</Text>
                <Text size="lg">{order.patientGpPractice.county}</Text>
                <Text size="lg">{order.patientGpPractice.postcode}</Text>
              </div>
            </div>
          </div>
        );
      })}
    </Container>
  );
};
