import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row } from 'components/common/grid';
import { Page } from 'components/Page';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { routes } from 'routes';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { ConditionGroupForm } from 'containers/ConditionGroupForm';

import styles from './ConditionGroupView.module.scss';

export const ConditionGroupView = () => {
  const { conditionGroupId } = useParams<{ conditionGroupId: string }>();
  const { t } = useTranslation();
  const {
    getConditionGroup,
    setConditionGroup,
    conditionGroup,
    loading: { getConditionGroupStatus },
  } = useConditionsContext();
  const [headingText, setHeadingText] = useState('');
  const [breadcrumbRoute, setBreadcrumbRoute] = useState('');

  useEffect(() => {
    conditionGroupId !== 'edit' && getConditionGroup(Number(conditionGroupId));
    return () => setConditionGroup(null);
  }, [conditionGroupId]);

  useEffect(() => {
    if (conditionGroup) {
      setHeadingText(conditionGroup?.name);
      setBreadcrumbRoute(
        `${routes.CONDITIONS.GROUPS.BASE}/${conditionGroupId}`,
      );
    } else {
      setHeadingText(t('conditionGroups.addCondition'));
      setBreadcrumbRoute(routes.CONDITIONS.GROUPS.ADD);
    }
  }, [conditionGroup]);

  return (
    <Page>
      <Branch
        status={getConditionGroupStatus}
        LoadingComponent={() => <Spinner />}
        Component={() => (
          <>
            <Container fluid={true}>
              <Row>
                <Col>
                  <Breadcrumbs
                    links={[
                      {
                        text: t('conditionGroups.title'),
                        route: routes.CONDITIONS.GROUPS.BASE,
                      },
                      {
                        text: headingText,
                        route: breadcrumbRoute,
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Container>
            <div className={cx(styles['condition-view__main'])}>
              <Container fluid={true}>
                <Row>
                  <Col>
                    <ConditionGroupForm conditionGroup={conditionGroup} />
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        )}
      />
    </Page>
  );
};
