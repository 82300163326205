import { Client } from 'api/utils';
import { GeneralHealthQuestionnaire } from 'models/GeneralHealthQuestionnaire';

export const getGeneralHealthQuestionnaireFromOrder = async (
  orderId: number,
): Promise<GeneralHealthQuestionnaire> => {
  const response = await Client.get(
    `/Questionnaires/GeneralHealth/Order/${orderId}`,
  );
  return response.data;
};
