import { Client } from 'api/utils';
import { OperatorFormFields } from 'containers/OperatorForm';
import { AdminDetail, AdminList } from 'models/Admin';

export type OperatorPayload = Omit<AdminDetail, 'id'>;

export const getAdmins = async (skip: number): Promise<AdminList> => {
  const response = await Client.get(`/Admins?top=20&skip=${skip}`);
  return response.data;
};

export const getAdmin = async (adminId: number): Promise<AdminDetail> => {
  const response = await Client.get(`/Admins/${adminId}`);
  return response.data;
};

export const parseOperatorPayload = (
  operatorFormFields: OperatorFormFields,
): OperatorPayload => ({
  firstName: operatorFormFields.firstName,
  lastName: operatorFormFields.lastName,
  emailAddress: operatorFormFields.emailAddress,
  mfaPhoneNumber: `${
    operatorFormFields.countryPhoneCode
  } ${operatorFormFields.phoneNumber.replace(/^0+/, '')}`,
});

export const createAdmin = async (
  operatorFormFields: OperatorFormFields,
): Promise<AdminDetail> => {
  const payload = parseOperatorPayload(operatorFormFields);
  const response = await Client.post('/Admins', payload);
  return response.data;
};

export const updateAdmin = async (
  id: number,
  operatorFormFields: OperatorFormFields,
): Promise<AdminDetail> => {
  const payload = parseOperatorPayload(operatorFormFields);
  const response = await Client.put(`/Admins/${id}`, payload);
  return response.data;
};

export const deleteAdmin = async (id: number): Promise<void> => {
  return Client.delete(`/Admins/${id}`);
};
