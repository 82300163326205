import React, { useState } from 'react';
import { useOrdersContext } from 'contexts/OrdersContext';
import { usePatientsContext } from 'contexts/PatientsContext';

import { getPharmacyById } from 'api/Pharmacies';
import { getAdmin } from 'api/Admin';

import { AdminDetail } from 'models/Admin';
import { PharmacyDetail } from 'models/Pharmacy';
import { Prescription } from 'models/Prescription';

import { Spinner } from 'components/common/Spinner';
import { ReactComponent as LawsatBrandLogo } from 'assets/icons/lawsat/LawsatBrandLogo.svg';

import { useParams } from 'react-router-dom';
import { differenceInYears } from 'date-fns';
import { formatAddress } from 'utils/Address';
import { formatDOBString, formatDateString } from 'utils/transformers';

import cx from 'classnames';

import styles from './PrintPrescriptions.module.scss';
import { RichTextViewer } from 'components/RichTextViewer';

const boldTitleClass = styles['print-prescription__bold-title'];
const boldSubtitleClass = styles['print-prescription__bold-subtitle'];
const rowClass = styles['print-prescription__row'];
const cellClass = styles['border'];
const flex50Class = styles['utils__flex-50'];
const flexGrowClass = styles['utils__flex-grow'];
const centerClass = styles['utils__center'];

export const PrintPrescriptions = () => {
  const {
    getOrderDetail,
    orderDetail,
    getPrescriptionAgainstOrder,
    prescription,
  } = useOrdersContext();

  const { orderId } = useParams<{ orderId: string }>();
  const { getPatientDetail, patientDetail } = usePatientsContext();

  const [prescriptionStamp, setPrescriptionStamp] = useState<PharmacyDetail>();
  const [prescriptionAddress, setPrescriptionAddress] =
    useState<PharmacyDetail>();
  const [prescriptionAdmin, setPrescriptionAdmin] = useState<AdminDetail>();

  React.useEffect(() => {
    getOrderDetail(parseInt(orderId));
    getPrescriptionAgainstOrder(orderId);
  }, []);

  React.useEffect(() => {
    if (orderDetail) {
      getPatientDetail(orderDetail.patientId);
    }
  }, [orderDetail]);

  React.useEffect(() => {
    if (prescription) {
      getPharmacyData(prescription);
    }
  }, [prescription]);

  const getPharmacyData = async (prescriptionData: Prescription) => {
    try {
      const pharmacyStampRes = await getPharmacyById(
        prescriptionData.pharmacyStampId,
      );
      pharmacyStampRes && setPrescriptionStamp(pharmacyStampRes);
      const prescriberAddressRes = await getPharmacyById(
        prescriptionData.prescriberPharmacyId,
      );
      prescriberAddressRes && setPrescriptionAddress(prescriberAddressRes);
      const adminRes = await getAdmin(prescriptionData.prescriberId);
      adminRes && setPrescriptionAdmin(adminRes);
    } catch {}
  };

  if (!orderDetail || !prescription || !patientDetail) {
    return <Spinner testId="print-prescriptions-page-spinner" />;
  }

  const patientFullName = `${orderDetail.patientFirstName} ${orderDetail.patientMiddleName} ${orderDetail.patientLastName}`;
  const patientDobFormatted = formatDOBString(patientDetail.dateOfBirth);
  const prescriptionDrugs = prescription.prescribedMedication;
  const prescriberNameAndGPHC = `${prescriptionAdmin?.firstName} ${prescriptionAdmin?.lastName} ${prescription?.gphcNumber}`;

  const patientAgeAtOrderTime = () => {
    return differenceInYears(
      new Date(orderDetail.orderReceivedDateTime),
      new Date(patientDetail.dateOfBirth),
    );
  };

  const formatPrescriptionId = () => {
    const prescriptionId = prescription.id.toString();
    switch (prescriptionId.length) {
      case 1:
        return `LSP-00${prescriptionId}`;
      case 2:
        return `LSP-0${prescriptionId}`;
      default:
        return `LSP-${prescriptionId}`;
    }
  };

  return (
    <div className={styles['print-prescription__container']}>
      <LawsatBrandLogo className={styles['print-prescription__logo']} />
      <div className={cx(rowClass)}>
        <div className={cx(cellClass, flex50Class)}>
          <p className={cx(boldTitleClass, centerClass)}>Patient Details</p>
        </div>
        <div className={cx(cellClass, flex50Class)}>
          <p className={cx(boldTitleClass)}>Prescription Stamp</p>
        </div>
      </div>
      <div className={cx(rowClass)}>
        <div className={cx(cellClass, flex50Class)}>
          <p>
            <span className={boldSubtitleClass}>Name:&nbsp;</span>
            {patientFullName}
          </p>
          <span className={boldSubtitleClass}>Address:&nbsp;</span>
          {formatAddress({
            line1: patientDetail?.patientAddressLine1 ?? '',
            line2: patientDetail?.patientAddressLine2 || null,
            line3: patientDetail?.patientAddressLine3 || null,
            county: null,
            townCity: patientDetail?.patientAddressTownCity ?? '',
            postCode: patientDetail?.patientAddressPostCode ?? '',
          })}
          <p>
            <span className={boldSubtitleClass}>D&gt;O&gt;B.&nbsp;</span>{' '}
            {patientDobFormatted}
            <span className={boldSubtitleClass}>&nbsp;Age&nbsp;</span>
            {patientAgeAtOrderTime()}
          </p>
        </div>
        <div className={cx(cellClass, flex50Class)}>
          <p>
            {formatAddress({
              line1: prescriptionStamp?.name ?? '',
              line2: prescriptionStamp?.addressLine1 ?? '',
              line3: prescriptionStamp?.addressLine2 || null,
              county: null,
              townCity: prescriptionStamp?.addressTownCity ?? '',
              postCode: prescriptionStamp?.addressPostCode ?? '',
            })}
          </p>
        </div>
      </div>
      <div className={cx(rowClass)}>
        <div
          className={cx(cellClass, flex50Class, styles['utils__flex-parent'])}
        >
          <p
            className={cx(
              boldTitleClass,
              styles['print-prescription__drugs-title'],
            )}
          >
            Prescription Items:
          </p>
        </div>
        <div className={cx(cellClass, flex50Class, boldTitleClass)}>
          <p>Prescription ID:</p>
          <p>{formatPrescriptionId()}</p>
        </div>
      </div>
      <div className={cellClass}>
        <p className={cx(flexGrowClass, styles['print-prescription__drugs'])}>
          <RichTextViewer
            richText={prescriptionDrugs}
            themeProps={{ textSize: 'md' }}
          />
        </p>
      </div>
      <div className={rowClass}>
        <div className={cx(cellClass, flexGrowClass, centerClass)}>
          <p className={boldSubtitleClass}>Date</p>
        </div>
        <div className={cx(cellClass, flex50Class, centerClass)}>
          <p className={boldSubtitleClass}>Prescriber</p>
        </div>
        <div className={cx(cellClass, flexGrowClass, centerClass)}>
          <p className={boldSubtitleClass}>Sign/date</p>
        </div>
      </div>
      <div className={rowClass}>
        <div className={cx(cellClass, flexGrowClass, centerClass)}>
          <p>{formatDateString(orderDetail.orderReceivedDateTime)}</p>
        </div>
        <div className={cx(cellClass, flex50Class, centerClass)}>
          <p>{prescriberNameAndGPHC}</p>
        </div>
        <div className={cx(cellClass, flexGrowClass, centerClass)}>
          <p>{formatDateString(orderDetail.orderReceivedDateTime)}</p>
        </div>
      </div>
      <div className={cx(cellClass, centerClass)}>
        <p className={cx(boldTitleClass)}>Pharmacy Address</p>
        <p>
          {formatAddress({
            line1: prescriptionAddress?.name ?? '',
            line2: prescriptionAddress?.addressLine1 ?? '',
            line3: prescriptionAddress?.addressLine2 || null,
            county: null,
            townCity: prescriptionAddress?.addressTownCity ?? '',
            postCode: prescriptionAddress?.addressPostCode ?? '',
          })}
        </p>
      </div>
    </div>
  );
};
