/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import i18n from 'i18n';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import { useOrdersContext } from 'contexts/OrdersContext';
import { useModalContext } from 'contexts/ModalContext';
import { useToastContext } from 'contexts/ToastContext';
import { Text } from 'components/common/Text';
import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import { SelectList, listValues } from 'components/SelectList';
import { InputText } from 'components/common/InputText';
import { orderStatusSchema } from 'formSchema/OrderStatusSchema';

import styles from './OrderStatusModal.module.scss';

const statusStrings: listValues[] = [
  {
    value: 'OrderReceived',
    label: i18n.t('orders.statusStrings.OrderReceived'),
  },
  {
    value: 'WithGp',
    label: i18n.t('orders.statusStrings.WithGp'),
  },
  {
    value: 'ApprovedByGp',
    label: i18n.t('orders.statusStrings.ApprovedByGp'),
  },
  {
    value: 'OutForDelivery',
    label: i18n.t('orders.statusStrings.OutForDelivery'),
  },
  {
    value: 'ReadyForCollection',
    label: i18n.t('orders.statusStrings.ReadyForCollection'),
  },
  {
    value: 'OrderComplete',
    label: i18n.t('orders.statusStrings.OrderComplete'),
  },
];

interface OrderStatusModalProps {
  orderId: string | number;
  testId?: string;
  className?: string;
  isUpdateDisabled?: { isDisabled: boolean; reason?: string };
}

export const OrderStatusModal = ({
  orderId,
  testId,
  className,
  isUpdateDisabled = { isDisabled: false },
}: OrderStatusModalProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const { updateOrderStatus, statusList, orderDetail } = useOrdersContext();
  const { close } = useModalContext();

  const { control, getValues, handleSubmit, watch, errors, formState, reset } =
    useForm({
      resolver: yupResolver(orderStatusSchema),
    });

  useEffect(() => {
    orderDetail &&
      reset({
        status: orderDetail.orderStatus,
        courier: orderDetail.courier ?? '',
        deliveryTrackingNumber: orderDetail.deliveryTrackingNumber ?? '',
      });
  }, [orderDetail]);

  const selectedStatus = watch('status');

  const onSubmit = () => {
    const status = getValues('status');
    const deliveryTrackingNumber =
      status === 'OutForDelivery'
        ? getValues('deliveryTrackingNumber')
        : undefined;
    const courier =
      status === 'OutForDelivery' ? getValues('courier') : undefined;

    updateOrderStatus(orderId, status, deliveryTrackingNumber, courier);
    close();
  };

  const invalid = () => {
    const status = getValues('status');
    !status &&
      setToast({
        status: 'error',
        title: t('orders.statusString.required'),
      });
  };

  const [statusSelectList, setStatusSelectList] = useState<listValues[]>([]);

  useEffect(() => {
    statusList?.length > 0 &&
      setStatusSelectList(
        statusStrings.filter((status) => {
          return statusList.includes(status.value);
        }),
      );
  }, [statusList]);

  return (
    <div
      className={cx(styles['order-status-modal'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading tag="h3" size="md">
        {t('orders.statusStrings.title')}
      </Heading>

      <Text size="md">
        {orderDetail?.orderStatus === 'Cancelled'
          ? t('orders.statusStrings.UpdateError')
          : t('orders.statusStrings.text')}
      </Text>

      {statusSelectList.length > 0 && (
        <form
          className={styles['order-status-modal__form']}
          onSubmit={handleSubmit(onSubmit, invalid)}
        >
          <Controller
            control={control}
            defaultValue={orderDetail?.orderStatus ?? ''}
            name="status"
            isRequired
            render={(props) => (
              <SelectList
                id="test"
                listValues={statusSelectList}
                labelBy="labelForList"
                {...props}
              />
            )}
          />
          {selectedStatus === 'OutForDelivery' && (
            <div
              className={
                styles['order-status-modal__delivery-tracking-info-container']
              }
            >
              <Heading tag="h3" size="md">
                {t('orders.statusStrings.deliveryTracking.title')}
              </Heading>
              <Controller
                control={control}
                defaultValue=""
                name="courier"
                render={(props, fieldState) => (
                  <InputText
                    maxLength={100}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.courier?.message}
                    label={t(
                      'orders.statusStrings.deliveryTracking.courierTitle',
                    )}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                name="deliveryTrackingNumber"
                render={(props, fieldState) => (
                  <InputText
                    maxLength={100}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.deliveryTrackingNumber?.message}
                    label={t(
                      'orders.statusStrings.deliveryTracking.numberTitle',
                    )}
                    {...props}
                  />
                )}
              />
            </div>
          )}
          {isUpdateDisabled.isDisabled && isUpdateDisabled.reason && (
            <Text className={styles['order-status-modal__disabled-reason']}>
              {isUpdateDisabled.reason}
            </Text>
          )}
          <div className={styles['order-status-modal__buttons']}>
            <Button
              testId="close-button"
              appearance="flat"
              variant="ui"
              label={t('orders.statusStrings.back')}
              onClick={() => {
                close();
              }}
            />
            <Button
              testId="submit-button"
              type="submit"
              label={t('orders.statusStrings.submit')}
              className={styles['order-status-modal__submit']}
              disabled={isUpdateDisabled.isDisabled || !formState.isDirty}
            />
          </div>
        </form>
      )}
      {statusSelectList.length === 0 ||
        (orderDetail?.orderStatus === 'Cancelled' && (
          <Text>{t('orders.statusString.noneFound')}</Text>
        ))}
    </div>
  );
};
