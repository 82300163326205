import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { ConditionsList } from 'containers/ConditionsList';
import { ConditionsGroupList } from 'containers/ConditionsGroupList';

import { routes } from 'routes';
import { ConditionView } from 'containers/ConditionView';
import { ConditionGroupView } from 'containers/ConditionGroupView';

export const Conditions = () => {
  return (
    <Switch>
      <PrivateRoute path={routes.CONDITIONS.BASE} exact>
        <ConditionsList />
      </PrivateRoute>
      <PrivateRoute path={routes.CONDITIONS.GROUPS.BASE} exact>
        <ConditionsGroupList />
      </PrivateRoute>
      <PrivateRoute path={`${routes.CONDITIONS.BASE}/:conditionId/`} exact>
        <ConditionView />
      </PrivateRoute>
      <PrivateRoute
        path={`${routes.CONDITIONS.GROUPS.BASE}/:conditionGroupId/`}
        exact
      >
        <ConditionGroupView />
      </PrivateRoute>
    </Switch>
  );
};
