import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
import { BranchStatus } from 'components/common/Branch/Branch';
import { InputText } from 'components/common/InputText';
import { IconButton } from 'components/IconButton';
import { ReactComponent as Search } from 'assets/icons/lawsat/Search.svg';
import styles from './TableSearch.module.scss';

interface TableSearchProps {
  testId?: string;
  className?: string;
  label: string;
  placeholder: string;
  changeSearch: Function;
  searchLoading?: BranchStatus;
}

export const TableSearch: React.FC<TableSearchProps> = ({
  testId,
  className,
  label,
  placeholder,
  changeSearch,
  searchLoading,
}) => {
  const { control, getValues, handleSubmit } = useForm({});

  const onSubmit = () => {
    const inputValue = getValues('search');
    changeSearch(inputValue);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cx(styles['table-search'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <Controller
        control={control}
        defaultValue=""
        name="search"
        isRequired
        render={(props) => (
          <InputText
            labelClassName={styles['table-search__label']}
            className={styles['table-search__input']}
            label={label}
            placeholder={placeholder}
            size="sm"
            {...props}
          />
        )}
      />
      <IconButton
        testId="table-search-submit"
        disabled={searchLoading === 'loading'}
        type="submit"
        className={styles['table-search__button']}
        icon={Search}
        label="search"
        variant="mid"
      />
    </form>
  );
};
