import React, { useEffect, useState } from 'react';
import { useOrdersContext, defaultFilters } from 'contexts/OrdersContext';
import { useSettingsContext } from 'contexts/SettingsContext';
import { useTranslation } from 'react-i18next';
import { OrdersActionsBar } from 'components/OrdersActionsBar';
import { formatDateString, formatTimeString } from 'utils/transformers';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { TableHeader } from 'components/TableHeader';
import { Container, Col, Row } from 'components/common/grid';
import { Table, TableRow, TDCell, THCellProps } from 'components/table';
import { StatusText } from 'components/StatusText';
import { Page } from 'components/Page';
import { EmptyOrders } from 'components/EmptyOrders';

import { routes } from 'routes';
import styles from './OrderList.module.scss';

interface OrdersListProps {
  deliveryOption?: 'Collection' | 'Delivery' | null;
  lapsed?: boolean | null;
  newOrders?: boolean | null;
}

export const OrdersList = ({
  deliveryOption = null,
  lapsed = null,
  newOrders = null,
}: OrdersListProps) => {
  const { t } = useTranslation();
  const { selectedPharmacy } = useSettingsContext();
  const {
    getOrdersList,
    setOrdersList,
    ordersListFilters,
    ordersList,
    setSort,
    count,
    setFulfillment,
    setDeliveryOption,
    setLapsed,
    loading: { getOrdersListStatus, getOrdersSearchStatus },
    selectedRows,
    setSelectedRows,
    getOrdersListBySearch,
    getOrderDetail,
    setOrderDetail,
    orderDetail,
  } = useOrdersContext();

  useEffect(() => {
    setSelectedRows([]);

    const loadingFilters = {
      ...defaultFilters,
      pharmacyId: selectedPharmacy,
    };

    if (deliveryOption === 'Delivery') {
      loadingFilters.deliveryOption = 'Delivery';
    }

    if (lapsed) {
      loadingFilters.lapsed = true;
    }

    if (newOrders) {
      loadingFilters.sortBy = 'OrderStatus';
      loadingFilters.sortDirection = 'Ascending';
    }

    getOrdersList(loadingFilters, true);
  }, [selectedPharmacy]);

  const reset = () => {
    getOrdersList(
      {
        ...defaultFilters,
        pharmacyId: selectedPharmacy,
      },
      true,
    );
  };

  useEffect(() => () => setOrdersList([]), []);

  useEffect(() => {
    const updateFilters = columns.map((col) =>
      col.field === ordersListFilters.sortBy
        ? { ...col, state: ordersListFilters.sortDirection }
        : { ...col, state: undefined },
    );
    setColumns(updateFilters);
  }, [ordersListFilters.sortBy, ordersListFilters.sortDirection]);

  useEffect(() => {
    if (selectedRows && selectedRows.length >= 1) {
      getOrderDetail(Number(selectedRows[0]));
    } else {
      setOrderDetail(null);
    }
  }, [selectedRows]);

  const allRowsSelected =
    ordersList.length > 0 && ordersList?.length === selectedRows?.length;

  const tableHeaders: THCellProps[] = [
    {
      title: t('orders.tableHeaders.date'),
      sortable: true,
      field: 'OrderReceivedDateTime',
    },
    {
      title: t('orders.tableHeaders.id'),
      sortable: false,
    },
    {
      title: t('orders.tableHeaders.name'),
      sortable: false,
    },
    {
      title: t('orders.tableHeaders.type'),
      sortable: false,
    },
    {
      title: t('orders.tableHeaders.assignedTo'),
      sortable: false,
    },
    {
      title: t('orders.tableHeaders.collectionFrom'),
      sortable: false,
    },
    {
      title: t('orders.tableHeaders.dueDate'),
      sortable: true,
      field: 'DeliveryDate',
    },
    {
      title: t('orders.tableHeaders.items'),
      sortable: false,
    },
    {
      title: t('orders.tableHeaders.status'),
      sortable: false,
    },
    {
      title: t('orders.tableHeaders.deliveryInfo'),
      sortable: false,
      className: styles['orders__delivery-info-col'],
    },
  ];

  const [columns, setColumns] = useState<THCellProps[]>(tableHeaders);

  const filterButtons = [
    {
      label: t('orders.tableHeader.delivery'),
      active: ordersListFilters.deliveryOption === 'Delivery',
      value: 'Delivery',
      setFilter: setDeliveryOption,
    },
    {
      label: t('orders.tableHeader.collection'),
      active: ordersListFilters.deliveryOption === 'Collection',
      value: 'Collection',
      setFilter: setDeliveryOption,
    },
    {
      label: t('orders.tableHeader.lapsed'),
      active: ordersListFilters.lapsed === true,
      value: true,
      setFilter: setLapsed,
    },
    {
      label: t('orders.tableHeader.reset'),
      active: false,
      value: true,
      setFilter: reset,
    },
  ];

  const tabs = [
    {
      label: t('orders.tableHeader.active'),
      count: count,
      active: ordersListFilters.fulfilled === false,
      value: false,
    },
    {
      label: t('orders.tableHeader.fulfilled'),
      active: ordersListFilters.fulfilled === true,
      value: true,
    },
  ];

  const ChangeVal = (state: string, value: string | number) => {
    state === 'true'
      ? setSelectedRows([...selectedRows, value])
      : setSelectedRows(
          selectedRows.filter((item) => {
            return item !== value;
          }),
        );
  };

  const toggleAll = (state: string) => {
    state === 'true'
      ? setSelectedRows(
          ordersList.map(({ id }) => {
            return id;
          }),
        )
      : setSelectedRows([]);
  };

  const areAllSelectedOrdersPrescription = selectedRows.reduce(
    (runningCheck, row) =>
      runningCheck &&
      !!ordersList.find(
        (order) => order.id === row && order.containsPrescriptionItems,
      ),
    true,
  );

  const areAllSelectedOrdersTreatments = selectedRows.every(
    (row) =>
      !ordersList.some(
        (order) => order.id === row && order.containsPrescriptionItems,
      ),
  );

  const getOrdersActionsBar = () => {
    if (ordersList.length && getOrdersListStatus === 'finished') {
      return (
        <OrdersActionsBar
          isList={true}
          selected={t('orders.dataContext.ordersSelectedWithCount', {
            count: selectedRows.length,
          })}
          isEmailEnabled={
            selectedRows.length === 1 && areAllSelectedOrdersPrescription
          }
          isPrintEnabled={
            selectedRows.length >= 1 && !areAllSelectedOrdersTreatments
          }
          isCancelEnabled={
            selectedRows.length === 1 &&
            orderDetail?.orderStatus !== 'Cancelled'
          }
        />
      );
    }
  };

  return (
    <Page className={'orders'} actionsBar={getOrdersActionsBar()}>
      <Container fluid={true}>
        <Row>
          <Col>
            <TableHeader
              title={t('orders.title')}
              filterButtons={filterButtons}
              tabs={tabs}
              search={true}
              searchLabel={t('orders.tableHeader.searchPlaceholder')}
              searchPlaceholder={t('orders.tableHeader.searchPlaceholder')}
              changeTab={setFulfillment}
              changeSearch={getOrdersListBySearch}
              searchLoading={getOrdersSearchStatus}
            />
            <Table
              className={`${
                getOrdersListStatus === 'loading'
                  ? styles['orders__loading']
                  : ''
              }`}
              headers={columns}
              selectable={true}
              onChange={toggleAll}
              onSort={setSort}
              allRowsSelected={allRowsSelected}
            >
              <Branch
                status={getOrdersListStatus}
                LoadingComponent={() => (
                  <tr>
                    <td colSpan={8}>
                      <Spinner />
                    </td>
                  </tr>
                )}
                EmptyComponent={() => (
                  <EmptyOrders
                    heading={t(
                      !ordersListFilters.fulfilled
                        ? 'orders.activeOrders.noActiveOrders'
                        : 'orders.archivedOrders.noArchivedOrders',
                    )}
                    text={t(
                      !ordersListFilters.fulfilled
                        ? 'orders.activeOrders.whenPatientOrders'
                        : 'orders.archivedOrders.orderComplete',
                    )}
                  />
                )}
                Component={() => (
                  <>
                    {ordersList.map((order, index) => {
                      return (
                        <TableRow
                          key={index}
                          selectable={true}
                          value={order.id}
                          onChange={ChangeVal}
                          label={order.id.toString()}
                          selected={selectedRows.includes(order.id)}
                        >
                          {/* Order Received date and time */}
                          <TDCell
                            line1={formatDateString(
                              order.orderReceivedDateTime,
                            )}
                            line2={formatTimeString(
                              order.orderReceivedDateTime,
                            )}
                          />

                          {/* Order id */}
                          <TDCell
                            link={{
                              to: `${routes.ORDER.SINGLE}/${order.id}`,
                              text: `#${order.id}`,
                              lapsed: order.lapsed,
                            }}
                          />

                          {/* Patient name */}
                          <TDCell
                            line1={`${order.patientFirstName} ${order.patientLastName}`}
                            flag={order.containsPmeds || order.containsNote}
                            className={styles['orders__cell--xl']}
                          />

                          {/* Order Type */}
                          <TDCell
                            line1={`${
                              order.containsPrescriptionItems ? 'Rx' : 'S'
                            }-${order.deliveryOption}`}
                            line2={
                              order.deliveryTimeSlot &&
                              `${order.deliveryTimeSlot}`
                            }
                          />

                          {/* Assigned To */}
                          <TDCell line1={order.assignedToPharmacyName} />

                          {/* Collection From */}
                          <TDCell
                            line1={
                              order.collectionPharmacyName ?? t('common.na')
                            }
                          />

                          {/* Due Date */}
                          <TDCell
                            line1={formatDateString(order.deliveryDate)}
                          />

                          {/* Number of items */}
                          <TDCell
                            line1={t('orders.dataContext.itemWithCount', {
                              count: order.numberOfItems,
                            })}
                            line2={`£${order.totalCost.toFixed(2)}`}
                          />

                          {/* Status */}
                          <TDCell>
                            <StatusText
                              lapsed={order.lapsed}
                              status={order.orderStatus}
                            />
                          </TDCell>

                          {/* Delivery info */}
                          <TDCell
                            line1={order.deliveryTrackingNumber}
                            line2={order.courier}
                            className={styles['orders__cell--md']}
                          />
                        </TableRow>
                      );
                    })}
                  </>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
