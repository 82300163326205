import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatDateString, formatTimeString } from 'utils/transformers';
import { Heading } from 'components/common/Heading';
import { Table, TableRow, TDCell, THCellProps } from 'components/table';
import styles from './PatientSummaryBlock.module.scss';

interface PatientSummaryBlockProps {
  testId?: string;
  className?: string;
  joinedDate?: string;
  isDependant?: boolean;
  lastOrdered?: string;
  totalSpend?: number;
  totalOrders?: number;
}

export const PatientSummaryBlock: React.FC<PatientSummaryBlockProps> = ({
  className,
  testId,
  joinedDate,
  isDependant,
  lastOrdered,
  totalSpend,
  totalOrders,
}) => {
  const { t } = useTranslation();
  const tableHeaders: THCellProps[] = [
    {
      title: t('patients.tableHeader.joined'),
      sortable: false,
    },
    {
      title: t('patients.tableHeader.dependant'),
      sortable: false,
    },
    {
      title: t('patients.tableHeader.ordered'),
      sortable: false,
    },
    {
      title: t('patients.tableHeader.totalSpend'),
      sortable: false,
    },
  ];

  const isDependantString: Record<string, string> = {
    true: t('patients.patientDetails.true'),
    false: t('patients.patientDetails.false'),
  };

  const totalOrdersString =
    totalOrders === 1
      ? t('patients.patientDetails.oneOrder')
      : t('patients.patientDetails.totalOrders', { count: totalOrders });

  return (
    <div
      className={cx(styles['patient-summary-block'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading
        tag="h5"
        size="sm"
        className={styles['patient-summary-block__title']}
      >
        {t('patients.patientSummary')}
      </Heading>
      <Table
        selectable={false}
        headers={tableHeaders}
        className={styles['patient-summary-block__table']}
      >
        <TableRow selectable={false}>
          <TDCell
            line1={joinedDate && formatDateString(joinedDate)}
            line2={joinedDate && formatTimeString(joinedDate)}
          />
          {isDependant !== undefined && (
            <TDCell line1={isDependantString[isDependant.toString()]} />
          )}
          {lastOrdered ? (
            <TDCell
              line1={formatDateString(lastOrdered)}
              line2={formatTimeString(lastOrdered)}
            />
          ) : (
            <TDCell line1={t('patients.notApplicable')} />
          )}
          <TDCell
            line1={t('patients.patientDetails.totalSpend', {
              total: (totalSpend ?? 0).toFixed(2),
            })}
            line2={totalOrdersString}
          />
        </TableRow>
      </Table>
    </div>
  );
};
