import React from 'react';
import { useTranslation } from 'react-i18next';
import { Branch } from 'components/common/Branch';
import { Button } from 'components/common/Button';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { useModalContext } from 'contexts/ModalContext';
import styles from './DeleteOperatorModal.module.scss';

interface DeleteOperatorModalProps {
  operatorId?: string;
  operatorName?: string;
  deleteOperator: Function;
}

export const DeleteOperatorModal: React.FC<DeleteOperatorModalProps> = ({
  operatorId,
  operatorName,
  deleteOperator,
}) => {
  const { t } = useTranslation();
  const { close } = useModalContext();

  return (
    <Branch
      Component={() => (
        <div className={styles['delete-operators-modal']}>
          <Heading
            tag="h4"
            className={styles['delete-operators-modal__heading']}
          >
            {t('admin.operatorDetails.deleteWithOperatorName', {
              operatorName,
            })}
          </Heading>
          <Text size="md" className={styles['delete-operators-modal__text']}>
            {t('admin.operatorDetails.areYouSure')}
          </Text>
          <div className={styles['delete-operators-modal__buttons']}>
            <Button
              testId="close-button"
              appearance="flat"
              variant="ui"
              label={t('admin.operatorDetails.cancel')}
              onClick={() => close()}
            />
            <Button
              testId="delete-button"
              appearance="solid"
              variant="negative"
              label={t('admin.operatorDetails.deleteOperator')}
              className={styles['delete-operators-modal__delete']}
              onClick={async () => {
                await deleteOperator(Number(operatorId));
                close();
              }}
            />
          </div>
        </div>
      )}
    />
  );
};
