import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { OrderStatus } from 'models/Order';
import { formatDateString, formatTimeString } from 'utils/transformers';
import { Table, TableRow, TDCell, THCellProps } from 'components/table';
import { StatusText } from 'components/StatusText';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { routes } from 'routes';
import styles from './OrderDetailBlock.module.scss';

interface OrderDetailBlockProps {
  testId?: string;
  className?: string;
  orderId: string;
  orderReceivedDateTime?: string;
  containsPrescriptionItems?: boolean;
  deliveryOption?: string;
  deliveryTimeSlot?: string;
  deliveryDate?: string;
  orderStatus?: OrderStatus;
  lapsed?: boolean;
  courier?: string;
  deliveryTrackingNumber?: string;
}

export const OrderDetailBlock: React.FC<OrderDetailBlockProps> = ({
  testId,
  className,
  orderId,
  orderReceivedDateTime,
  containsPrescriptionItems,
  deliveryOption,
  deliveryTimeSlot,
  deliveryDate,
  orderStatus,
  lapsed,
  courier,
  deliveryTrackingNumber,
}) => {
  const { t } = useTranslation();
  const tableHeaders: THCellProps[] = [
    {
      title: t('orders.tableHeaders.datePlaced'),
      sortable: false,
      field: 'OrderReceivedDateTime',
    },

    {
      title: t('orders.tableHeaders.type'),
      sortable: false,
    },
    {
      title: t('orders.tableHeaders.dueDate'),
      sortable: false,
      field: 'DeliveryDate',
    },
    {
      title: t('orders.tableHeaders.status'),
      sortable: false,
    },
  ];

  return (
    <div
      className={cx(styles['order-detail-block'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <div className={styles['order-detail-block__card-header']}>
        <Heading tag="h2" size="sm">
          {t('orders.orderDetailTitle')}
        </Heading>
        <Button
          className={styles['order-detail-block__cta']}
          link={{ to: `${routes.ORDER.AUDIT}/${orderId}` }}
          label={t('orders.auditCTA')}
          appearance="flat"
          variant="ui"
          size="sm"
        />
      </div>
      {courier && deliveryTrackingNumber && (
        <div className={styles['order-detail-block__tracking-info']}>
          <Heading size="xs">
            {t('orders.orderDetailTrackingInfoTitle')}
          </Heading>
          <span>&nbsp;</span>
          <Text tag="span">{`${courier} - ${deliveryTrackingNumber}`}</Text>
        </div>
      )}
      <Table
        selectable={false}
        headers={tableHeaders}
        className={styles['order-detail-block__table']}
      >
        <TableRow selectable={false}>
          {orderReceivedDateTime && (
            <TDCell
              line1={formatDateString(orderReceivedDateTime)}
              line2={formatTimeString(orderReceivedDateTime)}
            />
          )}

          <TDCell
            line1={`${
              containsPrescriptionItems ? 'Rx' : 'S'
            }-${deliveryOption}`}
            line2={deliveryTimeSlot && `${deliveryTimeSlot}`}
          />
          {deliveryDate && <TDCell line1={formatDateString(deliveryDate)} />}
          {orderStatus && (
            <TDCell>
              <StatusText lapsed={lapsed} status={orderStatus} />
            </TDCell>
          )}
        </TableRow>
      </Table>
    </div>
  );
};
