import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { OrderLine as OrderLineType } from 'models/Order';
import { OrderLine } from 'components/OrderLine';
import { Text } from 'components/common/Text';
import { formatDeliveryOptionTitle } from 'utils/DeliveryOption';

import styles from './OrderSummaryBlock.module.scss';

interface OrderSummaryBlockProps {
  testId?: string;
  className?: string;
  orderLines?: OrderLineType[];
  subTotal?: number;
  deliveryCost?: number;
  deliveryOption?: string;
  refunded?: number;
  totalVat?: number;
  totalCost?: number;
  orderId: number;
  pomReviewEnabled?: boolean;
}

export const OrderSummaryBlock: React.FC<OrderSummaryBlockProps> = ({
  testId,
  className,
  orderLines,
  subTotal,
  deliveryCost,
  deliveryOption,
  totalVat,
  totalCost,
  refunded,
  orderId,
  pomReviewEnabled = false,
}) => {
  const { t } = useTranslation();
  const rowClass = styles['order-summary-block__total-cost-row'];

  const rejectedPomOrderlines = orderLines?.filter(
    (orderLine) => orderLine.pomStatus === 'Rejected',
  );

  const remainingOrderilnes = orderLines?.filter(
    (orderLine) => orderLine.pomStatus !== 'Rejected',
  );

  const reorderedOrderlines = remainingOrderilnes?.concat(
    rejectedPomOrderlines ?? [],
  );

  return (
    <div
      className={cx(styles['order-summary-block'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      {reorderedOrderlines && (
        <ul>
          {reorderedOrderlines.map((orderline) => {
            return (
              <li key={orderline.id}>
                <OrderLine
                  orderId={orderId}
                  orderline={orderline}
                  pomReviewEnabled={pomReviewEnabled}
                />
              </li>
            );
          })}
        </ul>
      )}
      <div className={styles['order-summary-block__total-cost']}>
        <p className={rowClass}>
          <Text tag="span">{t('orders.orderSummaryLabels.subtotal')}</Text>
          <Text tag="span">£{subTotal?.toFixed(2)}</Text>
        </p>
        <p className={rowClass}>
          <Text tag="span">{t('orders.orderSummaryLabels.refunded')}</Text>
          <Text tag="span">£{refunded?.toFixed(2)}</Text>
        </p>
        <p className={rowClass}>
          <Text tag="span">
            {deliveryOption && formatDeliveryOptionTitle(deliveryOption)}
          </Text>
          <Text tag="span">£{deliveryCost?.toFixed(2)}</Text>
        </p>
        <p className={rowClass}>
          <Text tag="span">{t('orders.orderSummaryLabels.vat')}</Text>
          <Text tag="span">£{totalVat?.toFixed(2)}</Text>
        </p>

        <p className={styles['order-summary-block__total']}>
          <Text
            tag="span"
            className={styles['order-summary-block__total-label']}
          >
            {t('orders.orderSummaryLabels.total')}
          </Text>
          <Text tag="span" className={styles['order-summary-block__amount']}>
            £{totalCost?.toFixed(2)}
          </Text>
        </p>
      </div>
    </div>
  );
};
