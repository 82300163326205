export function moveArrayItem<T>(
  array: T[],
  from: number,
  to: number,
  arraySetter: React.Dispatch<React.SetStateAction<T[]>>,
) {
  const item = array.splice(from, 1)[0];
  array.splice(to, 0, item);
  arraySetter([...array]);
}
