import React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './Breadcrumbs.module.scss';

interface Crumb {
  text: string;
  route: string;
}

interface BreadcrumbsProps {
  testId?: string;
  className?: string;
  links: Crumb[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  testId,
  className,
  links,
}) => {
  return (
    <nav
      aria-label="breadcrumb"
      className={cx(styles['breadcrumbs'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <ol className={styles['breadcrumbs__list']}>
        {links.map(({ text, route }, index) => {
          return (
            <li key={index} className={styles['breadcrumbs__list-item']}>
              <NavLink
                exact
                to={route}
                className={styles['breadcrumbs__link']}
                activeClassName={styles['breadcrumbs__link--active']}
              >
                {text}
              </NavLink>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
