import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatAddress } from 'utils/Address';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { routes } from 'routes';
import { PatientDetail } from 'models/Patient';
import { formatDOBString } from 'utils/transformers';
import { differenceInYears } from 'date-fns';
import styles from './PrescriptionPatientDetailBlock.module.scss';

interface PrescriptionPatientDetailBlockProps {
  patient: PatientDetail;
  orderDate: string;
}

export const PrescriptionPatientDetailBlock: React.FC<
  PrescriptionPatientDetailBlockProps
> = ({ patient, orderDate }) => {
  const { t } = useTranslation();
  const labelClass = styles['prescription-patient-detail-block__label'];
  const listClass = styles['prescription-patient-detail-block__list-item'];

  const dob = formatDOBString(patient.dateOfBirth);
  const age = differenceInYears(
    new Date(orderDate),
    new Date(patient.dateOfBirth),
  );

  return (
    <div className={styles['prescription-patient-detail-block']}>
      <Heading tag="h2" size="sm">
        {t('orders.orderPatientDetailTitle')}
      </Heading>
      <ul className={styles['prescription-patient-detail-block__list']}>
        <li className={listClass}>
          <Text tag="span" className={labelClass}>
            {t('orders.patientDetailLabels.name')}:
          </Text>
          <Text tag="span">{`${patient.firstName} ${patient.lastName}`}</Text>
        </li>
        <li className={listClass}>
          <Text tag="span" className={labelClass}>
            {t('orders.patientDetailLabels.address')}:
          </Text>
          <Text tag="span">
            {formatAddress({
              line1: patient.patientAddressLine1,
              line2: patient.patientAddressLine2 || null,
              line3: patient.patientAddressLine3 || null,
              county: null,
              townCity: patient.patientAddressTownCity,
              postCode: patient.patientAddressPostCode,
            })}
          </Text>
        </li>
        <li className={listClass}>
          <Text tag="span" className={labelClass}>
            {t('orders.patientDetailLabels.dob')}:
          </Text>
          <Text tag="span">{`${dob} (Age: ${age})`}</Text>
        </li>
      </ul>

      <Button
        className={styles['prescription-patient-detail-block__button']}
        appearance="flat"
        variant="ui"
        label={t('orders.orderPatientDetailCTA')}
        link={{
          to: `${routes.PATIENTS.BASE}/${patient.id}`,
          rel: 'noreferrer noopener',
          target: 'blank',
        }}
      />
    </div>
  );
};
