import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useProductsContext } from 'contexts/ProductsContext';
import { Container, Col, Row } from 'components/common/grid';
import { ProductForm } from 'containers/ProductForm';
import { Page } from 'components/Page';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { routes } from 'routes';

import styles from './ProductView.module.scss';
import { LandingPageSpinner } from 'components/common/Spinner/Spinner';

export const ProductView = () => {
  const { productId } = useParams<{ productId: string }>();
  const { t } = useTranslation();
  const { productDetail, getProductDetail, setProductDetail } =
    useProductsContext();
  const [text, setText] = useState('');
  const [route, setRoute] = useState('');

  useEffect(() => {
    productId !== 'edit' && getProductDetail(Number(productId));
    return () => setProductDetail(null);
  }, [productId]);

  useEffect(() => {
    if (productDetail) {
      setText(productDetail?.title);
      setRoute(`${routes.PRODUCTS.BASE}/${productId}`);
    } else {
      setText(t('products.addProduct'));
      setRoute(routes.PRODUCTS.ADD);
    }
  }, [productDetail]);

  if (productId !== 'edit' && !productDetail) {
    return <LandingPageSpinner />;
  }

  return (
    <Page className={'products'}>
      <Container fluid={true}>
        <Row>
          <Col>
            <Breadcrumbs
              links={[
                {
                  text: t('products.title'),
                  route: routes.PRODUCTS.BASE,
                },
                {
                  text: text,
                  route: route,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
      <div className={cx(styles['product-view__main'])}>
        <Container fluid={true}>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <ProductForm
                productId={productId}
                productDetail={productDetail}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  );
};
