import React from 'react';
import {
  useRadioState,
  Radio,
  RadioGroup as ReakitRadioGroup,
} from 'reakit/Radio';
import { Label } from 'components/common/Label';
import cx from 'classnames';
import styles from './RadioGroup.module.scss';

export type radioValues = {
  value: string;
  label: string;
};

interface RadioGroupProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  labelBy?: string;
  onChange?: (value: string) => void;
  orientation?: 'horizontal' | 'vertical';
  size?: 'sm' | 'md' | 'lg';
  testId?: string;
  value?: string;
  values: radioValues[];
  variant?: 'ui' | 'accent';
  hasLeftPadding?: boolean;
}

const RadioGroupRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  RadioGroupProps
> = (
  {
    className,
    id,
    disabled = false,
    orientation = 'horizontal',
    labelBy,
    onChange,
    size = 'md',
    testId,
    value,
    values,
    variant = 'accent',
    hasLeftPadding = true,
  },
  ref,
) => {
  const radio = useRadioState({ state: value, orientation, wrap: true });

  React.useEffect(() => {
    radio.setState(value);
  }, [value]);

  const classes = cx(`${styles['input__radio-group']}`, {
    [`${styles[`input__radio-group--${variant}`]}`]: variant,
    [`${styles[`input__radio-group--${size}`]}`]: size,
    [`${styles[`input__radio-group--${orientation}`]}`]: orientation,
    [`${styles['disabled']}`]: disabled,
    [`${className}`]: className,
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value);
  };

  return (
    <ReakitRadioGroup
      {...radio}
      data-testid={testId}
      className={classes}
      aria-labelledby={labelBy}
      baseId={id}
    >
      {values.map((value, i) => (
        <Label
          testId={testId ? `${testId}-label` : undefined}
          className={cx(
            styles['input__radio-label'],
            styles[`input__radio-label--${size}`],
            !hasLeftPadding && styles[`input__radio-label--no-left-padding`],
          )}
          key={i}
          size={size}
        >
          <Radio
            {...radio}
            className={styles['input__radio']}
            disabled={disabled}
            data-testid={testId ? `${testId}-radio` : undefined}
            onChange={handleOnChange}
            value={value.value}
            ref={ref}
            id={`${id}-${i}`}
            name={`${id}-${i}`}
          />
          <span
            data-testid={testId ? `${testId}-circle` : undefined}
            className={styles['input__radio-circle']}
          />
          <span className={styles['input__radio-text']}>{value.label}</span>
          <span className={styles['input__faux-background']}></span>
        </Label>
      ))}
    </ReakitRadioGroup>
  );
};

export const RadioGroup = React.forwardRef(RadioGroupRef);
