import React from 'react';
import cx from 'classnames';
import { BranchStatus } from 'components/common/Branch/Branch';
import { FilterButtonSet, FilterButton } from 'components/FilterButtonSet';
import { Heading } from 'components/common/Heading';
import { TableSearch } from 'components/TableSearch';
import { TabSet, Tab } from 'components/TabSet';
import { ActionButton, Action } from 'components/ActionButton/ActionButton';
import styles from './TableHeader.module.scss';

interface TableHeaderProps {
  testId?: string;
  className?: string;
  title: string;
  filterButtons?: FilterButton[];
  tabs?: Tab[];
  changeTab?: Function;
  search?: boolean;
  searchLabel?: string;
  searchPlaceholder?: string;
  changeSearch?: Function;
  searchLoading?: BranchStatus;
  action?: Action;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  testId,
  className,
  filterButtons,
  tabs,
  changeTab,
  search = false,
  searchLabel = 'search',
  searchPlaceholder = 'search',
  changeSearch,
  searchLoading = 'idle',
  title,
  action,
}) => {
  return (
    <div
      className={cx(styles['table-header'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <div>
        <Heading className={styles['table-header__title']} size="lg">
          {title}
        </Heading>
        {tabs && changeTab && <TabSet tabs={tabs} changeTab={changeTab} />}
      </div>
      <div className={styles['table-header__right']}>
        {filterButtons && <FilterButtonSet filterButtons={filterButtons} />}
        {search && changeSearch && (
          <div>
            <TableSearch
              className={cx(styles['table-header__search'], {
                [styles['table-header__search--with-filter-buttons']]:
                  filterButtons,
              })}
              label={searchLabel}
              placeholder={searchPlaceholder}
              changeSearch={changeSearch}
              searchLoading={searchLoading}
            />
          </div>
        )}
        {action && (
          <ActionButton
            className={cx(styles['table-header__action'], {
              [styles['table-header__action--with-search']]: search,
            })}
            action={action}
            testId={testId && `${testId}__action`}
          />
        )}
      </div>
    </div>
  );
};
