import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { ProductList } from 'containers/ProductList';
import { ProductView } from 'containers/ProductView';
import { routes } from 'routes';

export const Product: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute path={routes.PRODUCTS.BASE} exact>
        <ProductList />
      </PrivateRoute>
      <PrivateRoute path={`${routes.PRODUCTS.BASE}/:productId`} exact>
        <ProductView />
      </PrivateRoute>
      <PrivateRoute path={`${routes.PRODUCTS.ADD}`} exact>
        <ProductView />
      </PrivateRoute>
      <PrivateRoute path={`${routes.PRODUCTS.BASE}/:productId/`} exact>
        <ProductView />
      </PrivateRoute>
    </Switch>
  );
};
