import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDebounce } from 'hooks/useDebounce';
import { InputText } from 'components/common/InputText';
import { Icon } from 'components/common/Icon';
import { ReactComponent as SearchIcon } from 'assets/icons/lawsat/Search.svg';
import { ReactComponent as ClearIcon } from 'assets/icons/lawsat/Clear.svg';
import styles from './AutoComplete.module.scss';

interface AutoCompleteProps {
  getResults: Function;
  clearResults?: Function;
  label: string;
  placeholder: string;
}

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  getResults,
  clearResults,
  label,
  placeholder,
}) => {
  const { control, getValues, watch, handleSubmit, setValue } = useForm({});
  const inputChanged = watch('autocomplete');
  const [text, setText] = React.useState('');
  const debouncedText = useDebounce(text, 100);

  React.useEffect(() => {
    const inputValue = getValues('autocomplete');
    setText(inputValue);
  }, [inputChanged]);

  React.useEffect(() => {
    processRequest(debouncedText);
  }, [debouncedText]);

  const processRequest = async (value: string) => {
    value.length >= 3 && (await getResults(value));
  };

  const onSubmit = async () => {
    const inputValue = getValues('autocomplete');
    await getResults(inputValue);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="autocomplete-form"
      className={styles['autocomplete']}
    >
      <Controller
        control={control}
        defaultValue=""
        name="autocomplete"
        isRequired
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setText(event.target.value);
        }}
        render={(props) => (
          <>
            <InputText
              icon={SearchIcon}
              className={styles['autocomplete__input']}
              labelClassName={styles['autocomplete__label']}
              placeholder={placeholder}
              label={label}
              {...props}
            />
            {clearResults && (
              <button
                className={styles['autocomplete__clear-btn']}
                onClick={(event) => {
                  event.preventDefault();
                  setValue('autocomplete', '');
                  clearResults();
                }}
              >
                <Icon icon={ClearIcon} srLabel="clear search" size="sm" />
              </button>
            )}
          </>
        )}
      />
    </form>
  );
};
