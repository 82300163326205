/* eslint-disable sonarjs/no-duplicate-string */
import * as yup from 'yup';
import i18n from 'i18next';

export const PrescriptionDetailsSchema = (
  initialPrescribedMedsValue: string,
  comparitor: (value: string | null | undefined) => boolean,
): yup.ObjectSchema => {
  return yup.object().shape({
    pharmacyStampId: yup
      .number()
      .required(i18n.t('prescriptionCreate.form.errors.pharmacyStampId')),
    prescriberPharmacyId: yup
      .number()
      .required(i18n.t('prescriptionCreate.form.errors.pharmacyAddress')),
    prescribedMedication: yup
      .string()
      .trim()
      .required(i18n.t('prescriptionCreate.form.errors.prescribedMedication'))
      .test(
        'custom-test',
        i18n.t('prescriptionCreate.form.errors.prescribedMedicationNotUpdated'),
        (value) => {
          return comparitor(value);
        },
      ),
    gphcNumber: yup
      .string()
      .trim()
      .min(1, i18n.t('prescriptionCreate.form.errors.gphcNumber'))
      .max(10, i18n.t('prescriptionCreate.form.errors.gphcNumberMaxLength'))
      .required(i18n.t('prescriptionCreate.form.errors.gphcNumber'))
      .matches(
        /^\d+$/,
        i18n.t('prescriptionCreate.form.errors.gphcNumbersOnly'),
      ),
  });
};
