import React from 'react';
import cx from 'classnames';
import styles from './GroupEmptyState.module.scss';
import { Button } from 'components/common/Button';
import { Text } from 'components/common/Text';

interface GroupEmptyStateProps {
  testId?: string;
  className?: string;
  cta: string;
  text: string;
  ctaFn: Function;
}

export const GroupEmptyState: React.FC<GroupEmptyStateProps> = ({
  testId,
  className,
  cta,
  text,
  ctaFn,
}) => {
  return (
    <div
      className={cx(styles['group-empty-state'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Button
        testId={`${testId}-button`}
        label={cta}
        appearance="flat"
        size="sm"
        onClick={() => ctaFn()}
      />
      <Text>{text}</Text>
    </div>
  );
};
