import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/common/Text';
import { ReactComponent as Chat } from 'assets/icons/lawsat/Chat.svg';
import styles from './OrderNoteBlock.module.scss';

interface OrderNoteBlockProps {
  testId?: string;
  className?: string;
  note: string;
}

export const OrderNoteBlock: React.FC<OrderNoteBlockProps> = ({
  testId,
  className,
  note,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(styles['order-note-block'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Chat />
      <p className={styles['order-note-block__text']}>
        <Text tag="span" className={styles['order-note-block__label']}>
          {t('orders.orderSummaryLabels.note')}:
        </Text>
        <Text tag="span">{note}</Text>
      </p>
    </div>
  );
};
