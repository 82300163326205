import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { ReactComponent as ChevronRight } from 'assets/icons/lawsat/ChevronRight.svg';
import { Icon } from 'components/common/Icon';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import styles from './CardButton.module.scss';

export type CardButtonType = {
  testId?: string;
  title: string;
  description?: string;
  link?: string;
  href?: string;
  icon?: React.FC;
};

export type CardButtonProps = {
  item: CardButtonType;
  className?: string;
};

const CardInner: React.FC<CardButtonProps> = ({ item }) => {
  const { title, description, link, icon, href } = item;
  return (
    <>
      {icon && <Icon className={styles['card__icon']} icon={icon} />}
      {(link || href) && (
        <Icon className={styles['card__arrow']} icon={ChevronRight} />
      )}
      <Heading size="sm" tag="h5" className={styles['card__title']}>
        {title}
      </Heading>
      {description && (
        <Text size="md" className={styles['card__text']}>
          {description}
        </Text>
      )}
    </>
  );
};

export const CardButton: React.FC<CardButtonProps> = ({ item, className }) => {
  const { href, link, icon, testId } = item;
  const classString = cx(styles['card'], styles['card--is-link'], className, {
    [styles['card--has-icon']]: icon,
    [styles['card--link']]: link,
  });
  return (
    <>
      {link && (
        <Link data-testid={testId} className={classString} to={link}>
          <CardInner item={item} />
        </Link>
      )}
      {href && (
        <a
          data-testid={testId}
          className={classString}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CardInner item={item} />
        </a>
      )}
    </>
  );
};
