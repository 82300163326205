import dompurify from 'dompurify';
import { Client } from 'api/utils';
import { Pharmacy as PharmacyType, PharmacyDetail } from 'models/Pharmacy';

const sanitizer = dompurify.sanitize;

export const transformPayload = (original: PharmacyDetail): PharmacyDetail => {
  return {
    ...original,
    name: sanitizer(original.name),
    addressLine1: sanitizer(original.addressLine1),
    addressLine2:
      !original.addressLine2 || original.addressLine2 === ''
        ? null
        : sanitizer(original.addressLine2),
    addressLine3:
      !original.addressLine3 || original.addressLine3 === ''
        ? null
        : sanitizer(original.addressLine3),
    addressTownCity: sanitizer(original.addressTownCity),
    addressCounty: sanitizer(original.addressCounty),
    addressPostCode: sanitizer(original.addressPostCode),
    phoneNumber: sanitizer(original.phoneNumber),
    emailAddress: sanitizer(original.emailAddress),
    deliveryCatchmentAreas:
      original.deliveryCatchmentAreas === undefined ||
      original.deliveryCatchmentAreas === ''
        ? ''
        : sanitizer(original.deliveryCatchmentAreas),
  };
};

export const getPharmacies = async (): Promise<PharmacyType[]> => {
  const response = await Client.get(`Pharmacies`);
  return response.data;
};

export const postPharmacy = async (
  payload: PharmacyDetail,
): Promise<PharmacyDetail> => {
  const response = await Client.post(`Pharmacies`, transformPayload(payload));
  return response.data;
};

export const getPharmacyById = async (
  pharmacyId: number | string,
): Promise<PharmacyDetail> => {
  const response = await Client.get(`Pharmacies/${pharmacyId}`);
  return response.data;
};
