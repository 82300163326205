import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Branch } from 'components/common/Branch';
import { Col, Container, Row } from 'components/common/grid';
import { Spinner } from 'components/common/Spinner';
import { Page } from 'components/Page';
import { OperatorForm } from 'containers/OperatorForm';
import { useAdminContext } from 'contexts/AdminContext';
import { routes } from 'routes';
import styles from './OperatorView.module.scss';

interface OperatorViewProps {
  testId?: string;
  className?: string;
}

export const OperatorView: React.FC<OperatorViewProps> = ({
  testId,
  className,
}) => {
  const { operatorId } = useParams<{ operatorId: string }>();
  const { t } = useTranslation();
  const {
    operatorDetails,
    getOperatorDetails,
    setOperatorDetails,
    loading: { getOperatorStatus },
  } = useAdminContext();
  const [text, setText] = React.useState('');
  const [route, setRoute] = React.useState('');

  React.useEffect(() => {
    operatorId !== 'add' && getOperatorDetails(Number(operatorId));
    return () => setOperatorDetails(null);
  }, [operatorId]);

  React.useEffect(() => {
    if (operatorDetails) {
      setText(`${operatorDetails?.firstName} ${operatorDetails?.lastName}`);
      setRoute(`${routes.ADMIN.OPERATOR}/${operatorId}`);
    } else {
      setText(t('admin.operatorDetails.addOperator'));
      setRoute(`${routes.ADMIN.OPERATOR}/add`);
    }
  }, [operatorDetails]);

  return (
    <Page
      className={cx(styles['operator-view'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <Branch
        status={getOperatorStatus}
        LoadingComponent={() => <Spinner />}
        Component={() => (
          <>
            <Container fluid={true}>
              <Row>
                <Col>
                  <Breadcrumbs
                    links={[
                      {
                        text: t('admin.title'),
                        route: routes.ADMIN.BASE,
                      },
                      {
                        text: t('admin.operatorDetails.title'),
                        route: routes.ADMIN.BASE,
                      },
                      {
                        text: text,
                        route: route,
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Container>
            <div className={cx(styles['operator-view__main'])}>
              <Container fluid={true}>
                <Row>
                  <Col md={{ size: 6, offset: 3 }}>
                    <OperatorForm
                      operatorId={operatorId}
                      operatorDetails={operatorDetails}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        )}
      />
    </Page>
  );
};
