import React from 'react';
import cx from 'classnames';
import { useModalContext } from 'contexts/ModalContext';
import { useToastContext } from 'contexts/ToastContext';
import {
  Dialog as BaseDialog,
  DialogBackdrop as BaseBackground,
} from 'reakit/Dialog';
import { IconButton } from 'components/IconButton';
import { ReactComponent as Close } from 'assets/icons/lawsat/Close.svg';

import styles from './Modal.module.scss';

export const DialogBackdrop: React.FC = () => {
  const { dialog } = useModalContext();

  return <BaseBackground {...dialog} className={styles['modal__backdrop']} />;
};

interface DialogProps {
  children?: React.ReactNode;
}

export const Dialog = ({ children }: DialogProps) => {
  const { dialog, close, optionalClass } = useModalContext();
  const { toastId } = useToastContext();

  return (
    <BaseDialog
      {...dialog}
      hideOnClickOutside={!toastId}
      className={cx(styles['modal__modal'], {
        [`${styles[`modal__modal--${optionalClass}`]}`]: optionalClass,
      })}
      tabIndex={0}
    >
      <div className={styles['modal__container']}>
        <div className={styles['modal__inner']}>{children}</div>
        <IconButton
          icon={Close}
          size="md"
          className={styles['modal__close']}
          label="close Modal"
          onClick={() => {
            close();
          }}
        />
      </div>
    </BaseDialog>
  );
};
