import * as yup from 'yup';
import i18n from 'i18n';

export const OperatorDetailsSchema = (): yup.ObjectSchema => {
  return yup.object().shape({
    firstName: yup
      .string()
      .required(i18n.t('admin.operatorDetails.formErrors.firstName')),
    lastName: yup
      .string()
      .required(i18n.t('admin.operatorDetails.formErrors.lastName')),
    emailAddress: yup
      .string()
      .email(i18n.t('admin.operatorDetails.formErrors.emailAddressValid'))
      .required(i18n.t('admin.operatorDetails.formErrors.emailAddress')),
    countryPhoneCode: yup.string().required(),
    phoneNumber: yup
      .string()
      .required(i18n.t('admin.operatorDetails.formErrors.phoneNumber'))
      .test(
        'validPhoneNumber',
        i18n.t('admin.operatorDetails.formErrors.phoneNumberValid'),
        (number) => /^[0-9]{1,13}$/.test(String(number)),
      ),
  });
};
