import React from 'react';
import { useToastContext, ToastStatus } from 'contexts/ToastContext';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button, ButtonVariant } from 'components/common/Button';
import styles from './Toast.module.scss';

interface ToastProps {
  title: string;
  description: string;
  type: ToastStatus;
}

export const Toast: React.FC<ToastProps> = ({ title, description, type }) => {
  const { dismissToast } = useToastContext();
  const variant = (toastType: ToastStatus) => {
    let button: ButtonVariant;
    switch (toastType) {
      case 'success':
        button = 'accent';
        break;
      case 'info':
        button = 'ui';
        break;
      case 'warning':
        button = 'ui';
        break;
      case 'error':
        button = 'negative';
        break;
      default:
        button = 'ui';
    }
    return button;
  };

  return (
    <div className={styles['toast']}>
      <Heading size="sm" tag="h5" className={styles['toast__title']}>
        {title}
      </Heading>
      <Text size="md" className={styles['toast__descripton']}>
        {description}
      </Text>
      <div className={styles['toast__buttons']}>
        <Button
          className={styles['toast__button']}
          appearance="ghost"
          variant={variant(type)}
          label="OK"
          onClick={() => {
            dismissToast();
          }}
        />
      </div>
    </div>
  );
};
