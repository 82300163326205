import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { PatientInvitePayload } from 'api/Patient';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { RadioGroup } from 'components/common/RadioGroup';
import { radioValues } from 'components/common/RadioGroup/RadioGroup';
import { InputText } from 'components/common/InputText';
import { useToastContext } from 'contexts/ToastContext';
import { usePatientsContext } from 'contexts/PatientsContext';
import { useModalContext } from 'contexts/ModalContext';
import { CountryPhoneCodeSelector } from 'components/CountryPhoneCodeSelector';
import styles from './PatientInviteModal.module.scss';

interface PatientInviteModalProps {
  testId?: string;
  className?: string;
}

export const PatientInviteModal: React.FC<PatientInviteModalProps> = ({
  testId,
  className,
}) => {
  const { t } = useTranslation();
  const { close } = useModalContext();
  const { setToast } = useToastContext();
  const { sendPatientInvite } = usePatientsContext();

  const options: radioValues[] = [
    {
      value: 'Email',
      label: t('patients.inviteStrings.emailOptionTitle'),
    },
    {
      value: 'Sms',
      label: t('patients.inviteStrings.smsOptionTitle'),
    },
  ];

  const schema = yup.object().shape({
    NotificationMethod: yup.string().required(),
    EmailAddress: yup.string().email().when('NotificationMethod', {
      is: 'Email',
      then: yup.string().required(),
    }),
    PhoneNumber: yup.string().when('NotificationMethod', {
      is: 'Sms',
      then: yup.string().required(),
    }),
  });

  const { control, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const countryPhoneCode = watch('PhoneNumberCountryCode');

  const onSubmit = (data: PatientInvitePayload) => {
    sendPatientInvite(data);
    close();
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('patients.inviteStrings.error'),
    });
  };

  const notificationMethod = watch('NotificationMethod');

  let optionField;

  switch (notificationMethod) {
    case 'Email': {
      optionField = (
        <Controller
          key={'email'}
          control={control}
          defaultValue={''}
          name={'EmailAddress'}
          render={(props) => (
            <InputText
              testId={'invite-email'}
              label={t('patients.inviteStrings.emailOptionField')}
              {...props}
            />
          )}
        />
      );
      break;
    }
    case 'Sms': {
      optionField = (
        <div
          className={styles['patient-invite-modal__contact-number__container']}
        >
          <Controller
            control={control}
            name="PhoneNumberCountryCode"
            defaultValue="+44"
            render={(props, fieldState) => (
              <CountryPhoneCodeSelector
                testId="formContactCountryCode"
                label={t('patients.inviteStrings.countryCode')}
                selected={countryPhoneCode ?? '+44'}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.PhoneNumberCountryCode?.message}
                className={
                  styles['patient-invite-modal__contact-number__country-code']
                }
                {...props}
              />
            )}
          />

          <Controller
            control={control}
            defaultValue=""
            name="PhoneNumber"
            render={(props, fieldState) => (
              <InputText
                testId="formPhoneNumber"
                maxLength={13}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                validationError={errors.PhoneNumber?.message}
                label={t('patients.inviteStrings.phoneNumber')}
                type="number"
                className={
                  styles['patient-invite-modal__contact-number__number']
                }
                {...props}
              />
            )}
          />
        </div>
      );
      break;
    }
    default: {
      optionField = (
        <InputText label={'hidden'} name={'hidden'} className={'invisible'} />
      );
    }
  }

  return (
    <div
      className={cx(styles['patient-invite-modal'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading tag="h3" size="md">
        {t('patients.inviteStrings.title')}
      </Heading>
      <Text size="md">{t('patients.inviteStrings.text')}</Text>
      <div className={styles['patient-invite-modal__options']}>
        <Heading tag="h4" size="xs">
          {t('patients.inviteStrings.optionsTitle')}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit, invalid)}>
          <Controller
            control={control}
            defaultValue={''}
            name={'NotificationMethod'}
            isRequired
            render={(props) => (
              <RadioGroup
                testId={'notification-method-radio-group'}
                className={styles['patient-invite-modal__radio-group']}
                values={options}
                orientation={'vertical'}
                hasLeftPadding={false}
                {...props}
              />
            )}
          />
          {optionField}
          <div className={styles['patient-invite-modal__buttons']}>
            <Button
              testId="close-button"
              appearance="flat"
              variant="ui"
              label={t('patients.inviteStrings.cancel')}
              onClick={() => {
                close();
              }}
            />
            <Button
              testId="send-button"
              variant="accent"
              label={t('patients.inviteStrings.send')}
              className={styles['patient-invite-modal__send']}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
