import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { useApiError } from 'hooks/useApiError';
import { BranchStatus } from 'components/common/Branch/Branch';
import {
  getOrders,
  getAvailableStatuses,
  getOrder,
  updateStatus,
  cancelOrder,
  updateOrderAdminNotes as updateOrderAdminNotesApi,
  processManualRefund as processManualRefundApi,
  processAutomaticRefund as processAutomaticRefundApi,
  createPrescription,
  getPrescription,
  updatePrescription,
} from 'api/Orders';
import { getGPOrders } from 'api/gpOrders/gpOrders';
import { getOrderAudit } from 'api/OrderAudit/OrderAudit';
import { AuditLine } from 'models/AuditLine';
import {
  updateOrderLineItem,
  updateOrderLinePomStatus as updateOrderLinePomStatusAPI,
} from 'api/OrderLine';
import { GPOrder } from 'models/GPOrder';
import { OrderListItem } from 'models/Orders';
import { Order, OrderStatus, PomStatus } from 'models/Order';
import { getPMedQuestionaire } from 'api/PMedQuestionnaire';
import { PMedQuestionnaire } from 'models/PMedQuestionnaire';
import { PomFormResponse } from 'models/PomForm';
import { GeneralHealthQuestionnaire } from 'models/GeneralHealthQuestionnaire';
import { getGeneralHealthQuestionnaireFromOrder } from 'api/GeneralHealthForm/GeneralHealthForm';
import { getPomFormFromOrderline } from 'api/PomForm';
import { PrescriptionPayload, Prescription } from 'models/Prescription';

export interface OrdersContextLoading {
  getOrdersListStatus?: BranchStatus;
  getOrdersSearchStatus?: BranchStatus;
  getStatusStatus?: BranchStatus;
  getOrderDetailStatus?: BranchStatus;
  updateStatusStatus?: BranchStatus;
  cancelOrderStatus?: BranchStatus;
  updateOrderLineStatus?: BranchStatus;
  getGPOrdersStatus?: BranchStatus;
  getPMedFormStatus?: BranchStatus;
  getPomFormStatus?: BranchStatus;
  getGenHealthFormStatus?: BranchStatus;
  getOrderAuditStatus?: BranchStatus;
  processAutomaticRefundStatus?: BranchStatus;
}

export interface OrdersContextProps {
  getOrdersList: (
    filters?: OrdersListFilters,
    resetCount?: boolean,
  ) => Promise<void>;
  setOrdersList: Function;
  getPatientOrdersList: (patientId: number | string) => Promise<void>;
  getOrderDetail: (orderId: number) => Promise<void>;
  orderDetail: Order | null;
  count?: number;
  ordersList: OrderListItem[];
  ordersListFilters: OrdersListFilters;
  setPage: (to: number) => void;
  setPharmacy: (id: number) => void;
  setSort: (field: string, direction: 'Ascending' | 'Descending') => void;
  setDeliveryOption: (state: 'Collection' | 'Delivery') => void;
  setLapsed: (state: boolean | null) => Promise<void>;
  setFulfillment: (state: boolean) => void;
  loading: OrdersContextLoading;
  selectedRows: (string | number)[];
  setSelectedRows: Function;
  getStatusList: (orderIds: (string | number)[]) => Promise<void>;
  statusList: (string | number)[];
  updateOrderStatus: (
    orderId: string | number,
    status: OrderStatus,
    deliveryTrackingNumber?: string,
    courier?: string,
  ) => Promise<void>;
  orderCancel: (
    orderId: string | number,
    reason: string,
    isList?: boolean,
  ) => Promise<void>;
  updateOrderAdminNotes: (orderId: number, notes: string) => Promise<void>;
  getOrdersListBySearch: (search: string) => Promise<void>;
  updateOrderLineMed: (
    orderId: number,
    orderlineId: number,
    itemId: number,
    close: Function,
  ) => Promise<void>;
  updateOrderLinePomStatus: (
    orderId: number,
    orderlineId: number,
    pomStatus: PomStatus,
    newQuantity?: number,
  ) => Promise<void>;
  getGPOrdersList: (orderIds: (string | number)[]) => Promise<void>;
  gpOrders: GPOrder[];
  setGPOrders: Function;
  getPMedForm: (orderId: number, orderlineId: number) => Promise<void>;
  pMedForm: PMedQuestionnaire | null;
  pomForm: PomFormResponse | null;
  getPomForm: (orderId: number, orderLineId: number) => Promise<void>;
  setPomForm: Dispatch<SetStateAction<PomFormResponse | null>>;
  genHealthForm: GeneralHealthQuestionnaire | null;
  setGenHealthForm: Dispatch<SetStateAction<GeneralHealthQuestionnaire | null>>;
  getGeneralHealthFormByOrderId: (formId: number) => Promise<void>;
  getOrderAuditList: (orderId: number) => Promise<void>;
  auditTrail: AuditLine[];
  setOrderDetail: Dispatch<SetStateAction<Order | null>>;
  processManualRefund: (orderId: string) => Promise<void>;
  createOrUpdatePrescription: (
    orderId: string | number,
    prescriptionPayload: PrescriptionPayload,
    prescriptionId?: string | number,
  ) => Promise<boolean>;
  getPrescriptionAgainstOrder: (orderId: number | string) => Promise<void>;
  prescription: Prescription | null;
  setPrescription: Dispatch<SetStateAction<Prescription | null>>;
  processAutomaticRefund: (orderId: string) => Promise<void>;
}

export interface OrdersListFilters {
  patientId: string | null;
  pharmacyId: number | null;
  page?: number;
  pages?: number;
  sortBy: string;
  sortDirection: 'Ascending' | 'Descending';
  fulfilled: boolean | null;
  deliveryOption: 'Collection' | 'Delivery' | null;
  lapsed: boolean | null;
  search: string | null;
}

export const defaultFilters: OrdersListFilters = {
  patientId: null,
  pharmacyId: null,
  fulfilled: false,
  sortBy: 'OrderReceivedDateTime',
  sortDirection: 'Ascending',
  deliveryOption: null,
  lapsed: null,
  search: null,
};

export const OrdersContextValues = (): OrdersContextProps => {
  const { handleApiError } = useApiError();
  const [gpOrders, setGPOrders] = useState<GPOrder[]>([]);
  const [auditTrail, setAuditTrail] = useState<AuditLine[]>([]);

  const [ordersListFilters, setOrdersListFilters] =
    useState<OrdersListFilters>(defaultFilters);

  const [ordersList, setOrdersList] = useState<OrderListItem[]>([]);
  const [orderDetail, setOrderDetail] = useState<Order | null>(null);
  const [prescription, setPrescription] = useState<Prescription | null>(null);
  const [count, setCount] = useState<number>();

  const [loading, setLoading] = useState<OrdersContextLoading>({
    getOrdersListStatus: 'idle',
  });

  const [selectedRows, setSelectedRows] = useState<(string | number)[]>([]);
  const [statusList, setStatusList] = useState<(string | number)[]>([]);
  const [pMedForm, setPMedForm] = useState<PMedQuestionnaire | null>(null);
  const [pomForm, setPomForm] = useState<PomFormResponse | null>(null);
  const [genHealthForm, setGenHealthForm] =
    useState<GeneralHealthQuestionnaire | null>(null);

  const getOrdersList = async (
    filters: OrdersListFilters = defaultFilters,
    resetCount: boolean = false,
  ) => {
    const skip = filters.page ? (filters.page - 1) * 20 : 0;
    try {
      setLoading({ ...loading, getOrdersListStatus: 'loading' });

      const result = await getOrders(filters, skip);
      setSelectedRows([]);

      if (resetCount) {
        const activeOrders = await getOrders(
          {
            ...filters,
            fulfilled: false,
          },
          skip,
        );
        setCount(activeOrders.count);
      }

      setOrdersListFilters({
        ...filters,
        pages: Math.ceil(result.count / result.top),
      });
      setOrdersList(result.items);
      result.items.length > 0
        ? setLoading({
            ...loading,
            getOrdersListStatus: 'finished',
            getOrdersSearchStatus: 'finished',
          })
        : setLoading({
            ...loading,
            getOrdersListStatus: 'empty',
            getOrdersSearchStatus: 'finished',
          });
    } catch (error) {
      setLoading({
        ...loading,
        getOrdersListStatus: 'error',
        getOrdersSearchStatus: 'finished',
      });
    }
  };

  const getPatientOrdersList = async (patientId: number | string) => {
    await getOrdersList({
      ...defaultFilters,
      patientId: String(patientId),
      fulfilled: null,
    });
  };

  const getOrderDetail = async (orderId: number | string) => {
    try {
      setLoading({
        ...loading,
        getOrderDetailStatus: 'loading',
        updateStatusStatus: 'idle',
      });
      const result = await getOrder(orderId);
      setOrderDetail(result);
      setLoading({ ...loading, getOrderDetailStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, getOrderDetailStatus: 'error' });
    }
  };

  const getOrdersListBySearch = async (search: string) => {
    setLoading({
      ...loading,
      getOrdersListStatus: 'loading',
      getOrdersSearchStatus: 'loading',
    });

    getOrdersList(
      {
        patientId: null,
        pharmacyId: ordersListFilters.pharmacyId,
        fulfilled: false,
        sortBy: 'OrderReceivedDateTime',
        sortDirection: 'Ascending',
        deliveryOption: null,
        lapsed: null,
        search: search,
        page: 1,
      },
      true, //reset count of active orders
    );
  };

  const getStatusList = async (orderIds: (string | number)[]) => {
    try {
      setLoading({ ...loading, getStatusStatus: 'loading' });
      const result = await getAvailableStatuses(orderIds);

      setStatusList(result);

      result.length > 0
        ? setLoading({ ...loading, getStatusStatus: 'finished' })
        : setLoading({ ...loading, getStatusStatus: 'empty' });
    } catch (error) {
      handleApiError(error);
    }
  };

  const setPage = (to: number) => {
    to !== ordersListFilters.page &&
      getOrdersList({
        ...ordersListFilters,
        page: to,
      });
  };

  const setPharmacy = (id: number) => {
    id !== ordersListFilters.pharmacyId &&
      getOrdersList(
        {
          ...ordersListFilters,
          pharmacyId: id,
          page: 1,
        },
        true, //reset count of active orders
      );
  };

  const setSort = (field: string, direction: 'Ascending' | 'Descending') => {
    getOrdersList({
      ...ordersListFilters,
      page: 1,
      sortBy: field,
      sortDirection: direction,
    });
  };

  const setFulfillment = (state: boolean) => {
    getOrdersList({
      ...ordersListFilters,
      page: 1,
      lapsed: null,
      deliveryOption: null,
      fulfilled: state,
    });
  };

  const setDeliveryOption = (state: 'Collection' | 'Delivery') => {
    getOrdersList({
      ...ordersListFilters,
      page: 1,
      lapsed: null,
      deliveryOption: state,
    });
  };

  const setLapsed = async (state: boolean | null) => {
    getOrdersList({
      ...ordersListFilters,
      page: 1,
      lapsed: state,
      deliveryOption: null,
    });
  };

  const updateOrderStatus = async (
    orderId: string | number,
    status: OrderStatus,
    deliveryTrackingNumber?: string,
    courier?: string,
  ) => {
    try {
      setLoading({ ...loading, updateStatusStatus: 'loading' });
      await updateStatus(orderId, status, deliveryTrackingNumber, courier);

      setLoading({ ...loading, getOrderDetailStatus: 'loading' });
      await getOrderDetail(orderId);
      await getOrdersList();
      setSelectedRows([orderId]);

      setLoading({
        ...loading,
        updateStatusStatus: 'finished',
        getOrdersListStatus: 'finished',
      });
    } catch (error) {
      handleApiError(error);
    }
  };

  const orderCancel = async (
    orderId: string | number,
    reason: string,
    isList: boolean = false,
  ) => {
    try {
      setLoading({ ...loading, cancelOrderStatus: 'loading' });
      await cancelOrder(orderId, reason);

      if (isList) {
        setLoading({ ...loading, getOrdersListStatus: 'loading' });
        await getOrdersList(
          ordersListFilters,
          true, //reset count of active orders
        );
      } else {
        setLoading({ ...loading, getOrderDetailStatus: 'loading' });
        await getOrderDetail(orderId);
      }

      setLoading({
        ...loading,
        cancelOrderStatus: 'finished',
      });
    } catch (error) {
      handleApiError(error);
    }
  };

  const updateOrderAdminNotes = async (orderId: number, notes: string) => {
    try {
      setLoading({ ...loading, getOrderDetailStatus: 'loading' });
      await updateOrderAdminNotesApi(orderId, notes);
      await getOrderDetail(orderId);
      setLoading({
        ...loading,
        getOrderDetailStatus: 'finished',
      });
    } catch (error) {
      handleApiError(error);
    }
  };

  const updateOrderLineMed = async (
    orderId: number,
    orderlineId: number,
    itemId: number,
    close: Function,
  ) => {
    try {
      setLoading({ ...loading, updateOrderLineStatus: 'loading' });
      await updateOrderLineItem(orderId, orderlineId, itemId);
      setLoading({ ...loading, updateOrderLineStatus: 'finished' });
      close();
      getOrderDetail(orderId);
    } catch (error) {
      handleApiError(error);
    }
  };

  const updateOrderLinePomStatus = async (
    orderId: number,
    orderlineId: number,
    pomStatus: PomStatus,
    newQuantity?: number,
  ) => {
    try {
      setLoading({ ...loading, updateOrderLineStatus: 'loading' });
      await updateOrderLinePomStatusAPI(
        orderId,
        orderlineId,
        pomStatus,
        newQuantity ?? null,
      );
      setLoading({ ...loading, updateOrderLineStatus: 'finished' });
      getOrderDetail(orderId);
    } catch (error) {
      handleApiError(error);
    }
  };

  const processAutomaticRefund = async (orderId: string) => {
    try {
      setLoading({ ...loading, processAutomaticRefundStatus: 'loading' });
      await processAutomaticRefundApi(orderId);
      await getOrderDetail(orderId);
      setLoading({ ...loading, processAutomaticRefundStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, processAutomaticRefundStatus: 'error' });
      handleApiError(error);
    }
  };

  const processManualRefund = async (orderId: string) => {
    try {
      await processManualRefundApi(orderId);
      getOrderDetail(orderId);
    } catch (error) {
      handleApiError(error);
    }
  };

  const createOrUpdatePrescription = async (
    orderId: string | number,
    prescriptionPayload: PrescriptionPayload,
    prescriptionId?: string | number,
  ) => {
    try {
      prescriptionId
        ? await updatePrescription(orderId, prescriptionPayload, prescriptionId)
        : await createPrescription(orderId, prescriptionPayload);
      await getPrescriptionAgainstOrder(orderId);
      return true;
    } catch (error) {
      handleApiError(error);
      return false;
    }
  };

  const getPrescriptionAgainstOrder = async (orderId: number | string) => {
    try {
      const result = await getPrescription(orderId);
      setPrescription(result);
    } catch (error) {
      handleApiError(error);
    }
  };

  const getGPOrdersList = async (orderIds: (string | number)[]) => {
    try {
      setLoading({ ...loading, getGPOrdersStatus: 'loading' });
      const result = await getGPOrders(orderIds);

      setGPOrders(result);

      result.length > 0
        ? setLoading({ ...loading, getGPOrdersStatus: 'finished' })
        : setLoading({ ...loading, getGPOrdersStatus: 'empty' });
    } catch (error) {
      handleApiError(error);
    }
  };
  const getOrderAuditList = async (orderId: number) => {
    try {
      setLoading({ ...loading, getOrderAuditStatus: 'loading' });
      const result = await getOrderAudit(orderId);
      setAuditTrail(result);
      setLoading({ ...loading, getOrderAuditStatus: 'finished' });
    } catch (error) {
      setLoading({
        ...loading,
        getOrderAuditStatus: 'error',
      });
    }
  };

  const getPMedForm = async (orderId: number, orderlineId: number) => {
    try {
      setLoading({ ...loading, getPMedFormStatus: 'loading' });
      const result = await getPMedQuestionaire(orderId, orderlineId);
      setPMedForm(result);

      result
        ? setLoading({ ...loading, getPMedFormStatus: 'finished' })
        : setLoading({ ...loading, getPMedFormStatus: 'empty' });
    } catch (error) {
      handleApiError(error);
    }
  };

  const getGeneralHealthFormByOrderId = async (orderId: number) => {
    try {
      setLoading({ ...loading, getGenHealthFormStatus: 'loading' });
      const result = await getGeneralHealthQuestionnaireFromOrder(orderId);
      setGenHealthForm(result);
      setLoading({ ...loading, getGenHealthFormStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, getGenHealthFormStatus: 'error' });
      handleApiError(error);
    }
  };

  const getPomForm = async (orderId: number, orderLineId: number) => {
    try {
      setLoading({ ...loading, getPomFormStatus: 'loading' });
      const result = await getPomFormFromOrderline(orderId, orderLineId);
      setPomForm(result);
      setLoading({ ...loading, getPomFormStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, getPomFormStatus: 'error' });
      handleApiError(error);
    }
  };

  return {
    count,
    getOrdersList,
    setOrdersList,
    getPatientOrdersList,
    getOrderDetail,
    orderDetail,
    ordersList,
    ordersListFilters,
    setPharmacy,
    setPage,
    setFulfillment,
    setSort,
    setDeliveryOption,
    setLapsed,
    loading,
    selectedRows,
    setSelectedRows,
    statusList,
    getStatusList,
    getOrdersListBySearch,
    updateOrderStatus,
    orderCancel,
    updateOrderLineMed,
    updateOrderLinePomStatus,
    getGPOrdersList,
    gpOrders,
    setGPOrders,
    getPMedForm,
    pMedForm,
    pomForm,
    getPomForm,
    genHealthForm,
    setGenHealthForm,
    setPomForm,
    getGeneralHealthFormByOrderId,
    auditTrail,
    getOrderAuditList,
    setOrderDetail,
    updateOrderAdminNotes,
    createOrUpdatePrescription,
    getPrescriptionAgainstOrder,
    setPrescription,
    prescription,
    processAutomaticRefund,
    processManualRefund,
  };
};

export const OrdersContext = createContext<OrdersContextProps>(
  {} as ReturnType<typeof OrdersContextValues>,
);

export const useOrdersContext = (): OrdersContextProps => {
  const context = useContext(OrdersContext);

  if (context === undefined) {
    throw new Error('context error');
  }

  return context;
};

interface OrdersProviderProps {
  children?: ReactNode;
}

export const OrdersProvider = ({ children }: OrdersProviderProps) => {
  return (
    <OrdersContext.Provider value={OrdersContextValues()}>
      {children}
    </OrdersContext.Provider>
  );
};
