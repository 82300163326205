import React from 'react';
import { GeneralHealthQuestionnaire } from 'models/GeneralHealthQuestionnaire';
import { useTranslation } from 'react-i18next';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Label } from 'components/common/Label';
import { GPResult } from 'api/GPSuggest';

import styles from '../PatientPomFormModal.module.scss';

interface GeneralHealthFormViewProps {
  genHealthForm: GeneralHealthQuestionnaire;
  gpDetails?: GPResult;
}

const answerStyle = styles['pom-modal__forms-container__form-content__answer'];

export const GeneralHealthFormView = ({
  genHealthForm,
  gpDetails,
}: GeneralHealthFormViewProps) => {
  const { t } = useTranslation();

  const trueAnswer = t('orders.genHealthForm.answers.true');
  const falseAnswer = t('orders.genHealthForm.answers.false');

  const dob = new Date(genHealthForm.dateOfBirth);
  const dobDay = dob.getDate().toString().padStart(2, '0');
  const dobMonth = (dob.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so adding 1
  const dobYear = dob.getFullYear();
  const formattedDate = `${dobDay}-${dobMonth}-${dobYear}`;

  const gpDetailsString = [
    gpDetails?.Name,
    gpDetails?.Address1,
    gpDetails?.Address2,
    gpDetails?.Address3,
    gpDetails?.City,
    gpDetails?.County,
    gpDetails?.Postcode,
  ]
    .filter((value) => !!value)
    .join(', ');

  return (
    <>
      <Heading size="sm">{t('orders.genHealthForm.heading')}</Heading>
      <div className={styles['pom-modal__forms-container__form-content']}>
        <div className={answerStyle}>
          <Label>{t('orders.genHealthForm.labels.registered')}</Label>
          <Text>
            {genHealthForm.isRegisteredWithGp ? trueAnswer : falseAnswer}
          </Text>
          {genHealthForm.isRegisteredWithGp && gpDetailsString && (
            <Text>{gpDetailsString}</Text>
          )}
          {genHealthForm.isRegisteredWithGp && gpDetailsString && (
            <Text>
              {`Permission to contact granted: ${
                genHealthForm.shareInformationToGpGranted
                  ? trueAnswer
                  : falseAnswer
              }`}
            </Text>
          )}
        </div>

        <div className={answerStyle}>
          <Label>{t('orders.genHealthForm.labels.gender')}</Label>
          <Text>{genHealthForm.gender}</Text>
        </div>

        <div className={answerStyle}>
          <Label>{t('orders.genHealthForm.labels.dateOfBirth')}</Label>
          <Text>{formattedDate}</Text>
        </div>

        <div className={answerStyle}>
          <Label>{t('orders.genHealthForm.labels.height')}</Label>
          <Text>{genHealthForm.height}</Text>
        </div>

        <div className={answerStyle}>
          <Label>{t('orders.genHealthForm.labels.weight')}</Label>
          <Text>{genHealthForm.weight}</Text>
        </div>

        <div className={answerStyle}>
          <Label>{t('orders.genHealthForm.labels.bloodPressure')}</Label>
          <Text>{genHealthForm.bloodPressure}</Text>
        </div>

        <div className={answerStyle}>
          <Label>{t('orders.genHealthForm.labels.allergies')}</Label>
          <Text>{`${genHealthForm.hasAllergies ? trueAnswer : falseAnswer} - ${
            genHealthForm.allergyDetails
          }`}</Text>
        </div>

        <div className={answerStyle}>
          <Label>{t('orders.genHealthForm.labels.medicalConditions')}</Label>
          <Text>{`${
            genHealthForm.hasMedicalCondition ? trueAnswer : falseAnswer
          } - ${genHealthForm.medicalConditionDetail}`}</Text>
        </div>

        <div className={answerStyle}>
          <Label>
            {t('orders.genHealthForm.labels.mentalHealthConditions')}
          </Label>
          <Text>{`${
            genHealthForm.hasMentalHealthCondition ? trueAnswer : falseAnswer
          } - ${genHealthForm.mentalHealthConditionDetail}`}</Text>
        </div>

        <div className={answerStyle}>
          <Label>{t('orders.genHealthForm.labels.takingMedication')}</Label>
          <Text>{`${
            genHealthForm.isTakingMedication ? trueAnswer : falseAnswer
          } - ${genHealthForm.takingMedicationDetail}`}</Text>
        </div>

        <div className={answerStyle}>
          <Label>{t('orders.genHealthForm.labels.anythingElse')}</Label>
          <Text>{`${
            genHealthForm.shareNotes === ''
              ? falseAnswer
              : genHealthForm.shareNotes
          }`}</Text>
        </div>
      </div>
    </>
  );
};
