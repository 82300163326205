/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditorState } from 'lexical';

export const RICH_TEXT_CHARACTER_LIMIT = 3000;

export const richTextFieldDefaultState =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

export const mockRichText =
  '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"Example ","type":"text","version":1},{"detail":0,"format":1,"mode":"normal","style":"","text":"Rich","type":"text","version":1},{"detail":0,"format":0,"mode":"normal","style":"","text":" ","type":"text","version":1},{"detail":0,"format":2,"mode":"normal","style":"","text":"Text","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}';

export const getRichTextLength = (state: EditorState) => {
  const sumRichTextLength = (node: any): number => {
    const textLength = node.text?.length || 0;
    const childrenTextLength =
      node.children?.reduce(
        (acc: number, child: any) => acc + sumRichTextLength(child),
        0,
      ) || 0;
    return textLength + childrenTextLength;
  };

  return sumRichTextLength(JSON.parse(JSON.stringify(state)).root);
};

export const isRichTextLengthValid = (
  richTextLength: number,
  maxLimit: number = RICH_TEXT_CHARACTER_LIMIT,
) => richTextLength <= maxLimit;

export const isValidJSON = (str: string): boolean => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};
