import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'contexts/ModalContext';
import { Branch } from 'components/common/Branch';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import styles from './DeleteProductModal.module.scss';

interface DeleteProductModalProps {
  productId?: string;
  productName?: string;
  deleteProduct: Function;
}

export const DeleteProductModal: React.FC<DeleteProductModalProps> = ({
  productId,
  productName,
  deleteProduct,
}) => {
  const { t } = useTranslation();
  const { close } = useModalContext();

  return (
    <Branch
      Component={() => (
        <div className={styles['delete-products-modal']}>
          <Heading
            tag="h4"
            className={styles['delete-products-modal__heading']}
          >
            {t('products.form.deleteWithProductName', { productName })}
          </Heading>
          <Text size="md" className={styles['delete-products-modal__text']}>
            {t('products.form.areYouSure')}
          </Text>
          <div className={styles['delete-products-modal__buttons']}>
            <Button
              appearance="flat"
              variant="ui"
              label={t('products.form.cancel')}
              onClick={() => close()}
            />
            <Button
              appearance="solid"
              variant="negative"
              label={t('products.form.deleteProduct')}
              className={styles['delete-products-modal__delete']}
              onClick={async () => {
                await deleteProduct(Number(productId));
                close();
              }}
            />
          </div>
        </div>
      )}
    />
  );
};
