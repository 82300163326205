import React from 'react';
import { useHistory } from 'react-router-dom';
import { useApiError } from 'hooks/useApiError';
import {
  getProducts as getProductsApi,
  deleteProductById,
  createProduct,
  ProductPayload,
  updateProductImage,
  getProduct,
  updateProductById,
} from 'api/Products';
import { getTaxBands } from 'api/TaxBands';
import { routes } from 'routes';
import { Product, ProductDetail } from 'models/Product';
import { selectValues } from 'components/DropDown';
import { BranchStatus } from 'components/common/Branch/Branch';
import { FilterWithSearch } from 'models/Filter';

export interface ProductsContextLoading {
  getProductsStatus?: BranchStatus;
  getProductTaxBandsStatus?: BranchStatus;
  deleteProductStatus?: BranchStatus;
  createProductStatus?: BranchStatus;
  getProductDetailStatus?: BranchStatus;
  updateProductStatus?: BranchStatus;
  getProductsSearchStatus?: BranchStatus;
}

export interface ProductsContextProps {
  loading: ProductsContextLoading;
  productsFilters: FilterWithSearch;
  products: Product[];
  getProducts: (filters?: FilterWithSearch) => Promise<void>;
  setProductListPage: (to: number) => void;
  setProductListSort: (
    field: string,
    direction: 'Ascending' | 'Descending',
  ) => void;
  getProductTaxBands: () => Promise<void>;
  taxBands: selectValues[];
  deleteProduct: (id: number) => Promise<void>;
  createNewProduct: (
    productDetails: ProductPayload,
    imageFileName?: File,
  ) => Promise<void>;
  getProductDetail: (productId: number) => Promise<void>;
  productDetail?: ProductDetail | null;
  setProductDetail: (productDetail: ProductDetail | null) => void;
  updateProduct: (
    productId: number,
    productDetails: ProductPayload,
    imageFile?: File,
  ) => Promise<void>;
  getProductsBySearch: (search: string) => void;
  setProducts: Function;
}

export const productFiltersDefaults: FilterWithSearch = {
  page: 1,
  pages: 1,
  sortBy: 'ConditionName',
  sortDirection: 'Ascending',
  search: null,
};

export const ProductsContextDefaults: ProductsContextProps = {
  loading: { getProductsStatus: 'idle' },
  productsFilters: productFiltersDefaults,
  products: [],
  getProducts: () => Promise.resolve(),
  setProductListPage: () => ({}),
  setProductListSort: () => ({}),
  getProductTaxBands: () => Promise.resolve(),
  taxBands: [],
  deleteProduct: () => Promise.resolve(),
  createNewProduct: () => Promise.resolve(),
  getProductDetail: () => Promise.resolve(),
  setProductDetail: () => ({}),
  updateProduct: () => Promise.resolve(),
  getProductsBySearch: () => ({}),
  setProducts: () => Promise.resolve(),
};

export const ProductsContextValues = (): ProductsContextProps => {
  const history = useHistory();
  const { handleApiError } = useApiError();
  const [loading, setLoading] = React.useState<ProductsContextLoading>({
    getProductsStatus: 'idle',
    getProductsSearchStatus: 'idle',
  });
  const [products, setProducts] = React.useState<Product[]>([]);
  const [taxBands, setTaxBands] = React.useState<selectValues[]>([]);
  const [productsFilters, setProductsFilters] =
    React.useState<FilterWithSearch>(productFiltersDefaults);
  const [productDetail, setProductDetail] =
    React.useState<ProductDetail | null>();

  const setProductListPage = (to: number) => {
    getProducts({
      ...productsFilters,
      page: to,
    });
  };

  const setProductListSort = (
    field: string,
    direction: 'Ascending' | 'Descending',
  ) => {
    getProducts({
      ...productsFilters,
      page: 1,
      sortBy: field,
      sortDirection: direction,
    });
  };

  const getProductsBySearch = (search: string) => {
    setLoading({ ...loading, getProductsSearchStatus: 'loading' });
    getProducts({
      ...productsFilters,
      search: search,
    });
  };

  const getProducts = async (
    filters: FilterWithSearch = productFiltersDefaults,
  ) => {
    const skip = filters.page ? (filters.page - 1) * 20 : 0;
    try {
      setLoading({ ...loading, getProductsStatus: 'loading' });
      const result = await getProductsApi(filters, skip);
      setProductsFilters({
        ...filters,
        pages: Math.ceil(result.count / result.top),
      });
      setProducts(result.items);
      result.items.length > 0
        ? setLoading({
            ...loading,
            getProductsStatus: 'finished',
            getProductsSearchStatus: 'finished',
          })
        : setLoading({
            ...loading,
            getProductsStatus: 'empty',
            getProductsSearchStatus: 'finished',
          });
    } catch (error) {
      setLoading({
        ...loading,
        getProductsStatus: 'error',
        getProductsSearchStatus: 'finished',
      });
    }
  };

  const getProductTaxBands = async () => {
    try {
      setLoading({ ...loading, getProductTaxBandsStatus: 'loading' });
      const tax = await getTaxBands();
      setTaxBands(
        tax.map((item) => {
          return { id: item.id, name: item.taxRate.toString() };
        }),
      );
      setLoading({
        ...loading,
        getProductTaxBandsStatus: 'finished',
      });
    } catch (error) {
      setLoading({
        ...loading,
        getProductTaxBandsStatus: 'error',
      });
    }
  };

  const deleteProduct = async (id: number) => {
    try {
      setLoading({ ...loading, deleteProductStatus: 'loading' });
      await deleteProductById(id);
      setLoading({
        ...loading,
        deleteProductStatus: 'finished',
      });
      history.push(routes.PRODUCTS.BASE);
    } catch (error) {
      handleApiError(error);
    }
  };

  const createNewProduct = async (
    productDetails: ProductPayload,
    imageFile?: File,
  ) => {
    let result;
    try {
      setLoading({ ...loading, createProductStatus: 'loading' });
      result = await createProduct(productDetails);
      if (result && imageFile) {
        await updateProductImage(result.id, imageFile);
      }
      setLoading({ ...loading, createProductStatus: 'finished' });
      history.push(routes.PRODUCTS.BASE);
    } catch (error) {
      setLoading({ ...loading, createProductStatus: 'error' });
      result && history.push(routes.PRODUCTS.BASE);
      handleApiError(error);
    }
  };

  const getProductDetail = async (productId: number) => {
    try {
      setLoading({ ...loading, getProductDetailStatus: 'loading' });
      const result = await getProduct(productId);
      setProductDetail(result);
      setLoading({ ...loading, getProductDetailStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, getProductDetailStatus: 'error' });
    }
  };

  const updateProduct = async (
    productId: number,
    productDetails: ProductPayload,
    imageFile?: File,
  ) => {
    let result;
    try {
      setLoading({ ...loading, updateProductStatus: 'loading' });
      result = await updateProductById(productId, productDetails);
      setProductDetail(result);
      if (result && imageFile) {
        await updateProductImage(productId, imageFile);
      }
      result && setLoading({ ...loading, updateProductStatus: 'finished' });
      history.push(routes.PRODUCTS.BASE);
    } catch (error) {
      setLoading({ ...loading, updateProductStatus: 'error' });
      result && history.push(routes.PRODUCTS.BASE);
      handleApiError(error);
    }
  };

  return {
    loading,
    products,
    productsFilters,
    getProducts,
    setProductListPage,
    setProductListSort,
    getProductTaxBands,
    taxBands,
    deleteProduct,
    createNewProduct,
    getProductDetail,
    productDetail,
    setProductDetail,
    updateProduct,
    getProductsBySearch,
    setProducts,
  };
};

export const ProductsContext = React.createContext<ProductsContextProps>(
  {} as ReturnType<typeof ProductsContextValues>,
);

export const useProductsContext = (): ProductsContextProps => {
  const context = React.useContext(ProductsContext);

  if (context === undefined) {
    throw new Error('context error');
  }

  return context;
};
interface ProductsProviderProps {
  children?: React.ReactNode;
}

export const ProductsProvider = ({ children }: ProductsProviderProps) => {
  return (
    <ProductsContext.Provider value={ProductsContextValues()}>
      {children}
    </ProductsContext.Provider>
  );
};
