import React from 'react';
import cx from 'classnames';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useOrdersContext } from 'contexts/OrdersContext';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Table, TableRow, TDCell, THCellProps } from 'components/table';
import { Branch } from 'components/common/Branch';
import { Spinner } from 'components/common/Spinner';
import { Container, Col, Row } from 'components/common/grid';
import { Page } from 'components/Page';
import { routes } from 'routes';
import { formatDateString, formatTimeString } from 'utils/transformers';
import { isValidJSON } from 'utils/RichTextField';
import { RichTextViewer } from 'components/RichTextViewer';

import styles from './OrderAudit.module.scss';

interface OrderDetailsProps {
  testId?: string;
  className?: string;
}

export const OrderAudit: React.FC<OrderDetailsProps> = ({
  testId,
  className,
}) => {
  const { orderId } = useParams<{ orderId: string }>();
  const { t } = useTranslation();
  const {
    setSelectedRows,
    getOrderAuditList,
    auditTrail,
    loading: { getOrderAuditStatus },
  } = useOrdersContext();

  const tableHeaders: THCellProps[] = [
    {
      title: t('orders.auditTrail.tableHeaders.dateCreated'),
      sortable: false,
    },
    {
      title: t('orders.auditTrail.tableHeaders.adminName'),
      sortable: false,
    },
    {
      title: t('orders.auditTrail.tableHeaders.eventType'),
      sortable: false,
    },
    {
      title: t('orders.auditTrail.tableHeaders.before'),
      sortable: false,
    },
    {
      title: t('orders.auditTrail.tableHeaders.after'),
      sortable: false,
    },
    {
      title: t('orders.auditTrail.tableHeaders.comments'),
      sortable: false,
    },
  ];

  React.useEffect(() => {
    setSelectedRows([orderId]);
    getOrderAuditList(parseInt(orderId));
  }, [orderId]);

  const renderAsRichText = (input: string) => {
    return (
      input &&
      input !== null &&
      input !== undefined &&
      input !== '' &&
      isValidJSON(input) &&
      input.includes('root')
    );
  };

  return (
    <Page
      className={cx(styles['orders-audit'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <Breadcrumbs
              links={[
                { text: t('orders.ordersMain'), route: routes.ORDER.BASE },
                {
                  text: `#${orderId}`,
                  route: `${routes.ORDER.SINGLE}/${orderId}`,
                },
                {
                  text: t('orders.auditCTA'),
                  route: `${routes.ORDER.AUDIT}/${orderId}`,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
      <div className={cx(styles['orders-audit__main'])}>
        <Container fluid={true}>
          <Row>
            <Col>
              <Table
                className={`${
                  getOrderAuditStatus === 'loading'
                    ? styles['orders-audit__loading']
                    : ''
                }`}
                headers={tableHeaders}
                selectable={false}
              >
                <Branch
                  status={getOrderAuditStatus}
                  LoadingComponent={() => (
                    <tr>
                      <td colSpan={8}>
                        <Spinner />
                      </td>
                    </tr>
                  )}
                  EmptyComponent={() => (
                    <tr>
                      <td colSpan={8}></td>
                    </tr>
                  )}
                  Component={() => (
                    <>
                      {auditTrail.map(
                        (
                          {
                            createdDateTime,
                            adminName,
                            eventType,
                            before,
                            after,
                            comments,
                          },
                          index,
                        ) => {
                          return (
                            <TableRow key={index} selectable={false}>
                              <TDCell
                                line1={formatDateString(createdDateTime)}
                                line2={formatTimeString(createdDateTime)}
                              />
                              <TDCell line1={adminName} />
                              <TDCell line1={eventType} />
                              {renderAsRichText(before) ? (
                                <TDCell>
                                  <RichTextViewer richText={before} />
                                </TDCell>
                              ) : (
                                <TDCell line1={before} />
                              )}
                              {renderAsRichText(after) ? (
                                <TDCell>
                                  <RichTextViewer richText={after} />
                                </TDCell>
                              ) : (
                                <TDCell line1={after} />
                              )}
                              <TDCell line1={comments} />
                            </TableRow>
                          );
                        },
                      )}
                    </>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  );
};
