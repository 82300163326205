import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Heading } from 'components/common/Heading';
import { Button } from 'components/common/Button';
import styles from './FormFooter.module.scss';

interface FormFooterProps {
  testId?: string;
  className?: string;
  isDirty?: boolean;
  discardFn?: Function;
  unsavedText?: string;
}

export const FormFooter: React.FC<FormFooterProps> = ({
  testId,
  className,
  isDirty,
  discardFn,
  unsavedText,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(styles['form-footer'], { [`${className}`]: className })}
      data-testid={testId}
    >
      {isDirty && (
        <Heading
          tag="h6"
          size="xs"
          className={styles['form-footer__unsaved-changes']}
        >
          {unsavedText}
        </Heading>
      )}

      <div className={styles['form-footer__actions-container']}>
        {discardFn && (
          <Button
            testId="discard-button"
            className={styles['form-footer__action-button']}
            label={t('common.form.discard')}
            type="button"
            variant="negative"
            appearance="flat"
            onClick={() => discardFn()}
          />
        )}

        <Button
          testId="submit-button"
          className={styles['form-footer__action-button']}
          label={t('common.form.save')}
          type="submit"
          disabled={!isDirty}
        />
      </div>
    </div>
  );
};
