import { eventTypes } from 'components/RichTextEditor/toolsList';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  $createParagraphNode,
  LexicalEditor,
} from 'lexical';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list';
import { $createHeadingNode, HeadingTagType } from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';

export const useOnClickListener = (editor: LexicalEditor) => {
  const onClick = (event: string) => {
    switch (event) {
      case eventTypes.FORMAT_BOLD:
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        break;
      case eventTypes.FORMAT_ITALIC:
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        break;
      case eventTypes.PARAGRAPH:
        formatParagraph();
        break;
      case eventTypes.H1:
        // One level down because the result will be rendered in a page already containing other headings
        formatHeading('h2');
        break;
      case eventTypes.H2:
        formatHeading('h3');
        break;
      case eventTypes.H3:
        formatHeading('h4');
        break;
      case eventTypes.UL:
        formatList('ul');
        break;
      case eventTypes.OL:
        formatList('ol');
        break;
    }
  };

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (tag: HeadingTagType) => {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createHeadingNode(tag));
      }
    });
  };

  const formatList = (tag: 'ul' | 'ol') => {
    const command =
      tag === 'ul'
        ? INSERT_UNORDERED_LIST_COMMAND
        : INSERT_ORDERED_LIST_COMMAND;

    editor.dispatchCommand(command, undefined);
  };

  return { onClick };
};
