import { Client } from 'api/utils';
import { OrdersListFilters } from 'contexts/OrdersContext';
import { OrderListItem } from 'models/Orders';
import { Order, OrderStatus } from 'models/Order';
import { PrescriptionPayload, Prescription } from 'models/Prescription';

export type getOrdersResponse = {
  count: number;
  skip: number;
  top: number;
  items: OrderListItem[];
};

export const createUrl = (filters: OrdersListFilters, skip: number): string => {
  let baseUrl = `Orders?top=20&skip=${skip}&sortBy=${filters.sortBy}&sortDirection=${filters.sortDirection}`;

  if (filters.fulfilled !== null) {
    baseUrl = `${baseUrl}&fulfilled=${filters.fulfilled}`;
  }

  if (filters.pharmacyId) {
    baseUrl = `${baseUrl}&pharmacyId=${filters.pharmacyId}`;
  }

  if (filters.lapsed) {
    baseUrl = `${baseUrl}&lapsed=${filters.lapsed}`;
  }

  if (filters.deliveryOption) {
    baseUrl = `${baseUrl}&deliveryOption=${filters.deliveryOption}`;
  }

  if (filters.patientId) {
    baseUrl = `${baseUrl}&patientId=${filters.patientId}`;
  }

  if (filters.search) {
    baseUrl = `${baseUrl}&search=${filters.search}`;
  }

  return baseUrl;
};

export const getOrders = async (
  filters: OrdersListFilters,
  skip: number,
): Promise<getOrdersResponse> => {
  const response = await Client.get(createUrl(filters, skip));
  return response.data;
};

export const getAvailableStatuses = async (
  orderIds: (string | number)[],
): Promise<(string | number)[]> => {
  let query = '?';

  orderIds.forEach((item) => {
    query = `${query}orderIds=${item}&`;
  });

  const response = await Client.get(`/Orders/availableStatuses${query}`);
  return response.data;
};

export const getOrder = async (orderId: number | string): Promise<Order> => {
  const response = await Client.get(`/Orders/${orderId}`);
  return response.data;
};

export const updateStatus = async (
  orderId: string | number,
  status: OrderStatus,
  deliveryTrackingNumber?: string,
  courier?: string,
): Promise<void> => {
  return await Client.put(`/Orders/orderStatus?orderIds=${orderId}`, {
    orderStatus: status,
    deliveryTrackingNumber: deliveryTrackingNumber ?? null,
    courier: courier ?? null,
  });
};

export const cancelOrder = async (
  orderId: string | number,
  reason: string,
): Promise<[{ orderId: number; orderStatus: OrderStatus }]> => {
  const response = await Client.post(`/Orders/${orderId}/cancel`, {
    cancellationReason: reason,
  });
  return response.data;
};

export const processManualRefund = async (
  orderId: string | number,
): Promise<void> => {
  return await Client.post(`/Orders/${orderId}/processManualRefund`);
};

export const processAutomaticRefund = async (
  orderId: string | number,
): Promise<void> => {
  return await Client.post(`/Orders/${orderId}/processAutomaticRefund`);
};

export const updateOrderAdminNotes = async (
  orderId: string | number,
  notes: string,
): Promise<void> => {
  return Client.put(`/Orders/${orderId}/notes`, {
    adminNotes: notes,
  });
};

export const createPrescription = async (
  orderId: number | string,
  prescriptionPayload: PrescriptionPayload,
) => {
  return await Client.post(
    `/Orders/${orderId}/createPrescription`,
    prescriptionPayload,
  );
};

export const updatePrescription = async (
  orderId: number | string,
  prescriptionPayload: PrescriptionPayload,
  prescriptionId: number | string,
) => {
  return await Client.put(`/Orders/${orderId}/updatePrescription`, {
    ...prescriptionPayload,
    id: prescriptionId,
  });
};

export const getPrescription = async (
  orderId: number | string,
): Promise<Prescription> => {
  const response = await Client.get(`/Orders/${orderId}/prescription`);
  return response.data;
};
