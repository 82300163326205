import { format, utcToZonedTime } from 'date-fns-tz';
/**
 * @name splitDate
 * @return {string[]}
 * @desc
 */

export const splitDate = (date: string): string[] => {
  date = date.split('T')[0];
  return date.split('-');
};

/**
 * @name getWeeks
 * @return {number}
 */
export const getWeeks = (days: number): number => {
  return Math.ceil(days / 7);
};

const timeZone = 'Europe/London';

// TODO: add more tests for this function
export const formatDateString = (date: string, long?: string): string => {
  const dateFormat = long ? 'd MMMM yyyy' : 'd MMM yy';
  return format(utcToZonedTime(new Date(date), timeZone), dateFormat);
};

export const formatTimeString = (date: string): string => {
  return format(utcToZonedTime(new Date(date), timeZone), 'HH:mm');
};

export const formatDOBString = (date: string): string => {
  return format(utcToZonedTime(new Date(date), timeZone), 'dd/MM/yyyy');
};

// Image encoding / decoding
export interface EncodedImage {
  fileName: string;
  encodedImage: string;
  fileType: string;
}

export const decodeBase64ToFile = ({
  encodedImage,
  fileName,
  fileType,
}: EncodedImage): File => {
  const base64Data = encodedImage.split(',')[1];
  const binaryString = atob(base64Data);
  const length = binaryString.length;
  const binaryArray = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    binaryArray[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([binaryArray], { type: 'application/octet-stream' });
  return new File([blob], fileName, { type: fileType });
};
