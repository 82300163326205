import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useModalContext } from 'contexts/ModalContext';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import styles from './DeleteGroupModal.module.scss';

interface DeleteGroupModalProps {
  testId?: string;
  className?: string;
  groupName?: string;
  groupId: string;
  deleteFn: Function;
}

export const DeleteGroupModal: React.FC<DeleteGroupModalProps> = ({
  testId,
  className,
  groupId,
  groupName,
  deleteFn,
}) => {
  const { t } = useTranslation();
  const { close } = useModalContext();

  return (
    <div
      className={cx(styles['delete-group-modal'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading size="md">
        {t('prescriptions.groupForm.deleteTitle', { groupName: groupName })}
      </Heading>
      <Text>{t('prescriptions.groupForm.deleteText')}</Text>
      <div className={styles['delete-group-modal__buttons']}>
        <Button
          appearance="flat"
          variant="ui"
          label={t('prescriptions.groupForm.deleteCancel')}
          onClick={() => close()}
        />
        <Button
          appearance="solid"
          variant="negative"
          label={t('prescriptions.groupForm.deleteCTA')}
          className={styles['delete-group-modal__delete']}
          onClick={async () => {
            deleteFn(groupId);
          }}
        />
      </div>
    </div>
  );
};
