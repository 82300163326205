import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePrescriptionsContext } from 'contexts/PrescriptionsContext';
import { PrescriptionItem } from 'models/Prescriptions/Prescriptions';
import { TableRow, TDCell } from 'components/table';
import { Button } from 'components/common/Button';
import styles from './ExemptItems.module.scss';

interface ExemptItemsProps {
  exemptItems?: PrescriptionItem[];
}

export const ExemptItems: React.FC<ExemptItemsProps> = ({ exemptItems }) => {
  const { t } = useTranslation();
  const { updateExemptStatus } = usePrescriptionsContext();
  return (
    <>
      {exemptItems?.map(({ name, id }) => {
        return (
          <TableRow key={id} selectable={false}>
            <TDCell line1={name} />
            <TDCell
              className={styles['exempt-items__delete']}
              button={
                <Button
                  className={styles['exempt-items__button']}
                  label={t('prescriptions.delete')}
                  size="md"
                  variant="negative"
                  appearance="flat"
                  onClick={() => updateExemptStatus(id, false)}
                />
              }
            />
          </TableRow>
        );
      })}
    </>
  );
};
