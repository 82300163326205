import React, { useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row } from 'components/common/grid';
import { Page } from 'components/Page';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { useConfigContext } from 'contexts/ConfigContext';
import { WebsiteConfigForm } from 'containers/WebsiteConfigForm';
import { routes } from 'routes';

import styles from './ConfigView.module.scss';
import { LandingPageSpinner } from 'components/common/Spinner/Spinner';

export const ConfigView = () => {
  const { t } = useTranslation();
  const { config, getShopConfig } = useConfigContext();

  useEffect(() => {
    getShopConfig();
  }, []);

  if (!config) {
    return <LandingPageSpinner />;
  }

  return (
    <Page>
      <Container fluid={true}>
        <Row>
          <Col>
            <Breadcrumbs
              links={[
                {
                  text: t('config.title'),
                  route: routes.CONFIG.BASE,
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
      <div className={cx(styles['config-view__main'])}>
        <Container fluid={true}>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <WebsiteConfigForm />
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  );
};
