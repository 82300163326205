import { isAxiosError } from 'axios';
import { useToastContext } from 'contexts/ToastContext';
import { useTranslation } from 'react-i18next';

interface UseApiErrorInterface {
  handleApiError: (error: unknown) => void;
}

interface Errors {
  [key: string]: string[];
}

export const useApiError = (): UseApiErrorInterface => {
  const { setToast } = useToastContext();
  const { t } = useTranslation();

  const genericError = {
    title: t('common.error.genericTitle'),
    message: t('common.error.genericMessage'),
  };

  const errorWithToast = (message?: string, title?: string) => {
    setToast({
      status: 'error',
      title: title ?? genericError.title,
      description: message ?? genericError.message,
    });
  };

  const processErrors = (errors: Errors) => {
    Object.keys(errors).forEach((responseErrorArray) => {
      errors[responseErrorArray].forEach((responseError: string) => {
        errorWithToast(responseError);
      });
    });
  };

  const handleApiError = (error: unknown) => {
    if (isAxiosError(error)) {
      if (error.response?.status === 401) {
        return;
      }
      error.response?.data?.errors
        ? processErrors(error.response.data.errors)
        : error.response?.data?.detail
        ? errorWithToast(error.response.data.detail)
        : errorWithToast();
    } else if (error instanceof Error && error.message) {
      errorWithToast(error.message);
    } else {
      errorWithToast();
    }
  };
  return { handleApiError };
};
