import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createAdmin,
  deleteAdmin,
  getAdmin,
  getAdmins,
  updateAdmin,
} from 'api/Admin';
import { BranchStatus } from 'components/common/Branch';
import { OperatorFormFields } from 'containers/OperatorForm';
import { useApiError } from 'hooks/useApiError';
import { AdminDetail } from 'models/Admin';
import { routes } from 'routes';

export interface AdminContextLoading {
  getOperatorsStatus?: BranchStatus;
  getOperatorStatus?: BranchStatus;
  createOperatorStatus?: BranchStatus;
  updateOperatorStatus?: BranchStatus;
  deleteOperatorStatus?: BranchStatus;
}

export interface AdminListFilters {
  page: number;
  pages: number;
}

export const adminsListFiltersDefaults: AdminListFilters = {
  page: 1,
  pages: 1,
};

export interface AdminContextProps {
  loading: AdminContextLoading;
  isMainView: boolean;
  setIsMainView: Function;
  adminListFilters: AdminListFilters;
  operatorList: AdminDetail[];
  getOperators: (filters?: AdminListFilters) => Promise<void>;
  getOperatorDetails: (id: number) => Promise<void>;
  setOperatorDetails: (details: AdminDetail | null) => void;
  operatorDetails?: AdminDetail | null;
  createOperator: (operatorFormFields: OperatorFormFields) => Promise<void>;
  updateOperator: (
    id: number,
    operatorFormFields: OperatorFormFields,
  ) => Promise<void>;
  deleteOperator: (id: number) => Promise<void>;
  setPage: (to: number) => void;
}

export const AdminContextDefaults: AdminContextProps = {
  loading: {
    getOperatorsStatus: 'idle',
    createOperatorStatus: 'idle',
  },
  isMainView: true,
  setIsMainView: () => Promise.resolve(),
  adminListFilters: adminsListFiltersDefaults,
  operatorList: [],
  getOperators: () => Promise.resolve(),
  getOperatorDetails: () => Promise.resolve(),
  setOperatorDetails: () => ({}),
  createOperator: () => Promise.resolve(),
  updateOperator: () => Promise.resolve(),
  deleteOperator: () => Promise.resolve(),
  setPage: () => ({}),
};

export const AdminContextValues = (): AdminContextProps => {
  const { handleApiError } = useApiError();
  const history = useHistory();

  const [isMainView, setIsMainView] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<AdminContextLoading>({
    getOperatorsStatus: 'idle',
    createOperatorStatus: 'idle',
  });
  const [adminListFilters, setAdminListFilters] =
    React.useState<AdminListFilters>(adminsListFiltersDefaults);
  const [operatorList, setOperatorList] = React.useState<AdminDetail[]>([]);
  const [operatorDetails, setOperatorDetails] = useState<AdminDetail | null>();

  const getOperators = async (
    filters: AdminListFilters = adminsListFiltersDefaults,
  ) => {
    const skip = filters.page ? (filters.page - 1) * 20 : 0;
    try {
      setLoading({ ...loading, getOperatorsStatus: 'loading' });
      const result = await getAdmins(skip);
      setAdminListFilters({
        ...filters,
        pages: Math.ceil(result.count / result.top),
      });
      setOperatorList(result.items);
      setLoading({ ...loading, getOperatorsStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, getOperatorsStatus: 'error' });
    }
  };

  const getOperatorDetails = async (id: number) => {
    try {
      setLoading({ ...loading, getOperatorStatus: 'loading' });
      const result = await getAdmin(id);
      setOperatorDetails(result);
      setLoading({ ...loading, getOperatorStatus: 'finished' });
    } catch (error) {
      setLoading({ ...loading, getOperatorStatus: 'error' });
    }
  };

  const createOperator = async (operatorFormFields: OperatorFormFields) => {
    try {
      setLoading({ ...loading, createOperatorStatus: 'loading' });
      await createAdmin(operatorFormFields);
      setLoading({ ...loading, createOperatorStatus: 'finished' });
      history.push(routes.ADMIN.BASE);
    } catch (error) {
      setLoading({ ...loading, createOperatorStatus: 'error' });
      handleApiError(error);
    }
  };

  const updateOperator = async (
    id: number,
    operatorFormFields: OperatorFormFields,
  ) => {
    try {
      setLoading({ ...loading, updateOperatorStatus: 'loading' });
      await updateAdmin(id, operatorFormFields);
      setLoading({ ...loading, updateOperatorStatus: 'finished' });
      history.push(routes.ADMIN.BASE);
    } catch (error) {
      setLoading({ ...loading, updateOperatorStatus: 'error' });
      handleApiError(error);
    }
  };

  const deleteOperator = async (id: number) => {
    try {
      setLoading({ ...loading, deleteOperatorStatus: 'loading' });
      await deleteAdmin(id);
      setLoading({ ...loading, deleteOperatorStatus: 'finished' });
      history.push(routes.ADMIN.BASE);
    } catch (error) {
      setLoading({ ...loading, deleteOperatorStatus: 'error' });
      handleApiError(error);
    }
  };

  const setPage = (to: number) => {
    getOperators({ ...adminListFilters, page: to });
  };

  return {
    loading,
    isMainView,
    setIsMainView,
    adminListFilters,
    operatorList,
    getOperators,
    getOperatorDetails,
    setOperatorDetails,
    operatorDetails,
    createOperator,
    updateOperator,
    deleteOperator,
    setPage,
  };
};

export const AdminContext = React.createContext<AdminContextProps>(
  {} as ReturnType<typeof AdminContextValues>,
);

export const useAdminContext = (): AdminContextProps =>
  React.useContext(AdminContext);

interface AdminProviderProps {
  children?: React.ReactNode;
}

export const AdminProvider = ({ children }: AdminProviderProps) => {
  return (
    <AdminContext.Provider value={AdminContextValues()}>
      {children}
    </AdminContext.Provider>
  );
};
