import React, { useEffect } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOrdersContext } from 'contexts/OrdersContext';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Container, Col, Row } from 'components/common/grid';
import { Card } from 'components/Card';
import { Heading } from 'components/common/Heading';
import { Page } from 'components/Page';
import { routes } from 'routes';
import { OrderLine } from 'components/OrderLine';
import { PrescriptionPatientDetailBlock } from 'components/PrescriptionPatientDetailBlock';
import { usePatientsContext } from 'contexts/PatientsContext';
import { PrescriptionForm } from 'containers/PrescriptionForm';

import styles from './PrescriptionDetails.module.scss';

interface PrescriptionDetailsProps {
  className?: string;
}

export const PrescriptionDetails = ({
  className,
}: PrescriptionDetailsProps) => {
  const { orderId, prescriptionId } = useParams<{
    orderId: string;
    prescriptionId?: string;
  }>();
  const { t } = useTranslation();
  const { setSelectedRows, getOrderDetail, orderDetail } = useOrdersContext();
  const { patientDetail, getPatientDetail } = usePatientsContext();

  useEffect(() => {
    setSelectedRows([orderId]);
    orderId && orderId !== '' && getOrderDetail(parseInt(orderId));
  }, []);

  useEffect(() => {
    orderDetail?.patientId && getPatientDetail(orderDetail.patientId);
  }, [orderDetail]);

  if (!orderDetail || !patientDetail) {
    return null;
  }

  const pomOrderlines = orderDetail.orderLines?.filter(
    (orderLine) =>
      orderLine.isPom &&
      (orderLine.pomStatus === 'Approved' || orderLine.pomStatus === 'Amended'),
  );

  const breadcrumbs = [
    { text: t('orders.ordersMain'), route: routes.ORDER.BASE },
    {
      text: `#${orderId}`,
      route: `${routes.ORDER.SINGLE}/${orderId}`,
    },
  ];

  if (prescriptionId) {
    breadcrumbs.push({
      text: t('prescriptionCreate.prescriptionBreadcrumb', {
        id: prescriptionId.padStart(3, '0'),
      }),
      route: `${routes.ORDER.SINGLE}/${orderId}${routes.ORDER.PRESCRIPTION.VIEW}/${prescriptionId}`,
    });
  } else {
    breadcrumbs.push({
      text: t('prescriptionCreate.createPrescription'),
      route: `${routes.ORDER.SINGLE}/${orderId}${routes.ORDER.PRESCRIPTION.ADD}`,
    });
  }

  return (
    <Page
      className={cx(styles['prescription-details'], {
        [`${className}`]: className,
      })}
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <Breadcrumbs links={breadcrumbs} />
          </Col>
        </Row>
      </Container>
      <div className={cx(styles['prescription-details__main'])}>
        <Container className={styles['prescription-details__main__container']}>
          <Row>
            <Col md={5}>
              <Card className={styles['prescription-details__card']}>
                <Heading tag="h2" size="sm">
                  {t('prescriptionCreate.pomHeading')}
                </Heading>
                {pomOrderlines && pomOrderlines.length > 0 && (
                  <ul className={styles['prescription-details__orderlines']}>
                    {pomOrderlines.map((orderline) => {
                      return (
                        <li key={orderline.id}>
                          <OrderLine
                            orderId={parseInt(orderId)}
                            orderline={orderline}
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </Card>
              <Card>
                <PrescriptionPatientDetailBlock
                  patient={patientDetail}
                  orderDate={orderDetail.orderReceivedDateTime}
                />
              </Card>
            </Col>
            <Col md={7}>
              <Card className={styles['prescription-details__card']}>
                <PrescriptionForm
                  orderId={orderId}
                  prescriptionId={prescriptionId}
                  pomOrderlines={pomOrderlines}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  );
};
