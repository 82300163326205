import React from 'react';
import cx from 'classnames';
import styles from './PatientDeleteModal.module.scss';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../../contexts/ModalContext';
import { Heading } from '../common/Heading';
import { Text } from '../common/Text';
import { Button } from '../common/Button';
import { usePatientsContext } from '../../contexts/PatientsContext';
import { useHistory } from 'react-router-dom';
import { routes } from '../../routes';

interface PatientDeleteModalProps {
  testId?: string;
  className?: string;
}

export const PatientDeleteModal: React.FC<PatientDeleteModalProps> = ({
  testId,
  className,
}) => {
  const { t } = useTranslation();
  const { selectedRows, patientDetail, deletePatientDetails } =
    usePatientsContext();
  const { close } = useModalContext();
  const history = useHistory();

  const patientId = patientDetail ? patientDetail.id : selectedRows[0];

  return (
    <div
      className={cx(styles['patient-delete-modal'], {
        [`${className}`]: className,
      })}
      data-testid={testId}
    >
      <Heading tag="h3" size="md">
        {t('patients.deleteStrings.title')}
      </Heading>
      <Text size="md">{t('patients.deleteStrings.text1')}</Text>
      <Text size="md">{t('patients.deleteStrings.text2')}</Text>
      <div className={styles['patient-delete-modal__buttons']}>
        <Button
          testId="close-button"
          appearance="flat"
          variant="ui"
          label={t('patients.deleteStrings.cancel')}
          onClick={() => {
            close();
          }}
        />
        <Button
          testId="delete-button"
          variant="accent"
          label={t('patients.deleteStrings.delete')}
          className={styles['patient-delete-modal__delete']}
          onClick={() => {
            deletePatientDetails(patientId, () => {
              close();
              if (patientDetail) {
                history.push(routes.PATIENTS.BASE);
              }
            });
          }}
        />
      </div>
    </div>
  );
};
