import React from 'react';
import { Branch, BranchStatus } from 'components/common/Branch/Branch';
import { Card } from 'components/Card';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Button } from 'components/common/Button';
import { Spinner } from 'components/common/Spinner';

import cx from 'classnames';
import styles from './DashboardStatCard.module.scss';

interface DashboardStatCardProps {
  testId?: string;
  className?: string;
  status: BranchStatus;
  number?: number;
  title: string;
  text: string;
  ctaText: string;
  route: string;
  urgent?: boolean;
  disabled?: boolean;
}

export const DashboardStatCard: React.FC<DashboardStatCardProps> = ({
  testId,
  className,
  status,
  number,
  title,
  text,
  ctaText,
  route,
  urgent = false,
  disabled = false,
}) => {
  return (
    <Card
      className={cx(styles['dashboard-stat-card'], {
        [`${className}`]: className,
        [styles['dashboard-stat-card--urgent']]: urgent,
      })}
      testId={testId}
    >
      <Branch
        status={status}
        LoadingComponent={() => <Spinner />}
        ErrorComponent={() => <Heading>Error</Heading>}
        Component={() => (
          <>
            <Heading className={styles['dashboard-stat-card__heading']}>
              <span className={styles['dashboard-stat-card__number']}>
                {number}
              </span>
              {title}
            </Heading>
            <Text className={styles['dashboard-stat-card__text']}>{text}</Text>
            <Button
              className={styles['dashboard-stat-card__button']}
              label={ctaText}
              link={{ to: route }}
              variant={urgent ? 'negative' : 'accent'}
              disabled={disabled}
            />
          </>
        )}
      />
    </Card>
  );
};
