import i18n from 'i18next';

export const formatDeliveryOptionTitle = (option: string) => {
  switch (option) {
    case 'FreeDelivery':
      return i18n.t('deliveryTypes.free');
    case 'ExpressDelivery':
      return i18n.t('deliveryTypes.express');
    case 'Collection':
      return i18n.t('deliveryTypes.collection');
    default:
      return i18n.t('deliveryTypes.standard');
  }
};
