import { Client } from 'api/utils';
import { PomFormResponse } from 'models/PomForm';

export const getPomFormFromOrderline = async (
  orderId: number,
  orderLineId: number,
): Promise<PomFormResponse> => {
  const response = await Client.get(
    `/Orders/${orderId}/orderLines/${orderLineId}/pomAnswers`,
  );
  return response.data;
};
