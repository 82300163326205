import dompurify from 'dompurify';
import { Client } from 'api/utils';
import { FilterWithSearch } from 'models/Filter';
import { Product, ProductDetail } from 'models/Product';
import { Collection } from 'models/Collection';

export type ProductPayload = {
  title: string;
  productCode: string | null;
  productCategoryId: string | number | null;
  productConditionId: string | number | null;
  price: number;
  orderLimit: string | number | null;
  description: string;
  warnings: string;
  suitableFor: string | null;
  howToUse: string | null;
  ingredients?: string | null;
  isPMed: boolean;
  isPom: boolean;
  inStock: boolean;
  taxBandId: number;
};

const sanitizer = dompurify.sanitize;

export const transformPayload = (
  original: ProductPayload | ProductDetail,
): ProductPayload => {
  return {
    title: sanitizer(original.title),
    productCode:
      original.productCode && original.productCode !== ''
        ? sanitizer(original.productCode)
        : null,
    productCategoryId:
      original.productCategoryId === '0' || original.productCategoryId === ''
        ? null
        : Number(original.productCategoryId),
    productConditionId:
      original.productConditionId === '0' || original.productConditionId === ''
        ? null
        : Number(original.productConditionId),
    price: original.price,
    orderLimit: original.orderLimit === '' ? null : Number(original.orderLimit),
    description: original.description,
    warnings: original.warnings,
    suitableFor:
      original.suitableFor && original.suitableFor !== ''
        ? sanitizer(original.suitableFor)
        : null,
    howToUse:
      original.howToUse && original.howToUse !== ''
        ? sanitizer(original.howToUse)
        : null,
    ingredients:
      original.ingredients && original.ingredients !== ''
        ? sanitizer(original.ingredients)
        : null,
    isPMed: Boolean(original.isPMed),
    isPom: Boolean(original.isPom),
    inStock: Boolean(!original.inStock),
    taxBandId: Number(original.taxBandId),
  };
};

export const getProductsUrl = (
  filters: FilterWithSearch,
  skip: number,
): string => {
  let baseUrl = `/products?top=20&skip=${skip}&sortBy=${filters.sortBy}&sortDirection=${filters.sortDirection}`;

  if (filters.search) {
    baseUrl = `${baseUrl}&search=${filters.search}`;
  }
  return baseUrl;
};

export const getProducts = async (
  filters: FilterWithSearch,
  skip: number,
): Promise<Collection<Product>> => {
  const response = await Client.get(getProductsUrl(filters, skip));
  return response.data;
};

export const getProduct = async (id: number): Promise<ProductDetail> => {
  const response = await Client.get(`/products/${id}`);
  return response.data;
};

export const deleteProductById = async (id: number): Promise<void> => {
  return Client.delete(`/products/${id}`);
};

export const createProduct = async (
  productDetails: ProductPayload,
): Promise<ProductDetail> => {
  const response = await Client.post(
    `products`,
    transformPayload(productDetails),
  );
  return response.data;
};

export const updateProductImage = async (
  id: number,
  imageFile: File,
): Promise<void> => {
  const formData = new FormData();
  formData.append('imageFile', imageFile);
  return Client.put(`/products/${id}/productImage`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateProductById = async (
  id: number,
  productDetails: ProductPayload,
): Promise<ProductDetail> => {
  const response = await Client.put(
    `/products/${id}`,
    transformPayload(productDetails),
  );

  return response.data;
};
