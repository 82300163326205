import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { routes } from 'routes';
import { ConfigView } from 'containers/ConfigView';
import { ConfigProvider } from 'contexts/ConfigContext';

export const Config = () => {
  return (
    <Switch>
      <PrivateRoute path={routes.CONFIG.BASE} exact>
        <ConfigProvider>
          <ConfigView />
        </ConfigProvider>
      </PrivateRoute>
    </Switch>
  );
};
