import React from 'react';
import { useApiError } from 'hooks/useApiError';
import {
  getCondition as getConditionAPI,
  getConditions as getConditionsAPI,
  getConditionGroups as getConditionGroupsAPI,
  getConditionGroup as getConditionGroupAPI,
  updateCondition as updateConditionAPI,
  updateConditionGroup as updateConditionGroupAPI,
  updateConditionProductRanks as updateConditionProductRanksAPI,
} from 'api/Conditions';
import { BranchStatus } from 'components/common/Branch/Branch';
import { Condition, ConditionGroup } from 'models/Categorisations/Conditions';
import { Filter } from 'models/Filter';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import { RankedProduct } from 'models/Product';

export interface ConditionsContextLoading {
  getConditionStatus?: BranchStatus;
  getConditionsStatus?: BranchStatus;
  getConditionGroupsStatus?: BranchStatus;
  getConditionGroupStatus?: BranchStatus;
  updateCondition?: BranchStatus;
  updateConditionGroup?: BranchStatus;
}

interface updateConditionProps {
  condition: Condition;
  rankedProducts?: RankedProduct[];
}

export interface ConditionsContextProps {
  loading: ConditionsContextLoading;
  conditionsFilters: Filter;
  conditionGroupsFilters: Filter;
  condition?: Condition | null;
  conditions: Condition[];
  conditionGroup?: ConditionGroup | null;
  conditionGroups: ConditionGroup[];
  getCondition: (conditionId: number) => void;
  setCondition: (
    value: React.SetStateAction<Condition | null | undefined>,
  ) => void;
  setConditionGroup: (
    value: React.SetStateAction<ConditionGroup | null | undefined>,
  ) => void;
  getConditions: (filters?: Filter) => void;
  getConditionGroup: (conditionGroupId: number) => void;
  getConditionGroups: (filters?: Filter) => void;
  setConditionsPage: (to: number) => void;
  setConditionsSort: (
    field: string,
    direction: 'Ascending' | 'Descending',
  ) => void;
  setConditionGroupsPage: (to: number) => void;
  setConditionGroupsSort: (
    field: string,
    direction: 'Ascending' | 'Descending',
  ) => void;
  updateConditionGroup: (conditionGroup: ConditionGroup) => void;
  updateCondition: ({
    condition,
    rankedProducts,
  }: updateConditionProps) => void;
}

export const conditionsFiltersDefaults: Filter = {
  page: 1,
  pages: 1,
  sortBy: 'name',
  sortDirection: 'Ascending',
};

export const ConditionsContextDefaults: ConditionsContextProps = {
  loading: {},
  conditionsFilters: conditionsFiltersDefaults,
  conditionGroupsFilters: conditionsFiltersDefaults,
  conditions: [],
  conditionGroups: [],
  getConditions: () => ({}),
  getCondition: () => ({}),
  setCondition: () => ({}),
  setConditionGroup: () => ({}),
  getConditionGroup: () => ({}),
  getConditionGroups: () => ({}),
  setConditionsPage: () => ({}),
  setConditionsSort: () => ({}),
  setConditionGroupsPage: () => ({}),
  setConditionGroupsSort: () => ({}),
  updateCondition: () => ({}),
  updateConditionGroup: () => ({}),
};

export const ConditionsContextValues = (): ConditionsContextProps => {
  const history = useHistory();
  const { handleApiError } = useApiError();
  const [loading, setLoading] = React.useState<ConditionsContextLoading>({});
  const [condition, setCondition] = React.useState<Condition | null>();
  const [conditionGroup, setConditionGroup] =
    React.useState<ConditionGroup | null>();
  const [conditions, setConditions] = React.useState<Condition[]>([]);
  const [conditionGroups, setConditionGroups] = React.useState<
    ConditionGroup[]
  >([]);
  const [conditionsFilters, setConditionsFilters] = React.useState<Filter>(
    conditionsFiltersDefaults,
  );
  const [conditionGroupsFilters, setConditionGroupsFilters] =
    React.useState<Filter>(conditionsFiltersDefaults);

  const setConditionsPage = (to: number) => {
    getConditions({
      ...conditionsFilters,
      page: to,
    });
  };

  const setConditionsSort = (
    field: string,
    direction: 'Ascending' | 'Descending',
  ) => {
    getConditions({
      ...conditionsFilters,
      page: 1,
      sortBy: field,
      sortDirection: direction,
    });
  };

  const setConditionGroupsPage = (to: number) => {
    getConditionGroups({
      ...conditionGroupsFilters,
      page: to,
    });
  };

  const setConditionGroupsSort = (
    field: string,
    direction: 'Ascending' | 'Descending',
  ) => {
    getConditionGroups({
      ...conditionGroupsFilters,
      page: 1,
      sortBy: field,
      sortDirection: direction,
    });
  };

  const getConditions = async (
    filters: Filter = conditionsFiltersDefaults,
  ): Promise<void> => {
    try {
      const skip = filters.page ? (filters.page - 1) * 20 : 0;
      setLoading({ ...loading, getConditionsStatus: 'loading' });
      const result = await getConditionsAPI(filters, skip);
      setConditionsFilters({
        ...filters,
        pages: Math.ceil(result.count / result.top),
      });
      setConditions(result.items);
      result.items.length > 0
        ? setLoading({
            ...loading,
            getConditionsStatus: 'finished',
          })
        : setLoading({
            ...loading,
            getConditionsStatus: 'empty',
          });
    } catch (error) {
      setLoading({ ...loading, getConditionsStatus: 'error' });
      handleApiError(error);
    }
  };

  const getCondition = async (conditionId: number): Promise<void> => {
    try {
      setLoading({ ...loading, getConditionStatus: 'loading' });
      const result = await getConditionAPI(conditionId);
      setCondition(result);
      setLoading({
        ...loading,
        getConditionStatus: 'finished',
      });
    } catch (error) {
      setLoading({ ...loading, getConditionStatus: 'error' });
      handleApiError(error);
    }
  };

  const getConditionGroups = async (
    filters: Filter = conditionsFiltersDefaults,
  ): Promise<void> => {
    try {
      const skip = filters.page ? (filters.page - 1) * 20 : 0;
      setLoading({ ...loading, getConditionGroupsStatus: 'loading' });
      const result = await getConditionGroupsAPI(filters, skip);
      setConditionGroupsFilters({
        ...filters,
        pages: Math.ceil(result.count / result.top),
      });
      setConditionGroups(result.items);
      result.items.length > 0
        ? setLoading({
            ...loading,
            getConditionGroupsStatus: 'finished',
          })
        : setLoading({
            ...loading,
            getConditionGroupsStatus: 'empty',
          });
    } catch (error) {
      setLoading({ ...loading, getConditionGroupsStatus: 'error' });
      handleApiError(error);
    }
  };

  const getConditionGroup = async (conditionGroupId: number): Promise<void> => {
    try {
      setLoading({ ...loading, getConditionGroupStatus: 'loading' });
      const result = await getConditionGroupAPI(conditionGroupId);
      setConditionGroup(result);
      setLoading({
        ...loading,
        getConditionGroupStatus: 'finished',
      });
    } catch (error) {
      setLoading({ ...loading, getConditionGroupStatus: 'error' });
      handleApiError(error);
    }
  };

  const updateCondition = async ({
    condition,
    rankedProducts,
  }: updateConditionProps) => {
    try {
      setLoading({ ...loading, updateCondition: 'loading' });
      const result = await updateConditionAPI(condition.id, condition);
      setCondition(result);
      if (result && rankedProducts) {
        await updateConditionProductRanksAPI(condition.id, rankedProducts);
      }
      result && setLoading({ ...loading, updateCondition: 'finished' });
    } catch (error) {
      setLoading({ ...loading, updateCondition: 'error' });
      handleApiError(error);
      history.push(routes.CONDITIONS.BASE);
    }
  };

  const updateConditionGroup = async (conditionGroup: ConditionGroup) => {
    try {
      setLoading({ ...loading, updateConditionGroup: 'loading' });
      const result = await updateConditionGroupAPI(conditionGroup);
      setConditionGroup(result);
      result && setLoading({ ...loading, updateConditionGroup: 'finished' });
      history.push(routes.CONDITIONS.GROUPS.BASE);
    } catch (error) {
      setLoading({ ...loading, updateConditionGroup: 'error' });
      handleApiError(error);
      history.push(routes.CONDITIONS.GROUPS.BASE);
    }
  };

  return {
    loading,
    condition,
    getCondition,
    setCondition,
    conditions,
    conditionsFilters,
    conditionGroupsFilters,
    getConditions,
    getConditionGroup,
    setConditionGroup,
    conditionGroup,
    conditionGroups,
    getConditionGroups,
    setConditionsPage,
    setConditionsSort,
    setConditionGroupsPage,
    setConditionGroupsSort,
    updateCondition,
    updateConditionGroup,
  };
};

export const ConditionsContext = React.createContext<
  ConditionsContextProps | undefined
>(undefined);

export const useConditionsContext = (): ConditionsContextProps => {
  const context = React.useContext(ConditionsContext);

  if (context === undefined) {
    throw new Error(
      'useConditionsContext must be used within a ConditionsProvider',
    );
  }

  return context;
};
interface ConditionsProviderProps {
  children?: React.ReactNode;
}

export const ConditionsProvider = ({ children }: ConditionsProviderProps) => {
  return (
    <ConditionsContext.Provider value={ConditionsContextValues()}>
      {children}
    </ConditionsContext.Provider>
  );
};
