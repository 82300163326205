import React from 'react';
import { Switch } from 'react-router-dom';
import { routes } from 'routes';
import { PrescriptionsProvider } from 'contexts/PrescriptionsContext';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { PrescriptionGroupsList } from 'containers/PrescriptionGroupsList';
import { GroupView } from 'containers/GroupView';
import { MedicinalProductsList } from 'containers/MedicinalProductsList';

export const Prescriptions: React.FC = () => {
  return (
    <Switch>
      <PrescriptionsProvider>
        <PrivateRoute path={routes.PRESCRIPTIONS.BASE} exact>
          <PrescriptionGroupsList />
        </PrivateRoute>
        <PrivateRoute path={`${routes.PRESCRIPTIONS.GROUP}/:groupId`} exact>
          <GroupView />
        </PrivateRoute>
        <PrivateRoute path={`${routes.PRESCRIPTIONS.ITEMS.ALL}`} exact>
          <MedicinalProductsList filterByUnmapped={false} />
        </PrivateRoute>
        <PrivateRoute path={`${routes.PRESCRIPTIONS.ITEMS.UNMAPPED}`} exact>
          <MedicinalProductsList filterByUnmapped={true} />
        </PrivateRoute>
      </PrescriptionsProvider>
    </Switch>
  );
};
