import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePatientsContext } from 'contexts/PatientsContext';
import { useModalContext } from 'contexts/ModalContext';
import { useSettingsContext } from 'contexts/SettingsContext';
import { ActionsBar, Action } from 'components/ActionsBar';
import { ContactPatientModal } from 'components/ContactPatientModal';
import { PatientDeleteModal } from 'components/PatientDeleteModal';

interface PatientsActionsBarProps {
  testId?: string;
  className?: string;
  selected?: string;
  showPagination?: boolean;
}

export const PatientsActionsBar: React.FC<PatientsActionsBarProps> = ({
  testId,
  className,
  selected,
  showPagination = true,
}) => {
  const { t } = useTranslation();
  const { open } = useModalContext();
  const { pharmacies } = useSettingsContext();
  const {
    patientListFilters: { page, pages },
    setPage,
    selectedRows,
    patientDetail,
    contactPatient,
  } = usePatientsContext();
  const [pharmacyId, setPharmacyId] = React.useState<number>();

  React.useEffect(() => {
    if (selectedRows.length === 1 && patientDetail?.pharmacyName) {
      const pharmacy = pharmacies.find((item) => {
        return item.name === patientDetail?.pharmacyName;
      });
      setPharmacyId(pharmacy?.id);
    }
  }, [selectedRows, patientDetail?.pharmacyName, pharmacies]);

  const tableActions: Action[] = [
    {
      buttonType: 'negative',
      label: t('patients.actionsBar.delete'),
      action: () => {
        open(<PatientDeleteModal />);
      },
      disabled: selectedRows.length !== 1,
    },
    {
      buttonType: 'accent',
      label: t('patients.actionsBar.contact'),
      action: async () => {
        open(
          <ContactPatientModal
            pharmacyName={patientDetail?.pharmacyName}
            pharmacyId={pharmacyId}
            patientId={patientDetail?.id}
            contactFn={contactPatient}
          />,
        );
      },
      disabled: selectedRows.length !== 1,
    },
  ];

  const paginationObject = showPagination
    ? {
        page: page ? page : 1,
        pages: pages ? pages : 1,
        change: setPage,
      }
    : undefined;

  return (
    <>
      <ActionsBar
        testId={testId}
        className={className}
        pagination={paginationObject}
        actions={tableActions}
        selected={selected}
      />
    </>
  );
};
