import React from 'react';
import cx from 'classnames';
import styles from './Card.module.scss';

interface CardProps {
  testId?: string;
  className?: string;
  children?: React.ReactNode;
}

export const Card = ({ testId, className, children }: CardProps) => {
  return (
    <div
      className={cx(styles['card'], { [`${className}`]: className })}
      data-testid={testId}
    >
      {children}
    </div>
  );
};
