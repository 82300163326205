import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'contexts/AuthContext';
import { Heading } from 'components/common/Heading';
import { Text } from 'components/common/Text';
import { Container, Col, Row } from 'components/common/grid';
import { CardButton } from 'components/CardButton';
import { Page } from 'components/Page';
import { DashboardStats } from 'containers/DashboardStats';
import { ReactComponent as Briefcase } from 'assets/icons/lawsat/Briefcase.svg';
import { ReactComponent as AccountOutline } from 'assets/icons/lawsat/Account-Outline.svg';
import { ReactComponent as PrescriptionOutline } from 'assets/icons/lawsat/Prescription-Outline.svg';
import { ReactComponent as ShopOutline } from 'assets/icons/lawsat/Shop-Outline.svg';
import { ReactComponent as LinkTo } from 'assets/icons/LinkTo.svg';
import { routes } from 'routes';
import styles from './Dashboard.module.scss';

export const Dashboard = () => {
  const { t } = useTranslation();
  const { userName } = useAuthContext();
  const buttonStyle = styles['dashboard__button'];
  const rowStyle = styles['dashboard__button-row'];

  return (
    <Page>
      <Container fluid={true} className={styles['dashboard__main']}>
        <Row>
          <Col>
            {userName && (
              <Heading size="xl">
                {t('dashboard.greeting', { name: userName.split(' ')[0] })}
              </Heading>
            )}
            <Text size="lg">{t('dashboard.intro')}</Text>
          </Col>
        </Row>

        <DashboardStats />

        <hr className={styles['dashboard__hr']} />
        <Row className={rowStyle}>
          <Col md={6}>
            <CardButton
              className={buttonStyle}
              item={{
                title: t('dashboard.productTitle'),
                description: t('dashboard.productDesc'),
                link: routes.PRODUCTS.BASE,
                icon: ShopOutline,
              }}
            ></CardButton>
          </Col>
          <Col md={6}>
            <CardButton
              className={buttonStyle}
              item={{
                title: t('dashboard.patientsTitle'),
                description: t('dashboard.patientsDesc'),
                link: routes.PATIENTS.BASE,
                icon: AccountOutline,
              }}
            ></CardButton>
          </Col>
        </Row>
        <Row className={rowStyle}>
          <Col md={6}>
            <CardButton
              className={buttonStyle}
              item={{
                title: t('dashboard.prescriptionTitle'),
                description: t('dashboard.prescriptionDesc'),
                link: routes.PRESCRIPTIONS.BASE,
                icon: PrescriptionOutline,
              }}
            ></CardButton>
          </Col>
          <Col md={6}>
            <CardButton
              className={buttonStyle}
              item={{
                title: t('dashboard.adminTitle'),
                description: t('dashboard.adminDesc'),
                link: routes.ADMIN.BASE,
                icon: Briefcase,
              }}
            ></CardButton>
          </Col>
        </Row>
        <Row className={rowStyle}>
          <Col md={6}>
            <CardButton
              className={buttonStyle}
              item={{
                title: t('dashboard.travelVaxTitle'),
                description: t('dashboard.travelVaxDesc'),
                icon: LinkTo,
                href: 'https://app.appointedd.com/loginv2?_ga=2.242749378.1324180882.1687955258-190089564.1682409676',
              }}
            />
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
