import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { useSettingsContext } from 'contexts/SettingsContext';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/IconButton';
import { DropDown } from 'components/DropDown';
import { ReactComponent as LogOut } from 'assets/icons/lawsat/LogOut.svg';
import { ReactComponent as Logo } from 'assets/icons/lawsat-symbol.svg';
import styles from './SiteNav.module.scss';
import { routes } from 'routes';

interface Page {
  title: string;
  route: string;
}

export const SiteNav: React.FC = () => {
  const { t } = useTranslation();
  const { logout } = useAuthContext();
  const {
    pharmacies,
    getPharmaciesList,
    setSelectedPharmacy,
    selectedPharmacy,
  } = useSettingsContext();

  React.useEffect(() => {
    pharmacies.length === 0 && getPharmaciesList();
  }, []);

  const pages: Page[] = [
    { title: t('navigation.orders'), route: routes.ORDER.BASE },
    { title: t('navigation.products'), route: routes.PRODUCTS.BASE },
    { title: t('navigation.conditions'), route: routes.CONDITIONS.BASE },
    { title: t('navigation.prescriptions'), route: routes.PRESCRIPTIONS.BASE },
    { title: t('navigation.patients'), route: routes.PATIENTS.BASE },
    { title: t('navigation.admin'), route: routes.ADMIN.BASE },
    { title: t('navigation.config'), route: routes.CONFIG.BASE },
  ];

  return (
    <div className={styles['site-nav']}>
      <Logo className={styles['site-nav__logo']} />
      <ul>
        <li className={styles['site-nav__list']}>
          <NavLink
            className={styles['site-nav__link']}
            to={routes.BASE}
            exact
            activeClassName={styles['site-nav__link--active']}
          >
            {t('navigation.dashboard')}
          </NavLink>
        </li>
        {pages.map(({ title, route }, index) => {
          return (
            <li key={index} className={styles['site-nav__list']}>
              <NavLink
                className={styles['site-nav__link']}
                to={route}
                activeClassName={styles['site-nav__link--active']}
              >
                {title}
              </NavLink>
            </li>
          );
        })}
      </ul>
      <div className={styles['site-nav__end']}>
        {pharmacies.length > 0 && (
          <DropDown
            className={styles['site-nav__pharmacy']}
            values={[{ id: 0, name: 'All' }, ...pharmacies]}
            onChange={(value: string) => {
              setSelectedPharmacy(parseInt(value));
            }}
            selected={String(selectedPharmacy)}
          />
        )}
        <IconButton
          className={styles['site-nav__logout']}
          icon={LogOut}
          label="LogOut"
          onClick={() => {
            logout();
          }}
        ></IconButton>
      </div>
    </div>
  );
};
