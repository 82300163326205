import React from 'react';
import cx from 'classnames';
import { TableEmptyState } from 'components/TableEmptyState';
import { ReactComponent as BoxGreen } from 'assets/icons/lawsat/BoxGreen.svg';
import styles from './EmptyOrders.module.scss';

interface EmptyOrdersProps {
  testId?: string;
  className?: string;
  heading?: string;
  text?: string;
}

export const EmptyOrders: React.FC<EmptyOrdersProps> = ({
  testId,
  className,
  heading,
  text,
}) => {
  return (
    <tr
      className={cx(styles['empty-orders'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <td colSpan={8}>
        <TableEmptyState icon={BoxGreen} heading={heading} text={text} />
      </td>
    </tr>
  );
};
