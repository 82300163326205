import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useHistory } from 'react-router-dom';
import { ProductPayload } from 'api/Products';
import { ProductDetailsSchema } from 'formSchema/ProductDetails';
import { ProductDetail } from 'models/Product';
import { useProductsContext } from 'contexts/ProductsContext';
import { useToastContext } from 'contexts/ToastContext';
import { useModalContext } from 'contexts/ModalContext';
import { Card } from 'components/Card';
import { Heading } from 'components/common/Heading';
import { InputText } from 'components/common/InputText';
import { Textarea } from 'components/common/Textarea';
import { CheckBox } from 'components/common/CheckBox';
import { DropDown } from 'components/DropDown';
import { Button } from 'components/common/Button';
import { DeleteProductModal } from 'components/DeleteProductModal';
import { FileUpload } from 'components/FileUpload/FileUpload';
import { useConditionsContext } from 'contexts/ConditionsContext';
import { EditorState } from 'lexical';
import { RichTextEditor } from 'components/RichTextEditor';
import {
  getRichTextLength,
  isRichTextLengthValid,
  isValidJSON,
  richTextFieldDefaultState,
} from 'utils/RichTextField';
import { LandingPageSpinner } from 'components/common/Spinner/Spinner';

import { routes } from 'routes';
import styles from './ProductForm.module.scss';

interface ProductFormProps {
  testId?: string;
  className?: string;
  productId?: string;
  productDetail?: ProductDetail | null;
}

export const ProductForm = ({
  testId,
  className,
  productId,
  productDetail,
}: ProductFormProps) => {
  const { t } = useTranslation();
  const { setToast } = useToastContext();
  const {
    getProductTaxBands,
    taxBands,
    deleteProduct,
    createNewProduct,
    updateProduct,
  } = useProductsContext();
  const { conditions, getConditions } = useConditionsContext();

  const { open } = useModalContext();
  const history = useHistory();
  const [conditionValue, setConditionValue] = useState<number>();
  const [taxBandValue, setTaxBandValue] = useState<number>();
  const [fileName, setFileName] = useState<string | undefined>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorsOnLastSubmit, setErrorsOnLastSubmit] = useState(false);
  const [warningsRichTextLength, setWarningsRichTextLength] = useState(0);
  const [descriptionRichTextLength, setDescriptionRichTextLength] = useState(0);

  const {
    control,
    getValues,
    handleSubmit,
    errors,
    register,
    formState,
    setValue,
  } = useForm({
    resolver: yupResolver(ProductDetailsSchema()),
  });

  useEffect(() => {
    getProductTaxBands();
    getConditions();
  }, []);

  useEffect(() => {
    setConditionValue(productDetail?.productConditionId);
  }, [productDetail?.productConditionId]);

  useEffect(() => {
    setTaxBandValue(productDetail?.taxBandId);
  }, [productDetail?.taxBandId]);

  useEffect(() => {
    // When form is submitted, make a note if there are validation errors
    if (formState.isSubmitted) {
      Object.keys(errors).length > 0 && setErrorsOnLastSubmit(true);
    }
  }, [formState.isSubmitted]);

  useEffect(() => {
    setFileName(productDetail?.imageFileName);
  }, [productDetail?.imageFileName]);

  register('imageFile');

  if (
    (productId !== 'edit' && !productDetail) ||
    !conditions ||
    conditions.length === 0
  ) {
    return <LandingPageSpinner />;
  }

  const onSubmit = async (data: ProductPayload) => {
    const productDetails = { ...data };
    const imageFile = getValues('imageFile');
    if (productDetail?.id) {
      updateProduct(productDetail?.id, productDetails, imageFile);
    } else {
      createNewProduct(productDetails, imageFile);
    }
  };

  const invalid = () => {
    setToast({
      status: 'error',
      title: t('common.formErrors.validationErrorsTitle'),
      description: t('common.formErrors.validationErrorsDescription'),
    });
  };

  const inputStyle = styles['product-form__input'];
  const cardStyle = styles['product-form__card'];
  const maxTextAreaLongError = t('common.formErrors.maxCaption', {
    max: '3000',
  });
  const maxTextAreaShortError = t('common.formErrors.maxCaption', {
    max: '300',
  });
  const checkboxStyle = styles['product-form__checkbox'];

  const handleWarningsRichTextChange = (state: EditorState) => {
    setValue('warnings', JSON.stringify(state), { shouldDirty: true });
    setWarningsRichTextLength(getRichTextLength(state));
  };

  const handleDescriptionRichTextChange = (state: EditorState) => {
    setValue('description', JSON.stringify(state), { shouldDirty: true });
    setDescriptionRichTextLength(getRichTextLength(state));
  };

  const defaultDescription =
    (productDetail?.description &&
      isValidJSON(productDetail.description) &&
      productDetail.description) ||
    richTextFieldDefaultState;

  const defaultWarnings =
    (productDetail?.warnings &&
      isValidJSON(productDetail.warnings) &&
      productDetail.warnings) ||
    richTextFieldDefaultState;

  return (
    <div
      className={cx(styles['product-form'], { [`${className}`]: className })}
      data-testid={testId}
    >
      <form
        className={styles['product-form__form']}
        onSubmit={handleSubmit(onSubmit, invalid)}
      >
        <Card className={cardStyle}>
          <Heading size="sm" className={styles['product-form__title']}>
            {t('products.form.detailFieldSet')}
          </Heading>
          <div className={styles['product-form__form-col-wrap']}>
            <div
              className={cx(
                styles['product-form__form-col'],
                styles['product-form__form-col--wide'],
              )}
            >
              <Controller
                control={control}
                defaultValue={productDetail?.title || ''}
                name="title"
                isRequired
                render={(props, fieldState) => (
                  <InputText
                    className={inputStyle}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.title?.message}
                    maxLength={300}
                    label={t('products.form.productName')}
                    {...props}
                    caption={maxTextAreaShortError}
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue={productDetail?.productCode || ''}
                name="productCode"
                render={(props) => {
                  // Adds a pseudo-validation error. It doesn't stop submission, but does get flagged
                  // Note: it will only be visibile if a 'proper' validation issue is present on another field.
                  const showPseudoValidationError =
                    props.value === '' && errorsOnLastSubmit;

                  return (
                    <InputText
                      className={inputStyle}
                      variant={showPseudoValidationError ? 'warning' : 'accent'}
                      validationError={
                        showPseudoValidationError
                          ? t('products.formErrors.productCode')
                          : ''
                      }
                      maxLength={300}
                      label={t('products.form.productCode')}
                      {...props}
                      caption={maxTextAreaShortError}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                defaultValue={
                  (productDetail?.price &&
                    Number(productDetail?.price).toFixed(2)) ||
                  ''
                }
                name="price"
                isRequired
                render={(props, fieldState) => (
                  <InputText
                    className={inputStyle}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.price?.message}
                    maxLength={300}
                    label={t('products.form.price')}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue={productDetail?.productConditionId || ''}
                name="productConditionId"
                isRequired
                render={(props, fieldState) => (
                  <DropDown
                    className={inputStyle}
                    label={t('products.form.condition')}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.productConditionId?.message}
                    values={[{ id: 0, name: 'Unassigned' }, ...conditions]}
                    selected={conditionValue?.toString()}
                    setChangedVal={setConditionValue}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue={productDetail?.orderLimit || ''}
                name="orderLimit"
                isRequired
                render={(props, fieldState) => (
                  <InputText
                    className={inputStyle}
                    maxLength={300}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.orderLimit?.message}
                    label={t('products.form.max')}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue={productDetail?.taxBandId || ''}
                name="taxBandId"
                isRequired
                render={(props, fieldState) => (
                  <DropDown
                    className={inputStyle}
                    label={t('products.form.tax')}
                    variant={fieldState.invalid ? 'negative' : 'accent'}
                    validationError={errors.taxBandId?.message}
                    values={[{ id: 0, name: 'Select one' }, ...taxBands]}
                    selected={taxBandValue?.toString()}
                    setChangedVal={setTaxBandValue}
                    {...props}
                  />
                )}
              />
            </div>
            <div className={styles['product-form__form-col']}>
              <Heading size="xs" className={styles['product-form__sub-title']}>
                {t('products.form.checkBoxes')}
              </Heading>
              <Controller
                control={control}
                defaultValue={productDetail?.isPMed ?? false}
                name="isPMed"
                render={({ onChange, value }) => (
                  <CheckBox
                    className={checkboxStyle}
                    label={t('products.form.pMed')}
                    size="md"
                    slim={true}
                    value={value}
                    onChange={(isChecked) => {
                      onChange(isChecked);
                      isChecked && setValue('isPom', false);
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue={productDetail?.isPom ?? false}
                name="isPom"
                render={({ onChange, value }) => {
                  return (
                    <CheckBox
                      className={checkboxStyle}
                      label={t('products.form.pom')}
                      size="md"
                      slim={true}
                      value={value}
                      onChange={(isChecked) => {
                        onChange(isChecked);
                        isChecked && setValue('isPMed', false);
                      }}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                defaultValue={
                  (productDetail && !productDetail.inStock) || false
                }
                name="inStock"
                render={(props) => (
                  <CheckBox
                    className={checkboxStyle}
                    label={t('products.form.outOfStock')}
                    size="md"
                    slim={true}
                    orientation="vertical"
                    {...props}
                  />
                )}
              />
            </div>
          </div>
        </Card>
        <Card className={cardStyle}>
          <Controller
            control={control}
            name="imageFile"
            defaultValue=""
            render={({ onChange }) => (
              <FileUpload
                inputLabel={t('products.form.productImage')}
                accept=".jpg, .jpeg"
                validationError={errors.imageFile?.message}
                fileName={fileName}
                setFileName={setFileName}
                ref={inputRef}
                onChange={onChange}
                editing={!!productDetail?.id}
              />
            )}
          />
        </Card>
        <Card className={cardStyle}>
          <Controller
            control={control}
            defaultValue={defaultDescription}
            name="description"
            render={() => (
              <RichTextEditor
                richText={defaultDescription}
                label={t('products.form.description')}
                caption={maxTextAreaLongError}
                onChangeHandler={handleDescriptionRichTextChange}
                error={!isRichTextLengthValid(descriptionRichTextLength)}
              />
            )}
          />
        </Card>
        <Card className={cardStyle}>
          <Controller
            control={control}
            defaultValue={productDetail?.suitableFor || ''}
            name="suitableFor"
            render={(props, fieldState) => (
              <Textarea
                className={inputStyle}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                maxLength={3000}
                label={t('products.form.suitableFor')}
                {...props}
                caption={maxTextAreaLongError}
              />
            )}
          />
        </Card>
        <Card className={cardStyle}>
          <Controller
            control={control}
            defaultValue={productDetail?.howToUse || ''}
            name="howToUse"
            render={(props, fieldState) => (
              <Textarea
                className={inputStyle}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                maxLength={3000}
                label={t('products.form.howToUse')}
                {...props}
                caption={maxTextAreaLongError}
              />
            )}
          />
        </Card>
        <Card className={cardStyle}>
          <Controller
            control={control}
            defaultValue={productDetail?.ingredients || ''}
            name="ingredients"
            render={(props, fieldState) => (
              <Textarea
                className={inputStyle}
                variant={fieldState.invalid ? 'negative' : 'accent'}
                maxLength={3000}
                label={t('products.form.ingredients')}
                {...props}
                caption={maxTextAreaLongError}
              />
            )}
          />
        </Card>
        <Card className={cardStyle}>
          <Controller
            control={control}
            defaultValue={defaultWarnings}
            name="warnings"
            render={() => (
              <RichTextEditor
                richText={defaultWarnings}
                label={t('products.form.warnings')}
                caption={maxTextAreaLongError}
                onChangeHandler={handleWarningsRichTextChange}
                error={!isRichTextLengthValid(warningsRichTextLength)}
              />
            )}
          />
        </Card>
        <div className={styles['product-form__button-container']}>
          {productId !== 'edit' && (
            <Button
              testId="product-form-delete"
              className={styles['product-form__delete-button']}
              appearance="solid"
              variant="negative"
              label={t('products.form.deleteProduct')}
              onClick={() =>
                open(
                  <DeleteProductModal
                    productId={productId}
                    productName={productDetail?.title}
                    deleteProduct={deleteProduct}
                  />,
                )
              }
            />
          )}
        </div>

        <div className={styles['product-form__footer']}>
          {formState.isDirty && (
            <Heading
              tag="h6"
              size="xs"
              className={styles['product-form__unsaved-changes']}
            >
              {t('common.form.unsavedChanges')}
            </Heading>
          )}

          <div className={styles['product-form__actions-container']}>
            <Button
              className={styles['product-form__action-button']}
              label={t('common.form.discard')}
              type="button"
              variant="negative"
              appearance="flat"
              onClick={() => {
                history.push(routes.PRODUCTS.BASE);
              }}
            />

            <Button
              testId="submit-button"
              className={styles['product-form__action-button']}
              label={t('common.form.save')}
              type="submit"
              disabled={
                !formState.isDirty ||
                !isRichTextLengthValid(warningsRichTextLength, 3000) ||
                !isRichTextLengthValid(descriptionRichTextLength, 3000)
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
};
