export const useAuth = (): {
  getAuthStatus: Function;
  isAuthenticated: Function;
} => {
  const getAuthStatus = () => window.sessionStorage.getItem('Authenticated');
  const isAuthenticated = () => getAuthStatus() === 'true';

  return {
    getAuthStatus,
    isAuthenticated,
  };
};
